import React, { Component } from "react";

export default class UI07 extends Component {
	render() {
		return (
			<div className="flex flex-row justify-between  self-stretch debug-border sm:mt-2 sm:pb-1 min-w-0 py-20 ">
				<div className="flex flex-row items-center self-stretch flex-shrink flex-grow min-w-0">
					{/* <div id={index} className="font-Inter font-semibold  text-base tracking-tight text-left ">
												{index + 1}
											</div> */}
					<div  className="font-Inter font-semibold  text-base tracking-tight  truncate ">
                        Addition,Subtraction,Multiplication & Division

					</div>

                    <div  className="self-stretch bg-indigo-700 text-base w-20 flex-shrink-0 ">
                       

					</div>
				</div>
			</div>
		);
	}
}
