// based on https://github.com/joshwnj/react-visibility-sensor/issues/118
// by https://github.com/carloquilala

import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";



const lists = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20
];

export default class UI06 extends React.Component {
  constructor(props) {
    super(props);

    this.stepElementRef = React.createRef();

    this.state = {
      // getElement: null,
      stepElementRef: null,
    };
  }

  componentDidMount() {
    this.setState(() => {
      return {
        // getElement: document.getElementById("sample"),
        stepElementRef: this.stepElementRef.current
      };
    });
  }

  render() {
    // var containmentDOMRect = this.state.getElement
    //   ? this.state.getElement
    //   : null;
      const {stepElementRef} = this.state;

    return (
      <div >
        <p>
          Demo: Scrolling through a list, and activating items when they become
          visible
        </p>
        {stepElementRef ? "DOLU" : "elementRef" }
        <div
          id="sample"
          ref={this.stepElementRef}
          style={{ height: 500, maxHeight: 500, overflowY: "scroll" }}
        >
          {lists.map(list => {
            return stepElementRef ? (
              // <VisibilitySensor key={list} containment={stepElementRef}>
              //      {({isVisible}) =>
              //         // <div>I am {isVisible ? 'visible' : 'invisible'}</div>
              //         <StepComponent isVisible={isVisible} index={list} />
              //       }
                  
                

                 

              // </VisibilitySensor>
              ''
            ) : null;
          })}
        </div>
      </div>
    );
  }
}



export class StepComponent extends Component {
  constructor(props) {
    super(props);

    // this.stepElementRef = React.createRef();

    this.state = {
      // getElement: null,
      // stepElementRef: null,
    };
  }

  componentDidMount() {
    this.setState(() => {
      return {
        // getElement: document.getElementById("sample"),
        // stepElementRef: this.stepElementRef.current
      };
    });
  }


  render() {
    const {isVisible,index} = this.props;
    return (
      <div
        style={{
          height: 100,
          lineHeight: "100px",
          color: "white",
          backgroundColor: isVisible ? "#593" : "#F33",
          margin: 5
        }}
      >
        I am #{index}
      </div>
    )
  }
}
