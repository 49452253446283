import React, { Component, Fragment } from "react";
import { gsap } from "gsap";

import styled from "styled-components";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import ProgressRing from "./ProgressRing";
gsap.registerPlugin(ScrollTrigger);

// import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
// import { DrawSVGPlugin } from "gsap/DrawSVGPlugin.js";
// import ProgressRing from './ProgressRing';
// gsap.registerPlugin(DrawSVGPlugin);



// background-color: lightgray;
const MarkdownDiv = styled.div`
background-color: lightgray;
	font-size: 0.9rem;
    flex-grow: 1;
	padding: 1rem;
	`
	

export default class UI05 extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
		  progress: 0
		};
	  }


	
	componentDidMount() {
		// var timeline =  new TimelineMax();
		// timeline_rectangle.to(this.cube01, 2, {x: "-=107", y: "+=63", ease:'Power2.easeInOut'});

		// TimelineMax.fromTo([element1, element2], 1, {x:0}, {x:100});
		// gsap.to('.rec', {x:250, duration: 5});
		// let tween = gsap.fromTo(".rec", { x: 0, scaleY: 0.5, scaleX: 0.5 }, { x: "+=200", y: "+=100", scaleY: 1, scaleX: 1, ease: "power.out", duration: "1" });
		// let tween2 = gsap.fromTo(".rec2", { x: 0 }, { x: "+=300", y: "+=0", ease: "power.out", duration: "1" });

		// tween.delay(2);
		// tween.play();
		// tween2.delay(3);
		// tween2.play();

		// gsap.to(`.two-one`, {
		// 	x: 500,
		// 	duration: 2,
		// 	scrollTrigger: {
		// 		trigger: `.two-one`,
		// 		start: "top center",
		// 		markers: true,
		// 		toggleActions: "play pause reverse none",
		// 	},
		// });




		// gsap.set('.data', {rotation:-90, transformOrigin:'center', drawSVG:'0'})
		// var action = gsap.timeline({
		// 	defaults:{duration:2, ease:'none'},
		// 	scrollTrigger: {
		// 	trigger: "one",
		// 	pin:true,
		// 	scrub:true,
		// 	start: "top -1px",
		// 	// end: "+=1000",
		// 	markers:true,
		// 	id:"oneid",
		// 	onUpdate: self => {
		// 		var no = self.progress.toFixed(2) * 100
		// 		gsap.set(".text", {textContent:no})
		// 		//gsap.set(".text", {textContent:no * 100 + '%'})
		// 	}
		// 	}
		// })
		// .set('.text', {autoAlpha:1})
		// .to('.data', {drawSVG:'100%', duration:3, ease:'none'})

		// gsap.set('svg', {autoAlpha:1})

		// gsap.timeline({
		// 	scrollTrigger: {
		// 	  trigger: '.pro',
		// 	  start: 'top top',
		// 	  end: '+=1000px',
		// 	  pin: true,
		// 	  scrub: true,
		// 	  markers: true
		// 	},
		// 	defaults:{duration:1, ease:'none'}
		//   })
		//   .set('.circ', {rotation:-90, transformOrigin:'center', drawSVG:'0'})
		//   .to('.data01', {drawSVG:'75%', duration:3, ease:'none'})


		// gsap.timeline({
		// 	scrollTrigger: {
		// 	  trigger: 'progressring',
		// 	  start: 'top center',
		// 	  end: 'center 30%',
		// 	//   end: 'bottom 70%',
		// 	  pin: true,
		// 	  scrub: true,
		// 	  markers: true
		// 	},
		// 	defaults:{duration:1, ease:'none'}
		//   })

		// gsap.to(
		// 	'.progressring',
		// 	{progress :75,
		// 	duration:1 
		// 	}
		// 	{
		// 	scrollTrigger: {
		// 	  trigger: 'progressring',
		// 	  start: 'top center',
		// 	  end: 'center 30%',
		// 	//   end: 'bottom 70%',
		// 	  pin: true,
		// 	  scrub: true,
		// 	  markers: true
		// 	},
		// 	defaults:{duration:1, ease:'none'}
		//   })


		gsap.to(`.box`, {
			x: 500,
			duration: 2,
			scrollTrigger: {
				trigger: `.progressring`,
				start: "top 30%",
				markers: true,
				toggleActions: "play pause reverse none",
				onUpdate: self => {
					var no = self.progress.toFixed(2) * 100
					gsap.set(".text", {textContent:no})
					//gsap.set(".text", {textContent:no * 100 + '%'})
					console.log('onUpdate :', no);
					this.setState((prevState, props) => ({
						...prevState,
						progress: no,
					}));
				  }
			},
		});

	}

	render() {
		
		return (
			<Fragment>



{/* 
<svg
   class="progress-ring"
   width="120"
   height="120">
  <circle
    class="progress-ring__circle"
    stroke="white"
    strokeWidth="4"
    fill="transparent"
    r="52"
    cx="60"
    cy="60"/>
</svg> */}


				{/* <MarkdownDiv>

				</MarkdownDiv> */}


				<div className="one bg-bluegray-200 flex flex-col items-start self-stretch " style={{height:"10000px"}}>

	
				<div className=" debug-border mt-40 h-96 self-stretch">one</div>
				<div className=" debug-border h-96 self-stretch">two</div>
				<div className=" debug-border h-96 self-stretch">three
							<ProgressRing
									className="progressring mt-20" style={{width:"120px", height:"120px"}} 
									radius={ 60 }
									stroke={ 4 }
									progress={ this.state.progress }/>

<div className="box bg-purple-500 h-20 w-20"></div>
<svg id="progress" viewBox="0 0 300 200" xlink="http://www.w3.org/1999/xlink">

  <circle class="data" cx="150" cy="55" r="40" stroke="green" />

  <text class="text" y="62"  x="150" text-anchor="middle" >xx</text>
</svg>

							</div>
				<div className="mt-10 bg-purple-500 h-20 w-20"></div>

					{/* <div className="one-one bg-purple-500 h-20 w-20"></div> */}

					{/* <svg id="progress debug-border" viewBox="0 0 300 200" xlink="http://www.w3.org/1999/xlink" style={{position:"fixed",top:"20px", right:"50px",width:"350px"}}>
					<circle class="data" cx="150" cy="55" r="20" stroke="green" style={{strokeWidth:"7", fill:"none", top:"100px"}}/>
					<text class="text" y="62" x="150" text-anchor="middle" style={{fontWeight:"bolder"}}>
						xx
					</text>
					</svg> */}

					{/* <svg  className="pro" viewBox="0 0 300 200" style={{visibility:"hidden"}}>
					<circle className="circ data02" cx="150" cy="55" r="40" stroke="indigo" style={{strokeWidth:"7",fill:"none",}} />
					<circle className="circ data01" cx="150" cy="55" r="40" stroke="green" tyle={{strokeWidth:"7",fill:"none",}}/>
					</svg> */}



<div className="two bg-bluegray-400 h-72">
										
					
				</div>




				</div>
				{/* <div className="two bg-bluegray-200 h-screen">
					<div className="two-one mt-10 bg-purple-500 h-20 w-20"></div>
				</div>
				<div className="three bg-bluegray-400 h-screen"></div> */}
			</Fragment>
		);
	}
}
