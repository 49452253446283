import React, { Component } from 'react'

export default class PortalMiddleHeader extends Component {
    render() {
        return (
            <div className="sticky top-12 container " style={{ backgroundColor: "#edf2f7" }}>
                PortalMiddleHeader
            </div>
        )
    }
}
