import React, { Component,Fragment } from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import { BrowserRouter as Router, Switch, Route, Link, NavLink, withRouter, useParams } from "react-router-dom";
import * as Yup from "yup";
import APPCONFIG, { AppConfig } from "./../../../utilities/AppConfig";
import axios from "axios";
import { Auth } from "aws-amplify";
const _ = require("lodash");


const schema = Yup.object().shape({
	category: Yup.string().min(5, "Category must be 3 characters at minimum!").max(30, "Category can not be longer than 30 characters!").required("Category is required!"),
	title: Yup.string().min(5, "Title must be 3 characters at minimum!").max(125, "Title can not be longer than 125 characters!").required("Title is required!"),
	description: Yup.string().min(5, "description must be 3 characters at minimum!").max(5000, "Description can not be longer than 5000 characters!").required("Description is required!"),
});



class WDetailsComponent extends Component {
    constructor(props) {
		super(props);
		this.state = {
			category: "",
			title: "",
			description: "",
			isSavingWalkthu: false,
			errors: "",
		};
	}

    saveDetails = async ({ setSubmitting }) => {
        const { walkbo: { WMetadata: { title = "", originalcreator = "", description = "", tmedium = "", tstandard = "" } = {}, WMetadata, WSteps: steps } = {} } = this.props;
		const { category : statecategory, title : statetitle, description :statedescription } = this.state;

        var walkboclone = _.cloneDeep(this.props.walkbo);
        walkboclone.WMetadata.title = statetitle;
        walkboclone.WMetadata.description = statedescription;


		this.setState((prevState, props) => ({
			...prevState,
			isSavingWalkthu: true,
		}));

		const {
			idToken: { jwtToken },
		} = await Auth.currentSession();

		axios({
			method: "post",
			baseURL: AppConfig.ApiWalkthruBaseUrl,
			url: "walkthru",
			// params: {
			// 	videoid: '' + YoutubeAddress.trim(),
			// },
			data: {
				 ...walkboclone 
            },
			headers: {
				authorization: `${jwtToken}`,
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		})
			// .then(ResponseDelayer(1000))
			.then((response) => {
				console.log("saving success response.data", JSON.stringify(response.data, null, 2));
				this.setState((prevState, props) => ({
					...prevState,
					isSavingWalkthu: false,
				}));
				// this.props.history.push({
				//     pathname: "/portal/walkthrus/" + walkthru.metadata.id ,
				//     routerpayload : walkthru   //response.data // { routerdata : JSON.stringify(result.data, null, 2) }
				//   });
			})
			.catch((error) => {
				console.log("saving error response.data", JSON.stringify(error, null, 2));
				this.setState((prevState, props) => ({
					...prevState,
					isSavingWalkthu: false,
				}));
			})
			.then(() => {
				// always executed
				console.log("saving >> Finally <<");
			});

    }


    render() {
        const { walkbo: { WMetadata: { title = "", originalcreator = "", description = "", tmedium = "", tstandard = "" } = {}, WMetadata, WSteps: steps } = {} } = this.props;
        const { setActiveTab } = this.props;
        const { path, url } = this.props.match;
        const { isSavingWalkthu} = this.state;
        return (
			<Fragment>
                <section className="container flex flex-col items-center self-stretch flex-grow pt-2" style={{ backgroundColor: "#fff" }}>

                <Formik
								initialValues={{ category: "Online Courses", title: title, description: description}}
								validationSchema={schema}
								isInitialValid="false"
								// validator={() => ({})}
								onSubmit={(values, { setSubmitting }) => {
									console.log("onSubmit: ", JSON.stringify(values, null, 2));

									this.setState((prevState, props) => ({
										...prevState,
										category: values.category,
										title: values.title,
										description: values.description,
									}));

									this.saveDetails({ setSubmitting });
									// setSubmitting(false);
								}}
							>
								{({ touched, errors, isSubmitting, values, isValid, dirty }) => (
									<Form className="self-stretch flex flex-col justify-between items-center h-full flex-wrap">
										<div className="self-stretch flex flex-col items-center flex-wrap ">
											{/* HEADER SECTION */}
											<div className=" border-b border-gray-200 flex flex-row items-center self-stretch flex-wrap md:justify-between ">
												{/* <div  className="self-stretch  flex-c  pt-4 pl-4 pb-4 "> */}
												<div className="font-inter text-base leading-8 font-bold tracking-tight text-bluegray-600 self-stretch  w-full p-3 space-x-2  flex flex-row items-center pb-6 flex-wrap ">
													<div className="text-bluegray-400  font-semibold ">Walkthru</div>
													<svg className="h-5 w-5 text-bluegray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
													</svg>
													<div className="">Edit Details</div>
												</div>
												{/* </div> */}

												{/* <span className="font-inter text-lg leading-8 font-semibold tracking-tight text-indigo-500 self-stretch  w-full p-3 md:w-1/2 xl:w-1/2 r">Import Youtube Chapters</span> */}
											</div>

											{/* CONTENT SECTION */}
											<div className="w-full  flex flex-row items-center self-stretch flex-wrap ">
												<div className="self-stretch flex flex-col items-center border border-solid border-gray-200 w-full  md:w-1/4 ">
													<div className="m-2  flex items-center self-stretch flex-row md:flex-row-reverse ">
														<div className="mr-2 py-2 border-gray-300 font-semibold text-sm text-bluegray-600 ">Category: *</div>
													</div>
												</div>
												<div className="self-stretch flex flex-col items-center  border border-solid border-gray-200 w-full  md:w-2/4">
													<div className="border-gray-300 font-bold p-2  flex flex-row items-center self-stretch ">
														<Field
                                                            disabled
															name="category"
															placeholder=""
															autoComplete="off"
															className={`bg-white text-sm appearance-none border-2 border-white rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                                            ${touched.category && errors.category ? "border-Theme05-500 " : ""}`}
														/>
														<ErrorMessage component="div" name="category" className=" text-Theme05-500  text-xs tracking-wide ml-2" />
													</div>
												</div>
												<div className=" self-stretch flex flex-col items-center border border-solid border-gray-200 w-full  md:w-1/4">
													<div className="m-2 flex-r">
														<div className=" border-gray-200 font-light text-xs italic">
															Please provide a category  <br />
                                                            This selection is currently non editable
															
														</div>
													</div>
												</div>
											</div>
											<div className="w-full  flex flex-row items-center self-stretch flex-wrap ">
												<div className="self-stretch flex flex-col items-center border border-solid border-gray-200 w-full  md:w-1/4 ">
													<div className="m-2  flex items-center self-stretch flex-row md:flex-row-reverse ">
														<div className="mr-2 py-2 border-gray-300 font-semibold text-sm text-bluegray-600 ">Title: *</div>
													</div>
												</div>
												<div className="self-stretch flex flex-col items-center  border border-solid border-gray-200 w-full  md:w-2/4">
													<div className="border-gray-300 font-bold p-2  flex flex-row items-center self-stretch ">
														<Field
															name="title"
															placeholder=""
															autoComplete="off"
															className={`bg-gray-200 text-sm appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                                            ${touched.title && errors.title ? "border-Theme05-500 " : ""}`}
														/>
														<ErrorMessage component="div" name="title" className=" text-Theme05-500  text-xs tracking-wide ml-2" />
													</div>
												</div>
												<div className=" self-stretch flex flex-col items-center border border-solid border-gray-200 w-full  md:w-1/4">
													<div className="m-2 flex-r">
														<div className=" border-gray-200 font-light text-xs italic">
															Please provide a title <br />
															Must be at most 125 characters long
														</div>
													</div>
												</div>
											</div>
											<div className="w-full  flex flex-row items-center self-stretch flex-wrap ">
												<div className="self-stretch flex flex-col items-center border border-solid border-gray-200 w-full  md:w-1/4 ">
													<div className="m-2  flex items-center self-stretch flex-row md:flex-row-reverse ">
														<div className="mr-2 py-2 border-gray-300 font-semibold text-sm text-bluegray-600 ">Description: *</div>
													</div>
												</div>
												<div className="self-stretch flex flex-col items-center  border border-solid border-gray-200 w-full  md:w-2/4">
                                                    <div className="m-2 self-stretch ">
                                                        <div className="border-gray-300 font-bold self-stretch ">
                                                            <Field
                                                                name="description"
                                                                placeholder=""
                                                                component="textarea"
                                                                autoComplete="off" 
                                                                className={`h-96 bg-gray-200 text-sm appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                                                                ${touched.description && errors.description ? "border-Theme05-500 " : ""
                                                                }`}
                                                            />
                                                            <ErrorMessage
                                                                component="div"
                                                                name="description"
                                                                className=" text-Theme05-500  text-xs tracking-wide ml-2"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
												<div className=" self-stretch flex flex-col items-center border border-solid border-gray-200 w-full  md:w-1/4">
													<div className="m-2 flex-r">
														<div className=" border-gray-200 font-light text-xs italic">
															Please provide a description<br />
                                                            Must be at most 1500 characters long
															
														</div>
													</div>
												</div>
											</div>

											{/* BUTTONS SECTION */}
											<div className="px-4 py-4 w-full flex flex-row justify-between content-center">
												<span
													className="sm:block rounded-md mr-4 mt-2"
												>
													<button onClick={() => setActiveTab(0)}   type="button" className="font-inter  shadow-sm inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-bold rounded-md text-gray-700 bg-white hover:bg-gray-200 focus:outline-none focus:shadow-outline focus:border-indigo-400 transition duration-150 ease-in-out">
                                                        Cancel
													</button>
												</span>
												{/* <svg className="ml-2  h-3 w-3 text-gray-200" fill="currentColor" viewBox="0 0 512.005 512.005">
                                    <path d="M388.418 240.923L153.751 6.256c-8.341-8.341-21.824-8.341-30.165 0s-8.341 21.824 0 30.165L343.17 256.005 123.586 475.589c-8.341 8.341-8.341 21.824 0 30.165 4.16 4.16 9.621 6.251 15.083 6.251 5.461 0 10.923-2.091 15.083-6.251l234.667-234.667c8.34-8.34 8.34-21.823-.001-30.164z"/>
                                </svg> */}

												<button
													type="submit"
													disabled={!isValid || isSubmitting}
													//  (!isValid || dirty || isSubmitting)
													className={`mr-4 mt-2 font-Inter shadow-sm inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md  hover:bg-blue-500 focus:outline-none focus:shadow-outline focus:border-indigo-400 transition duration-150 ease-in-out ${!isValid || isSubmitting ? "opacity-75 pointer-events-none bg-indigo-500 text-gray-100" : "bg-indigo-500 text-gray-100"}`}
												>
													{isSavingWalkthu && (
														<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
															<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
															<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
														</svg>
													)}{" "}
													Save
												</button>

												{/* {isValid ? 'isvalid' : 'not isvalid'} / {dirty ? 'is dirty' : ' is not dirty'} / {touched.length ? 'is touched' : ' is not touched'} */}
											</div>
										</div>

										{/* <div className="mt-8 mb-2 p-2 border border-solid border-gray-400">
											<strong>Injected Formik props (the form's state)</strong>
											<div>
												<code>errors:</code> {JSON.stringify(errors, null, 2)}
											</div>
											<div>
												<code>values:</code> {JSON.stringify(values, null, 2)}
											</div>
											<div>
												<code>isValid:</code> {JSON.stringify(isValid, null, 2)}
											</div>
											<div>
												<code>dirty:</code> {JSON.stringify(dirty, null, 2)}
											</div>
											<div>
												<code>values.youtubeaddress:</code> {JSON.stringify(values.youtubeaddress, null, 2)}
											</div>
											<div>
												<code>isSubmitting:</code> {JSON.stringify(isSubmitting, null, 2)}
											</div>
										</div> */}
									</Form>
								)}
							</Formik>
	
                </section>
			</Fragment>

        )
    }
}


export default withRouter(WDetailsComponent);