import React, { Component, Fragment } from 'react'

import { BrowserRouter as Router, Switch, Route, Link as RouterLink, NavLink, withRouter, useParams } from "react-router-dom";
import ReactModal from "react-modal";

import { Auth } from "aws-amplify";
import axios from "axios";

import PortalSideBar from './PortalSideBar';
import PortalMiddleHeader from './PortalMiddleHeader';
import { AppConfig } from './../../../../utilities/AppConfig';
import PortalTopHeader from './PortalTopHeader';
import PortalContentComponent from './PortalContentComponent';

class PortalComponent extends Component {
    constructor(props) {
		super(props);

		this.portalContentComponentRef = null;

		this.state = {


			isWalkthruDetailsLoading: false,
			// previously named walkbo
			walkthruObj: {
				WMetadata: {
					title: "",
					originalcreator: "",
					description: "",
					privacyStatus: "",
					embeddable: "",
					tdefault: {},
					tmedium: {},
					thigh: {},
					tstandard: {},
					tmaxres: {},
				},
				WSummary: {},
				WSteps: [],
			},


		}




    }

	setPortalContentComponentRef = (_portalContentComponentRef) => {
		this.portalContentComponentRef = _portalContentComponentRef;
	};

    componentDidMount() {
		this.setupScreen();
    }

    setupScreen(){

		// ***************  For react modal    ****************
		ReactModal.setAppElement("body");
		// ****************************************************

		// const { routerpayload, state } = this.props.location; // (this.props && this.props.location) || {};

		// let state = {  walkthruid : "qTFNXyaCp"};
		// if (state) {
		// 	const { walkthruid } = JSON.parse(state);
		// 	if (walkthruid) {
		// 		console.log("showDetails of walkthruid >>>>>>>>>>>>> ", walkthruid);
		// 		this.fetchWalkthruDetails(walkthruid);
		// 		return;
		// 	}
		// }

		this.fetchWalkthruDetails("qTFNXyaCp");
    
    }

    
	fetchWalkthruDetails = async (walkthruId) => {
		this.setState((prevState, props) => ({
			...prevState,
			isWalkthruDetailsLoading: true,
		}));

		const {
			idToken: { jwtToken },
		} = await Auth.currentSession();
		// console.log('idToken jwtToken ', JSON.stringify(jwtToken, null, 2));

		axios({
			method: "get",
			baseURL: AppConfig.ApiWalkthruBaseUrl,
			url: `walkthru/${walkthruId}`,
			// params: {
			// 	videoid: '' + YoutubeAddress.trim(),
			// },
			headers: {
				authorization: `${jwtToken}`,
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		})
			// .then(ResponseDelayer(1000))
			.then((response) => {
				const { data = [] } = response;

				// console.log("fetchWalkthruDetails success response.data[0]", JSON.stringify(data[0], null, 2));
				const { WMetadata: { title = "...", id = "..." } = {} ,WSteps: steps } = data[0];
				console.log("fetchWalkthruDetails success: ", id ,title , steps.length) ;

				if (data) {
					this.setState((prevState, props) => ({
						...prevState,
						walkthruObj: data[0],
						filteredwalkbo: data[0],
					}));
				}
				this.setState((prevState, props) => ({
					...prevState,
					isWalkthruDetailsLoading: false,
				}));

				if (this.stepsComponentRef)
					this.stepsComponentRef.updateDurationCalculation();
			})
			.catch((error) => {
				console.log("success response.data", JSON.stringify(error, null, 2));
				this.setState((prevState, props) => ({
					...prevState,
					isWalkthruDetailsLoading: false,
				}));
			})
			.then(() => {
				// always executed
				console.log(">> Finally <<");
			});
	};


	selectAndScrollStep = (index, steps) => {
		this.portalContentComponentRef.setSelectedStepAtPortalContentComponent(index);
		console.log("PortalComponent -> selectAndScrollStep", this.portalContentComponentRef);

		const newSteps = steps.map((step, _index) => {
			if (_index === index) {
				step.metadata.selected = true;
				console.log("found id ", step);
				console.log("found step ", step);
				return step;
			}
			step.metadata.selected = false;
			return step;
		});

		this.setState((prevState, props) => ({
			...prevState,
			filteredwalkbo: {
				...prevState.walkbo,
				WSteps: newSteps,
			},
			selectedStepOnSideBar: index,
			stepIndexBeingDeleted: index,
		}));
	};


    render() {
		const {  walkthruObj = {} } = this.state;
		


        return (
			<Fragment>
				<div className=" flex flex-col self-stretch flex-grow min-h-screen ">

					<PortalTopHeader />
                    <PortalMiddleHeader />

					
					{/* items-start is needed for the children to be sticky */}
					<div className="mx-auto container pt-0 flex flex-row self-stretch flex-grow  px-4 sm:px-6 items-start">

						
						
							{/* flex-shrink-0 flex-grow-0  => same as flex-none */}
							<div className="sticky top-20  h-screen w-1/4 flex flex-col items-center flex-none  ">
								{/* <div className="self-stretch  debug-border ">1</div> */}
								<div className="flex flex-col self-stretch items-center flex-grow d pb-40 ">
									<PortalSideBar
									
									walkthruObj={walkthruObj} 
									selectAndScrollStep={this.selectAndScrollStep}
									
									/>
								</div>
                                
							</div>

							{/* flex-shrink-0 flex-grow-0  => same as flex-none */}
							<div className="w-3/4 flex flex-col items-center flex-none  ">

								<PortalContentComponent
									ref={this.setPortalContentComponentRef}
									walkthruObj={walkthruObj}


								/>

							</div>

							{/* <div className="sticky top-0  pt-20 flex flex-col items-center self-stretch flex-grow  border-2 border-gray-600 space-y-2 p-4">

									{[...Array(100).keys()].map((item, key) => (
										<div className="p-20 bg-white self-stretch " key={item}>{key}</div>
									))}

							</div> */}

						
					
					
					
					</div>

				</div>
			</Fragment>
        )
    }
}


export default withRouter(PortalComponent);