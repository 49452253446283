import React, { Component, Fragment } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { Menu, MenuItem, MenuDivider, MenuHeader, MenuButton, SubMenu } from "@szhsin/react-menu";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs, dark, dracula, vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import TextareaAutosize from 'react-textarea-autosize';


import styled from "styled-components";

// background-color: lightgray;
const MarkdownDiv = styled.div`
	font-size: 0.9rem;
	flex-grow: 1;
	padding: 1rem;

	h1 {
		font-size: 1.6rem;
		line-height: 2rem;
		font-weight: 400;
	}
	h2 {
		font-size: 1.5rem;
		line-height: 1.8rem;
		font-weight: 500;
	}
	h3 {
		font-size: 1.3rem;
		line-height: 1.7rem;
		font-weight: 600;
	}
	h4 {
		font-size: 1.2rem;
		line-height: 1.7rem;
		font-weight: 700;
	}
	h5 {
		font-size: 1rem;
		line-height: 1.6rem;
		font-weight: 700;
	}
	h6 {
		font-size: 0.9rem;
		line-height: 1.5rem;
		font-weight: 700;
	}
`;

const markdown = `

# Customizing
## Customizing
### Customizing
#### Customizing
##### Customizing
###### Customizing


 









Changes are automatically rendered as you type.

* Implements [GitHub Flavored Markdown](https://github.github.com/gfm/)
* Renders actual, "native" React DOM elements
* Allows you to escape or skip HTML (try toggling the checkboxes above)
* If you escape or skip the HTML, no  is used! Yay!

###### Customizing
A paragraph with *emphasis* and **strong importance**.

> A block quote with ~strikethrough~ and a URL: https://reactjs.org.

* Lists
* [ ] todo
* [x] done

A table:

| a | b | a | b |
| - | - | - | - |
| - | - | deneme | - |
| - | - | deneme | - |
| - | - | deneme | - |


Here is some JavaScript code:

~~~js

// Create Resource /walkthru/{pk}
const walkthru_Id_Resource = walkthru_Resource.addResource("{wid}");
walkthru_Id_Resource.addMethod(
  "GET",
  new apigateway.LambdaIntegration(getOne_Walk_Function, {}),
  {
    authorizationType: apigateway.AuthorizationType.COGNITO,
    authorizer: {
      authorizerId: authorizer.ref,
    },
  }
);
~~~


`;
// Did you know you can use tildes instead of backticks for code in markdown? ✨
const codetext = `
(num) => num + 1

`;

const renderers = {
	code: ({ language, value }) => {
		return <SyntaxHighlighter style={vscDarkPlus} language={language} children={value} showLineNumbers={true}   />;
	},
};

export default class MarkdownComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			markdownvalue: markdown,
		};
	}

	handleEdit = (value) => {
		console.log("YoutubeComponent handleEdit  ", value);

		this.setState((prevState) => ({
			...prevState,
			markdownvalue : value,
		}));


	}

	handleExpand = (e) => {
		const { currentTarget: { dataset: { source } = {} } = {} } = e;
		var {
			card: {
				metadata: { id: cardid, size: cardsize },
			},
			stepmetadata: { id: stepid },
			stepindex,
			handleExpand,
		} = this.props;
		console.log("YoutubeComponent handleExpand  ", e);

		var requestedcardsize = 4;
		if (source == "expand") {
			requestedcardsize = cardsize + 2;
		} else if (source == "contract") {
			requestedcardsize = cardsize - 2;
		} else {
			requestedcardsize = e.value;
		}

		handleExpand({ stepindex: stepindex, cardid: cardid, cardsize: requestedcardsize });
		// handleExpand({ stepindex: stepindex, cardid: cardid, cardsize: e.value });
	};

	handleDeleteCard = () => {
		const {
			card: {
				metadata: { id: cardid },
			},
			stepmetadata: { id: stepid },
			stepindex,
			handleShowDeleteCardModal,
		} = this.props;
		handleShowDeleteCardModal({ stepindex: stepindex, cardid: cardid });
	};

	render() {
		const {
			stepindex,
			card: {
				metadata: { id: cardid, size: cardsize, title: cardtitle },
			},
			stepmetadata: { title: steptitle },
		} = this.props;

		const { isEditMode } = this.props;

		const { markdownvalue } = this.state;

		return (
			<div className="self-stretch flex flex-row items-center p-3">
				<div className="flex-grow self-stretch flex flex-col items-center  overflow-hidden bg-white">
					{/* Header */}
					<Fragment>
						<div className="self-stretch flex flex-row-reverse items-center flex-none min-w-0 ">
							<div className="flex-0 flex flex-row items-center self-stretch">
								{/* <div className="mr-2">
							<svg className="w-6 h-6 opacity-40  " fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
							<path d="M15 3h6v6M9 21H3v-6M21 3l-7 7M3 21l7-7"/>
							</svg>
</div>
<div className="">
							<svg className="w-6 h-6 opacity-40 " fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
							<path d="M15 3h6v6M9 21H3v-6M21 3l-7 7M3 21l7-7"/>
							</svg>
</div> */}

								{cardsize > 5 ? (
									<button onClick={this.handleExpand} data-source="contract" disabled={false} className=" disabled:opacity-30  focus:outline-none w-full inline-flex justify-center pl-3 px-2 py-1 font-medium  hover:bg-bluegray-50 hover:text-bluegray-100 sm:mt-0 sm:ml-0 sm:w-auto sm:text-sm">
										<span className="self-stretch flex flex-row items-center -ml-2">
											<svg className="h-5 w-5 fill-current text-bluegray-600" viewBox="0 0 20 20" fill="currentColor">
												<path fillRule="evenodd" d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z" clipRule="evenodd" />
											</svg>
										</span>
									</button>
								) : null}

								<button onClick={this.handleExpand} data-source="expand" disabled={false} className=" disabled:opacity-50  focus:outline-none w-full inline-flex justify-center  px-2 py-1 font-medium  hover:bg-bluegray-50 hover:text-bluegray-100 sm:mt-0 sm:ml-0 sm:w-auto sm:text-sm">
									<span className="self-stretch flex flex-row  items-center ">
										<svg className="h-5 w-5 fill-current text-bluegray-600" viewBox="0 0 20 20" fill="currentColor">
											<path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
										</svg>
									</span>
								</button>

								<Menu
									menuButton={
										<span className="group self-center  hover:text-black  group-hover:text-bluegray-600 focus:text-bluegray-600 focus:outline-none text-bluegray-500 font-Inter font-base text-sm  py-1 px-1 inline-flex items-center  cursor-pointer">
											{/* <svg className="h-6 w-6 p-0 mr-1 fill-current  text-bluegray-600   opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
																				</svg> */}

											{/* <svg className="mx-2 h-6  opacity-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
										</svg> */}

											<svg className="mx-2 h-6  opacity-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
											</svg>
										</span>

										// <svg className="h-6 w-6 p-0 mr-2 fill-current  text-bluegray-600   opacity-50" viewBox="0 0 16 16"><path d="M8 6.5A1.5 1.5 0 1 0 9.5 8 1.5 1.5 0 0 0 8 6.5zm0 5A1.5 1.5 0 1 0 9.5 13 1.5 1.5 0 0 0 8 11.47zm0-6.97A1.5 1.5 0 1 0 6.5 3 1.5 1.5 0 0 0 8 4.53z"></path></svg>
									}
								>
									{/* <MenuItem onClick={this.handleAddCard} value={cardid} >
																			<div className="self-stretch  flex flex-row items-center">
																				<svg className="-ml-4 mr-2 h-4 w-4 p-0  fill-current  text-bluegray-600   opacity-80" viewBox="0 0 461.001 461.001">
																					<path fill="#f61c0d" d="M365.257 67.393H95.744C42.866 67.393 0 110.259 0 163.137v134.728c0 52.878 42.866 95.744 95.744 95.744h269.513c52.878 0 95.744-42.866 95.744-95.744V163.137c0-52.878-42.866-95.744-95.744-95.744zm-64.751 169.663l-126.06 60.123c-3.359 1.602-7.239-.847-7.239-4.568V168.607c0-3.774 3.982-6.22 7.348-4.514l126.06 63.881c3.748 1.899 3.683 7.274-.109 9.082z" />
																				</svg>
																				<span className="text-base font-Inter font-medium">Edit</span>
																			</div>
																		</MenuItem>
																		<MenuDivider /> */}
									{/* <MenuHeader>Size</MenuHeader> */}
									{/* <MenuItem onClick={this.toggleModelAddAsset} value={cardid}>
																			<div className="self-stretch  flex flex-row items-center">
																				<svg className="-ml-4 mr-2 h-4 w-4 p-0  opacity-80" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" clipRule="evenodd" viewBox="0 0 512 512">
																					<g fillRule="nonzero">
																						<path fill="#1da1f2" d="M448 512H64c-35.328 0-64-28.672-64-64V64C0 28.672 28.672 0 64 0h384c35.328 0 64 28.672 64 64v384c0 35.328-28.672 64-64 64z"/>
																						<path fill="#fff" d="M196.608 386.048c120.704 0 186.752-100.096 186.752-186.752 0-2.816 0-5.632-.128-8.448 12.8-9.216 23.936-20.864 32.768-34.048-11.776 5.248-24.448 8.704-37.76 10.368 13.568-8.064 23.936-20.992 28.928-36.352-12.672 7.552-26.752 12.928-41.728 15.872-12.032-12.8-29.056-20.736-47.872-20.736-36.224 0-65.664 29.44-65.664 65.664 0 5.12.64 10.112 1.664 14.976-54.528-2.688-102.912-28.928-135.296-68.608-5.632 9.728-8.832 20.992-8.832 33.024 0 22.784 11.648 42.88 29.184 54.656a66.39 66.39 0 01-29.696-8.192v.896c0 31.744 22.656 58.368 52.608 64.384-5.504 1.536-11.264 2.304-17.28 2.304-4.224 0-8.32-.384-12.288-1.152 8.32 26.112 32.64 45.056 61.312 45.568-22.528 17.664-50.816 28.16-81.536 28.16-5.248 0-10.496-.256-15.616-.896 28.928 18.432 63.488 29.312 100.48 29.312"/>
																					</g>
																					</svg>
																				<span className="text-base font-Inter font-medium">Expand</span>
																			</div>
																		</MenuItem>


																			<MenuItem ><div className="flex-grow flex flex-row items-center self-stretch">

																			<div className="self-stretch  flex flex-row items-center flex-1 debug-border" onClick={this.toggleModelAddAsset} value={cardid}>
																				<svg className=" p-0  opacity-80" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect></svg>
																				</div>
																				<div className="self-stretch  flex flex-row items-center flex-1 debug-border" onClick={this.toggleModelAddAsset} value={cardid}>
																				<svg className=" p-0  opacity-80" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect></svg>
																				</div>
																				<div className="self-stretch  flex flex-row items-center flex-1 debug-border" onClick={this.toggleModelAddAsset} value={cardid}>
																				<svg className=" p-0  opacity-80" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" className="feather feather-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect></svg>
																				</div>
																				<div className="self-stretch  flex flex-row items-center flex-1 debug-border" onClick={this.toggleModelAddAsset} value={cardid}>
																				<svg className=" h-4  p-0  opacity-80" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect></svg>
																				</div>
																				</div>

																			</MenuItem> */}

									{isEditMode ? (
										<MenuItem onClick={this.handleEdit} value="2">
											<span className="text-sm font-Inter font-semibold">Edit</span>
										</MenuItem>
									) : null}

									{isEditMode ? (
										<MenuItem onClick={this.handleDeleteCard} value="">
											<span className="text-sm font-Inter font-semibold">Delete</span>
										</MenuItem>
									) : null}

									<SubMenu label={<span className="text-sm font-Inter font-semibold ">Card Size</span>}>
										<MenuItem onClick={this.handleOnClickFullscreen}>
											<span className="text-sm font-Inter font-semibold">Fullscreen</span>
										</MenuItem>
										<MenuItem onClick={this.handleExpand} value="12">
											<span className="text-sm font-Inter font-semibold">Full Width</span>
										</MenuItem>
										<MenuItem onClick={this.handleExpand} value="10">
											<span className="text-sm font-Inter font-semibold">XLarge</span>
										</MenuItem>
										<MenuItem onClick={this.handleExpand} value="8">
											<span className="text-sm font-Inter font-semibold">Large</span>
										</MenuItem>
										<MenuItem onClick={this.handleExpand} value="6">
											<span className="text-sm font-Inter font-semibold">Medium</span>
										</MenuItem>
										<MenuItem onClick={this.handleExpand} value="4">
											<span className="text-sm font-Inter font-semibold">Small</span>
										</MenuItem>
										{/* <MenuItem onClick={this.handleExpand} value="2">
										<span className="text-sm font-Inter font-semibold">Mini</span>
									</MenuItem> */}
									</SubMenu>

									{/* <MenuItem onClick={this.toggleModelAddAsset} value={cardid}>
																			<div className="self-stretch  flex flex-row items-center">
																			<svg  className="-ml-4 mr-2 h-4 w-4 p-0  opacity-80"   fill="none" viewBox="0 0 24 24" stroke="currentColor">
																			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
																			</svg>
																				<span className="text-base font-Inter font-medium">Collapse</span>
																			</div>
																		</MenuItem> */}
								</Menu>
							</div>

							<div className="self-stretch flex flex-row items-center flex-grow min-w-0">
								{/* <div className="self-stretch flex flex-row items-center flex-0">
								<svg className="ml-4 w-5 opacity-70 self-stretch " fill="currentColor" viewBox="0 -77 512.00213 512" xmlns="http://www.w3.org/2000/svg">
									<path
										d="m501.453125 56.09375c-5.902344-21.933594-23.195313-39.222656-45.125-45.128906-40.066406-10.964844-200.332031-10.964844-200.332031-10.964844s-160.261719 0-200.328125 10.546875c-21.507813 5.902344-39.222657 23.617187-45.125 45.546875-10.542969 40.0625-10.542969 123.148438-10.542969 123.148438s0 83.503906 10.542969 123.148437c5.90625 21.929687 23.195312 39.222656 45.128906 45.128906 40.484375 10.964844 200.328125 10.964844 200.328125 10.964844s160.261719 0 200.328125-10.546875c21.933594-5.902344 39.222656-23.195312 45.128906-45.125 10.542969-40.066406 10.542969-123.148438 10.542969-123.148438s.421875-83.507812-10.546875-123.570312zm0 0"
										fill="#f00"
									/>
									<path d="m204.96875 256 133.269531-76.757812-133.269531-76.757813zm0 0" fill="#fff" />
								</svg>
							</div> */}

								<div className="self-stretch font-Inter text-sm font-semibold text-bluegray-500 tracking-tight leading-tight   flex flex-row items-center ml-1 truncate"> {cardtitle} </div>
							</div>
						</div>
					</Fragment>

					<div className="flex flex-row items-center self-stretch flex-grow">
						<div className="max-h-96 flex-1 overflow-scroll flex flex-row items-center flex-grow self-stretch p-2  border border-gray-300 rounded-md shadow-md ">
							<TextareaAutosize
								className="p-1 h-full font-Inter text-sm  flex-grow self-stretch border border-gray-300 "

								value={markdownvalue}
								onChange={ev => this.handleEdit(ev.target.value)}
		
								/>
						</div>
						<div className="flex-1 max-h-96 flex-grow self-stretch p-2  border border-gray-300 rounded-md shadow-md overflow-scroll">
							<MarkdownDiv>
								{/* <ReactMarkdown># Hello, *world*! ## Heading 2</ReactMarkdown>
	  
	  
	                  <ReactMarkdown
	                  plugins={[gfm]}
	                  children={markdown}
	                  >
	                      
	                  </ReactMarkdown> */}
	
								<ReactMarkdown renderers={renderers} plugins={[gfm]} children={markdownvalue} />
							</MarkdownDiv>
	
							{/* 
	<span className="font-Inter text-xs font-semibold ml-1 debug-border -mb-2">xxs</span>
	<span className="font-Inter text-xs font-semibold ml-1 debug-border -mb-2">xxs</span>
	<SyntaxHighlighter className="pt-0" language="javascript" style={vs} children={codetext} showLineNumbers='true'>
	     {codeString}
	    </SyntaxHighlighter> */}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
