import React, { Component, Fragment, useState } from 'react';
import Xarrow from "react-xarrows";

// //{props: {line, setSelected, selected}}
// export default ({setSelected, selected, line: {props}}) => {
//   // console.log(sss)
//   const [state, setState] = useState({color: "coral"});
//   const defProps = {
//     // consoleWarning: false,
//     passProps: {
//       className: "xarrow",
//       onMouseEnter: () => setState({color: "IndianRed"}),
//       onMouseLeave: () => setState({color: "coral"}),
//       onClick: (e) => {
//         e.stopPropagation(); //so only the click event on the box will fire on not on the conainer itself
//         setSelected({id: {start: props.start, end: props.end}, type: "arrow"});
//       },
//       cursor: "pointer",
//     },
//   };


//   let color = state.color;
//   if (selected && selected.type === "arrow" && selected.id.start === props.start && selected.id.end === props.end)
//     color = "red";
//   return <Xarrow {...{...defProps, ...props, ...state, color}} />;
// };


// //{props: {line, setSelected, selected}}
export default class Arrow extends Component {
	constructor(props) {
		super(props);

		this.state = {
      color: "coral",
		};
  }


  
  componentDidMount() {
    const { selected, setSelected ,line} = this.props;

    if (selected && selected.type === "arrow" && selected.id.start === line.start && selected.id.end === line.end) {
      this.setState((prevState, props) => ({
				...prevState,
        color : "red",
			}));
    }

  }

  
  render() {
    const { color } = this.state;
    const { selected, setSelected ,line } = this.props;

    const passProps = {
      className: "xarrow",
      onMouseEnter: () => {
          this.setState((prevState, props) => ({
            ...prevState,
            color : "red",
          }));
      },
      onMouseLeave: () => {
          this.setState((prevState, props) => ({
            ...prevState,
            color : "coral",
          }));
      },
      onClick: (e) => {
        console.log("clicked");
        e.stopPropagation(); //so only the click event on the box will fire on not on the conainer itself
        setSelected({id: {start: line.start, end: line.end}, type: "arrow"});
      },
      cursor: "pointer",
    };


    return (<Fragment>
      <Xarrow  
      {...line.props} 
      {...passProps} 

      color={color}
      selected={selected} 
      setSelected={setSelected}
         />
    </Fragment>)  

  }
}



// export default class Arrow extends Component {
// 	constructor(props) {
// 		super(props);

// 		this.state = {
//       color: "coral",
// 		};
//   }
  




//   componentDidMount() {
//     const { selected, setSelected , line, start, end} = this.props;

//     if (selected && selected.type === "arrow" && selected.id.start === start && selected.id.end === end) {
//       this.setState((prevState, props) => ({
// 				...prevState,
//         color : "red",
// 			}));
//     }

//   }


//   render() {
//     const { color} = this.state;
//     const { selected, setSelected , line, start, end} = this.props;

//     const defProps = {
//       // consoleWarning: false,
//       passProps: {
//         className: "xarrow",
//         onMouseEnter: () => 
//         {
//           this.setState((prevState, props) => ({
//             ...prevState,
//             color : "IndianRed",
//           }));
//         },
//         onMouseLeave: () =>         {
//           this.setState((prevState, props) => ({
//             ...prevState,
//             color : "coral",
//           }));
//         },
//         onClick: (e) => {
//           e.stopPropagation(); //so only the click event on the box will fire on not on the conainer itself
//           setSelected({id: {start: this.props.start, end: this.props.end}, type: "arrow"});
//         },
//         cursor: "pointer",
//       },
//     };

//     return (
//       <Fragment>
//         <Xarrow {...{...defProps, ...this.props, color}} />
//       </Fragment>
//     )
//   }
// }




