import React, { Component, Fragment } from "react";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";
import { Waypoint } from "react-waypoint";
import CardComponent from './../../CardComponent';

export default class PortalContentComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedStepOnSideBar: -1,
		};
	}

	setSelectedStepAtPortalContentComponent(index) {
		console.log("PortalContentComponent setSelected index ", index);

		// this.setState((prevState, props) => ({
		// 	...prevState,
		// 	selectedStepOnSideBar: index + 1,
		// }));

		// Somewhere else, even another file
		scroller.scrollTo(index, {
			duration: 500,
			delay: 100,
			smooth: true,
			// containerId: "containerElementForScrollingItems",
			offset: -100, // Scrolls to element + 50 pixels down the page
		});
	}

	_handleWaypointEnter = (payload, index) => {
		// console.log('   ${previousPosition} ${currentPosition} ${event}', previousPosition,  currentPosition ,event   );
		// console.log('enter payload', JSON.stringify(payload,null,2)  );
		// console.log('enter index', index  );
		// this.setState((prevState, props) => ({
		//   ...prevState,
		//   selectedStepOnSideBar : index+1
		// }));
		// this.props.setStepProgressFromWaypoint(index);
	};

	render() {
		const { walkthruObj = {} } = this.props;
		const { walkthruObj: { WSteps: steps = [] } = {} } = this.props;

		return (
			<div className=" flex flex-col self-stretch items-center  flex-grow overflow-y-auto debug-border" id="containerElementForScrollingItems">
				{/* 
				{[...Array(120).keys()].map((item, index) => (
					<div className="flex flex-row items-center self-stretch  debug-border h-36 flex-shrink-0" key={index} name={index}>
						{index}
					</div>
				))} */}

				{steps.map(({ metadata: stepmetadata, metadata: { id: stepsid, completed: stepcompleted, selected: stepselected, title: steptitle }, cards }, index) => (
					<div className="flex flex-row items-start self-stretch  flex-shrink-0" key={index} name={index}>
						{/* TIMELINE */}
						<Fragment>
							<div className="pl-9 sm:pl-1 sm:ml-0 flex flex-col items-center self-stretch w-5">
								<Waypoint
									key={index}
									// debug={true}
									// onEnter={(p) => this._handleWaypointEnter(p, index)}
									onEnter={(p) => this._handleWaypointEnter(p, index)}
									// onLeave={this._handleWaypointLeave}
									fireOnRapidScroll={false}
								/>

								{/* LINE */}
								<Fragment>
									<div className="pb-0.5 h-8 flex flex-col align-middle items-center">
										<div className={`flex-grow  flex items-center ${stepcompleted ? "bg-green-400" : "bg-bluegray-500"} `} style={{ width: "0.175rem" }}>
											{" "}
										</div>
									</div>
								</Fragment>

								{/* YUVARLAK */}
								<Fragment>
									{stepcompleted ? (
										<div className="flex flex-row items-center justify-center shadow-sm h-4 w-4 bg-green-500 text-white rounded-full">
											<svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" strokeWidth="2">
												<polyline points="20 6 9 17 4 12"></polyline>
											</svg>
										</div>
									) : (
										<div className="-ml-2 sm:ml-0 w-4 h-4  border-2 border-bluegray-500 mx-auto rounded-full text-white flex flex-row  items-center">
											<span className="text-gray-700 text-center w-full font-inter font-semibold text-sm"></span>
										</div>
									)}
								</Fragment>

								{/* LINE */}
								<Fragment>
									<div className="pt-0.5 flex-grow flex flex-col align-middle items-center">
										<div className={` flex-grow  flex items-center  ${stepcompleted ? "bg-green-400" : "bg-bluegray-500"} `} style={{ width: "0.175rem" }}>
											{" "}
										</div>
									</div>
								</Fragment>
							</div>
						</Fragment>

                       
						<Fragment>
							<div className=" flex flex-col items-start self-stretch flex-grow pt-5 min-w-0">

                                {/* STEP TITLE & TOOLBAR */}
								<div className="flex flex-row justify-between  self-stretch border-b border-bluegray-300 pt-1 pl-3">
									<Fragment>
										<div id={index} className="font-Inter font-semibold  text-base tracking-tight truncate ">
											{index + 1} {steptitle}
										</div>
									</Fragment>
                                    <Fragment>
                                        Right
                                    </Fragment>
								</div> 

                                {/* CARDS SECTION */}
                                <Fragment>
                                <div className="  flex flex-row items-center self-stretch flex-wrap sm:ml-7 sm:mb-2 mr-2  ">
								{cards && cards.length}
								ss
										{cards && cards.map((card, cardindex) => <CardComponent {...this.props} key={cardindex} card={card} stepmetadata={stepmetadata} stepindex={index} />)}</div>
								
                                </Fragment>


							</div>
						</Fragment>


 



					</div>
				))}
			</div>
		);
	}
}
