import React, { Component, Fragment } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, NavLink, withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import ShowTheLocation from "utilities/ShowTheLocation";
import PortalHeader from "./PortalHeader";
import PortalContent from "./PortalContent";
import PortalContent2 from "./PortalContent2";
import PortalContent3 from "./PortalContent3";
import PortalContent4 from "./PortalContent4";
import Catalog from "./catalog/Catalog";
import NewWalkthruComponent from "./walkthrus/NewWalkthruComponent";
import PortalHeaderNextGen from './PortalHeaderNextGen';
import SubHeader from "./SubHeader";
import PortalComponent from "./layout/content/PortalComponent";

class Portal extends Component {
	render() {
		const { path, url } = this.props.match;
		return (
			<Fragment>
				<Switch>


				<Route exact path={`${path}/walkthrus/new`}>
						<div className="mx-auto px-2 container max-h-screen flex flex-col self-stretch font-Inter">
							<PortalHeader />
							<NewWalkthruComponent />
						</div>
					</Route>
					
					{/* <Route path={`${path}/environments/:environmentId`}> */}
					<Route path={`${path}/walkthrus/:walkthruId`}>


						

						<div className=" flex flex-col self-stretch h-screen font-Inter" style={{ backgroundColor: "#edf2f7" }}>
							
							
							<PortalHeaderNextGen/>
							<PortalContent4 />

							


						</div>

						{/* <PortalComponent/> */}
					</Route>





					<Route path={`${path}`}>
						<div className="flex flex-col self-stretch flex-grow h-screen font-Inter" style={{ backgroundColor: "#edf2f7" }}>
							<PortalHeaderNextGen/>
							<Catalog />
							{/* <NavLink to={`${path}/walkthrus`} style={{ textDecoration: "none" }} activeClassName="border-purple-500  text-gray-800  no-underline  " className="block pl-4 align-middle text-gray-500 font-semibold no-underline hover:text-purple-500 border-b-2 border-transparent leading-tight pt-2 font-inter text-xs  tracking-widest">
								<span className="pb-1 md:pb-0 text-sm">Catalog</span>
							</NavLink> */}
						</div>
					</Route>

					<Route exact path={`${path}/walkthrus`}>
						<div className="flex flex-col self-stretch h-screen font-Inter" style={{ backgroundColor: "#edf2f7" }}>
							<PortalHeaderNextGen/>
							<Catalog />
						</div>
					</Route>




				</Switch>

				{/* <PortalContent4 /> */}

				{/* <Catalog /> */}

				{/* <div className="mt-14  max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 container"> */}

				{/* <div className="mt-14  mx-auto "> */}
				{/* <section>content</section>
							<ShowTheLocation /> */}
				{/* <PortalContent /> */}
				{/* <PortalContent2 /> */}
				{/* <PortalContent3 /> */}

				{/* </div> */}
			</Fragment>
		);
	}
}

export default withRouter(Portal);
