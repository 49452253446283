import React, { Component, Fragment } from "react";
import { Menu } from "@szhsin/react-menu";
import { MenuItem } from "@szhsin/react-menu";
import CardComponent from "./../CardComponent";
import * as Scroll from "react-scroll";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";
import { Waypoint } from "react-waypoint";

export default class WStepsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedStepOnSideBar: -1,
			isEditStepModalOpen: false,
		};
	}

	componentDidMount() {}

	setSelected(index) {
		// alert('child set selected index: ');
		console.log("index ", index);

		this.setState((prevState, props) => ({
			...prevState,
			selectedStepOnSideBar: index + 1,
		}));

		// Somewhere else, even another file
		scroller.scrollTo(index, {
			duration: 500,
			delay: 100,
			smooth: true,
			containerId: "containerElementForScrollingItems",
			// offset: 50, // Scrolls to element + 50 pixels down the page
		});
	}

	_handleWaypointEnter = (payload, index) => {
		// console.log('   ${previousPosition} ${currentPosition} ${event}', previousPosition,  currentPosition ,event   );
		// console.log('enter payload', JSON.stringify(payload,null,2)  );
		// console.log('enter index', index  );
		// this.setState((prevState, props) => ({
		//   ...prevState,
		//   selectedStepOnSideBar : index+1
		// }));

		this.props.setStepProgressFromWaypoint(index);
	};
	_handleWaypointLeave = (payload) => {
		// console.log('   ${previousPosition} ${currentPosition} ${event}', previousPosition,  currentPosition ,event   );
		// console.log('leave payload', JSON.stringify(payload,null,2)  );
	};

	render() {
		const { steps = [], handleExpand, handleShowAddContentYoutubeModel, handleAddEditor, handleShowEditStepModal, handleShowDeleteStepModal, handleDeleteStep } = this.props;
		const { stepBeingEdited } = this.state;
		const { isEditMode } = this.props;
		return (
			<Fragment>
				{/* {selectedStepOnSideBar} */}

				<div className=" flex flex-col self-stretch items-center  flex-grow overflow-y-auto " id="containerElementForScrollingItems">
					{/* {[...Array(100).keys()].map((item, key) => (
									<div className="flex flex-row items-center self-stretch  debug-border h-36 flex-shrink-0" key={item}>
										{key}
									</div>
								))} */}

					{steps.map(({ metadata: stepmetadata, metadata: { id: stepsid, completed: stepcompleted, selected: stepselected, title: steptitle }, cards }, index) => (
						<div key={index} name={index} className="flex flex-row items-center self-stretch flex-grow flex-shrink  ">
							{/* TIMELINE */}
							<Fragment>
								<div className="pl-9 sm:pl-1 sm:ml-0   flex flex-col items-center self-stretch  w-5">
									<Waypoint
										key={index}
										// debug={true}
										// onEnter={(p) => this._handleWaypointEnter(p, index)}
										onEnter={(p) => this._handleWaypointEnter(p, index)}
										// onLeave={this._handleWaypointLeave}
										fireOnRapidScroll={false}
									/>

									{/* LINE */}
									<Fragment>
										<div className="pb-0.5 h-8 flex flex-col align-middle items-center">
											<div className={`flex-grow  flex items-center ${stepcompleted ? "bg-green-400" : "bg-bluegray-500"} `} style={{ width: "0.175rem" }}>
												{" "}
											</div>
										</div>
									</Fragment>

									{/* YUVARLAK */}
									<Fragment>
										{stepcompleted ? (
											<div className="flex flex-row items-center justify-center shadow-sm h-4 w-4 bg-green-500 text-white rounded-full">
												<svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" strokeWidth="2">
													<polyline points="20 6 9 17 4 12"></polyline>
												</svg>
											</div>
										) : (
											<div className="-ml-2 sm:ml-0 w-4 h-4  border-2 border-bluegray-500 mx-auto rounded-full text-white flex flex-row  items-center">
												<span className="text-gray-700 text-center w-full font-inter font-semibold text-sm"></span>
											</div>
										)}
									</Fragment>

									{/* LINE */}
									<Fragment>
										<div className="pt-0.5 flex-grow flex flex-col align-middle items-center">
											<div className={` flex-grow  flex items-center  ${stepcompleted ? "bg-green-400" : "bg-bluegray-500"} `} style={{ width: "0.175rem" }}>
												{" "}
											</div>
										</div>
									</Fragment>
								</div>
							</Fragment>

							<Fragment>
								<div className=" flex flex-col items-start self-stretch flex-grow pt-5 min-w-0">
									{/* STEP TOOLBAR */}
									<div className="flex flex-row justify-between  self-stretch border-b border-bluegray-300 sm:mt-2 sm:pb-1  min-w-0 ">
										<div className="flex flex-row items-center self-stretch flex-shrink flex-grow min-w-0 pl-3">
											<div id={index} className="font-Inter font-semibold  text-base tracking-tight  truncate ">
												{index + 1} {steptitle}
											</div>

											{/* <div className="flex flex-col items-center justify-center self-stretch bg-bluegray-400  text-white w-8">
																<span className="font-Inter font-semibold">{index + 1} </span>
															</div>

															<div className="flex flex-col self-stretch pl-3">
																<span id={index} className="font-Inter font-semibold  text-base tracking-tight text-left">
																	{steptitle}{" "}
																</span>

																<span className=" font-Inter  text-gray-600  text-xs tracking-tight ">
																	{" "}
																	Step {index + 1} of {steps.length}{" "}
																</span>
															</div> */}
										</div>

										{isEditMode ? 
										<Fragment>

										<div className="flex flex-row items-center self-stretch flex-shrink-0 flex-grow-0">
											<Menu
												menuButton={
													<button onClick={this.handleShowAddStepModal} disabled={false} className=" disabled:opacity-50  focus:outline-none w-full inline-flex justify-center  px-3 py-1 font-medium  hover:bg-bluegray-50 hover:text-bluegray-100 sm:mt-0 sm:ml-0 sm:w-auto sm:text-sm">
														<span className="self-stretch flex flex-row  items-center ">
															<svg className="h-5 w-5 fill-current text-purple-700" viewBox="0 0 20 20" fill="currentColor">
																<path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
															</svg>
														</span>

														<span className="self-stretch font-Inter font-base text-sm text-purple-700 ">Add Content</span>
													</button>
												}
												arrow={true}
											>
												<MenuItem onClick={handleShowAddContentYoutubeModel} value={index}>
													<div className="self-stretch  flex flex-row items-center">
														<span className="text-sm font-Inter font-medium">Youtube Video</span>
													</div>
												</MenuItem>
												<MenuItem onClick={handleAddEditor} value={index}>
													<div className="self-stretch  flex flex-row items-center">
														<span className="text-sm font-Inter font-medium">Markdown Text</span>
													</div>
												</MenuItem>
												<MenuItem disabled={true} >
													<div className="self-stretch  flex flex-row items-center">
														<span className="text-sm font-Inter font-medium">Question</span>
													</div>
												</MenuItem>

											</Menu>
											<div className="border-l border-bluegray-400  self-stretch mx-0"></div>

											<div className="flex flex-row items-center justify-center self-stretch   ">
												
												<Menu
													menuButton={
														// <span className="px-2 group self-stretch border-l border-bluegray-200  hover:bg-bluegray-100  group-hover:text-bluegray-600 focus:text-bluegray-600 focus:outline-none text-bluegray-500 font-Inter font-base text-sm  inline-flex items-center cursor-pointer">
														// 	<svg className="h-6 w-6 p-0 mr-1 fill-current  text-bluegray-600   opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
														// 		<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
														// 	</svg>
														// 	<span className="text-sm font-Inter font-medium">Add Card</span>
														// </span>
														// <svg className="mx-2 h-6 text-bluegray-600 opacity-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
														// 	<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
														// </svg>

														<svg className="mx-2 h-6  opacity-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
															<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
														</svg>
													}
													arrow={true}
												>
													<MenuItem onClick={handleShowEditStepModal} value={index}>
														<div className="self-stretch  flex flex-row items-center">
															<svg className="-ml-4 mr-2 h-4 w-4 p-0  fill-current  text-bluegray-600   opacity-80" viewBox="0 0 20 20" fill="currentColor">
																<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
															</svg>
															{/* <svg className="-ml-4 mr-2 h-4 w-4 p-0  fill-current  text-bluegray-600   opacity-80" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" ><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg> */}
															<span className="text-base font-Inter font-medium">Edit</span>
														</div>
													</MenuItem>
													<MenuItem onClick={handleShowDeleteStepModal} value={index}>
														<div className="self-stretch  flex flex-row items-center">
															<svg className="-ml-4 mr-2 h-4 w-4 p-0  fill-current  text-bluegray-600   opacity-80" viewBox="0 0 20 20" fill="currentColor">
																<path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
															</svg>
															{/* <svg className="-ml-4 mr-2 h-4 w-4 p-0  fill-current  text-bluegray-600   opacity-80" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" ><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg> */}
															<span className="text-base font-Inter font-medium">Delete</span>
														</div>
													</MenuItem>
												</Menu>

											</div>
										</div>
									
									
										</Fragment>
																						:
																						null
																						}

									</div>

									{/* CARDS SECTION */}
									<div className="  flex flex-row items-center self-stretch flex-wrap sm:ml-7 sm:mb-2 mr-2  ">
										{cards && cards.map((card, cardindex) => <CardComponent {...this.props} key={cardindex} card={card} stepmetadata={stepmetadata} stepindex={index} />)}</div>
								</div>
							</Fragment>
						</div>
					))}
				</div>
			</Fragment>
		);
	}
}
