import React, { Component, Fragment } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { BrowserRouter as Router, Switch, Route, Link, NavLink, withRouter, useParams } from "react-router-dom";
import * as Yup from "yup";
import ResponseDelayer from "./../../../utilities/ResponseDelayer";
import APPCONFIG, { AppConfig } from "./../../../utilities/AppConfig";
import axios from "axios";
import uuid from "react-uuid";
import { nanoid } from "nanoid";

import { sampletext5 } from "utilities/sampletext";

const _ = require("lodash");

const NewWalkthruSchema = Yup.object().shape({
	youtubeaddress: Yup.string().min(5, "Youtube address must be 3 characters at minimum!").max(100, "Youtube address can not be longer than 100 characters!").required("Youtube address is required!"),
});

class NewWalkthruComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			YoutubeAddress: "",
			isCreating: false,
			errors: "",
		};
	}

	createWalkthruHandler = ({ setSubmitting }) => {
		const { path, url } = this.props.match;
		const { YoutubeAddress } = this.state;

		console.log("createWalkthruHandler YoutubeAddress: ", YoutubeAddress);

		let dataToSend = {
			// address: "https://www.youtube.com/watch?v=3hLmDS179YE",
			// address: "https://www.youtube.com/watch?v=aoQ6S1a32j8",
			address: YoutubeAddress,
		};
		console.log("got result: ", JSON.stringify(dataToSend, null, 2));


		axios({
			method: "get",
			baseURL: AppConfig.ApiYoutubeBaseUrl,
			// url: '',
			params: {
				videoid: "" + YoutubeAddress.trim(),
			},
		})
			.then((response) => {
				console.log("success");
				// console.log( JSON.stringify(response.data, null, 2) );

				// this.setState((prevState, props) => ({
				//     ...prevState,
				// }));

				var walkthru = scaffoldWalkthru(response.data.items[0]);
				console.log(walkthru, JSON.stringify(walkthru, null, 2));
				// console.log( JSON.stringify(walkthru, null, 2) );
				// console.log( JSON.parse(walkthru) );

				setSubmitting(false);

				this.props.history.push({
					pathname: "/portal/walkthrus/" + walkthru.WMetadata.id,
					routerpayload: walkthru, //response.data // { routerdata : JSON.stringify(result.data, null, 2) }
				});
			})
			.catch((error) => {
				console.log("error");
				console.log(JSON.stringify(error, null, 2));

				// this.setState((prevState, props) => ({
				//     ...prevState,
				// }));

				setSubmitting(false);
			})
			.then(function () {
				// always executed
				console.log(">> Finally <<");
			});

		// axios
		// .post(`${APPCONFIG.ApiScrapper}`
		// , JSON.stringify(dataToSend)
		// , { headers: {"Authorization" : `Bearer ${IdToken}`, "Content-Type":"application/json"} })
		// // .then(ResponseDelayer(APPCONFIG.apiDelay))
		// .then(result => {
		//     console.log("got result: ", JSON.stringify(result.data, null, 2)  );
		//   this.setState((prevState, props) => ({
		//       ...prevState,
		//     }));

		//     setSubmitting(false);

		//     this.props.history.push({
		//         pathname: "/portal/walkthrus/" + uuid(),
		//         routerpayload : result.data // { routerdata : JSON.stringify(result.data, null, 2) }
		//       });

		// })
		// .catch(error => {
		//   console.error("Error: ", JSON.stringify(error, null, 2));
		//   this.setState((prevState, props) => ({
		//     ...prevState,
		//   }));

		//   setSubmitting(false);
		// });

		// this.props.history.push({
		//     pathname: `../`,
		//   });
	};

	btnHandlerImportMock = () => {
		console.log("btnHandlerImportMock");
		//  let mockdata = JSON.parse(sampletext5);
		//  let mockdata =  JSON.stringify(sampletext5);
		let mockdata = sampletext5;
		console.log("mockdata: ", mockdata);

		this.props.history.push({
			pathname: "/portal/walkthrus/" + uuid(),
			routerpayload: mockdata, // { routerdata : JSON.stringify(result.data, null, 2) }
		});
	};

	render() {
		const {} = this.state;
		const { path, url } = this.props.match;
		const chaptersDesc = `Youtube Chapters are sections that are defined in the video description as timestamps. To define a chapter, you need to have timestampts on seperate lines followed by the title of the chapters.
Each chapter will show up in the video progress bar (aka the “video scrubber bar”) below the videos.
Eg: A video with 4 chapters is defined like the following
00:00 Introduction
01:17 What are YouTube chapters?
02:28 This is how you add a chapter
17:28 This is how you to add another chapter`;
		return (
			<Fragment>
				<React.Fragment>
					<div className="self-stretch flex flex-row items-start flex-wrap  p-3  font-Inter text-sm text-bluegray-700">
						<div className="flex flex-col justify-center items-center bg-white  w-full md:w-3/4">
							<Formik
								initialValues={{ youtubeaddress: "aoQ6S1a32j8" }}
								validationSchema={NewWalkthruSchema}
								isInitialValid="false"
								// validator={() => ({})}
								onSubmit={(values, { setSubmitting }) => {
									console.log("onSubmit: ", JSON.stringify(values, null, 2));

									//   setTimeout(() => {
									//     console.log('onSubmit: ', JSON.stringify(values, null, 2));
									//     setSubmitting(false);
									//   }, 5000);

									this.setState((prevState, props) => ({
										...prevState,
										YoutubeAddress: values.youtubeaddress,
									}));

									this.createWalkthruHandler({ setSubmitting });
									// setSubmitting(false);
								}}
							>
								{({ touched, errors, isSubmitting, values, isValid, dirty }) => (
									<Form className="self-stretch flex flex-col justify-between items-center h-full flex-wrap">
										<div className="self-stretch flex flex-col items-center flex-wrap ">
											{/* HEADER SECTION */}
											<div className=" border-b border-gray-200 flex flex-row items-center self-stretch flex-wrap md:justify-between ">
												{/* <div  className="self-stretch  flex-c  pt-4 pl-4 pb-4 "> */}
												<div className="font-inter text-base leading-8 font-bold tracking-tight text-bluegray-600 self-stretch  w-full p-3 space-x-2  flex flex-row items-center pb-6 flex-wrap ">
													<div className="text-bluegray-400  font-semibold ">Create a New Walkthru</div>
													<svg className="h-5 w-5 text-bluegray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
													</svg>
													<div className="">Import Youtube Chapters</div>
												</div>
												{/* </div> */}

												{/* <span className="font-inter text-lg leading-8 font-semibold tracking-tight text-indigo-500 self-stretch  w-full p-3 md:w-1/2 xl:w-1/2 r">Import Youtube Chapters</span> */}
											</div>

											{/* CONTENT SECTION */}
											<div className="w-full  flex flex-row items-center self-stretch flex-wrap ">
												<div className="self-stretch flex flex-col items-center border border-solid border-gray-200 w-full  md:w-1/4 ">
													<div className="m-2  flex items-center self-stretch flex-row md:flex-row-reverse ">
														<div className="mr-2 py-2 border-gray-300 font-semibold text-sm text-bluegray-600 ">Youtube address: *</div>
													</div>
												</div>
												<div className="self-stretch flex flex-col items-center  border border-solid border-gray-200 w-full  md:w-2/4">
													<div className="border-gray-300 font-bold p-2  flex flex-row items-center self-stretch ">
														<Field
															name="youtubeaddress"
															placeholder=""
															autoComplete="off"
															className={`bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                                            ${touched.youtubeaddress && errors.youtubeaddress ? "border-Theme05-500 " : ""}`}
														/>
														<ErrorMessage component="div" name="youtubeaddress" className=" text-Theme05-500  text-xs tracking-wide ml-2" />
													</div>
												</div>
												<div className=" self-stretch flex flex-col items-center border border-solid border-gray-200 w-full  md:w-1/4">
													<div className="m-2 flex-r">
														<div className=" border-gray-200 font-light text-xs italic">
															Please provide a youtube video url with chapters <br />
															You can check the description section of the youtube video to see if the video has any chapters
														</div>
													</div>
												</div>
											</div>

											{/* BUTTONS SECTION */}
											<div className="px-4 py-4 w-full flex flex-row justify-between content-center">
												<span
													className="sm:block rounded-md mr-4 mt-2"
													onClick={() => {
														this.props.history.goBack();
													}}
												>
													<button type="button" className="font-inter  shadow-sm inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-bold rounded-md text-gray-700 bg-white hover:bg-gray-200 focus:outline-none focus:shadow-outline focus:border-indigo-400 transition duration-150 ease-in-out">
														Cancel
													</button>
												</span>
												{/* <svg className="ml-2  h-3 w-3 text-gray-200" fill="currentColor" viewBox="0 0 512.005 512.005">
                                    <path d="M388.418 240.923L153.751 6.256c-8.341-8.341-21.824-8.341-30.165 0s-8.341 21.824 0 30.165L343.17 256.005 123.586 475.589c-8.341 8.341-8.341 21.824 0 30.165 4.16 4.16 9.621 6.251 15.083 6.251 5.461 0 10.923-2.091 15.083-6.251l234.667-234.667c8.34-8.34 8.34-21.823-.001-30.164z"/>
                                </svg> */}

												<button
													type="submit"
													disabled={!isValid || isSubmitting}
													//  (!isValid || dirty || isSubmitting)
													className={`mr-4 mt-2 font-Inter shadow-sm inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md  hover:bg-blue-500 focus:outline-none focus:shadow-outline focus:border-indigo-400 transition duration-150 ease-in-out ${!isValid || isSubmitting ? "opacity-75 pointer-events-none bg-indigo-500 text-gray-100" : "bg-indigo-500 text-gray-100"}`}
												>
													{isSubmitting && (
														<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
															<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
															<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
														</svg>
													)}{" "}
													Import
												</button>

												{/* {isValid ? 'isvalid' : 'not isvalid'} / {dirty ? 'is dirty' : ' is not dirty'} / {touched.length ? 'is touched' : ' is not touched'} */}
											</div>
										</div>

										{/* <div className="mt-8 mb-2 p-2 border border-solid border-gray-400">
											<strong>Injected Formik props (the form's state)</strong>
											<div>
												<code>errors:</code> {JSON.stringify(errors, null, 2)}
											</div>
											<div>
												<code>values:</code> {JSON.stringify(values, null, 2)}
											</div>
											<div>
												<code>isValid:</code> {JSON.stringify(isValid, null, 2)}
											</div>
											<div>
												<code>dirty:</code> {JSON.stringify(dirty, null, 2)}
											</div>
											<div>
												<code>values.youtubeaddress:</code> {JSON.stringify(values.youtubeaddress, null, 2)}
											</div>
											<div>
												<code>isSubmitting:</code> {JSON.stringify(isSubmitting, null, 2)}
											</div>
										</div> */}
									</Form>
								)}
							</Formik>
						</div>

						<div className="self-stretch flex flex-col justify-center items-center w-full sm:w-1/4 pt-5 sm:pt-1 sm:px-5">
							<div className="flex flex-row items-center self-stretch ">
								<svg className="w-5 self-stretch text-bluegray-600 fill-current " viewBox="0 0 20 20" fill="currentColor">
									<path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
								</svg>
								<span className="self-stretch font-Inter text-sm font-semibold tracking-tight text-indigo-900">Youtube Videos with Chapters</span>
							</div>
							<div className="whitespace-pre-wrap ">{chaptersDesc}</div>
						</div>
					</div>
				</React.Fragment>
			</Fragment>
		);
	}
}

export default withRouter(NewWalkthruComponent);

function processDescription(textToProcess) {
	let z = null;
	let counter = 0;
	let list = [];
	const reg = /\(*(?:(\d{1,2}):)*([0-5]?\d):([0-5]?\d)\)*\s(.*)[\n|\r\n|\r]/g;

	while (null != (z = reg.exec(textToProcess))) {
		// console.log(z[0]); // match : (4:20:43) Python Interpreter
		// console.log(z[1]); // group 01 : 4
		// console.log(z[2]); // group 02 : 20
		// console.log(z[3]); // group 03 : 43
		// console.log(z[4]); // group 04 : Python Interpreter

		let _start = parseInt((z[1] ? z[1] : 0) * 3600) + parseInt((z[2] ? z[2] : 0) * 60) + parseInt(z[3] ? z[3] : 0);

		if (_start == 0) _start = "00:00";

		list.push({
			start: _start,
			hour: z[1],
			min: z[2],
			sec: z[3],
			label: z[4],
		});
		counter++;
	}

	for (let i = 0; i < list.length; i++) {
		let endhour,
			endmin,
			endsec,
			end = 0;
		let start = 0;
		if (list[i + 1]) {
			endhour = list[i + 1].hour;
			endmin = list[i + 1].min;
			endsec = list[i + 1].sec;
			end = parseInt((endhour ? endhour : 0) * 3600) + parseInt((endmin ? endmin : 0) * 60) + parseInt(endsec ? endsec : 0);
		}

		list[i].end = end;
		list[i].endhour = endhour;
		list[i].endmin = endmin;
		list[i].endsec = endsec;

		list[i].start = parseInt((list[i].hour ? list[i].hour : 0) * 3600) + parseInt((list[i].min ? list[i].min : 0) * 60) + parseInt(list[i].sec ? list[i].sec : 0);
		list[i].duration = parseInt(end) - parseInt(list[i].start);

		list[i].durationhour = Math.floor(parseInt(list[i].duration) / 3600);
		list[i].durationmin = Math.floor(parseInt(list[i].duration) / 60);
		list[i].durationsec = parseInt(list[i].duration) % 60;
	}

	for (let i = 0; i < list.length; i++) {
		list[i].hour = list[i].hour ? list[i].hour : "00";
		list[i].min = list[i].min ? list[i].min : "00";
		list[i].sec = list[i].sec ? list[i].sec : "00";
		list[i].endhour = list[i].endhour ? list[i].endhour : "00";
		list[i].endmin = list[i].endmin ? list[i].endmin : "00";
		list[i].endsec = list[i].endsec ? list[i].endsec : "00";

		list[i].hour = _.pad(list[i].hour, 2, "0");
		list[i].min = _.pad(list[i].min, 2, "0");
		list[i].sec = _.pad(list[i].sec, 2, "0");
		list[i].endhour = _.pad(list[i].endhour, 2, "0");
		list[i].endmin = _.pad(list[i].endmin, 2, "0");
		list[i].endsec = _.pad(list[i].endsec, 2, "0");

		list[i].scale = 100;
		list[i].ready = false;
	}

	return list;
}

function scaffoldWalkthru(responseDataObject) {
	const {
		id: videoid,
		snippet: {
			title,
			channelTitle,
			description,
			thumbnails: { default: tdefault, medium: tmedium, high: thigh, standard: tstandard, maxres: tmaxres },
		},
		status: { privacyStatus, embeddable },
	} = responseDataObject;

	let z = null;
	let counter = 0;
	let walkthru = {
		WMetadata: {
			id: nanoid(9),
			title: title,
			originalcreator: channelTitle,
			description: description,
			privacyStatus: privacyStatus,
			embeddable: embeddable,
			tdefault: tdefault,
			tmedium: tmedium,
			thigh: thigh,
			tstandard: tstandard,
			tmaxres: tmaxres,
		},
		WSteps: [],
	};

	const reg = /\(*(?:(\d{1,2}):)*([0-5]?\d):([0-5]?\d)\)*\s(.*)[\n|\r\n|\r]/g;

	while (null != (z = reg.exec(responseDataObject.snippet.description))) {
		// console.log(z[0]); // match : (4:20:43) Python Interpreter
		// console.log(z[1]); // group 01 : 4
		// console.log(z[2]); // group 02 : 20
		// console.log(z[3]); // group 03 : 43
		// console.log(z[4]); // group 04 : Python Interpreter

		let _start = parseInt((z[1] ? z[1] : 0) * 3600) + parseInt((z[2] ? z[2] : 0) * 60) + parseInt(z[3] ? z[3] : 0);

		if (_start == 0) _start = "00:00";

		var card = {
			metadata: {
				id: nanoid(9),
				type: "video",
				vendor: "youtube",
				title: z[4],
				size: "6",
			},
			details: {
				youtubevideoid: videoid,
				youtubeoriginalcreator: channelTitle,
				youtubeprivacystatus: privacyStatus,
				youtubeembeddable: embeddable,
				durationstart: _start,
				durationend: 0,
				youtubethumbnaildefault: tdefault.url,
				youtubethumbnailstandard: tstandard.url,
				youtubethumbnailhigh: thigh.url,
				addedOn: "",

				start: _start,
				starthour: z[1],
				startmin: z[2],
				startsec: z[3],

				end: "",
				endhour: "",
				endmin: "",
				endsec: "",

				mastervideo: {
					url: `https://www.youtube.com/watch?v=${videoid}`,
					title: title,
					originalcreator: channelTitle,
					// description: description,
					privacyStatus: privacyStatus,
					embeddable: embeddable,
					tdefault: tdefault.url,
					tmedium: tmedium.url,
					thigh: thigh.url,
					tstandard: tstandard.url,
					tmaxres: tmaxres.url,
				},
			},
		};

		var step = {
			metadata: {
				id: nanoid(9),
				completed: false,
				selected: false,
				layoutmode: "default",
				title: z[4],
			},
			cards: [],
		};

		step.cards.push(card);
		walkthru.WSteps.push(step);

		counter++;
	}

	for (let i = 0; i < walkthru.WSteps.length; i++) {
		var nextstart,
			nextstarthour,
			nextstartmin,
			nextstartsec = 0;
		if (walkthru.WSteps[i + 1]) {
			var { starthour, startmin, startsec } = walkthru.WSteps[i + 1].cards[0].details;
			nextstarthour = starthour;
			nextstartmin = startmin;
			nextstartsec = startsec;
			nextstart = parseInt((nextstarthour ? nextstarthour : 0) * 3600) + parseInt((nextstartmin ? nextstartmin : 0) * 60) + parseInt(nextstartsec ? nextstartsec : 0);
		}

		var { starthour, startmin, startsec } = walkthru.WSteps[i].cards[0].details;
		var details = walkthru.WSteps[i].cards[0].details;

		details.durationend = nextstart; // important

		details.end = nextstart;
		details.endhour = nextstarthour;
		details.endmin = nextstartmin;
		details.endsec = nextstartsec;

		details.durationstart = parseInt((starthour ? starthour : 0) * 3600) + parseInt((startmin ? startmin : 0) * 60) + parseInt(startsec ? startsec : 0); // important

		details.start = parseInt((starthour ? starthour : 0) * 3600) + parseInt((startmin ? startmin : 0) * 60) + parseInt(startsec ? startsec : 0);

		details.duration = parseInt(nextstart) - parseInt(details.start);
		details.durationhour = Math.floor(parseInt(details.duration) / 3600);
		details.durationmin = Math.floor(parseInt(details.duration) / 60);
		details.durationsec = parseInt(details.duration) % 60;
	}

	for (let i = 0; i < walkthru.WSteps.length; i++) {
		var details = walkthru.WSteps[i].cards[0].details;
		details.hour = details.hour ? details.hour : "00";
		details.min = details.min ? details.min : "00";
		details.sec = details.sec ? details.sec : "00";
		details.endhour = details.endhour ? details.endhour : "00";
		details.endmin = details.endmin ? details.endmin : "00";
		details.endsec = details.endsec ? details.endsec : "00";

		details.hour = _.pad(details.hour, 2, "0");
		details.min = _.pad(details.min, 2, "0");
		details.sec = _.pad(details.sec, 2, "0");
		details.endhour = _.pad(details.endhour, 2, "0");
		details.endmin = _.pad(details.endmin, 2, "0");
		details.endsec = _.pad(details.endsec, 2, "0");

		details.scale = 100;
		details.ready = false;
	}

	return walkthru;
}
