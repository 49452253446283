import React, { Component, Fragment } from "react";
import { gsap } from "gsap";

import { ReactComponent as Rect } from "content/landing/sub/images/rect.svg";

export default class LandingHero extends Component {

    // constructor(props){
    //     super(props);
  
    //   }

      componentDidMount(){

        // var timeline =  new TimelineMax();
        // timeline_rectangle.to(this.cube01, 2, {x: "-=107", y: "+=63", ease:'Power2.easeInOut'});

        // TimelineMax.fromTo([element1, element2], 1, {x:0}, {x:100});
        // gsap.to('.rec', {x:250, duration: 5});
        let tween =gsap.fromTo(".rec",{x:0, scaleY:0.5, scaleX:0.5  },{x: "+=200", y: "+=100", scaleY:1, scaleX:1, ease:'power.out',duration:'1'})
        let tween2 =gsap.fromTo(".rec2",{x:0 },{x: "+=300", y: "+=0", ease:'power.out',duration:'1'})

        tween.delay(2)
        tween.play();
        tween2.delay(3)
        tween2.play();


    }


	render() {
		return (
			<Fragment>
				<div
					className="mt-14 h-96 
                max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 
                 text-gray-200"
					style={{ backgroundColor: "#072032" }}
				>
					<section className="text-gray-200 body-font ">
						<div className=" flex px-8 pt-5  md:flex-row flex-col items-center">
							<div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center -mt-20">
								<div className="font-Herolight  sm:text-4xl text-xl mb-2 ">
									<span className="text-base font-Hero">Introducing</span>
									<br className="hidden lg:inline-block" />
									Serverless Functions <br className="hidden lg:inline-block" /> with batteries included
									<br />
								</div>
								{/* <div className="text-xl font-Inter  font-extralight ">Function as a service  </div> */}

								<div className="flex-cc my-2">
									<div className="flex flex-row items-center  self-stretch ">
										<div className=" flex flex-row  ">
											<svg className="h-full w-4 text-green-400 bg-transparent mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
												<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
												<polyline points="22 4 12 14.01 9 11.01"></polyline>
											</svg>
										</div>
										<span className=" sm:text-sm font-Inter font-light h-full  ">Enterprise grade security </span>
									</div>
									<div className="flex flex-row items-center  self-stretch ">
										<div className=" flex flex-row  ">
											<svg className="h-full w-4 text-green-400 bg-transparent mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
												<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
												<polyline points="22 4 12 14.01 9 11.01"></polyline>
											</svg>
										</div>
										<span className=" sm:text-sm font-Inter font-light h-full  ">SOC, PCI, HIPAA BAA compliant </span>
									</div>
									<div className="flex flex-row items-center  self-stretch ">
										<div className=" flex flex-row  ">
											<svg className="h-full w-4 text-green-400 bg-transparent mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
												<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
												<polyline points="22 4 12 14.01 9 11.01"></polyline>
											</svg>
										</div>
										<span className="  sm:text-sm font-Inter font-light h-full tracking-wide ">Fully Serverless </span>
									</div>
									<div className="flex flex-row items-center self-stretch ">
										<div className=" flex flex-row  ">
											<svg className="h-full w-4 text-green-400 bg-transparent mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
												<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
												<polyline points="22 4 12 14.01 9 11.01"></polyline>
											</svg>
										</div>
										<span className=" sm:text-sm font-Inter font-light h-full tracking-wide ">Web based execution monitoring</span>
									</div>
								</div>

								{/* <div className="flex justify-center">
									<button className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Free Trial</button>
									<button className="ml-4 inline-flex text-gray-700 bg-gray-200 border-0 py-2 px-6 focus:outline-none hover:bg-gray-300 rounded text-lg">Button</button>

									<svg className="h-8 w-8 mr-1 fill-current text-indigo-400 group-hover:text-white " viewBox="0 0 512 512">
										<path d="m456 88h-216v-8a40.045 40.045 0 0 0 -40-40h-144a40.045 40.045 0 0 0 -40 40v352a40.045 40.045 0 0 0 40 40h400a40.045 40.045 0 0 0 40-40v-304a40.045 40.045 0 0 0 -40-40zm24 344a24.028 24.028 0 0 1 -24 24h-400a24.028 24.028 0 0 1 -24-24v-352a24.028 24.028 0 0 1 24-24h144a24.028 24.028 0 0 1 24 24v64a8 8 0 0 0 8 8h224a24.028 24.028 0 0 1 24 24zm0-287.978a39.788 39.788 0 0 0 -24-8.022h-216v-32h216a24.028 24.028 0 0 1 24 24z" />
										<path d="m283.337 277.305a40 40 0 1 0 -54.465-.087c-29.225 11.594-50.79 41.352-52.859 77.131a8 8 0 0 0 1.463 5.09 96.29 96.29 0 0 0 157.048 0 8 8 0 0 0 1.463-5.09c-2.064-35.693-23.532-65.391-52.65-77.044zm-27.187-53.305a24 24 0 1 1 -24 24 24.028 24.028 0 0 1 24-24zm-.15 160a80.412 80.412 0 0 1 -63.8-31.545c3.379-36.367 30.93-64.455 63.8-64.455s60.421 28.088 63.8 64.455a80.412 80.412 0 0 1 -63.8 31.545z" />
									</svg>
								</div> */}
							</div>
							<div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 z-20 pt-20 pr-10">
								{/* <img className="object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600" /> */}
								<div className="object-cover object-center rounded w-full h-96 relative" style={{ backgroundColor: "#203747" }}>
									{/* <svg width="85" height="42" className="ml-16 absolute h-full  text-green-400 bg-transparent mr-2 fill-current stroke-current" viewBox="0 0 85 42">
										<rect x="1.34381" y="1.98676" width="81.7427" height="38.1092" rx="4.75" strokeWidth="2.5" fill="none" />
									</svg> */}
                                    <Rect className="rec ml-16 absolute h-full  text-green-400 bg-transparent mr-2 fill-current stroke-current"/>
                                    <Rect className="rec2 ml-16 absolute h-full  text-purple-500 bg-transparent mr-2 fill-current stroke-current"/>
                                    <Rect className="rec3 ml-16 absolute h-full  text-red-600 bg-transparent mr-2 fill-current stroke-current"/>


								</div>
							</div>

							<div className="grid grid-cols-3 gap-2 w-36">
  <div className="rounded-lg bg-white opacity-40 h-1 "></div>
  <div className="rounded-lg bg-white opacity-40 h-1 "></div>
  <div className="rounded-lg bg-white opacity-40 h-1 "></div>
  <div className="col-span-2 rounded-lg bg-white opacity-40 h-1"></div>
  <div className="rounded-lg bg-white opacity-40 h-1 "></div>
  <div className="rounded-lg bg-white opacity-40 h-1 "></div>
  <div className="col-span-2  bg-white opacity-40 h-1 "></div>
</div>
							{/* style={{ backgroundColor: "rgba(7, 32, 50, 0.7)" }}  */}
						</div>
					</section>
				</div>
			</Fragment>
		);
	}
}
