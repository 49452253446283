import React, { Component } from "react";
import ShowTheLocation from "utilities/ShowTheLocation";

export default class LandingContent extends Component {
	render() {
		return (
			<div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 container " >
				<div className="container min-h-screen bg-white shadow ">

					<section>
						content
					</section>
					<ShowTheLocation />
				</div>
			</div>
		);
	}
}
