import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import UI from "./content/dev/UI";
import "./assets/styles/base.scss";
import Landing from "content/landing/Landing";
import Signup from "content/auth/Signup";
import Login from "./content/auth/Login";
import ForgotPass from "./content/auth/ForgotPass";
import ForgotPassConfirm from "./content/auth/ForgotPassConfirm";
import SignupConfirm from "./content/auth/SignupConfirm";
import Portal from "./content/portal/Portal";
import UI03 from "./content/portal/dev/UI03";
import UI04 from "./content/portal/dev/UI04";
import LandingTemp from "./content/portal/dev/LandingTemp";
import Layout from "./content/portal/dev/Layout";

import withClearCache from "./ClearCache";
import packageJson from "../package.json";
// import { getBuildDate } from "./utilities/utils";

// dont do > import moment from 'moment';
import * as moment from "moment";
import UI05 from './content/portal/dev/UI05';
import UI06 from "content/portal/dev/UI06";
import UI07 from './content/portal/dev/UI07';
import Resume from './content/portal/dev/Resume';
import PortalComponent from './content/portal/layout/content/PortalComponent';

const ClearCacheComponent = withClearCache(ApplicationWithVersion);

function getBuildDate(epoch) {
	const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
	return buildDate;
}

export default class Application extends Component {
	render() {
		return <ClearCacheComponent />;
	}
}

{
	/* <p>Build date: {getBuildDate(packageJson.buildDate)}</p> */
}

function ApplicationWithVersion(props) {
	return (
		<Fragment>
			{/* <div  className="flex flex-col items-center flex-grow self-stretch min-h-screen overflow-hidden" style={{ backgroundColor: "#edf2f7" }}> */}

			<div className="flex flex-col self-stretch flex-grow font-Inter" style={{ backgroundColor: "#edf2f7" }}>
				<Router>
					{/* <ul>
			<li>
				<Link to="/">Landing</Link>
			</li>

			<li>
				<Link to="/UI">UI</Link>
			</li>
		</ul> */}

					<Switch>
						<Route exact path="/">
							<Landing />
						</Route>

						<Route path="/ui">
							<UI />
						</Route>
						<Route path="/ui3">
							<UI03 />
						</Route>
						<Route path="/ui4">
							<UI04 />
						</Route>

						<Route path="/ui5">
							<UI05 />
						</Route>
						<Route path="/ui6">
							<UI06 />
						</Route>
						<Route path="/ui7">
							<UI07 />
						</Route>

						<Route path="/ltemp">
							<LandingTemp />
						</Route>

						<Route path="/layout">
							<Layout />
						</Route>

						<Route path="/newlayout">
							<PortalComponent />
						</Route>

						<Route path="/resume">
							<Resume />
						</Route>

						<Route exact path="/signup">
							<Signup></Signup>
						</Route>
						<Route exact path="/signup/confirm">
							<SignupConfirm></SignupConfirm>
						</Route>
						<Route exact path="/login">
							<Login></Login>
						</Route>
						<Route exact path="/forgotpassconfirm">
							<ForgotPassConfirm></ForgotPassConfirm>
						</Route>
						<Route exact path="/forgot">
							<ForgotPass></ForgotPass>
						</Route>

						<Route path="/portal">
							<Portal></Portal>
						</Route>

						<Redirect to="/" />
					</Switch>
				</Router>
				<div className="font-Poppins text-2xs font-medium flex  flex-row-reverse items-center self-stretch pr-5 -mt-4">Build date: {getBuildDate(packageJson.buildDate)}</div>
			</div>
			{/* </div> */}
		</Fragment>
	);
}
