import React, { Component ,Fragment} from 'react'
import { Auth } from "aws-amplify";
import axios from "axios";
import { AppConfig } from './../../../utilities/AppConfig';
import { BrowserRouter as Router,  Switch,  Route,  Link, NavLink, withRouter, useParams } from "react-router-dom";

class StudioComponentList extends Component {
    constructor(props) {
		super(props);
		this.state = {
			isNewWalkthruModalOpen: false,
			newselection: '',
			searchText: "",
			wbolist: [],
			isWbolistLoading: false,
		};
	}
	componentDidMount() {
		this.initilizeData();
	}

	initilizeData = async (event) => {
		console.log("initilizeData");

		this.setState((prevState, props) => ({
			...prevState,
			isWbolistLoading: true,
		}));
		
		const { idToken: { jwtToken } } = await Auth.currentSession();

		axios({
			method: "get",
			baseURL: AppConfig.ApiWalkthruBaseUrl,
			url: "walkthru",
			params: {
				userscope: 'user',
			},
			headers: {
			    'authorization' : `${jwtToken}`,
			    'Content-Type':'application/json',
			    'Accept' : 'application/json'
			}
		})
			// .then(ResponseDelayer(1000))
			.then((response) => {
				// console.log("success response.data", JSON.stringify(response.data, null, 2));

				if (response && response.data) {
					this.setState((prevState, props) => ({
						...prevState,
						wbolist: response.data,
					}));
				}
				this.setState((prevState, props) => ({
					...prevState,
					isWbolistLoading: false,
				}));
			})
			.catch((error) => {
				// console.log("success response.data", JSON.stringify(error, null, 2));
				this.setState((prevState, props) => ({
					...prevState,
					isWbolistLoading: false,
				}));
			})
			.then(() => {
				// always executed
				console.log(">> StudioComponentList Finally <<");
			});
	};

	handleWalkthruDetails= (event) => {
		console.log("handleWalkthruDetails");
		const { currentTarget : { dataset : { id } = {}} = {}} = event;
		console.log("event.currentTarget.dataset.id ", id);
		if (id){
			var payload = {  "walkthruid": id };
			this.props.history.push({
				pathname: "/portal/walkthrus/" + id,
				state : JSON.stringify(payload),
				//routerpayload : walkthru   //response.data // { routerdata : JSON.stringify(result.data, null, 2) }
			  });
		}	

	}

    render() {
        const { searchText, wbolist, isWbolistLoading } = this.state;
        return (
            <Fragment>
                

                
				<Fragment>
									<div className="flex flex-col items-center self-stretch  flex-grow ">
										<Fragment>
											<div className="flex flex-row items-center justify-between self-stretch ">
												<div className="flex flex-row items-center self-stretch p-3 ">
													{/* <svg className=" text-bluegray-500 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
													</svg>
													<span className="font-Inter text-base font-semibold ml-2 ">Trending </span> */}
												</div>
												<div>
													<button className="p-3 focus:outline-none" onClick={this.initilizeData}>
														<svg className="h-6 w-6 text-bluegray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
															<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
														</svg>
													</button>
												</div>
											</div>
										</Fragment>

										<div className="self-stretch flex flex-row flex-wrap items-center">
											{isWbolistLoading ? (
												// <svg className="animate-spin -ml-1 mr-3 h-7 w-7 text-indigo-600 " fill="none" viewBox="0 0 24 24">
												// 	<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
												// 	<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
												// </svg>
												(
													[1,2,3,4,5,6,7,8].map((item,index) => (
													<div key={index} className="w-72  flex-grow-0 m-3  border border-bluegray-300 shadow rounded-md bg-white ">
														<div className="animate-pulse flex space-x-4 flex-col">
															<div className="w-full h-40 bg-bluegray-200 self-stretch" ></div>
															{/* <div className="rounded-full bg-bluegray-300 h-12 w-12"></div> */}
															<div className="flex-1 space-y-2 pt-4 px-4 pb-10 ">
															<div className="h-3 bg-bluegray-200 rounded w-3/4"></div>
															<div className="space-y-2">
																<div className="h-3 bg-bluegray-200 rounded"></div>
																<div className="h-3 bg-bluegray-200 rounded w-5/6"></div>
																<div className="h-3 bg-bluegray-200 rounded w-5/6"></div>
																<div className="h-3 bg-bluegray-200 rounded w-5/6"></div>
															</div>
															</div>
														</div>
														</div>
													))
													)
												
											) : wbolist && wbolist.length > 0 ? (
												wbolist.map(({ WMetadata : { title = "..." , id = "..."  } = {}, WSummary : { creator : { userName} = {}, createdOn = "..." , description = "..." , totalSteps = "...",  tmedium = ""} = {}}, index) => (
													<div key={index} onClick={this.handleWalkthruDetails} data-id={id} className="group w-72   m-3  self-stretch flex flex-col items-center border border-gray-300 rounded-lg shadow-md overflow-hidden cursor-pointer bg-white ">

														{/* <div className="w-full h-40 opacity-95 debug-border bg-cover bg-center " style={{ backgroundImage: `url('${"https://i.ytimg.com/vi/3hLmDS179YE/mqdefault.jpg"}')` }}></div> */}
														<div className="w-full h-40 opacity-95 bg-cover bg-center group-hover:bg-bluegray-200" style={{ backgroundImage: `url('${tmedium}')` }}></div>

														<div className="flex flex-row self-stretch justify-between 	z-10 group-hover:bg-bluegray-100 group-hover:text-bluegray-900">

														<div className="font-Inter text-2xs font-semibold tracking-tight leading-tight text-bluegray-300 ml-2">{createdOn} </div>
														<div className="flex flex-row-reverse self-stretch ">
															<div className="font-Inter text-2xs font-semibold tracking-tight leading-tight text-bluegray-300 mr-3">steps</div>

															<div  className="w-10 h-10 -mt-4 
																bg-gradient-to-br from-purple-500 via-indigo-600 to-indigo-400 mr-1 rounded-full border-solid border-white border-2 
																flex flex-row self-stretch items-center justify-center text-white text-smaller font-medium font-Inter">
																<div>{totalSteps}</div>
															</div>	
														</div>
														</div>
														<div className=" self-stretch flex-grow bg-white px-3 pb-3 pt-1 group-hover:bg-bluegray-100 group-hover:text-bluegray-900">
															<div className="self-stretch flex flex-col items-center">
																<div className="font-Inter text-sm font-bold tracking-tight leading-tight mb-1 line-clamp-2"> {title} </div>
																<div className="font-Inter text-xs font-medium tracking-tight flex-grow-0 line-clamp-4 break-all"> {description && description.toString().substring(0,200)} </div>
																<div className=" border-b-2 border-bluegray-200  h-4 flex-grow-0 self-stretch "> {' '} </div>
																<div className="flex flex-row self-stretch items-center justify-between">
																	<div className="font-Inter text-xs font-medium tracking-tight  "> {id} </div>
																	<div className="font-Inter text-xs font-medium tracking-tight  "> {createdOn} </div>
																	<div className="font-Inter text-xs font-medium tracking-tight  "> {userName} </div>
																</div>
															</div>
														</div>
													</div>
												))
											) : (
												<span className="font-Inter text-base font-medium opacity-60 tracking-tight leading-tight text-indigo-600 ">No walkthrus found</span>
											)}
										</div>
									</div>
								</Fragment>
						



            </Fragment>
        )
    }
}

export default withRouter(StudioComponentList);