import React, { Component, Fragment } from "react";
import uuid from "react-uuid";
import { Auth } from "aws-amplify";
import axios from "axios";
import ResponseDelayer from "./../../../utilities/ResponseDelayer";
import { BrowserRouter as Router,  Switch,  Route,  Link, NavLink, withRouter, useParams } from "react-router-dom";
import { AppConfig } from './../../../utilities/AppConfig';
class CatalogDiscovery extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchText: "",
			wbolist: [],
			isWbolistLoading: false,
		};
	}
	componentDidMount() {
		this.initilizeData();
	}

	handleOnInputChange = (event) => {
		const {
			// walkbo: { steps: walkbosteps },
		} = this.state;
		const searchText = event.target.value;

		console.log("searchText: ", searchText);

		// var filteredwalkbosteps = searchText ? walkbosteps.filter(({ cards: [card] }) => card.metadata.title.toLowerCase().includes(searchText.toLowerCase())) : walkbosteps;
		// console.log("filteredwalkbosteps: ", filteredwalkbosteps.length);

		this.setState((prevState, props) => ({
			...prevState,
			searchText: searchText,
		}));
	};

	handleSearch = async () => {
		this.initilizeData();
	};

	handleWalkthruDetails= (event) => {
		console.log("handleWalkthruDetails");
		const { currentTarget : { dataset : { id } = {}} = {}} = event;
		console.log("event.currentTarget.dataset.id ", id);
		if (id){
			var payload = {  "walkthruid": id };
			this.props.history.push({
				pathname: "/portal/walkthrus/" + id,
				state : JSON.stringify(payload),
				//routerpayload : walkthru   //response.data // { routerdata : JSON.stringify(result.data, null, 2) }
			  });
		}	

	}

	initilizeData = async (event) => {
		// const { accessToken: { jwtToken } } = await Auth.currentSession();
		// console.log('jwtToken', jwtToken);

		// let dataToSend = {
		//     // address: "https://www.youtube.com/watch?v=3hLmDS179YE",
		//     // address: "https://www.youtube.com/watch?v=aoQ6S1a32j8",
		//     // address: YoutubeAddress,
		// };
		// console.log("got result: ", JSON.stringify(dataToSend, null, 2)  );

		// let IdToken = "";

		this.setState((prevState, props) => ({
			...prevState,
			isWbolistLoading: true,
		}));

		
		
		const { idToken: { jwtToken } } = await Auth.currentSession();
		// console.log('idToken jwtToken ', JSON.stringify(jwtToken, null, 2));
		// console.log('Auth.currentSession()  ', JSON.stringify( await Auth.currentSession(), null, 2));

		axios({
			method: "get",
			baseURL: AppConfig.ApiWalkthruBaseUrl,
			url: "walkthru",
			params: {
				userscope: 'all',
			},
			headers: {
			    // 'Authorization' : `Bearer ${jwtToken}`,
			    'authorization' : `${jwtToken}`,
			    'Content-Type':'application/json',
			    'Accept' : 'application/json'
			}

			// headers: {
			//     'Content-Type':'application/json',
			//     'Accept' : 'application/json'
			// }
		})
			// .then(ResponseDelayer(1000))
			.then((response) => {
				// console.log("success response.data", JSON.stringify(response.data, null, 2));

				const { data : {Items = []} = {}} = response;
				this.setState((prevState, props) => ({
					...prevState,
					wbolist: Items,
				}));

				this.setState((prevState, props) => ({
					...prevState,
					isWbolistLoading: false,
				}));

				// console.log( JSON.parse(walkthru) );

				// this.props.history.push({
				//     pathname: "/portal/walkthrus/" + walkthru.metadata.id ,
				//     routerpayload : walkthru   //response.data // { routerdata : JSON.stringify(result.data, null, 2) }
				//   });
			})
			.catch((error) => {
				// console.log("success response.data", JSON.stringify(error, null, 2));
				this.setState((prevState, props) => ({
					...prevState,
					isWbolistLoading: false,
				}));
			})
			.then(() => {
				// always executed
				console.log(">> CatalogDiscovery Finally <<");
			});
	};

	render() {
		const { searchText, wbolist, isWbolistLoading } = this.state;
		return (
			<Fragment>
				<section className="flex flex-col items-center self-stretch flex-grow border-b-2 border-gray-200" style={{ backgroundColor: "#edf2f7" }}>
					
					
					<div className="flex flex-col items-center self-stretch flex-grow mx-auto container ">
						{/* <Fragment>
							<div className="flex flex-row items-center self-stretch p-5 lg:pl-60">
								<div className="flex flex-col self-stretch items-center flex-grow py-5 ">
									
									<div className="flex flex-row items-center self-stretch w-full lg:w-3/5 mb-2 ">
										<div className={` self-stretch h-10  mr-3 flex-grow  flex flex-row items-center  border border-gray-300 bg-bluegray-200  `}>
											<svg className="ml-2 h-5 text-gray-500 " viewBox="0 0 20 20" fill="currentColor">
												<path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
											</svg>
											<input className="self-stretch flex-grow  border-1 border-bluegray-400 bg-bluegray-200  focus:bg-bluegray-200  pl-2 pr-5 text-sm focus:outline-none" type="search" name="search" placeholder="Search" onChange={this.handleOnInputChange} value={searchText} />
										</div>
										<button className="px-2 h-10 group self-stretch  border border-gray-300 hover:bg-bluegray-100  group-hover:text-bluegray-600 focus:text-bluegray-600 focus:outline-none text-bluegray-500 font-Inter font-base text-sm  inline-flex items-center cursor-pointer" onClick={this.handleSearch}>
											<span className="text-sm font-Inter font-medium pl-2 pr-1"> Search</span>
										</button>
									</div>

									<div className="flex flex-row items-center self-stretch flex-wrap space-x-1 space-y-1  pt-2">
										<span className="inline-flex bg-indigo-500 bg-opacity-80 text-white  font-Inter text-xs font-medium   rounded-full h-6 px-3 justify-center items-center cursor-pointer">Python</span>
										<span className="inline-flex bg-indigo-500 bg-opacity-80 text-white font-Inter text-xs font-medium  rounded-full h-6 px-3 justify-center items-center cursor-pointer">Course</span>
										<span className="inline-flex bg-indigo-500 bg-opacity-80 text-white font-Inter text-xs font-medium  rounded-full h-6 px-3 justify-center items-center cursor-pointer">Machine Learning</span>
										<span className="inline-flex bg-indigo-500 bg-opacity-80 text-white  font-Inter text-xs font-medium   rounded-full h-6 px-3 justify-center items-center cursor-pointer">AWS</span>
										<span className="inline-flex bg-indigo-500 bg-opacity-80 text-white font-Inter text-xs font-medium  rounded-full h-6 px-3 justify-center items-center cursor-pointer">Azure</span>
										<span className="inline-flex bg-indigo-500 bg-opacity-80 text-white font-Inter text-xs font-medium  rounded-full h-6 px-3 justify-center items-center cursor-pointer">React</span>
										<span className="inline-flex bg-pink-600 bg-opacity-80 text-white  font-Inter text-xs font-medium   rounded-full h-6 px-3 justify-center items-center cursor-pointer">Python</span>
										<span className="inline-flex bg-purple-600 bg-opacity-80 text-white font-Inter text-xs font-medium  rounded-full h-6 px-3 justify-center items-center cursor-pointer">Course</span>
										<span className="inline-flex bg-indigo-600 bg-opacity-80 text-white font-Inter text-xs font-medium  rounded-full h-6 px-3 justify-center items-center cursor-pointer">Machine Learning</span>
										<span className="inline-flex bg-pink-600 bg-opacity-80 text-white  font-Inter text-xs font-medium   rounded-full h-6 px-3 justify-center items-center cursor-pointer">AWS</span>
										<span className="inline-flex bg-purple-600 bg-opacity-80 text-white font-Inter text-xs font-medium  rounded-full h-6 px-3 justify-center items-center cursor-pointer">Azure</span>
										<span className="inline-flex bg-indigo-600 bg-opacity-80 text-white font-Inter text-xs font-medium  rounded-full h-6 px-3 justify-center items-center cursor-pointer">React</span>
									</div>
								</div>
							</div>
						</Fragment> */}

						<Fragment>
							<div className="flex flex-row items-center self-stretch flex-grow">
{/* 								
								<Fragment>
									<div className=" self-stretch flex flex-col items-center p-2 pt-12 w-0 lg:w-64" >
										
										<Fragment>
											<div className="flex flex-row items-center self-stretch p-3">
												<svg className=" text-bluegray-500 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
													<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
												</svg>
												<span className="font-Inter text-base font-semibold ml-2">Trending</span>
											</div>
										</Fragment>


										<Fragment>
											<div className="flex flex-row items-center self-stretch p-3">
												<svg className=" text-bluegray-500 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
													<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
												</svg>
												<span className="font-Inter text-base font-semibold ml-2 ">Recents</span>
											</div>
										</Fragment>


										<Fragment>
											<div className="flex flex-row items-center self-stretch p-3">
												<svg className=" text-bluegray-500 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
													<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
												</svg>
												<span className="font-Inter text-base font-semibold ml-2">Subscribed</span>
											</div>
										</Fragment>


										<Fragment>
											<div className="flex flex-row items-center self-stretch p-3">
												<svg className=" text-bluegray-500 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
													<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
												</svg>
												<span className="font-Inter text-base font-semibold ml-2">Walkthrus</span>
											</div>
										</Fragment>

										<Fragment>
											<div className="flex flex-row items-center self-stretch p-3">
												<svg className="fill-current text-bluegray-500 h-5 " viewBox="0 0 411 315" fill="none">
													<path d="M162.157 115.113C162.138 115.471 162.138 115.829 162.157 116.187C162.454 121.661 167.132 125.859 172.606 125.562H347.626C347.984 125.581 348.342 125.581 348.7 125.562C354.174 125.265 358.372 120.587 358.075 115.113V10.623C358.094 10.265 358.094 9.90699 358.075 9.54899C357.778 4.07499 353.1 -0.123015 347.626 0.173985H172.606C172.248 0.154985 171.89 0.154985 171.532 0.173985C166.058 0.470985 161.86 5.14898 162.157 10.623V115.113V115.113ZM183.055 21.072H337.177V104.664H183.055V21.072Z" />
													<path d="M410.321 297.296V192.806C410.34 192.448 410.34 192.09 410.321 191.732C410.024 186.258 405.346 182.06 399.872 182.357H172.606C172.248 182.338 171.89 182.338 171.532 182.357C166.058 182.654 161.86 187.332 162.157 192.806V297.296C162.138 297.654 162.138 298.012 162.157 298.37C162.454 303.844 167.132 308.042 172.606 307.745H399.871C400.229 307.764 400.587 307.764 400.945 307.745C406.42 307.449 410.617 302.77 410.321 297.296ZM389.423 286.847H183.055V203.255H389.422V286.847H389.423Z" />
													<path d="M212.341 73.317H272.423C278.194 73.317 282.872 68.639 282.872 62.868C282.872 57.097 278.194 52.419 272.423 52.419H212.341C206.57 52.419 201.892 57.097 201.892 62.868C201.892 68.639 206.57 73.317 212.341 73.317Z" />
													<path d="M212.341 255.5H324.668C330.439 255.5 335.117 250.822 335.117 245.051C335.117 239.28 330.439 234.602 324.668 234.602H212.341C206.57 234.602 201.892 239.28 201.892 245.051C201.892 250.822 206.57 255.5 212.341 255.5Z" />
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M44.1767 2.7746C73.3476 -3.75451 102.287 14.6015 108.815 43.7724C115.344 72.9432 96.9884 101.883 67.8175 108.411V209.196C92.4527 214.774 109.83 236.82 109.5 262.077C109.83 287.332 92.4527 309.38 67.8175 314.957V314.982H42.9324V314.957C22.2543 310.445 6.10236 294.294 1.59045 273.616C-4.78267 244.41 13.727 215.569 42.9324 209.196V108.411C18.4092 102.795 1.23679 80.6798 1.87148 55.5303C1.90125 30.2164 19.4726 8.30226 44.1767 2.7746ZM56.0347 291.318C72.183 291.307 85.2662 278.207 85.2555 262.059C85.246 245.909 72.1461 232.827 55.9977 232.838C40.0923 233.176 27.3759 246.169 27.3794 262.078C27.3759 277.987 40.0923 290.98 55.9977 291.318H56.0347ZM27.3794 55.5303C27.3759 71.4392 40.0911 84.432 55.9966 84.7702C72.2497 84.7737 85.5211 71.7798 85.8604 55.5303C85.5211 39.2819 72.2497 26.2868 55.9977 26.2904C40.0923 26.6286 27.3759 39.6213 27.3794 55.5303Z"
													/>
												</svg>
												<span className="font-Inter text-base font-semibold ml-2">My Walkthrus</span>
											</div>
										</Fragment>
									</div>
								</Fragment>
								 */}
								<Fragment>
									<div className="flex flex-col items-center self-stretch  flex-grow ">
										<Fragment>
											<div className="flex flex-row items-center justify-between self-stretch ">
												<div className="flex flex-row items-center self-stretch p-3 ">
													{/* <svg className=" text-bluegray-500 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
													</svg>
													<span className="font-Inter text-base font-semibold ml-2 ">Trending </span> */}
												</div>
												<div>
													<button className="p-3 focus:outline-none" onClick={this.initilizeData}>
														<svg className="h-6 w-6 text-bluegray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
															<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
														</svg>
													</button>
												</div>
											</div>
										</Fragment>

										<div className="self-stretch flex flex-row flex-wrap items-center p-3 sm:p-0">
											{isWbolistLoading ? (
												// <svg className="animate-spin -ml-1 mr-3 h-7 w-7 text-indigo-600 " fill="none" viewBox="0 0 24 24">
												// 	<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
												// 	<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
												// </svg>
												(
													[1,2,3,4,5,6,7,8].map((item,index) => (
													<div key={index} className="w-72  flex-grow-0 m-3  border border-bluegray-300 shadow rounded-md bg-white ">
														<div className="animate-pulse flex space-x-4 flex-col">
															<div className="w-full h-40 bg-bluegray-200 self-stretch" ></div>
															{/* <div className="rounded-full bg-bluegray-300 h-12 w-12"></div> */}
															<div className="flex-1 space-y-2 pt-4 px-4 pb-10 ">
															<div className="h-3 bg-bluegray-200 rounded w-3/4"></div>
															<div className="space-y-2">
																<div className="h-3 bg-bluegray-200 rounded"></div>
																<div className="h-3 bg-bluegray-200 rounded w-5/6"></div>
																<div className="h-3 bg-bluegray-200 rounded w-5/6"></div>
																<div className="h-3 bg-bluegray-200 rounded w-5/6"></div>
															</div>
															</div>
														</div>
														</div>
													))
													)
												
											) : wbolist && wbolist.length > 0 ? (
												wbolist.map(({ WMetadata : { title = "..." , id = "..."  } = {}, WSummary : { creator : { userName} = {}, createdOn = "..." , description = "..." , totalSteps = "...",  tmedium = ""} = {}}, index) => (
													<div key={index} onClick={this.handleWalkthruDetails} data-id={id} className="group w-72   m-3  self-stretch flex flex-col items-center border border-gray-300 rounded-lg shadow-md overflow cursor-pointer bg-white ">

														{/* <div className="w-full h-40 opacity-95 debug-border bg-cover bg-center " style={{ backgroundImage: `url('${"https://i.ytimg.com/vi/3hLmDS179YE/mqdefault.jpg"}')` }}></div> */}
														<div className="w-full h-40 opacity-95 bg-cover bg-center group-hover:bg-bluegray-200" style={{ backgroundImage: `url('${tmedium}')` }}></div>

														<div className="flex flex-row self-stretch justify-between 	z-10 group-hover:bg-bluegray-100 group-hover:text-bluegray-900">

														{/* <div className="font-Inter text-2xs font-semibold tracking-tight leading-tight text-bluegray-300 ml-2">{createdOn} </div> */}
														<div className="flex flex-row self-stretch ">
															<div className="w-10 h-10 -mt-4 ml-6
																bg-gradient-to-br from-purple-400 via-indigo-600   to-indigo-600  mr-1 rounded-full border-solid border-white border-2 
																flex flex-row self-stretch items-center justify-center text-bluegray-100 text-smaller font-bold font-Inter">
																<div>{totalSteps}</div>
															</div>	
																<div className="font-Inter text-2xs font-semibold tracking-tight leading-tight text-bluegray-600 mr-3">steps</div>
														</div>
														</div>
														<div className=" self-stretch flex-grow bg-white px-3 pb-2  pt-1 group-hover:bg-bluegray-100 group-hover:text-bluegray-900 border-b-2 border-bluegray-200  ">
															<div className="self-stretch flex flex-col items-center">
																<div className="font-Inter text-sm font-bold tracking-tight leading-tight mb-1 line-clamp-2  h-9 flex-grow-0 self-stretch"> {title} </div>
																<div className="font-Inter text-xs font-medium tracking-tight flex-grow-0 line-clamp-4 break-all text-bluegray-500"> {description && description.toString().substring(0,200)} </div>

															</div>
														</div>
														<div className="flex flex-row self-stretch items-center justify-between px-4 p-2">
																	<div className="font-Inter text-xs font-medium tracking-tight  "> {id} </div>
																	<div className="font-Inter text-xs font-medium tracking-tight leading-tight   "> {userName} </div>
																	<div className="font-Inter text-xs font-medium tracking-tight  "> {createdOn} </div>
																</div>
													</div>
												))
											) : (
												<span className="font-Inter text-base font-medium opacity-60 tracking-tight leading-tight text-indigo-600 ">No walkthrus found</span>
											)}
										</div>
									</div>
								</Fragment>
							</div>
						</Fragment>
					</div>
				</section>
			</Fragment>
		);
	}
}



export default withRouter(CatalogDiscovery);