import React, { Component, Fragment } from 'react'
import styled from "styled-components";


const StyledSvg = styled.svg`
  circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;

    stroke-linecap: round;
  }
`;


// https://css-tricks.com/building-progress-ring-quickly/
export default class ProgressRing extends Component {

    constructor(props) {
        super(props);
        this.state = {
          progress: 0,
          index: 1,
          totalSteps: 1,
        };
        
        const { radius, stroke } = this.props;
        
        this.normalizedRadius = radius - stroke * 2;
        this.circumference = this.normalizedRadius * 2 * Math.PI;
      }

      setProgress = (index, totalSteps, percentage) => {
        // console.log('percentage ', percentage );
        // console.log('ProgressRing percentage',JSON.stringify(percentage,null,2));

        this.setState((prevState, props) => ({
          ...prevState,
          progress: parseInt(percentage),
          index: index,
          totalSteps: totalSteps,
        }));
      }
  
      render() {
        const { radius, stroke } = this.props;
        const {  progress, index, totalSteps } = this.state;
    
        const strokeDashoffset = this.circumference - progress / 100 * this.circumference;
      
        return (
<div className="self-stretch font-Inter">
  
            <StyledSvg
              height={radius * 2}
              width={radius * 2}
             >
               {/* <circle
                stroke="rgba(148, 163, 184,0.7)"
                fill="transparent"
                strokeWidth={ 2 }
                r={ this.normalizedRadius + 2 }
                cx={ radius }
                cy={ radius }
               /> */}
                <circle
                stroke="rgba(148, 163, 184,0.32)"
                fill="transparent"
                strokeWidth={ 6 }
                r={ this.normalizedRadius    }
                cx={ radius }
                cy={ radius }
               />

              <circle
                stroke="rgb(124, 58, 237)"
                fill="transparent"
                strokeWidth={ stroke }
                strokeDasharray={ this.circumference + ' ' + this.circumference }
                style={ { strokeDashoffset } }
                r={ this.normalizedRadius }
                cx={ radius }
                cy={ radius }
               />
               <text className="text-sm font-Open  "   x="50%" y="50%" textAnchor="middle" stroke="rgba(124, 58, 237,1)" strokeWidth="1px" dx="-0.0em" dy="-.1em">{index}</text>
               <text style={{ fontSize: "0.6rem" }} className="t font-Open   opacity-100" x="50%" y="50%" textAnchor="middle" stroke="rgba(148, 163, 184, 0.7)" strokeWidth="0.5px" dx="0.0em" dy="1.1em">of {totalSteps}</text>
            </StyledSvg>

            {/* {progress} */}

</div>
        );
      }
}
