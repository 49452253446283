import React, { Component, Fragment } from "react";
import ReactPlayer from "react-player";
import { ReactComponent as PlaySvg } from "assets/images/play.svg";
import ProgressBar from "./../../assets/components/ProgressBar";
import screenfull from "screenfull";
import { findDOMNode } from "react-dom";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import { Menu, MenuItem, MenuDivider, MenuHeader, MenuButton, SubMenu } from "@szhsin/react-menu";




var _ = require("underscore.string");


function HourMinSec(props) {
	const {durationstart, durationend} = props;
	const hour = _.pad( Math.floor( (durationend - durationstart) / 3600), 2, "0")  ;
	
	const min =  Math.floor( ((durationend - durationstart) % 3600) / 60 ) ;
	const sec = (durationend - durationstart) % 60 ;
	return (
		<Fragment>
			{parseInt(hour) != 0 ? <Fragment>{hour} hours </Fragment> : null}
			{parseInt(min) != 0 ? <Fragment>{min} mins</Fragment> : null} {sec} secs{" "}
		</Fragment>
	);
}



function StartEndHourMinSec(props) {
	const {durationstart, durationend} = props;
	const endhour = _.pad( Math.floor( (durationend) / 3600), 2, "0")  ;
	const endmin =  _.pad( Math.floor( ((durationend) % 3600) / 60 ), 2, "0")  ;
	const endsec = _.pad((durationend) % 60, 2, "0") ;

	const starthour = _.pad( Math.floor( (durationstart) / 3600), 2, "0")  ;
	const startmin =  _.pad( Math.floor( ((durationstart) % 3600) / 60 ), 2, "0")  ;
	const startsec = _.pad( (durationstart) % 60 , 2, "0");
	return (
		<Fragment>
			<svg className="h-3 mr-1 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z" />
			</svg>

			<span className="font-Poppins font-medium  text-2xs tracking-normal text-right  ">
					<StartHourMinSec />
			</span>

			<svg className="mx-1" width="13" height="11" viewBox="0 0 13 11">
				<path
					d="M9.38278 5.94244H1.85331C1.71043 5.94244 1.5734 5.89342 1.47237 5.80617C1.37133 5.71891 1.31458 5.60057 1.31458 5.47717C1.31458 5.35378 1.37133 5.23543 1.47237 5.14818C1.5734 5.06092 1.71043 5.01191 1.85331 5.01191H9.38278L9.92151 5.47717L9.38278 5.94244ZM8.33986 8.59778L11.5722 5.80617C11.6223 5.76297 11.662 5.71168 11.689 5.65523C11.7161 5.59878 11.73 5.53827 11.73 5.47717C11.73 5.41607 11.7161 5.35557 11.689 5.29912C11.662 5.24267 11.6223 5.19138 11.5722 5.14817L8.33986 2.35657C8.23883 2.26931 8.1018 2.22029 7.95891 2.22029C7.81603 2.22029 7.679 2.26931 7.57796 2.35657C7.47693 2.44382 7.42017 2.56217 7.42017 2.68557C7.42017 2.80897 7.47693 2.92732 7.57796 3.01457L10.4294 5.47717L7.57796 7.93977C7.47693 8.02703 7.42017 8.14537 7.42017 8.26877C7.42017 8.39217 7.47693 8.51052 7.57796 8.59778C7.679 8.68503 7.81603 8.73405 7.95891 8.73405C8.1018 8.73405 8.23883 8.68503 8.33986 8.59778Z"
					fill="#949494"
				/>
			</svg>

			<span className=" font-Poppins  font-medium  text-2xs tracking-normal text-right  ">
				<EndHourMinSec />
			</span>
		</Fragment>
	);
}


function StartHourMinSec(props) {
	const {durationstart, durationend} = props;
	const starthour = _.pad( Math.floor( (durationstart) / 3600), 2, "0")  ;
	const startmin =  _.pad( Math.floor( ((durationstart) % 3600) / 60 ), 2, "0")  ;
	const startsec = _.pad( (durationstart) % 60 , 2, "0");
	return (
		<Fragment>
				{parseInt(starthour) != 0 ? <Fragment>{starthour}:</Fragment> : null}
				<Fragment>{startmin}:</Fragment> 
				{startsec}{" "}
		</Fragment>
	);
}



function EndHourMinSec(props) {
	const {durationstart, durationend} = props;
	const endhour = _.pad( Math.floor( (durationend) / 3600), 2, "0")  ;
	const endmin =  _.pad( Math.floor( ((durationend) % 3600) / 60 ), 2, "0")  ;
	const endsec = _.pad((durationend) % 60, 2, "0") ;
	return (
		<Fragment>
				{parseInt(endhour) != 0 ? <Fragment>{endhour}:</Fragment> : null}
				{parseInt(endmin) != 0 ? <Fragment>{endmin}:</Fragment> : null}
				{endsec}{" "}
		</Fragment>
	);
}



// const handle = props => {
// 	const { value, dragging, index, ...restProps } = props;
// 	return (
// 	  <SliderTooltip
// 		prefixCls="rc-slider-tooltip"
// 		overlay={`${value} %`}
// 		visible={dragging}
// 		placement="top"
// 		key={index}
// 	  >
// 		<Handle value={value} {...restProps} />
// 	  </SliderTooltip>
// 	);
//   };

export default class YoutubeComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			url: null,
			pip: false,
			playing: true,
			controls: false,
			light: false,
			volume: 0.8,
			muted: false,
			played: 0,
			loaded: 0,
			duration: 0,
			playbackRate: 1.0,
			loop: false,
			//[BV]
			playedSeconds: 0,
			loadedSeconds: 0,
			controlsVisible: false,
		};
	}

	onRCSliderChange = (value) => {
		const {playedSeconds} = this.state;
		console.log("onRCSliderChange value : ", value);
		this.player.seekTo(parseFloat(value))
		// this.setState((prevState, props) => ({
		// 	...prevState,
		// 	playedSeconds: parseFloat(value),
		// }));
	};



	handleProgress = ({ playedSeconds, played, loadedSeconds, loaded }) => {
		const {
			stepmetadata,
			card: {
				metadata,
				details: { tstandard: { url = "" } = {} },
			},
		} = this.props;

		// console.log('playedSeconds: ', playedSeconds);
		// console.log('played: ', played);
		// console.log('loadedSeconds: ', loadedSeconds);
		// console.log('details.start : ', details.start);
		// console.log('details.endhour: ', details.endhour);
		// console.log('details.duration: ', details.duration);
		// console.log('calculation : ', (   (playedSeconds - details.start ) / details.duration ) * 100     );

		// console.log("state:  ", JSON.stringify(state, null, 2));

		// We only want to update time slider if we are not currently seeking
		if (!this.state.seeking) {
			this.setState((prevState, props) => ({
				...prevState,
				playedSeconds: playedSeconds,
				played: played,
				loadedSeconds: loadedSeconds,
				loaded: loaded,
			}));
		}
	};

	handleOnPlay = () => {
		console.log("onPlay");
		this.setState((prevState, props) => ({
			...prevState,
			playing: true,
		}));
	};

	handleonPause = () => {
		console.log("onPause");
		this.setState((prevState, props) => ({
			...prevState,
			playing: false,
		}));
	};

	handlePlayPause = () => {
		console.log("handlePlayPause");
		this.setState((prevState, props) => ({
			...prevState,
			playing: !prevState.playing,
		}));
	};


	// ----------------------------------------------------------------------------------
	//  Video navigation (seek, move to and back)
	//	handleSeekMouseDown > handleSeekChange > handleSeekMouseUp
	//	
	// ----------------------------------------------------------------------------------
	handleSeekMouseDown = (e) => {
		console.log("handleSeekMouseDown");
		this.setState((prevState, props) => ({
			...prevState,
			seeking: true,
		}));
	};

	handleSeekChange = (e) => {
		const {playedSeconds} = this.state;
		console.log("handleSeekChange e.target.value : ", parseFloat(e.target.value));
		console.log("playedSeconds : ", playedSeconds);
		console.log("handleSeekChange event : ", e);
		this.setState((prevState, props) => ({
			...prevState,
			playedSeconds: parseFloat(e.target.value),
		}));
	};

	handleSeekMouseUp = (e) => {
		console.log("handleSeekMouseUp event : ", e);
		console.log("handleSeekMouseUp e.target.value : ", parseFloat(e.target.value));
		this.player.seekTo(parseFloat(e.target.value));

		console.log("handleSeekMouseDown");
		this.setState((prevState, props) => ({
			...prevState,
			seeking: false,
		}));
	};

	onSliderChange = (value) => {
		this.player.seekTo(parseFloat(value));
		// this.setState((prevState, props) => ({
		// 	...prevState,
		// 	playedSeconds: parseFloat(value),
		// }));
	};

	handleOnClickFullscreen = () => {
		screenfull.request(findDOMNode(this));
	};

	handleExpand = (e) => {
		const { currentTarget : { dataset : { source } = {}} = {}} = e;
		var {
			card: {
				metadata: { id: cardid, size: cardsize },
			},
			stepmetadata: { id: stepid },
			stepindex,
			handleExpand,
		} = this.props;
		console.log("YoutubeComponent handleExpand  ", e);

		var requestedcardsize = 4;
		if (source=="expand")
		{
			requestedcardsize = cardsize + 2;

		} else if (source=="contract")
		{
			requestedcardsize = cardsize - 2;

		} else {
			requestedcardsize = e.value;
		}

		handleExpand({ stepindex: stepindex, cardid: cardid, cardsize: requestedcardsize });
		// handleExpand({ stepindex: stepindex, cardid: cardid, cardsize: e.value });
	};

	handleDeleteCard = () => {
		const {
			card: {
				metadata: { id: cardid },
			},
			stepmetadata: { id: stepid },
			stepindex,
			handleShowDeleteCardModal,
		} = this.props;
		handleShowDeleteCardModal({ stepindex: stepindex, cardid: cardid });
	}
	

	preloadClick = () => {
		const { controlsVisible } = this.state;
		console.log("preloadClick ");
		const {
			stepindex,
			card: {
				metadata: { id: cardid, size: cardsize },
			},
			handleExpand,
		} = this.props;

		let newcardsize = cardsize;
		if (cardsize < 4) {
			newcardsize = 4;
		}
		handleExpand({ stepindex: stepindex, cardid: cardid, cardsize: newcardsize });

		this.setState((prevState) => ({
			...prevState,
			controlsVisible: !controlsVisible,
		}));
	};

	ref = (player) => {
		this.player = player;
	};

	render() {
		const { controlsVisible } = this.state;
		const {
			stepindex,
			card: {
				metadata: { id: cardid, size: cardsize, title: cardtitle },
				details: {
					youtubevideoid,
					durationstart,
					durationstarthour,
					durationstartmin,
					durationstartsec,
					durationend,
					durationendhour,
					durationendmin,
					durationendsec,
					youtubethumbnailhigh,
				} = {},
				// details,
			},
			stepmetadata: { title: steptitle },
		} = this.props;

		const { playing, played, playedSeconds } = this.state;
		const { isEditMode } = this.props;

		// console.log("youtubecomponent props:  ", JSON.stringify(this.props, null, 2));
		return (
			<Fragment>
				<div className="self-stretch flex flex-row items-center p-3">
					<div className="flex-grow self-stretch flex flex-col items-center overflow-hidden cursor-pointer  ">
						<div className="self-stretch flex flex-row-reverse items-center flex-none min-w-0 ">
							<div className="flex-0 flex flex-row items-center self-stretch">
								{/* <div className="mr-2">
							<svg className="w-6 h-6 opacity-40  " fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
							<path d="M15 3h6v6M9 21H3v-6M21 3l-7 7M3 21l7-7"/>
							</svg>
</div>
<div className="">
							<svg className="w-6 h-6 opacity-40 " fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
							<path d="M15 3h6v6M9 21H3v-6M21 3l-7 7M3 21l7-7"/>
							</svg>
</div> */}

								{cardsize > 5 ? (
									<button onClick={this.handleExpand} data-source="contract" disabled={false} className=" disabled:opacity-30  focus:outline-none w-full inline-flex justify-center pl-3 px-2 py-1 font-medium  hover:bg-bluegray-50 hover:text-bluegray-100 sm:mt-0 sm:ml-0 sm:w-auto sm:text-sm">
										<span className="self-stretch flex flex-row items-center -ml-2">
											<svg className="h-5 w-5 fill-current text-bluegray-600" viewBox="0 0 20 20" fill="currentColor">
												<path fillRule="evenodd" d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z" clipRule="evenodd" />
											</svg>
										</span>
									</button>
								) : null}

								<button onClick={this.handleExpand} data-source="expand" disabled={false} className=" disabled:opacity-50  focus:outline-none w-full inline-flex justify-center  px-2 py-1 font-medium  hover:bg-bluegray-50 hover:text-bluegray-100 sm:mt-0 sm:ml-0 sm:w-auto sm:text-sm">
									<span className="self-stretch flex flex-row  items-center ">
										<svg className="h-5 w-5 fill-current text-bluegray-600" viewBox="0 0 20 20" fill="currentColor">
											<path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
										</svg>
									</span>
								</button>

								<Menu
									menuButton={
										<span className="group self-center  hover:text-black  group-hover:text-bluegray-600 focus:text-bluegray-600 focus:outline-none text-bluegray-500 font-Inter font-base text-sm  py-1 px-1 inline-flex items-center  cursor-pointer">
											{/* <svg className="h-6 w-6 p-0 mr-1 fill-current  text-bluegray-600   opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
																				</svg> */}

											{/* <svg className="mx-2 h-6  opacity-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
										</svg> */}

											<svg className="mx-2 h-6  opacity-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
											</svg>
										</span>

										// <svg className="h-6 w-6 p-0 mr-2 fill-current  text-bluegray-600   opacity-50" viewBox="0 0 16 16"><path d="M8 6.5A1.5 1.5 0 1 0 9.5 8 1.5 1.5 0 0 0 8 6.5zm0 5A1.5 1.5 0 1 0 9.5 13 1.5 1.5 0 0 0 8 11.47zm0-6.97A1.5 1.5 0 1 0 6.5 3 1.5 1.5 0 0 0 8 4.53z"></path></svg>
									}
								>
									{/* <MenuItem onClick={this.handleAddCard} value={cardid} >
																			<div className="self-stretch  flex flex-row items-center">
																				<svg className="-ml-4 mr-2 h-4 w-4 p-0  fill-current  text-bluegray-600   opacity-80" viewBox="0 0 461.001 461.001">
																					<path fill="#f61c0d" d="M365.257 67.393H95.744C42.866 67.393 0 110.259 0 163.137v134.728c0 52.878 42.866 95.744 95.744 95.744h269.513c52.878 0 95.744-42.866 95.744-95.744V163.137c0-52.878-42.866-95.744-95.744-95.744zm-64.751 169.663l-126.06 60.123c-3.359 1.602-7.239-.847-7.239-4.568V168.607c0-3.774 3.982-6.22 7.348-4.514l126.06 63.881c3.748 1.899 3.683 7.274-.109 9.082z" />
																				</svg>
																				<span className="text-base font-Inter font-medium">Edit</span>
																			</div>
																		</MenuItem>
																		<MenuDivider /> */}
									{/* <MenuHeader>Size</MenuHeader> */}
									{/* <MenuItem onClick={this.toggleModelAddAsset} value={cardid}>
																			<div className="self-stretch  flex flex-row items-center">
																				<svg className="-ml-4 mr-2 h-4 w-4 p-0  opacity-80" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" clipRule="evenodd" viewBox="0 0 512 512">
																					<g fillRule="nonzero">
																						<path fill="#1da1f2" d="M448 512H64c-35.328 0-64-28.672-64-64V64C0 28.672 28.672 0 64 0h384c35.328 0 64 28.672 64 64v384c0 35.328-28.672 64-64 64z"/>
																						<path fill="#fff" d="M196.608 386.048c120.704 0 186.752-100.096 186.752-186.752 0-2.816 0-5.632-.128-8.448 12.8-9.216 23.936-20.864 32.768-34.048-11.776 5.248-24.448 8.704-37.76 10.368 13.568-8.064 23.936-20.992 28.928-36.352-12.672 7.552-26.752 12.928-41.728 15.872-12.032-12.8-29.056-20.736-47.872-20.736-36.224 0-65.664 29.44-65.664 65.664 0 5.12.64 10.112 1.664 14.976-54.528-2.688-102.912-28.928-135.296-68.608-5.632 9.728-8.832 20.992-8.832 33.024 0 22.784 11.648 42.88 29.184 54.656a66.39 66.39 0 01-29.696-8.192v.896c0 31.744 22.656 58.368 52.608 64.384-5.504 1.536-11.264 2.304-17.28 2.304-4.224 0-8.32-.384-12.288-1.152 8.32 26.112 32.64 45.056 61.312 45.568-22.528 17.664-50.816 28.16-81.536 28.16-5.248 0-10.496-.256-15.616-.896 28.928 18.432 63.488 29.312 100.48 29.312"/>
																					</g>
																					</svg>
																				<span className="text-base font-Inter font-medium">Expand</span>
																			</div>
																		</MenuItem>


																			<MenuItem ><div className="flex-grow flex flex-row items-center self-stretch">

																			<div className="self-stretch  flex flex-row items-center flex-1 debug-border" onClick={this.toggleModelAddAsset} value={cardid}>
																				<svg className=" p-0  opacity-80" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect></svg>
																				</div>
																				<div className="self-stretch  flex flex-row items-center flex-1 debug-border" onClick={this.toggleModelAddAsset} value={cardid}>
																				<svg className=" p-0  opacity-80" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect></svg>
																				</div>
																				<div className="self-stretch  flex flex-row items-center flex-1 debug-border" onClick={this.toggleModelAddAsset} value={cardid}>
																				<svg className=" p-0  opacity-80" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" className="feather feather-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect></svg>
																				</div>
																				<div className="self-stretch  flex flex-row items-center flex-1 debug-border" onClick={this.toggleModelAddAsset} value={cardid}>
																				<svg className=" h-4  p-0  opacity-80" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect></svg>
																				</div>
																				</div>

																			</MenuItem> */}

									{isEditMode ? (
										<MenuItem disabled={true} onClick={this.handleExpand} value="2">
											<span className="text-sm font-Inter font-semibold">Edit</span>
										</MenuItem>
									) : null}

									{isEditMode ? (
										<MenuItem onClick={this.handleDeleteCard} value="">
											<span className="text-sm font-Inter font-semibold">Delete</span>
										</MenuItem>
									) : null}

									<SubMenu label={<span className="text-sm font-Inter font-semibold ">Card Size</span>}>
										<MenuItem onClick={this.handleOnClickFullscreen}>
											<span className="text-sm font-Inter font-semibold">Fullscreen</span>
										</MenuItem>
										<MenuItem onClick={this.handleExpand} value="12">
											<span className="text-sm font-Inter font-semibold">Full Width</span>
										</MenuItem>
										<MenuItem onClick={this.handleExpand} value="10">
											<span className="text-sm font-Inter font-semibold">XLarge</span>
										</MenuItem>
										<MenuItem onClick={this.handleExpand} value="8">
											<span className="text-sm font-Inter font-semibold">Large</span>
										</MenuItem>
										<MenuItem onClick={this.handleExpand} value="6">
											<span className="text-sm font-Inter font-semibold">Medium</span>
										</MenuItem>
										<MenuItem onClick={this.handleExpand} value="4">
											<span className="text-sm font-Inter font-semibold">Small</span>
										</MenuItem>
										{/* <MenuItem onClick={this.handleExpand} value="2">
										<span className="text-sm font-Inter font-semibold">Mini</span>
									</MenuItem> */}
									</SubMenu>

									{/* <MenuItem onClick={this.toggleModelAddAsset} value={cardid}>
																			<div className="self-stretch  flex flex-row items-center">
																			<svg  className="-ml-4 mr-2 h-4 w-4 p-0  opacity-80"   fill="none" viewBox="0 0 24 24" stroke="currentColor">
																			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
																			</svg>
																				<span className="text-base font-Inter font-medium">Collapse</span>
																			</div>
																		</MenuItem> */}
								</Menu>
							</div>

							<div className="self-stretch flex flex-row items-center flex-grow min-w-0">
								{/* <div className="self-stretch flex flex-row items-center flex-0">
								<svg className="ml-4 w-5 opacity-70 self-stretch " fill="currentColor" viewBox="0 -77 512.00213 512" xmlns="http://www.w3.org/2000/svg">
									<path
										d="m501.453125 56.09375c-5.902344-21.933594-23.195313-39.222656-45.125-45.128906-40.066406-10.964844-200.332031-10.964844-200.332031-10.964844s-160.261719 0-200.328125 10.546875c-21.507813 5.902344-39.222657 23.617187-45.125 45.546875-10.542969 40.0625-10.542969 123.148438-10.542969 123.148438s0 83.503906 10.542969 123.148437c5.90625 21.929687 23.195312 39.222656 45.128906 45.128906 40.484375 10.964844 200.328125 10.964844 200.328125 10.964844s160.261719 0 200.328125-10.546875c21.933594-5.902344 39.222656-23.195312 45.128906-45.125 10.542969-40.066406 10.542969-123.148438 10.542969-123.148438s.421875-83.507812-10.546875-123.570312zm0 0"
										fill="#f00"
									/>
									<path d="m204.96875 256 133.269531-76.757812-133.269531-76.757813zm0 0" fill="#fff" />
								</svg>
							</div> */}

								{/* <div className="self-stretch font-Inter text-sm font-semibold text-bluegray-500 tracking-tight leading-tight   flex flex-row items-center ml-1 truncate"> {cardtitle} </div> */}
							</div>
						</div>

						{/* <div className="w-full h-32 opacity-95 debug-border bg-cover bg-center " style={{ backgroundImage: `url('${"https://i.ytimg.com/vi/3hLmDS179YE/mqdefault.jpg"}')` }}></div> */}

						<div className={`w-full border-0 `} style={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden" }}>
							<ReactPlayer
								style={{ position: "absolute", width: "100%", height: "100%", left: "0", top: "0" }}
								ref={this.ref}
								playing={playing}
								onPlay={this.handleOnPlay}
								onPause={this.handleonPause}
								progressInterval={200}
								// url={url}
								url={`https://www.youtube.com/watch?v=${youtubevideoid}`}
								// url="https://www.youtube.com/watch?v=a_kVafB-0C4"
								// url="https://www.youtube.com/watch?v=rfscVS0vtbw&t=17s"
								// url="https://www.youtube.com/watch/9qZAfcZx6U0"
								width="100%"
								height="100%"
								light={true}
								playIcon={
									// <PlaySvg className=" w-1/5 bg-gray-600 " />
									<div className="w-full h-full bg-cover bg-center hover:opacity-60" onClick={this.preloadClick} style={{ backgroundImage: `url('${youtubethumbnailhigh}')` }}>
										{/* overlay */}
									</div>

									// <svg className=" w-full fill-current text-indigo-500 bg-red-500 " xviewBox="0 0 24 24" stroke="currentColor">
									// <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
									// <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
									// </svg>
								}
								onStart={() => console.log("onStart")}
								onReady={() => {
									// console.log("onReady: ", stepindex);
									// this.setState((prevState) => {
									// 	const newLabelList = [...prevState.labelList];
									// 	newLabelList[index].ready = true;
									// 	return { labelList: newLabelList };
									// }
								}}
								onEnded={() => {
									// this.scrollToNext(index);
									// this.setState((prevState) => {
									// 	const newLabelList = [...prevState.labelList];
									// 	newLabelList[index].ready = false;
									// 	newLabelList[index + 1].ready = true;
									// 	return { labelList: newLabelList };
									// });
								}}
								onProgress={this.handleProgress}
								// onDuration={this.handleDuration}
								stopOnUnmount={true}
								config={{
									youtube: {
										playerVars: { start: durationstart == 0 ? 1 : durationstart, end: durationend, modestbranding: 1, controls: 0, rel: 0, showinfo: 0 },
									},
								}}
							/>
							<div className="absolute h-7 w-full m-auto bg-bluegray-700 bg-opacity-70 text-white">
								<div className="self-stretch flex flex-row items-center flex-grow min-w-0 pl-2 pt-1">
									<div className="self-stretch font-Inter text-sm  font-medium  tracking-tight leading-tight   flex flex-row items-center ml-1 truncate">
										
										{cardtitle}
									</div>
								</div>
							</div>

							<div className="absolute h-17 bottom-0 w-full mx-auto bg-bluegray-400 bg-opacity-70">
								{/* <div className="self-stretch flex flex-row items-center flex-grow min-w-0 pl-2 pt-1">
									<div className="self-stretch font-Inter text-base font-semibold  tracking-tight leading-tight   flex flex-row items-center ml-1 truncate"> {cardtitle} </div>
							</div> */}

								{/* <div className=" self-stretch flex-grow py-1 px-3 group-hover:bg-bluegray-200 group-hover:text-bluegray-900">
								<div className="self-stretch flex-grow  flex flex-row items-center justify-between  h-3 ">
									<div className="flex flex-row items-center  border-r border-gray-900 pr-3">
										<svg className="h-3 mr-2 text-black stroke-current" fill="none" viewBox="0 0 24 24" >
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z" />
										</svg>

										<span className=" font-medium  text-xs tracking-normal text-right  ">
											{details.starthour && parseInt(details.starthour) != 0 ? <span>{details.starthour}:</span> : ""}
											{details.startmin}:{details.startsec}
										</span>
										<svg className="mx-1 text-black stroke-current" width="13" height="11" viewBox="0 0 13 11">
											<path
												d="M9.38278 5.94244H1.85331C1.71043 5.94244 1.5734 5.89342 1.47237 5.80617C1.37133 5.71891 1.31458 5.60057 1.31458 5.47717C1.31458 5.35378 1.37133 5.23543 1.47237 5.14818C1.5734 5.06092 1.71043 5.01191 1.85331 5.01191H9.38278L9.92151 5.47717L9.38278 5.94244ZM8.33986 8.59778L11.5722 5.80617C11.6223 5.76297 11.662 5.71168 11.689 5.65523C11.7161 5.59878 11.73 5.53827 11.73 5.47717C11.73 5.41607 11.7161 5.35557 11.689 5.29912C11.662 5.24267 11.6223 5.19138 11.5722 5.14817L8.33986 2.35657C8.23883 2.26931 8.1018 2.22029 7.95891 2.22029C7.81603 2.22029 7.679 2.26931 7.57796 2.35657C7.47693 2.44382 7.42017 2.56217 7.42017 2.68557C7.42017 2.80897 7.47693 2.92732 7.57796 3.01457L10.4294 5.47717L7.57796 7.93977C7.47693 8.02703 7.42017 8.14537 7.42017 8.26877C7.42017 8.39217 7.47693 8.51052 7.57796 8.59778C7.679 8.68503 7.81603 8.73405 7.95891 8.73405C8.1018 8.73405 8.23883 8.68503 8.33986 8.59778Z"
												fill="#949494"
											/>
										</svg>

										<span className="  font-medium  text-xs tracking-normal text-right  ">
											{details.endhour && parseInt(details.endhour) != 0 ? <span>{details.endhour}:</span> : ""}
											{details.endmin}:{details.endsec}
										</span>
									</div>

									<div className="flex flex-row items-center  ml-3 pl-3 border-l border-gray-900">
										<svg className="h-3 mr-2" fill="none" viewBox="0 0 24 24" >
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
										</svg>

										<span className=" font-medium  text-xs tracking-normal text-right ">
											{details.durationhour ? <span>{details.durationhour} hour</span> : ""}
											{details.durationmin} min {details.durationsec} sec
										</span>
									</div>
								</div>
							</div> */}
							</div>
						</div>

						<div className="-mt-5 self-stretch flex flex-row items-center justify-between flex-grow min-w-0  pt-1 z-10">
							<div className="self-stretch font-Inter text-base font-semibold  tracking-tight leading-tight   flex flex-row items-center truncate bg-bluegray-500 bg-opacity-70 text-white">
							<svg className="h-3 pl-2  stroke-current "  fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z" />
											</svg>
											
											<span className="font-Poppins font-medium pl-2 text-xs tracking-normal text-right ">
									<StartHourMinSec durationstart={durationstart} durationend={durationend} />
								</span>
								<svg className="mx-1 w-4 fill-current "  viewBox="0 0 13 11">
									<path
										d="M9.38278 5.94244H1.85331C1.71043 5.94244 1.5734 5.89342 1.47237 5.80617C1.37133 5.71891 1.31458 5.60057 1.31458 5.47717C1.31458 5.35378 1.37133 5.23543 1.47237 5.14818C1.5734 5.06092 1.71043 5.01191 1.85331 5.01191H9.38278L9.92151 5.47717L9.38278 5.94244ZM8.33986 8.59778L11.5722 5.80617C11.6223 5.76297 11.662 5.71168 11.689 5.65523C11.7161 5.59878 11.73 5.53827 11.73 5.47717C11.73 5.41607 11.7161 5.35557 11.689 5.29912C11.662 5.24267 11.6223 5.19138 11.5722 5.14817L8.33986 2.35657C8.23883 2.26931 8.1018 2.22029 7.95891 2.22029C7.81603 2.22029 7.679 2.26931 7.57796 2.35657C7.47693 2.44382 7.42017 2.56217 7.42017 2.68557C7.42017 2.80897 7.47693 2.92732 7.57796 3.01457L10.4294 5.47717L7.57796 7.93977C7.47693 8.02703 7.42017 8.14537 7.42017 8.26877C7.42017 8.39217 7.47693 8.51052 7.57796 8.59778C7.679 8.68503 7.81603 8.73405 7.95891 8.73405C8.1018 8.73405 8.23883 8.68503 8.33986 8.59778Z"
										
									/>
								</svg>
								<span  className="font-Poppins font-medium pr-1 text-xs tracking-normal text-right ">
									<EndHourMinSec durationstart={durationstart} durationend={durationend} />
								</span>
							</div>
							<div className="self-stretch font-Inter text-base font-semibold  tracking-tight leading-tight   flex flex-row items-center ml-1 truncate bg-bluegray-500  bg-opacity-80 text-white">
								<div className="flex flex-row items-center pl-3 ">
									<svg className="h-3 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>

									<span className="font-Poppins font-medium  text-xs tracking-normal text-right pr-2">
										<HourMinSec durationstart={durationstart} durationend={durationend} />
									</span>
								</div>
							</div>
						</div>

						{/* <div className=" self-stretch flex-grow bg-white p pt-1  group-hover:bg-bluegray-200 group-hover:text-bluegray-900">
						<div className="self-stretch flex flex-col items-center ">
							<div className="px-2  font-Inter text-sm font-bold tracking-tight leading-tight  justify-self-start self-stretch">
								<div className="self-stretch flex flex-row items-center ">
								{steptitle}


								</div>



								<div className="flex flex-row items-center mb-1">
									<span className="font-Poppins font-medium  text-2xs tracking-normal text-right text-gray-700 ">
										{details.starthour && parseInt(details.starthour) != 0 ? <span>{details.starthour}:</span> : ""}
										{details.startmin}:{details.startsec}
									</span>
									<svg className="mx-1" width="13" height="11" viewBox="0 0 13 11">
										<path
											d="M9.38278 5.94244H1.85331C1.71043 5.94244 1.5734 5.89342 1.47237 5.80617C1.37133 5.71891 1.31458 5.60057 1.31458 5.47717C1.31458 5.35378 1.37133 5.23543 1.47237 5.14818C1.5734 5.06092 1.71043 5.01191 1.85331 5.01191H9.38278L9.92151 5.47717L9.38278 5.94244ZM8.33986 8.59778L11.5722 5.80617C11.6223 5.76297 11.662 5.71168 11.689 5.65523C11.7161 5.59878 11.73 5.53827 11.73 5.47717C11.73 5.41607 11.7161 5.35557 11.689 5.29912C11.662 5.24267 11.6223 5.19138 11.5722 5.14817L8.33986 2.35657C8.23883 2.26931 8.1018 2.22029 7.95891 2.22029C7.81603 2.22029 7.679 2.26931 7.57796 2.35657C7.47693 2.44382 7.42017 2.56217 7.42017 2.68557C7.42017 2.80897 7.47693 2.92732 7.57796 3.01457L10.4294 5.47717L7.57796 7.93977C7.47693 8.02703 7.42017 8.14537 7.42017 8.26877C7.42017 8.39217 7.47693 8.51052 7.57796 8.59778C7.679 8.68503 7.81603 8.73405 7.95891 8.73405C8.1018 8.73405 8.23883 8.68503 8.33986 8.59778Z"
											fill="#949494"
										/>
									</svg>

									<span className=" font-Poppins  font-medium  text-2xs tracking-normal text-right text-gray-700 ">
										{details.endhour && parseInt(details.endhour) != 0 ? <span>{details.endhour}:</span> : ""}
										{details.endmin}:{details.endsec}
									</span>
								</div>

								<div className="flex flex-row items-center mb-1">
									<span className="font-Poppins font-medium  text-2xs tracking-normal text-right text-gray-700">
										{details.durationhour ? <span>{details.durationhour} hour</span> : ""}
										{details.durationmin} min {details.durationsec} sec
									</span>
								</div>
							</div>




							<div className="self-stretch border-b border-gray-300 border-1"></div>
							<div className="self-stretch flex flex-row items-center justify-between">

<div className="flex flex-row items-start self-stretch ">
										<svg className="ml-1  h-4  opacity-70 self-stretch " fill="currentColor" viewBox="0 -77 512.00213 512" xmlns="http://www.w3.org/2000/svg">
											<path
												d="m501.453125 56.09375c-5.902344-21.933594-23.195313-39.222656-45.125-45.128906-40.066406-10.964844-200.332031-10.964844-200.332031-10.964844s-160.261719 0-200.328125 10.546875c-21.507813 5.902344-39.222657 23.617187-45.125 45.546875-10.542969 40.0625-10.542969 123.148438-10.542969 123.148438s0 83.503906 10.542969 123.148437c5.90625 21.929687 23.195312 39.222656 45.128906 45.128906 40.484375 10.964844 200.328125 10.964844 200.328125 10.964844s160.261719 0 200.328125-10.546875c21.933594-5.902344 39.222656-23.195312 45.128906-45.125 10.542969-40.066406 10.542969-123.148438 10.542969-123.148438s.421875-83.507812-10.546875-123.570312zm0 0"
												fill="#f00"
											/>
											<path d="m204.96875 256 133.269531-76.757812-133.269531-76.757813zm0 0" fill="#fff" />
										</svg>
										<div className="ml-1 font-Inter text-xs font-semibold tracking-tight leading-tight self-stretch ">Youtube</div>
</div>


							</div>


						</div>
					</div> */}

						<Fragment>
							{controlsVisible ? (
								<div className="flex flex-col items-center self-stretch ">
									<div className="w-full flex flex-row self-stretch items-center p-1">
										<div className="">
											<button className="ring-0 ring-bluegray-400 hover:text-purple-700 focus:outline-none focus:ring-0 focus:ring-bluegray-500 focus:ring-opacity-50" onClick={this.handlePlayPause}>
												{!playing ? (
													<Fragment>
														{/* <svg className="h-8 fill-current text-bluegray-400 bg-gray-300" viewBox="0 0 100 100">
													<defs />
													<path d="M39.806 72.858h-8.915a3.94 3.94 0 01-3.94-3.94V31.119a3.94 3.94 0 013.94-3.94h8.915a3.94 3.94 0 013.94 3.94v37.799a3.94 3.94 0 01-3.94 3.94zM68.109 72.821h-8.915a3.94 3.94 0 01-3.94-3.94V31.082a3.94 3.94 0 013.94-3.94h8.915a3.94 3.94 0 013.94 3.94v37.799a3.94 3.94 0 01-3.94 3.94z" />
													<path d="M40.489 27.248a3.919 3.919 0 011.257 2.871v37.799a3.94 3.94 0 01-3.94 3.94h-8.915c-.234 0-.46-.03-.683-.069a3.916 3.916 0 002.683 1.069h8.915a3.94 3.94 0 003.94-3.94V31.119a3.934 3.934 0 00-3.257-3.871zM68.792 27.211a3.919 3.919 0 011.257 2.871v37.799a3.94 3.94 0 01-3.94 3.94h-8.915c-.234 0-.46-.03-.683-.069a3.916 3.916 0 002.683 1.069h8.915a3.94 3.94 0 003.94-3.94V31.082a3.933 3.933 0 00-3.257-3.871z" />
													<path fill="none" stroke="#000" strokeMiterlimit="10" d="M39.806 72.858h-8.915a3.94 3.94 0 01-3.94-3.94V31.119a3.94 3.94 0 013.94-3.94h8.915a3.94 3.94 0 013.94 3.94v37.799a3.94 3.94 0 01-3.94 3.94zM68.109 72.821h-8.915a3.94 3.94 0 01-3.94-3.94V31.082a3.94 3.94 0 013.94-3.94h8.915a3.94 3.94 0 013.94 3.94v37.799a3.94 3.94 0 01-3.94 3.94z" />
												</svg> */}
														<svg className="h-10  text-purple-500 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
															<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.7} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
															<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.7} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
														</svg>
													</Fragment>
												) : (
													<Fragment>
														<svg className="h-10  text-purple-500 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
															<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
														</svg>
														{/* <svg className="h-8 fill-current  text-bluegray-400 bg-gray-300" viewBox="0 0 100 100">
													<path d="M31.356 25.677l38.625 22.3c1.557.899 1.557 3.147 0 4.046l-38.625 22.3c-1.557.899-3.504-.225-3.504-2.023V27.7c0-1.798 1.946-2.922 3.504-2.023z" />
													<path d="M69.981 47.977l-38.625-22.3a2.343 2.343 0 00-.716-.259l37.341 21.559c1.557.899 1.557 3.147 0 4.046l-38.625 22.3a2.296 2.296 0 01-1.078.301c.656.938 1.961 1.343 3.078.699l38.625-22.3c1.557-.899 1.557-3.147 0-4.046z" />
													<path fill="none" stroke="#000" strokeMiterlimit="10" d="M31.356 25.677l38.625 22.3c1.557.899 1.557 3.147 0 4.046l-38.625 22.3c-1.557.899-3.504-.225-3.504-2.023V27.7c0-1.798 1.946-2.922 3.504-2.023z" />
												</svg> */}
													</Fragment>
												)}
											</button>
										</div>
										<div className="ml-1  pt-2 pb-1 self-stretch w-full flex flex-col items-center cursor-default ">
											<ProgressBar className="" played={((playedSeconds - durationstart) / durationend) * 100} playedSeconds={playedSeconds - durationstart} />

											<Slider
												className=" cursor-pointer  self-stretch"
												style={{ paddingTop: "0px", paddingBottom: "0px" }}
												min={durationstart}
												max={durationend}
												defaultValue={durationstart}
												value={playedSeconds}
												onChange={this.onRCSliderChange}
												trackStyle={{ backgroundColor: "rgba(139, 92, 246,1)", height: 10, marginTop: 0 }}
												handleStyle={{
													display: "none",
													//   borderColor: 'rgba(139, 92, 246,1)',
													//   height: 18,
													//   width: 18,
													//   marginLeft: 0,
													//   marginTop: -6,
													//   backgroundColor: 'rgba(139, 92, 246,0.5)',
												}}
												railStyle={{ backgroundColor: "lightgray", height: 10 }}
											/>

											{/* <input className="w-full"
                                        type='range' min={0} max={0.999999} step='any'
                                        value={((playedSeconds - details.start) / details.duration)}
                                        onMouseDown={this.handleSeekMouseDown}
                                        onChange={this.handleSeekChange}
                                        onMouseUp={this.handleSeekMouseUp}
                                    />  */}

											{/* <input className="w-full"
                                        type='range' min={details.start} max={details.start} step='any'
                                        value={((playedSeconds - details.start) / details.duration)}
                                        onMouseDown={this.handleSeekMouseDown}
                                        onChange={this.handleSeekChange}
                                        onMouseUp={this.handleSeekMouseUp}
                                    /> */}

											{/* <input className="w-full" type="range" 
										min={details.start} max={details.end} 
										step="any" value={playedSeconds}
										 onMouseDown={this.handleSeekMouseDown}
										  onChange={this.handleSeekChange} 
										  onMouseUp={this.handleSeekMouseUp} /> */}
										</div>

										{/* <button onClick={this.handleOnClickFullscreen}>Fullscreen</button> */}

										{/* {cardsize} */}
									</div>

									{/*  
								 <div className="self-stretch text-2xs">
									{playing ? "playing" : "paused"}
									{details.start} / {details.end} / playedSeconds : {playedSeconds} / played : {played}
								</div>  */}
								</div>
							) : (
								""
							)}
						</Fragment>

						<Fragment>
							{/* <div className=" self-stretch flex-grow bg-white  group-hover:bg-bluegray-200 group-hover:text-bluegray-900  border-b border-gray-200"></div>

							<div className=" self-stretch flex-grow py-1 px-3 group-hover:bg-bluegray-200 group-hover:text-bluegray-900">
								<div className="self-stretch flex-grow  flex flex-row items-center justify-between text-bluegray-500  h-3 ">
									<div className="flex flex-row items-center  border-r border-gray-200 pr-3">
										<Fragment>
											<svg className="h-3 mr-1 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z" />
											</svg>

											<span className="font-Poppins font-medium  text-2xs tracking-normal text-right  ">
												<StartHourMinSec durationstart={durationstart} durationend={durationend} />
											</span>

											<svg className="mx-1" width="13" height="11" viewBox="0 0 13 11">
												<path
													d="M9.38278 5.94244H1.85331C1.71043 5.94244 1.5734 5.89342 1.47237 5.80617C1.37133 5.71891 1.31458 5.60057 1.31458 5.47717C1.31458 5.35378 1.37133 5.23543 1.47237 5.14818C1.5734 5.06092 1.71043 5.01191 1.85331 5.01191H9.38278L9.92151 5.47717L9.38278 5.94244ZM8.33986 8.59778L11.5722 5.80617C11.6223 5.76297 11.662 5.71168 11.689 5.65523C11.7161 5.59878 11.73 5.53827 11.73 5.47717C11.73 5.41607 11.7161 5.35557 11.689 5.29912C11.662 5.24267 11.6223 5.19138 11.5722 5.14817L8.33986 2.35657C8.23883 2.26931 8.1018 2.22029 7.95891 2.22029C7.81603 2.22029 7.679 2.26931 7.57796 2.35657C7.47693 2.44382 7.42017 2.56217 7.42017 2.68557C7.42017 2.80897 7.47693 2.92732 7.57796 3.01457L10.4294 5.47717L7.57796 7.93977C7.47693 8.02703 7.42017 8.14537 7.42017 8.26877C7.42017 8.39217 7.47693 8.51052 7.57796 8.59778C7.679 8.68503 7.81603 8.73405 7.95891 8.73405C8.1018 8.73405 8.23883 8.68503 8.33986 8.59778Z"
													fill="#949494"
												/>
											</svg>

											<span className=" font-Poppins  font-medium  text-2xs tracking-normal text-right  ">
												<EndHourMinSec durationstart={durationstart} durationend={durationend} />
											</span>
										</Fragment>
									</div>

									<div className="flex flex-row items-center  ml-3 pl-3 border-l border-gray-200">
										<svg className="h-3 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
										</svg>

										<span className="font-Poppins font-medium  text-2xs tracking-normal text-right ">
											<HourMinSec durationstart={durationstart} durationend={durationend} />
										</span>
									</div>
								</div>
							</div> */}
						</Fragment>
					</div>
				</div>
			</Fragment>
		);
	}
}

// // ----------------------------------------------------------------------------------
// //  React Player functions
// // ----------------------------------------------------------------------------------

// handleProgress = state => {
// 	console.log('onProgress: ', state)
// 	// We only want to update time slider if we are not currently seeking
// 	// if (!this.state.seeking) {
// 	//   this.setState(state)
// 	// }

// 	// We only want to update time slider if we are not currently seeking
// 	if (!this.state.seeking) {
// 	  this.setState(state)
// 	}

// 	this.setState((prevState, props) => ({
// 		...prevState,
// 		isModalAddAssetToStepOpen: !prevState.isModalAddAssetToStepOpen,
// 	}));

// 	// https://stackoverflow.com/questions/29537299/react-how-to-update-state-item1-in-state-using-setstate
// 	// 1. Make a shallow copy of the items
// 	let items = [...filteredLabelList];
// 	// 2. Make a shallow copy of the item you want to mutate
// 	let item = { ...items[index] };
// 	// 3. Replace the property you're intested in
// 	item.completed = !item.completed;
// 	// 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
// 	items[index] = item;
// 	// 5. Set the state to our new copy
// 	this.setState((prevState, props) => ({
// 		...prevState,
// 		filteredLabelList: items,
// 	}));

// }

// handleDuration = (duration) => {
// 	console.log('onDuration ', duration)
// 	// this.setState({ duration })
//   }

// // ----------------------------------------------------------------------------------
// // end of React Player functions
// // ----------------------------------------------------------------------------------
