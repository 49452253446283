import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Switch, Route, Link, NavLink, withRouter } from "react-router-dom";
import PropTypes from 'prop-types'

// A simple component that shows the pathname of the current location
class ShowTheLocation extends React.Component {
    static propTypes = {
      match: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired
    };
  
    render() {
      const { match, location, history } = this.props;
      
  
      return <Fragment>
<div className="flex flex-col self-stretch items-start">
          <span className="text-xs  font-Lato text-gray-500 ">{'       '}   Your location is {location.pathname} , match is {match.path} </span>
          {/* <span className="text-2xs  font-Lato text-gray-500">location is {JSON.stringify(location,2,null)} </span>
          <span className="text-2xs  font-Lato text-gray-500">match is {JSON.stringify(match,2,null)} </span> */}
</div>
      
      
      </Fragment>;
    }
  }
  

  export default withRouter(ShowTheLocation);