import React, { Component, Fragment } from "react";
import QRCode from "react-qr-code";
import { useMediaQuery } from "react-responsive";
import MediaQuery from "react-responsive";
import { Scrollbars } from "react-custom-scrollbars";
import CategoryTitleCreatorComponent from "./CategoryTitleCreatorComponent";
import ProgressRing from "./../dev/ProgressRing";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";
import WStepsComponent from "./WStepsComponent";
import { MenuItem } from "@szhsin/react-menu";
import { Menu } from "@szhsin/react-menu";

const breakpoints = {
	sm: 640,
	md: 768,
	lg: 1024,
	xl: 1280,
	xxl: 1536,
};

export default class StepsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			videocount: "",
			hours: "",
			mins: "",
			secs: "",
		};
	}

	updateDurationCalculation = () => {
		const { filteredwalkbo: { WSteps: steps } = {} } = this.props;

		var videocount = 0;
		var totalduration = 0;
		steps.map((step) => {
			const {
				metadata: { title },
				cards,
			} = step;
			// console.log("title", title);
			cards.map((card) => {
				const {
					metadata: { type = "" },
					details: { duration = 0 } = {},
				} = card;
				if (type == "video") {
					videocount += 1;
					totalduration = totalduration + duration;
				}
			});
		});
		// console.log("videocount ", videocount);
		// console.log("totalduration sec ", totalduration);
		var hours = Math.floor(totalduration / 3600);
		// console.log("hours ", hours);
		var remainingsecs = totalduration - hours * 3600;
		var mins = Math.floor(remainingsecs / 60);
		// console.log("mins ", mins);
		remainingsecs = remainingsecs - mins * 60;
		// console.log("remainingsecs ", remainingsecs);

		this.setState((prevState, props) => ({
			...prevState,
			videocount: videocount,
			hours: hours,
			mins: mins,
			secs: remainingsecs,
		}));
	};

	render() {
		const { videocount, hours, mins } = this.state;

		const { isPageFullWidth, handlePageSideBarChange,  isPageHasSideBar, isEditMode } = this.props;

		const { filteredwalkbo: { WMetadata: { title = "", originalcreator = "", description = "", tmedium = "", tstandard = "" } = {}, WMetadata, WSteps: steps } = {} } = this.props;

		const { setProgressRingRef } = this.props;

		const { setWStepsComponentRef } = this.props;
		const { selectAndScrollStep, setStepProgressFromWaypoint, handleExpand, handleShowAddContentYoutubeModel, handleShowDeleteCardModal, handleAddEditor, handleShowEditStepModal, handleShowDeleteStepModal, handleDeleteStep, handleShowAddStepModal, onDragEnd } = this.props;

		const { searchText, handleOnInputChange } = this.props;
		const { changeMode } = this.props;
		const { isScrollAtTheTop } = this.props;
		let someStyle = isPageHasSideBar
			? {
					width: "27rem",
					// marginLeft: "-2px",
			  }
			: { width: "5rem", marginLeft: "-6px" };
		return (
			<Fragment>
				{/* style={{ backgroundColor: "#e2e8f0" }} */}
				<section className={`container flex flex-col items-center self-stretch  flex-shrink-0  border-b border-bluegray-300 transition-all duration-500 bg-white ${isScrollAtTheTop ?  ' ' : ''} `}>
					

					<div className={`flex flex-col items-center self-stretch  flex-shrink-0   ${isPageFullWidth ? "" : "container"} `}>
						<div className="flex flex-row items-center self-stretch flex-grow ">
							<div className="flex flex-row-reverse items-center self-stretch pt-1" style={someStyle}>
								<Fragment>
									<div className="flex flex-row items-center self-stretch flex-grow-0" style={{ width: "4.5rem" }}>
										<Fragment>
											<div className=" flex flex-col items-center self-stretch ">
												<div className="self-stretch flex flex-col items-center justify-center">
													<ProgressRing ref={setProgressRingRef} className=" self-stretch flex-grow-0" style={{ width: "72px", minHeight: "72px" }} radius={36} stroke={5} />
													{/* <div className=" w-14 h-14 bg-bluegray-50 border-2 border-gray-600 mx-auto rounded-full text-white flex items-center opacity-50" style={{ marginTop: "-64px" }}>
													<span className="text-gray-700 text-center w-full font-inter font-semibold text-sm"></span>
												</div> */}
													{/* <div className=" w-4 h-4 bg-white border-2 border-gray-600 mx-auto rounded-full text-white flex items-center opacity-30" style={{ marginTop: "-45px" }}>
													<span className="text-gray-700 text-center w-full font-inter font-semibold text-sm"></span>
												</div> */}
												</div>

												{/* LINE */}
												<React.Fragment>
													<div className="-mt-1 z-10 flex-grow flex flex-col align-middle items-center">
														<div className=" flex-grow bg-gray-500   flex items-center" style={{ width: "0.175rem" }}>
															{" "}
														</div>
													</div>
												</React.Fragment>
											</div>
										</Fragment>
									</div>
								</Fragment>

								<Fragment>
									<MediaQuery minWidth={breakpoints.sm}>
										<div className="flex flex-col-reverse items-center self-stretch flex-grow ">{isPageHasSideBar ? <CategoryTitleCreatorComponent WMetadata={WMetadata} /> : null}</div>
									</MediaQuery>
								</Fragment>
							</div>

							<Fragment>
								<div className="flex flex-row items-start justify-between self-stretch  flex-grow pb-2 pt-3">

									
									
									{/* DESCRIPTION */}
									<div className="flex flex-row items-center self-stretch">

										{isPageHasSideBar ? null : <CategoryTitleCreatorComponent WMetadata={WMetadata} /> }
										<div className="p-1 pl-5 flex flex-col items-center self-stretch w-96">
											
												
											

											<div className="font-Inter font-base text-smaller text-gray-600  tracking-tighter overflow-hidden line-clamp-3 ">{description || "Description"}</div>
											<div className="flex flex-row items-center self-stretch flex-wrap   pt-2">
												{/* <span className="inline-flex bg-indigo-500 bg-opacity-80 text-white  font-Inter text-xs font-medium   rounded-full h-6 px-3 justify-center items-center cursor-pointer">Python</span>
												<span className="inline-flex bg-indigo-500 bg-opacity-80 text-white font-Inter text-xs font-medium  rounded-full h-6 px-3 justify-center items-center cursor-pointer">Course</span>
												<span className="inline-flex bg-indigo-500 bg-opacity-80 text-white font-Inter text-xs font-medium  rounded-full h-6 px-3 justify-center items-center cursor-pointer">Machine Learning</span>
												<span className="inline-flex bg-indigo-500 bg-opacity-80 text-white  font-Inter text-xs font-medium   rounded-full h-6 px-3 justify-center items-center cursor-pointer">AWS</span>
												<span className="inline-flex bg-indigo-500 bg-opacity-80 text-white font-Inter text-xs font-medium  rounded-full h-6 px-3 justify-center items-center cursor-pointer">Azure</span>
												<span className="inline-flex bg-indigo-500 bg-opacity-80 text-white font-Inter text-xs font-medium  rounded-full h-6 px-3 justify-center items-center cursor-pointer">React</span>
												<span className="inline-flex bg-pink-600 bg-opacity-80 text-white  font-Inter text-xs font-medium   rounded-full h-6 px-3 justify-center items-center cursor-pointer">Python</span>
												<span className="inline-flex bg-purple-600 bg-opacity-80 text-white font-Inter text-xs font-medium  rounded-full h-6 px-3 justify-center items-center cursor-pointer">Course</span> */}
												{/* <span className="inline-flex bg-indigo-600 bg-opacity-80 text-white font-Inter text-xs font-normal  rounded-full h-6 px-3 justify-center items-center cursor-pointer ml-2">Machine Learning</span>
												<span className="inline-flex bg-pink-600 bg-opacity-80 text-white  font-Inter text-xs font-medium   rounded-full h-6 px-3 justify-center items-center cursor-pointer ml-2">AWS</span>
												<span className="inline-flex bg-purple-600 bg-opacity-80 text-white font-Inter text-xs font-medium  rounded-full h-6 px-3 justify-center items-center cursor-pointer ml-2 ">Azure</span>
												<span className="inline-flex bg-indigo-600 bg-opacity-80 text-white font-Inter tracking-wider text-2xs font-normal  rounded-full h-6 px-3 justify-center items-center cursor-pointer ml-2">React</span> */}
												{/* <span className="inline-flex bg-bluegray-400 bg-opacity-80 text-white font-Inter text-xs font-normal  rounded-full h-6 px-3 justify-center items-center cursor-pointer ml-2">React</span> */}
											</div>
										</div>

										<div className="flex flex-col items-center self-stretch  pt-2 pl-5">
											<div className=" flex flex-row items-center self-stretch ">
												<div className="flex-row flex items-center justify-center self-stretch w-5">
													<svg className="h-4 text-purple-600 stroke-current" viewBox="0 0 5 12" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="2.45571" cy="2.34633" r="1.43657" strokeWidth="1.2" />
														<circle cx="2.45571" cy="9.54165" r="1.43657" strokeWidth="1.2" />
														<line x1="2.42441" y1="4.19531" x2="2.42441" y2="7.62943" strokeWidth="1.2" />
													</svg>
												</div>
												<div className="ml-1 font-Inter text-2xs font-bold">{steps.length} Steps</div>
											</div>
											<div className="flex flex-row items-center self-stretch pt-0.5">
												<div className="flex-row flex items-center justify-center self-stretch w-5">
													<svg className="h-4 text-purple-600 stroke-current" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path
															d="M8.4077 4.5406L3.79064 1.46159C3.57294 1.31635 3.31986 1.23298 3.05846 1.22042C2.79705 1.20785 2.53715 1.26655 2.30652 1.39024C2.07589 1.51394 1.88321 1.69798 1.74907 1.92269C1.61492 2.1474 1.54436 2.40434 1.54493 2.66604V8.82261C1.54489 9.08407 1.61583 9.34064 1.75018 9.56494C1.88453 9.78923 2.07726 9.97285 2.30779 10.0962C2.53833 10.2195 2.79803 10.278 3.05918 10.2653C3.32033 10.2526 3.57314 10.1693 3.79064 10.0242L8.4077 6.94517C8.60549 6.81328 8.76767 6.63461 8.87983 6.425C8.992 6.21539 9.05069 5.98134 9.05069 5.74361C9.05069 5.50587 8.992 5.27182 8.87983 5.06221C8.76767 4.85261 8.60549 4.67393 8.4077 4.54204V4.5406Z"
															strokeWidth="1.4"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</div>

												<div className="ml-1 font-Inter text-2xs font-bold">{videocount} Videos</div>
											</div>
											<div className="flex flex-row items-center self-stretch pt-0.5">
												<div className="flex-row flex items-center justify-center self-stretch w-5">
													<svg className="h-4 text-purple-500 stroke-current" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M10.4496 1.16992H2.46114C1.84618 1.16992 1.34766 1.66844 1.34766 2.2834V10.2719C1.34766 10.8868 1.84618 11.3854 2.46114 11.3854H10.4496C11.0646 11.3854 11.5631 10.8868 11.5631 10.2719V2.2834C11.5631 1.66844 11.0646 1.16992 10.4496 1.16992Z" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
														<path d="M3.90137 1.16992V11.3854" strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round" />
														<path d="M9.00977 1.16992V11.3854" strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round" />
														<path d="M1.34766 6.27734H11.5631" strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round" />
														<path d="M1.34766 3.72461H3.90152" strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round" />
														<path d="M1.34766 8.83203H3.90152" strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round" />
														<path d="M9.00977 8.83203H11.5636" strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round" />
														<path d="M9.00977 3.72461H11.5636" strokeWidth="1.0" strokeLinecap="round" strokeLinejoin="round" />
													</svg>
												</div>

												<div className="ml-1 font-Inter text-2xs font-bold">
													{" "}
													{hours} hours {mins} mins
												</div>
											</div>
										</div>
									</div>

									{/* ACTIONS */}
									<div className="flex flex-row items-start flex-grow-0 p-2 pr-6 self-stretch " style={{}}>
										<div className="self-stretch ">
											<QRCode size={40} value="hey" />
										</div>
										{!isEditMode ? (
											<Fragment>
												<button type="submit" className=" w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-3 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
													<span className=" mr-2 self-stretch flex flex-row  items-center ">
														<svg className="h-4 w-4 text-gray-700 fill-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
															<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
														</svg>
													</span>

													<span className="self-stretch font-Inter font-base text-sm text-gray-700 ">Share</span>
												</button>
												<Fragment>
													<div className="flex flex-row items-start self-stretch flex-wrap flex-grow ">
														<button onClick={changeMode} type="button" className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-3  bg-purple-600 text-base font-medium text-bluegray-50 hover:bg-purple-500 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" style={{ paddingTop: "0.375rem", paddingBottom: "0.375rem" }}>
															<div className="px-2 self-stretch flex flex-row  items-center ">Edit</div>
														</button>
														<Menu
															menuButton={
																// <span className="px-2 group self-stretch border-l border-bluegray-200  hover:bg-bluegray-100  group-hover:text-bluegray-600 focus:text-bluegray-600 focus:outline-none text-bluegray-500 font-Inter font-base text-sm  inline-flex items-center cursor-pointer">
																// 	<svg className="h-6 w-6 p-0 mr-1 fill-current  text-bluegray-600   opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																// 		<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
																// 	</svg>
																// 	<span className="text-sm font-Inter font-medium">Add Card</span>
																// </span>
																// <svg className="mx-2 h-6 text-bluegray-600 opacity-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																// 	<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
																// </svg>

																<div className="w-full inline-flex justify-center rounded-md border border-bluegray-300 shadow-sm  text-base font-medium   hover:bg-bluegray-300 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" style={{ paddingTop: "0.225rem", paddingBottom: "0.225rem" }}>
																	<svg className="mx-1 my-0 w-6 text-bluegray-500 opacity-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																		<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
																	</svg>
																</div>
															}
															arrow={true}
														>
															<MenuItem disabled>
																<div className="self-stretch  flex flex-row items-center">
																	{/* <svg className="-ml-4 mr-2 h-4 w-4 p-0  fill-current  text-bluegray-600   opacity-80" viewBox="0 0 20 20" fill="currentColor">
														<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
													</svg> */}
																	{/* <svg className="-ml-4 mr-2 h-4 w-4 p-0  fill-current  text-bluegray-600   opacity-80" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" ><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg> */}
																	<span className="text-sm font-Inter font-medium">Placeholder</span>
																</div>
															</MenuItem>
															<MenuItem disabled>
																<div className="self-stretch  flex flex-row items-center">
																	{/* <svg className="-ml-4 mr-2 h-4 w-4 p-0  fill-current  text-bluegray-600   opacity-80" viewBox="0 0 20 20" fill="currentColor">
														<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
													</svg> */}
																	{/* <svg className="-ml-4 mr-2 h-4 w-4 p-0  fill-current  text-bluegray-600   opacity-80" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" ><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg> */}
																	<span className="text-sm font-Inter font-medium">Placeholder</span>
																</div>
															</MenuItem>
														</Menu>
													</div>
												</Fragment>
											</Fragment>
										) : // Edit Mode Actions
										null}
									</div>
								</div>
							</Fragment>

							<div className="border border-bluegray-200 self-stretch w-8 flex flex-col items-center ">
								{/* <div className="group flex flex-col self-stretch justify-center bg-bluegray-200  hover:bg-bluegray-400 hover:text-white group-hover:text-white " style={{flex:1}}>
									<svg className="mx-auto  h-6 w-6 stroke-current text-purple-700 group-hover:text-white " fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
										<rect width="18" height="18" x="3" y="3" rx="2" ry="2" />
										<path d="M9 3v18" />
									</svg>
								</div>
								<div className="flex flex-col self-stretch justify-center  border-t border-bluegray-200" style={{flex:1}}>
									<svg className="mx-auto h-6 w-6 stroke-current text-purple-700" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
										<rect width="18" height="18" x="3" y="3" rx="2" ry="2" />
										<path d="M9 3v18" />
									</svg>
								</div> */}
							</div>
						</div>
					</div>
				</section>

				<div className={`flex flex-row items-start  flex-grow overflow-y-hidden self-stretch  ${isPageFullWidth ? "" : "container"} `}>

					{isPageHasSideBar ? 						
					null : <button onClick={handlePageSideBarChange} disabled={false} className="disabled:opacity-50  focus:outline-none w-full inline-flex justify-center   pt-2 pl-3 pr-1 font-medium text-gray-700 hover:bg-bluegray-200  hover:text-white  sm:mt-0 sm:ml-0 sm:w-auto sm:text-sm">
					<span className="self-stretch flex flex-row items-center -ml-2">

					<svg className="h-5 w-5 stroke-current text-bluegray-500" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.647458 1.27637L16.6475 1.27637M0.64746 7.27637L13.5904 7.27637M0.647461 13.2764L16.6475 13.2764M8.64783 7.27674L15.7545 7.27674M15.7545 7.27674L12.7934 10.2379M15.7545 7.27674L12.7934 4.31562" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

					</span>
				</button>}
					<div
						className={`flex flex-col self-stretch items-center overflow-y-hidden flex-grow-0 bg-white  ${isPageHasSideBar ? "w-0 sm:w-96" : "w-0 sm:w-0"}`}
						style={{
							transition: "all 0.4s cubic-bezier(0.65, 0, 0.35, 1)",
							// backgroundColor: "#edf2f7",
						}}
					>
						{/* STEPS TOOLBAR */}
						<Fragment>
							{isEditMode ? (
								<div className="flex flex-row self-stretch items-center py-1 ">
									<button onClick={handleShowAddStepModal} disabled={false} className=" disabled:opacity-50  focus:outline-none w-full inline-flex justify-center  px-3 py-1 font-medium text-gray-700 hover:bg-bluegray-50 hover:text-bluegray-100 sm:mt-0 sm:ml-0 sm:w-auto sm:text-sm">
										<span className="self-stretch flex flex-row  items-center ">
											<svg className="h-5 w-5 fill-current text-purple-700" viewBox="0 0 20 20" fill="currentColor">
												<path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
											</svg>
										</span>

										<span className="self-stretch font-Inter font-base text-sm text-purple-700 ">Add Step</span>
									</button>
									<div className="border-l border-bluegray-400  self-stretch mx-0"></div>
									<button onClick={handleShowDeleteStepModal} disabled={false} className=" disabled:opacity-50  focus:outline-none w-full inline-flex justify-center  px-3 py-1 font-medium text-gray-700 hover:bg-gray-50  sm:mt-0 sm:ml-0 sm:w-auto sm:text-sm">
										<span className="self-stretch flex flex-row items-center -ml-2">
											<svg className="h-5 w-5 fill-current text-purple-700" viewBox="0 0 20 20" fill="currentColor">
												<path fillRule="evenodd" d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z" clipRule="evenodd" />
											</svg>
										</span>

										<span className="self-stretch font-Inter font-base text-sm text-purple-700 ">Remove Step</span>
									</button>
								</div>
							) : (
								""
							)}
						</Fragment>

<div className="flex flex-row items-center self-stretch pl-3">

{/* <svg className="h-5 w-5 stroke-current text-bluegray-500"  viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.8682 13.2764L1.86816 13.2764M17.8682 7.27637L4.9252 7.27637M17.8682 1.27637L1.86816 1.27637"  strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.8678 7.276L2.76111 7.276M2.76111 7.276L5.72223 4.31488M2.76111 7.276L5.72223 10.2371"  strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
</svg> */}




						<button onClick={handlePageSideBarChange} disabled={false} className="disabled:opacity-50  focus:outline-none w-full inline-flex justify-center  pl-3 pr-1 font-medium text-gray-700 hover:bg-bluegray-200  hover:text-white  sm:mt-0 sm:ml-0 sm:w-auto sm:text-sm">
							<span className="self-stretch flex flex-row items-center -ml-2">
							<svg className="h-5 w-5 stroke-current text-bluegray-500"  viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M16.8682 13.2764L0.868165 13.2764M16.8682 7.27637L3.9252 7.27637M16.8682 1.27637L0.868164 1.27637M8.8678 7.276L1.76111 7.276M1.76111 7.276L4.72223 4.31488M1.76111 7.276L4.72223 10.2371"  strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>
							</span>
						</button>






							{/* SEARCH COMPONENT */}
							<div className="flex flex-col-reverse self-stretch items-start flex-shrink-0 flex-grow py-1">
								<div className={` h-8 ml-2  mr-1 my-1  flex flex-row items-center self-stretch  border border-gray-300 bg-bluegray-100  ${true ? "  " : " hidden  "}`}>
									<svg className="ml-2 h-5 text-gray-500 " viewBox="0 0 20 20" fill="currentColor">
										<path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
									</svg>
									<input className="self-stretch flex-grow  border-1 border-bluegray-400 bg-bluegray-100  focus:bg-bluegray-200  pl-2 pr-5 text-sm focus:outline-none" type="search" name="search" placeholder="Search" onChange={handleOnInputChange} value={searchText} />
								</div>
							</div>
</div>

						<Scrollbars className="flex flex-col self-stretch items-center flex-grow overflow-y-scroll ">
							{/* {[...Array(100).keys()].map((item, key) => (
									<div key={item}>{key}</div>
								))} */}

							{isEditMode ? (
								<DragDropContext onDragEnd={onDragEnd}>
									<Droppable droppableId="droppable">
										{(droppableProvided, droppableSnapshot) => (
											<div ref={droppableProvided.innerRef}>
												{steps &&
													steps.map(({ metadata: { id, title: steptitle, selected: stepselected, completed: stepcompleted } }, index) => (
														<Draggable key={id} draggableId={id} index={index}>
															{(draggableProvided, draggableSnapshot) =>
																index != 5 ? (
																	<div
																		ref={draggableProvided.innerRef}
																		{...draggableProvided.draggableProps}
																		className={`ml-2 self-stretch cursor-pointer border-b border-l bg-bluegray-200 border-gray-300  hover:bg-bluegray-300 flex flex-row items-center   ${draggableSnapshot.isDragging ? "bg-bluegray-300" : ""}`}
																		onClick={() => {
																			selectAndScrollStep(index, steps);
																		}}
																		{...draggableProvided.dragHandleProps}
																	>
																		<div className={`self-stretch flex flex-row items-center flex-nowrap flex-grow overflow-hidden  ${stepselected ? " bg-bluegray-300 " : ""} `}>
																			<div className={`  w-1 self-stretch     ${stepselected ? " bg-indigo-500 " : stepcompleted ? "" : ""}   `}></div>

																			<div className=" w-6 self-stretch flex flex-col items-center justify-center ">
																				<svg className="text-gray-400 fill-current self-stretch" style={{ height: "0.365rem", color: "rgba(156, 163, 175, 0.9)" }} viewBox="0 0 16 6" fill="none">
																					<path d="M15 0H1C0.45 0 0 0.45 0 1C0 1.55 0.45 2 1 2H15C15.55 2 16 1.55 16 1C16 0.45 15.55 0 15 0ZM1 6H15C15.55 6 16 5.55 16 5C16 4.45 15.55 4 15 4H1C0.45 4 0 4.45 0 5C0 5.55 0.45 6 1 6Z" />
																				</svg>
																				{/* {droppableProvided.placeholder} */}
																			</div>

																			<span className="ml-2 font-Inter font-semibold  text-2xs tracking-tightest  text-gray-500 "> {index + 1} </span>

																			<div title={steptitle} className={`pl-1 self-stretch  ml-2 font-Inter text-xs tracking-tight font-semibold truncate  ${stepselected ? " text-gray-800" : "text-gray-900"}`} style={{ paddingTop: "0.375rem", paddingBottom: "0.375rem" }}>
																				{steptitle}
																			</div>
																		</div>
																	</div>
																) : (
																	<div
																		ref={draggableProvided.innerRef}
																		{...draggableProvided.draggableProps}
																		className={`self-stretch cursor-pointer border-b border-l bg-bluegray-200 border-gray-300  hover:bg-bluegray-300 flex flex-row items-center   ${draggableSnapshot.isDragging ? "bg-bluegray-300" : ""}`}
																		onClick={() => {
																			selectAndScrollStep(index, steps);
																		}}
																	>
																		<div {...draggableProvided.dragHandleProps} className="bg-bluegray-300 flex-grow font-Inter text-xs text-bluegray-600 py-2	font-medium uppercase ml-0 self-stretch flex flex-row items-center ">
																			<svg className="mx-2 h-4 text-bluegray-700 opacity-90" viewBox="0 0 20 20" fill="currentColor">
																				<path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
																			</svg>
																			Functions
																		</div>
																	</div>
																)
															}
														</Draggable>
													))}
											</div>
										)}
									</Droppable>
								</DragDropContext>
							) : (
								<Fragment>
									{steps &&
										steps.map(({ metadata: { id, title: steptitle, selected: stepselected, completed: stepcompleted } }, index) => (
											<div key={id} index={index} className="">
												<div
													className={`ml-2 self-stretch cursor-pointer border-b border-l bg-bluegray-200 border-gray-300  hover:bg-bluegray-300 flex flex-row items-center   `}
													onClick={() => {
														selectAndScrollStep(index, steps);
													}}
												>
													<div className={`self-stretch flex flex-row items-center flex-nowrap flex-grow overflow-hidden  ${stepselected ? " bg-bluegray-300 " : ""} `}>
														<div className={`  w-1 self-stretch     ${stepselected ? " bg-indigo-500 " : stepcompleted ? "" : ""}   `}></div>

														<span className="ml-2 font-Inter font-semibold  text-2xs tracking-tightest  text-gray-500 "> {index + 1} </span>

														<div title={steptitle} className={`pl-1 self-stretch  ml-2 font-Inter text-xs tracking-tight font-semibold truncate  ${stepselected ? " text-gray-600" : "text-gray-600"}`} style={{ paddingTop: "0.375rem", paddingBottom: "0.375rem" }}>
															{steptitle}
														</div>
													</div>
												</div>
											</div>
										))}
								</Fragment>
							)}
						</Scrollbars>
					</div>

					<div
						className={`flex flex-col self-stretch items-center  overflow-y-hidden flex-grow flex-shrink bg-white  ${isPageHasSideBar ? "" : ""} `}
						style={
							{
								//  backgroundColor: "#edf2f7"
								marginLeft: isPageHasSideBar ? '0px' : '-2px' 
							}
						}
					>


{/* <section className="flex flex-row items-center flex-none self-stretch border-b border-bluegray-200 bg-white h-10   ">
	<div className="self-stretch flex-row flex items-center  border-r border-bluegray-100 w-36">
	</div>
	<div className="self-stretch flex-row flex items-center  border-r border-bluegray-100 w-36">
<div className="self-stretch flex-row flex items-center   ">
			<svg className=" h-4 text-bluegray-500 opacity-90 stroke-current"  viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M17.7148 1.96387H3.71484C2.61027 1.96387 1.71484 2.8593 1.71484 3.96387V17.9639C1.71484 19.0684 2.61027 19.9639 3.71484 19.9639H17.7148C18.8194 19.9639 19.7148 19.0684 19.7148 17.9639V3.96387C19.7148 2.8593 18.8194 1.96387 17.7148 1.96387Z"  strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M7.71484 1.96387V19.9639"  strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>
			<div className="font-Inter text-xs font-semibold ml-2 ">Sidebar Expanded</div>
</div>
	</div>
</section> */}
						{/* WSteps */}
						<WStepsComponent
							ref={setWStepsComponentRef}
							isEditMode={isEditMode}
							steps={steps}
							setStepProgressFromWaypoint={setStepProgressFromWaypoint}
							handleExpand={handleExpand}
							handleAddEditor={handleAddEditor}
							handleShowEditStepModal={handleShowEditStepModal}
							handleShowDeleteStepModal={handleShowDeleteStepModal}
							handleDeleteStep={handleDeleteStep}
							handleShowDeleteCardModal={handleShowDeleteCardModal}
							updateDurationCalculation={this.updateDurationCalculation}
							
							handleShowAddContentYoutubeModel={handleShowAddContentYoutubeModel}
							setPayloadFromYoutube={this.setPayloadFromYoutube}
						/>
					</div>
				</div>
			</Fragment>
		);
	}
}

// {index = 8 ? (
// 	<Fragment>
// 		<div className={`self-stretch cursor-pointer border-b border-gray-300  hover:bg-bluegray-200 flex flex-row items-center  bg-bluegray-200`}>
// 			<div className="self-stretch flex flex-row-reverse items-center justify-between flex-grow pl-1 ">
// 				<div className="self-stretch flex flex-row items-center justify-center border-l border-gray-300" aria-expanded={item.height !== 0} aria-controls="example-panel" onClick={this.toggle} data-index={index}>
// 					<svg className={` text-bluegray-500 w-4 ml-4 mr-4`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
// 						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
// 					</svg>
// 				</div>

// 				<div className="self-stretch ml-5 flex flex-row items-center py-2">
// 					<span className="ml-2  font-Inter text-sm tracking-tight font-semibold flex-grow text-bluegray-600 ">Title</span>
// 				</div>
// 			</div>
// 		</div>
// 		<AnimateHeight
// 			style={{ flexShrink: 0 }}
// 			id="example-panel"
// 			duration={100}
// 			height={item.height} // see props documentation below
// 		>
// 			<h1>
// 				Your content goes here {index} - {item.height} - {item.label}{" "}
// 			</h1>
// 			<p>Put as many React or HTML components here.</p>
// 		</AnimateHeight>
// 	</Fragment>
// ) : (
// 	""
// )}
