import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link, NavLink, withRouter } from "react-router-dom";
import { MenuDivider } from '@szhsin/react-menu';
import { gsap } from 'gsap';

export default class LandingHeader extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isHeaderMenuOpen: false,
			isLoggedIn: false,
		};
	}

	toggleHeaderMenu = () => {
		const { isHeaderMenuOpen } = this.state;
		this.setState((prevState, props) => ({
			...prevState,
			isHeaderMenuOpen: !prevState.isHeaderMenuOpen,
		}));
		console.log("isHeaderMenuOpen", isHeaderMenuOpen);
	};

	btnHandlerCancel = () => {
		this.props.history.push({ pathname: "/" });
	};

	render() {
		const { isHeaderMenuOpen, isLoggedIn } = this.state;
		return (
			<Fragment>
				<nav className=" scrolled " style={{ backgroundColor: "#072032" }}>
					<div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 container    ">
						<div className="relative flex flex-row items-center justify-between h-14">
							{/* Hamburger Menu */}
							<div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
								{/* Mobile menu button */}
								<button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-expanded="false">
									<span className="sr-only">Open main menu</span>
									{/* Icon when menu is closed  Heroicon name: menu  Menu open: "hidden", Menu closed: "block"  */}
									<svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
									</svg>
									{/* Icon when menu is open Heroicon name: x Menu open: "block", Menu closed: "hidden" */}
									<svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
									</svg>
								</button>
							</div>
							{/* Logo Section */}
							<div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
								<div className="flex-shrink-0 flex items-center ">
									{/* <span className="brandletter ml-4 text-5xl ">D</span> */}

									{/* <svg className="fill-current w-8 h-8 text-white bg-gradient-to-br from-purple-800  to-purple-600  rounded-full " style={{ padding: "0.2rem" }} viewBox="0 0 393 424" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M296.722 55.9049H282.588C263.831 55.8972 245.619 62.2129 230.894 73.8319C223.678 79.5197 217.449 86.3589 212.459 94.0739C207.336 102 203.596 110.738 201.397 119.916L159.18 295.821C157.863 301.326 155.621 306.568 152.548 311.322C148.043 318.281 141.886 324.016 134.627 328.018C127.228 332.099 118.914 334.236 110.464 334.227H96.3297C87.1077 334.227 79.6307 341.704 79.6307 350.926C79.6307 360.148 87.1077 367.625 96.3297 367.625H110.464C129.221 367.633 147.433 361.317 162.158 349.698C169.374 344.01 175.603 337.171 180.593 329.456C185.715 321.53 189.456 312.792 191.655 303.614L233.872 127.709C235.188 122.204 237.431 116.962 240.504 112.208C245.009 105.249 251.165 99.5135 258.425 95.5119C265.823 91.4297 274.138 89.2933 282.588 89.3029H296.722C305.944 89.3029 313.421 81.8259 313.421 72.6039C313.421 63.3819 305.945 55.9049 296.722 55.9049Z"
											fill="white"
										/>
									</svg> */}




									<svg className=" w-10 h-10 "  viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M10.4683 12.258V17.7222C10.4683 19.7462 8.8276 21.3869 6.80365 21.3869C5.13848 21.3869 3.68263 20.2643 3.25935 18.6538L1.87033 13.369C1.14197 10.5979 3.23228 7.88721 6.09754 7.88721C8.51146 7.88721 10.4683 9.84408 10.4683 12.258Z" stroke="rgb(99, 102, 241)" strokeWidth="1.5"/>
									<line x1="10.0933" y1="16.0557" x2="3.42618" y2="16.9009" stroke="rgb(99, 102, 241)" strokeLinecap="round" strokeLinejoin="round"/>
									<line x1="20.2878" y1="10.4541" x2="13.5287" y2="9.67196" stroke="#7c3aed" strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M21.4448 6.47902L20.1622 11.9589C19.78 13.592 18.3235 14.7469 16.6463 14.7469C14.6521 14.7469 13.0354 13.1302 13.0354 11.136V5.50797C13.0354 3.15481 14.943 1.24719 17.2962 1.24719C20.0417 1.24719 22.0705 3.80575 21.4448 6.47902Z" stroke="#7c3aed" strokeWidth="1.5"/>
									</svg>
									{/* <span className="ml-3  font-Poppins   font-bold  text-bluegray-100" style={{fontSize: "1.645rem"}}>walktru</span> */}
									{/* <span className="ml-3  font-Gochi  font-normal uppercase tracking-widest  text-white" style={{fontSize: "1.945rem"}}>walktru</span> */}
									<span className="ml-3  font-MonAlt  font-bold    text-white" style={{fontSize: "1.545rem"}}>walktru</span>



									{/* <svg width="267" height="26" viewBox="0 0 267 26" className="fill-current w-32  text-gray-100 antialiased ml-3 hidden sm:block" xmlns="http://www.w3.org/2000/svg">
										<path d="M16.8789 0.960556C17.0122 1.17389 17.1322 1.46722 17.2389 1.84056C17.3722 2.18722 17.4389 2.57389 17.4389 3.00056C17.4389 3.72056 17.2655 4.26722 16.9189 4.64056C16.5989 5.01389 16.1589 5.20056 15.5989 5.20056H5.95887V11.5606H15.5189C15.6522 11.7739 15.7722 12.0539 15.8789 12.4006C16.0122 12.7472 16.0789 13.1339 16.0789 13.5606C16.0789 14.2806 15.9055 14.8272 15.5589 15.2006C15.2389 15.5472 14.7989 15.7206 14.2389 15.7206H6.03887V25.2006C5.79887 25.2539 5.4522 25.3206 4.99887 25.4006C4.5722 25.4806 4.11887 25.5206 3.63887 25.5206C2.62553 25.5206 1.90553 25.3339 1.47887 24.9606C1.0522 24.5872 0.838867 23.9606 0.838867 23.0806V3.72056C0.838867 2.86722 1.07887 2.20056 1.55887 1.72056C2.06553 1.21389 2.74553 0.960556 3.59887 0.960556H16.8789Z" />
										<path d="M31.8554 25.8806C30.2554 25.8806 28.8288 25.6672 27.5754 25.2406C26.3221 24.7872 25.2554 24.1606 24.3754 23.3606C23.5221 22.5339 22.8688 21.5606 22.4154 20.4406C21.9621 19.2939 21.7354 18.0139 21.7354 16.6006V0.960556C21.9488 0.907223 22.2821 0.840558 22.7354 0.760557C23.2154 0.680558 23.6821 0.640558 24.1354 0.640558C25.0954 0.640558 25.7888 0.813891 26.2154 1.16056C26.6688 1.50722 26.8954 2.14722 26.8954 3.08056V16.4006C26.8954 18.0806 27.3621 19.3739 28.2954 20.2806C29.2288 21.1606 30.4154 21.6006 31.8554 21.6006C33.2954 21.6006 34.4821 21.1606 35.4154 20.2806C36.3488 19.3739 36.8154 18.0806 36.8154 16.4006V0.960556C37.0554 0.907223 37.4021 0.840558 37.8554 0.760557C38.3088 0.680558 38.7621 0.640558 39.2154 0.640558C40.1754 0.640558 40.8821 0.813891 41.3354 1.16056C41.7888 1.50722 42.0154 2.14722 42.0154 3.08056V16.6006C42.0154 18.0139 41.7888 19.2939 41.3354 20.4406C40.8821 21.5606 40.2154 22.5339 39.3354 23.3606C38.4554 24.1606 37.3888 24.7872 36.1354 25.2406C34.8821 25.6672 33.4554 25.8806 31.8554 25.8806Z" />
										<path d="M50.7845 25.5206C49.7978 25.5206 49.1045 25.3472 48.7045 25.0006C48.3045 24.6272 48.1045 24.0139 48.1045 23.1606V1.84056C48.3978 1.46722 48.8245 1.17389 49.3845 0.960556C49.9712 0.747224 50.6112 0.640558 51.3045 0.640558C52.1312 0.640558 52.8645 0.787224 53.5045 1.08056C54.1712 1.37389 54.7578 1.96056 55.2645 2.84056L61.0245 13.2006C61.5578 14.1606 62.0645 15.1606 62.5445 16.2006C63.0512 17.2139 63.4912 18.1472 63.8645 19.0006L64.0245 18.9606C63.8912 17.3872 63.7978 15.8672 63.7445 14.4006C63.7178 12.9072 63.7045 11.4406 63.7045 10.0006V0.920557C63.9178 0.867224 64.2378 0.813891 64.6645 0.760557C65.1178 0.680558 65.5712 0.640558 66.0245 0.640558C67.0112 0.640558 67.7045 0.827224 68.1045 1.20056C68.5312 1.54722 68.7445 2.14723 68.7445 3.00056V24.6006C68.4512 24.8939 68.0245 25.1206 67.4645 25.2806C66.9312 25.4406 66.2912 25.5206 65.5445 25.5206C64.7445 25.5206 64.0112 25.3872 63.3445 25.1206C62.7045 24.8272 62.1312 24.2406 61.6245 23.3606L55.8645 13.0006C55.3312 12.0672 54.8112 11.0806 54.3045 10.0406C53.8245 8.97389 53.3978 8.01389 53.0245 7.16056L52.7845 7.20056C52.9178 8.66722 52.9978 10.1739 53.0245 11.7206C53.0778 13.2672 53.1045 14.7339 53.1045 16.1206V25.2006C52.8912 25.2539 52.5712 25.3206 52.1445 25.4006C51.7178 25.4806 51.2645 25.5206 50.7845 25.5206Z" />
										<path d="M93.3014 4.08056C93.3014 4.61389 93.1547 5.08056 92.8614 5.48056C92.568 5.85389 92.2347 6.14722 91.8614 6.36056C91.168 5.90722 90.3947 5.49389 89.5414 5.12056C88.688 4.74722 87.6347 4.56056 86.3814 4.56056C84.1147 4.56056 82.3014 5.29389 80.9414 6.76056C79.608 8.22723 78.9414 10.3472 78.9414 13.1206C78.9414 15.9472 79.6347 18.0672 81.0214 19.4806C82.408 20.8939 84.2747 21.6006 86.6214 21.6006C87.848 21.6006 88.9014 21.4406 89.7814 21.1206C90.688 20.7739 91.488 20.3739 92.1814 19.9206C92.5547 20.1606 92.8614 20.4939 93.1014 20.9206C93.3414 21.3206 93.4614 21.7872 93.4614 22.3206C93.4614 22.7739 93.3414 23.1872 93.1014 23.5606C92.8614 23.9339 92.4614 24.2672 91.9014 24.5606C91.368 24.8806 90.608 25.1739 89.6214 25.4406C88.6614 25.7339 87.4614 25.8806 86.0214 25.8806C84.2614 25.8806 82.6214 25.6272 81.1014 25.1206C79.608 24.5872 78.3014 23.8006 77.1814 22.7606C76.0614 21.6939 75.1814 20.3606 74.5414 18.7606C73.9014 17.1606 73.5814 15.2806 73.5814 13.1206C73.5814 11.0406 73.9014 9.21389 74.5414 7.64056C75.1814 6.04056 76.048 4.70723 77.1414 3.64056C78.2614 2.54722 79.5547 1.72056 81.0214 1.16056C82.5147 0.600558 84.088 0.320557 85.7414 0.320557C86.888 0.320557 87.928 0.427224 88.8614 0.640558C89.7947 0.827224 90.5947 1.09389 91.2614 1.44056C91.928 1.78722 92.4347 2.18722 92.7814 2.64056C93.128 3.09389 93.3014 3.57389 93.3014 4.08056Z" />
										<path d="M115.692 0.960556C115.826 1.17389 115.946 1.46722 116.052 1.84056C116.186 2.18722 116.252 2.57389 116.252 3.00056C116.252 3.72056 116.079 4.26722 115.732 4.64056C115.412 5.01389 114.959 5.20056 114.372 5.20056H108.852V25.2006C108.639 25.2539 108.306 25.3206 107.852 25.4006C107.399 25.4806 106.932 25.5206 106.452 25.5206C105.439 25.5206 104.719 25.3339 104.292 24.9606C103.866 24.5872 103.652 23.9606 103.652 23.0806V5.20056H96.9323C96.799 4.96056 96.6656 4.66722 96.5323 4.32056C96.4256 3.97389 96.3723 3.58722 96.3723 3.16056C96.3723 2.41389 96.5323 1.86722 96.8523 1.52056C97.199 1.14722 97.6656 0.960556 98.2523 0.960556H115.692Z" />
										<path d="M123.014 25.5206C122.001 25.5206 121.281 25.3339 120.854 24.9606C120.427 24.5872 120.214 23.9606 120.214 23.0806V0.960556C120.454 0.907223 120.801 0.840558 121.254 0.760557C121.707 0.680558 122.161 0.640558 122.614 0.640558C123.574 0.640558 124.281 0.813891 124.734 1.16056C125.187 1.50722 125.414 2.14722 125.414 3.08056V25.2006C125.174 25.2539 124.827 25.3206 124.374 25.4006C123.947 25.4806 123.494 25.5206 123.014 25.5206Z" />
										<path d="M154.581 13.0806C154.581 15.1606 154.274 17.0006 153.661 18.6006C153.048 20.2006 152.194 21.5472 151.101 22.6406C150.034 23.7072 148.754 24.5206 147.261 25.0806C145.768 25.6139 144.154 25.8806 142.421 25.8806C140.688 25.8806 139.074 25.6139 137.581 25.0806C136.088 24.5206 134.794 23.7072 133.701 22.6406C132.634 21.5472 131.794 20.2006 131.181 18.6006C130.568 17.0006 130.261 15.1606 130.261 13.0806C130.261 11.0006 130.568 9.17389 131.181 7.60056C131.821 6.00056 132.688 4.66722 133.781 3.60056C134.874 2.50723 136.154 1.69389 137.621 1.16056C139.114 0.600558 140.714 0.320557 142.421 0.320557C144.128 0.320557 145.714 0.600558 147.181 1.16056C148.674 1.69389 149.968 2.50723 151.061 3.60056C152.154 4.66722 153.008 6.00056 153.621 7.60056C154.261 9.17389 154.581 11.0006 154.581 13.0806ZM149.261 13.0806C149.261 10.3072 148.634 8.20056 147.381 6.76056C146.154 5.29389 144.501 4.56056 142.421 4.56056C140.368 4.56056 138.714 5.28056 137.461 6.72056C136.234 8.16056 135.621 10.2806 135.621 13.0806C135.621 15.8806 136.234 18.0006 137.461 19.4406C138.688 20.8806 140.341 21.6006 142.421 21.6006C144.501 21.6006 146.154 20.8806 147.381 19.4406C148.634 18.0006 149.261 15.8806 149.261 13.0806Z" />
										<path d="M162.113 25.5206C161.126 25.5206 160.433 25.3472 160.033 25.0006C159.633 24.6272 159.433 24.0139 159.433 23.1606V1.84056C159.726 1.46722 160.153 1.17389 160.713 0.960556C161.299 0.747224 161.939 0.640558 162.633 0.640558C163.459 0.640558 164.193 0.787224 164.833 1.08056C165.499 1.37389 166.086 1.96056 166.593 2.84056L172.353 13.2006C172.886 14.1606 173.393 15.1606 173.873 16.2006C174.379 17.2139 174.819 18.1472 175.193 19.0006L175.353 18.9606C175.219 17.3872 175.126 15.8672 175.073 14.4006C175.046 12.9072 175.033 11.4406 175.033 10.0006V0.920557C175.246 0.867224 175.566 0.813891 175.993 0.760557C176.446 0.680558 176.899 0.640558 177.353 0.640558C178.339 0.640558 179.033 0.827224 179.433 1.20056C179.859 1.54722 180.073 2.14723 180.073 3.00056V24.6006C179.779 24.8939 179.353 25.1206 178.793 25.2806C178.259 25.4406 177.619 25.5206 176.873 25.5206C176.073 25.5206 175.339 25.3872 174.673 25.1206C174.033 24.8272 173.459 24.2406 172.953 23.3606L167.193 13.0006C166.659 12.0672 166.139 11.0806 165.633 10.0406C165.153 8.97389 164.726 8.01389 164.353 7.16056L164.113 7.20056C164.246 8.66722 164.326 10.1739 164.353 11.7206C164.406 13.2672 164.433 14.7339 164.433 16.1206V25.2006C164.219 25.2539 163.899 25.3206 163.473 25.4006C163.046 25.4806 162.593 25.5206 162.113 25.5206Z" />
										<path d="M188.989 25.3206C188.136 25.3206 187.456 25.0672 186.949 24.5606C186.469 24.0539 186.229 23.3739 186.229 22.5206V0.960556C186.443 0.907223 186.776 0.840558 187.229 0.760557C187.709 0.680558 188.176 0.640558 188.629 0.640558C189.589 0.640558 190.296 0.813891 190.749 1.16056C191.203 1.50722 191.429 2.14722 191.429 3.08056V21.0406H201.709C201.843 21.2539 201.976 21.5472 202.109 21.9206C202.243 22.2672 202.309 22.6539 202.309 23.0806C202.309 23.8006 202.136 24.3606 201.789 24.7606C201.469 25.1339 201.016 25.3206 200.429 25.3206H188.989Z" />
										<path d="M209.263 25.3206C208.41 25.3206 207.73 25.0672 207.223 24.5606C206.743 24.0539 206.503 23.3739 206.503 22.5206V3.72056C206.503 2.86722 206.743 2.20056 207.223 1.72056C207.73 1.21389 208.41 0.960556 209.263 0.960556H222.503C222.636 1.17389 222.756 1.45389 222.863 1.80056C222.97 2.14723 223.023 2.53389 223.023 2.96056C223.023 3.65389 222.863 4.18723 222.543 4.56056C222.223 4.93389 221.783 5.12056 221.223 5.12056H211.543V10.8006H221.303C221.436 11.0139 221.556 11.2939 221.663 11.6406C221.796 11.9606 221.863 12.3339 221.863 12.7606C221.863 14.2006 221.25 14.9206 220.023 14.9206H211.543V21.1206H222.663C222.796 21.3339 222.916 21.6272 223.023 22.0006C223.156 22.3472 223.223 22.7339 223.223 23.1606C223.223 24.6006 222.61 25.3206 221.383 25.3206H209.263Z" />
										<path d="M235.224 21.6006C236.77 21.6006 237.904 21.3072 238.624 20.7206C239.344 20.1072 239.704 19.3472 239.704 18.4406C239.704 17.6139 239.424 16.9606 238.864 16.4806C238.304 15.9739 237.45 15.5472 236.304 15.2006L233.744 14.4006C232.73 14.0806 231.81 13.7206 230.984 13.3206C230.157 12.9206 229.45 12.4539 228.864 11.9206C228.277 11.3606 227.824 10.7206 227.504 10.0006C227.184 9.25389 227.024 8.37389 227.024 7.36056C227.024 5.20056 227.837 3.49389 229.464 2.24056C231.09 0.960557 233.317 0.320557 236.144 0.320557C237.264 0.320557 238.29 0.41389 239.224 0.600555C240.184 0.760557 241.01 1.00056 241.704 1.32056C242.397 1.61389 242.93 1.98722 243.304 2.44056C243.704 2.89389 243.904 3.40056 243.904 3.96056C243.904 4.49389 243.77 4.96056 243.504 5.36056C243.264 5.73389 242.957 6.04056 242.584 6.28056C241.944 5.82722 241.09 5.44056 240.024 5.12056C238.984 4.77389 237.837 4.60056 236.584 4.60056C235.197 4.60056 234.144 4.85389 233.424 5.36056C232.704 5.84056 232.344 6.50722 232.344 7.36056C232.344 8.05389 232.584 8.58722 233.064 8.96056C233.57 9.33389 234.357 9.69389 235.424 10.0406L237.704 10.7606C240.024 11.4539 241.824 12.4006 243.104 13.6006C244.384 14.8006 245.024 16.4272 245.024 18.4806C245.024 19.5739 244.81 20.5739 244.384 21.4806C243.957 22.3872 243.33 23.1739 242.504 23.8406C241.704 24.4806 240.69 24.9739 239.464 25.3206C238.264 25.6939 236.89 25.8806 235.344 25.8806C234.117 25.8806 232.984 25.7739 231.944 25.5606C230.93 25.3472 230.05 25.0672 229.304 24.7206C228.557 24.3472 227.97 23.9206 227.544 23.4406C227.144 22.9339 226.944 22.3872 226.944 21.8006C226.944 21.1872 227.117 20.6672 227.464 20.2406C227.837 19.7872 228.264 19.4539 228.744 19.2406C229.41 19.8272 230.29 20.3739 231.384 20.8806C232.477 21.3606 233.757 21.6006 235.224 21.6006Z" />
										<path d="M256.708 21.6006C258.255 21.6006 259.388 21.3072 260.108 20.7206C260.828 20.1072 261.188 19.3472 261.188 18.4406C261.188 17.6139 260.908 16.9606 260.348 16.4806C259.788 15.9739 258.935 15.5472 257.788 15.2006L255.228 14.4006C254.215 14.0806 253.295 13.7206 252.468 13.3206C251.641 12.9206 250.935 12.4539 250.348 11.9206C249.761 11.3606 249.308 10.7206 248.988 10.0006C248.668 9.25389 248.508 8.37389 248.508 7.36056C248.508 5.20056 249.321 3.49389 250.948 2.24056C252.575 0.960557 254.801 0.320557 257.628 0.320557C258.748 0.320557 259.775 0.41389 260.708 0.600555C261.668 0.760557 262.495 1.00056 263.188 1.32056C263.881 1.61389 264.415 1.98722 264.788 2.44056C265.188 2.89389 265.388 3.40056 265.388 3.96056C265.388 4.49389 265.255 4.96056 264.988 5.36056C264.748 5.73389 264.441 6.04056 264.068 6.28056C263.428 5.82722 262.575 5.44056 261.508 5.12056C260.468 4.77389 259.321 4.60056 258.068 4.60056C256.681 4.60056 255.628 4.85389 254.908 5.36056C254.188 5.84056 253.828 6.50722 253.828 7.36056C253.828 8.05389 254.068 8.58722 254.548 8.96056C255.055 9.33389 255.841 9.69389 256.908 10.0406L259.188 10.7606C261.508 11.4539 263.308 12.4006 264.588 13.6006C265.868 14.8006 266.508 16.4272 266.508 18.4806C266.508 19.5739 266.295 20.5739 265.868 21.4806C265.441 22.3872 264.815 23.1739 263.988 23.8406C263.188 24.4806 262.175 24.9739 260.948 25.3206C259.748 25.6939 258.375 25.8806 256.828 25.8806C255.601 25.8806 254.468 25.7739 253.428 25.5606C252.415 25.3472 251.535 25.0672 250.788 24.7206C250.041 24.3472 249.455 23.9206 249.028 23.4406C248.628 22.9339 248.428 22.3872 248.428 21.8006C248.428 21.1872 248.601 20.6672 248.948 20.2406C249.321 19.7872 249.748 19.4539 250.228 19.2406C250.895 19.8272 251.775 20.3739 252.868 20.8806C253.961 21.3606 255.241 21.6006 256.708 21.6006Z" />
									</svg> */}

									{/* <img className="block lg:hidden h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg" alt="Workflow" />
									<img className="hidden lg:block h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg" alt="Workflow" /> */}
								</div>
							</div>
							{/* Header Menu Items */}
							<div className="">
								<div className="hidden sm:hidden sm:ml-6">
									<div className="flex space-x-4">
										{/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
										<a href="/#" className="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium">
											Dashboard
										</a>
										<a href="/#" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
											Team
										</a>
										<a href="/#" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
											Projects
										</a>
										<a href="/#" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
											Calendar
										</a>
									</div>
								</div>
							</div>

							{isLoggedIn ? (
								<div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
									<button className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
										<span className="sr-only">View notifications</span>
										{/* Heroicon name: bell */}
										<svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
										</svg>
									</button>

									{/* Profile dropdown */}
									<div className="ml-3 relative">
										<div>
											<button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu" aria-haspopup="true">
												<span className="sr-only">Open user menu</span>
												<img className="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
											</button>
										</div>
										{/* Profile dropdown panel, show/hide based on dropdown state.
                                    Entering: "transition ease-out duration-100"
                                    From: "transform opacity-0 scale-95"
                                    To: "transform opacity-100 scale-100"
                                    Leaving: "transition ease-in duration-75"
                                    From: "transform opacity-100 scale-100"
                                    To: "transform opacity-0 scale-95" */}
										<div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
											<a href="/#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
												Your Profile
											</a>
											<a href="/#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
												Settings
											</a>
											<a href="/#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
												Sign out
											</a>
										</div>
									</div>
								</div>
							) : (
								<div className="self-stretch flex items-center    ">
									<Link to="/login">
									<div className="text-white mx-2 font-medium">Login</div>
									</Link>

									{/* <Link to="/signup">
									</Link> */}
									<div className=" cursor-pointer ml-2 bg-purple-600 hover:bg-purple-500 hover:text-white text-xs text-white py-2 px-4 rounded font-medium font-Inter uppercase">Get Started</div>
								</div>
							)}
						</div>
					</div>
					{/* Mobile menu, toggle classes based on menu state.
                    Menu open: "block", Menu closed: "hidden" */}
					<div className="hidden sm:hidden">
						<div className="px-2 pt-2 pb-3 space-y-1">
							{/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
							<a href="/#" className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">
								Dashboard
							</a>
							<a href="/#" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
								Team
							</a>
							<a href="/#" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
								Projects
							</a>
							<a href="/#" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
								Calendar
							</a>
						</div>
					</div>
				</nav>
			</Fragment>
		);
	}
}
