import React, { Component, Fragment } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BrowserRouter as Router, Switch, Route, Link, NavLink, withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";


const ConfirmValidationSchema = Yup.object().shape({
    confirmationcode: Yup.string()
      .length(6, "Confirmation code must be 6 characters long!")
      .required("Confirmation code is required!")
  });
  
class SignupConfirm extends Component {
    constructor(props) {
        super(props);

        const { location } = this.props;
    

        this.state = {
          Username: "",
          Email: location && location.state && location.state.email,
          Password: "",
          ConfirmationCode: "",
    
          isConfirming: false,
          isErrored: false,
          errorMessage: "",

          isResending: false,
          resendMessage: ""
        };
        
      }


    ResendConfirmationCode = () => {
        const { Email, ConfirmationCode, Firstname,Lastname, Password, errorMessage, resendErrorMessage} = this.state;

        this.setState((prevState, props) => ({
            ...prevState,
            isResending:true,
            resendMessage:"Resend succesfull"
          }));

        Auth.resendSignUp(Email)
        .then(result => {
            this.setState((prevState, props) => ({
                ...prevState,
                isResending:false,
                resendMessage: "Resend succesfull"
              }));
        })
        .catch(err => {
            this.setState((prevState, props) => ({
                ...prevState,
                isResending:false,
                resendMessage: "Resend unsuccesfull"
              }));
            }
        );

    }


    btnHandlerConfirm = () => {
        const { Email, ConfirmationCode, Firstname,Lastname, Password, errorMessage } = this.state;
        const { location } = this.props;


        this.setState((prevState, props) => ({
            ...prevState,
            isConfirming: true,
            isSigningin: false,
            isErrored: false
          }));


          Auth.confirmSignUp(
            Email,
            ConfirmationCode,
          )
          .then(result => {
            this.setState((prevState, props) => ({
                ...prevState,
                isConfirming: false,
                isSigningin: true,
                isErrored: false
              }));

            console.log('successful confirmation' , result);

            let pass = location ? location.state && location.state.password : "";

            return Auth.signIn(
                Email,
                pass,
            );

          })
          .then(result => {
            this.setState((prevState, props) => ({
                ...prevState,
                isConfirming: false,
                isSigningin: false,
                isErrored: false
              }));

              this.props.history.push({
                pathname: "/portal/discover",
                state: {
                firstname: Firstname,
                lastname: Lastname,
                email: Email,
                }
            });


          })
         .catch(err => {
            this.setState((prevState, props) => ({
              ...prevState,
              isConfirming: false,
              isSigningin: false,
              isErrored: true,
              errorMessage: "Error during confirmation"
            }));
            console.log("err.message", err.message);
          });


    
    
    }

    render() {
        const {
            isConfirming,
            isSigningin,
            isErrored,
            errorMessage
          } = this.state;
          const { location } = this.props;


        return (
    <Fragment>
        <div className="self-stretch flex flex-col justify-center items-center min-h-screen ">
          {isConfirming || isSigningin ? (
            <div className="self-stretch flex flex-col justify-center items-center my-8">
              <span className=" text-indigo-500 font-bold font-Inter text-lg mb-3">
                {isConfirming ? (
                  "Confirming"
                ) : isSigningin ? (
                  <span>Confirmed... Signing in</span>
                ) : (
                  ""
                )}
              </span>             
            </div>
          ) : (
            <Fragment>
              <Formik
                initialValues={{
                  username: location
                    ? location.state && location.state.username
                    : "",
                  email: location ? location.state && location.state.email : "",
                  confirmationcode: ""
                }}
                validationSchema={ConfirmValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  this.setState((prevState, props) => ({
                    ...prevState,
                    Username: values.username,
                    Email: values.email,
                    ConfirmationCode: values.confirmationcode
                  }));

                  this.btnHandlerConfirm();
                  // setSubmitting(false);
                }}
              >
                {({ touched, errors, isSubmitting, values }) => (
                  <Form className="self-stretch flex flex-col justify-center items-center h-full">
                    {/* CONFIRM PANEL */}
                    <div className="relative flex flex-col justify-start items-center bg-white shadow-md rounded w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/3  border-solid border-t-4 border-purple-500">
                    <div className="absolute w-full h-12  border-b border-gray-400 bg-gray-100 opacity-50"  ></div>
                      <div className="px-6 pb-6 h-full w-full z-10">
                        <div className="mb-5 flex flex-row justify-between items-center h-12  ">
                          <span className="font-semibold text-gray-700 text-lg ml-2 font-Inter">
                            Confirm
                          </span>
                        </div>

                        <div className="mb-8">
                          <div className=" mb-6 font-Inter text-gray-600  font-medium text-sm">
                            We want to make sure it's really you. <br />
                            In order to verify your identity, please enter the
                            confirmation code that was sent to your email:
                            <span className="pl-2 text-indigo-500">
                              {location
                                ? location.state && location.state.email
                                : ""}
                            </span>
                          </div>

                          <label className="block mb-1 font-Inter text-gray-800  font-semibold text-smaller">
                            Confirmation Code
                          </label>
                          <Field
                            name="confirmationcode"
                            placeholder=""
                            autoComplete="off"
                            className={` appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                                                            ${
                                                              touched.confirmationcode &&
                                                              errors.confirmationcode
                                                                ? "border-Theme05-500 "
                                                                : ""
                                                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="confirmationcode"
                            className=" text-Theme05-500  text-xs tracking-wide ml-2"
                          />

                          {isErrored ? (
                            <div className="flex flex-row items-center justify-center mb-4 font-bold text-Theme04-500 mt-4">
                              {errorMessage}
                            </div>
                          ) : (
                            <br />
                          )}
                        </div>

                        <div className="flex flex-row items-center justify-center ">
                          <button
                            className="ml-2 bg-indigo-500  text-white py-2 px-4 rounded font-medium font-Inter  text-sm"
                            // onClick={this.btnHandlerSignup}
                            type="submit"
                          >
                            												{isConfirming || isSigningin  ? (
													<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
														<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
														<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
													</svg>
												) : (
													""
												)}
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col justify-center items-center w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/3 ">
                      <div className="mt-4 text-center font-Inter text-gray-800  font-medium text-smaller">
                        Your confirmation email may be marked as a spam, please
                        dont forget to check your spam folder.
                      </div>

                      <div
                        className=" no-underline  font-Inter font-medium  text-sm text-gray-800 hover:text-blue-dark mt-6 ml-4 cursor-pointer"
                        onClick={this.ResendConfirmationCode}
                      >
                        Not received? Resend code
                      </div>

                      <a
                        className=" no-underline  font-Inter font-semibold text-sm text-gray-400 hover:text-blue-dark mt-8 ml-4"
                        href="/"
                      >
                        Home
                      </a>
                    </div>

                    {/* <div className="mt-8 mb-2 p-2 border border-solid border-gray-400">
                                            <strong>Injected Formik props (the form's state)</strong>
                                            <div>
                                            <code>errors:</code> {JSON.stringify(errors, null, 2)}
                                            </div>
                                            <div>
                                            <code>values:</code> {JSON.stringify(values, null, 2)}
                                            </div>
                                            <div>
                                            <code>isSubmitting:</code>{" "}
                                            {JSON.stringify(isSubmitting, null, 2)}
                                            </div>
                                    </div> */}
                  </Form>
                )}
              </Formik>
            </Fragment>
          )}
        </div>
      </Fragment>
 
        )
    }
}




export default withRouter(SignupConfirm);
