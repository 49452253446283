import React, { Component } from "react";

export default class LandingFooter extends Component {
	render() {
		return (
			<div className="" style={{ backgroundColor: "#072032" }}>
				<div className="container h-28"></div>
			</div>
		);
	}
}
