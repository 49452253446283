import React, { Component, Fragment } from "react";
import MenuFeatures from "./MenuFeatures";
import MenuMobile from "./MenuMobile";
import MenuMore from "./MenuMore";
import { BrowserRouter as Router, Switch, Route, Link, NavLink, withRouter } from "react-router-dom";

var ReactRotatingText = require("react-rotating-text");

const LandingContent = () => {
	// const [isOpen, setIsOpen] = useState(false);
	// const toggle = () => setIsOpen(!isOpen);
	// Menu.handleClickOutside = () => setIsOpen(false);
	return (
		<div className="font-Inter container max-w-lg px-4 py-32 mx-auto text-left sm:max-w-none sm:text-center bg-white debug-border">
			<div className=" font-extrabold tracking-tight text-left text-gray-900 leading-tightest md:leading-10 md:text-center text-3xl sm:leading-none md:text-6xl lg:text-7xl">
				{/* <span className="inline md:block">Simplify the way you</span>  */}
				<div className="inline md:block whitespace-pre-wrap ">A whole new way to</div>
				<div className="relative mt-2 text-transparent md:inline-block bg-clip-text bg-gradient-to-br from-purple-600 to-blue-500 leading-snug ">
					<ReactRotatingText items={["create online courses", "learn any topic", "watch youtube chapters", "read twitter floods", "build event timelines", "create product docs"]} pause={4000} emptyPause={1500} cursor={true} />
				</div>
			</div>
			<div className="mx-auto mt-5 text-gray-500 md:mt-12 md:max-w-lg md:text-center lg:text-lg">Simplifying the creation of online courses, curated contents, social learning flows and so much more!</div>
			<div className="flex flex-col items-center mt-12 text-center">
				{/* <span className="relative inline-flex w-full rounded-full shadow-sm md:w-auto">
					<button type="button" className="inline-flex items-center justify-center w-full px-8 py-4 text-base font-bold leading-6 text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-full md:w-auto hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700">
						Purchase Now
					</button>
					<span className="absolute top-0 right-0 px-2 py-1 -mt-3 -mr-6 text-xs font-medium leading-tight text-white bg-green-400 rounded-full">only $15/mo</span>
				</span> */}
				<div className="relative flex flex-col items-center max-w-md mx-auto mt-2 text-center">
					<div className="font-Inter text-smaller font-medium self-stretch justify-self-start text-left text-gray-500 ml-3">Reserve your spot on the waiting list*</div>
					<div className="flex flex-row items-center">
						<input type="text" name="email" placeholder="Email Address" className="w-full h-12 px-6 py-2 font-medium text-bluegray-800 rounded-l-full focus:outline-none bg-bluegray-200" />
						<span className="relative top-0 right-0 block rounded-full shadow-sm">
							<button type="button" className="inline-flex items-center w-48 h-12 px-8 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-r-full hover:bg-indigo-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700">
								Get early access
							</button>
						</span>
					</div>
				</div>
				{/* <a href="#" className="mt-3 text-sm text-teal-500">
					Learn More
				</a> */}
			</div>
		</div>
	);
};

export default class LandingTemp extends Component {
	constructor(props) {
		super(props);

		this.refMobileMenu = React.createRef();
		this.refFeaturesMenu = React.createRef();
		this.refMoreMenu = React.createRef();
		this.state = {
			showMenuMobile: false,
			showMenuSolutions: false,
			showMenuMore: false,
		};
	}

	toggleMenuMobile = () => {
		const { showMenuMobile } = this.state;
		this.setState((prevState, props) => ({
			...prevState,
			showMenuMobile: !prevState.showMenuMobile,
		}));
		console.log("LandingTemp toggleMenuMobile new showMenuMobile ", showMenuMobile);
	};

	// https://codedaily.io/tutorials/63/Create-a-Dropdown-in-React-that-Closes-When-the-Body-is-Clicked
	handleClickOutsideforMobileMenu = (event) => {
		const { showMenuMobile } = this.state;
		this.setState((prevState, props) => ({
			...prevState,
			showMenuMobile: false,
		}));
		console.log("LandingTemp handleClickOutsideforMobileMenu showMenuMobile ", showMenuMobile);
	};

	render() {
		const { showMenuMobile, showMenuMore, showMenuSolutions } = this.state;
		return (
			<Fragment>
				<div className="h-10 flex flex-row items-center justify-center self-stretch" style={{ backgroundColor: "#072032" }}>
					<div className=" px-4 sm:px-6  text-purple-500 flex flex-row items-center justify-center font-Inter font-semibold text-sm self-stretch ">We are launching soon! </div>
				</div>

				<div className="relative bg-white flex flex-col items-center self-stretch">
					<div className="max-w-7xl mx-auto px-4 sm:px-6 container">
						<div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
							<div className="flex justify-start lg:w-0 lg:flex-1">
								{/* <a href="#">
									<span className="sr-only">Walkthru</span>
									<img className="h-8 w-auto sm:h-10" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="" />
								</a> */}
								{/* <svg className=" w-10 h-10 "  viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M10.4683 12.258V17.7222C10.4683 19.7462 8.8276 21.3869 6.80365 21.3869C5.13848 21.3869 3.68263 20.2643 3.25935 18.6538L1.87033 13.369C1.14197 10.5979 3.23228 7.88721 6.09754 7.88721C8.51146 7.88721 10.4683 9.84408 10.4683 12.258Z" stroke="rgb(99, 102, 241)" strokeWidth="1.5"/>
									<line x1="10.0933" y1="16.0557" x2="3.42618" y2="16.9009" stroke="rgb(99, 102, 241)" strokeLinecap="round" strokeLinejoin="round"/>
									<line x1="20.2878" y1="10.4541" x2="13.5287" y2="9.67196" stroke="#7c3aed" strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M21.4448 6.47902L20.1622 11.9589C19.78 13.592 18.3235 14.7469 16.6463 14.7469C14.6521 14.7469 13.0354 13.1302 13.0354 11.136V5.50797C13.0354 3.15481 14.943 1.24719 17.2962 1.24719C20.0417 1.24719 22.0705 3.80575 21.4448 6.47902Z" stroke="#7c3aed" strokeWidth="1.5"/>
									</svg> */}
								{/* <span className="ml-3  font-Poppins   font-bold   text-gray-700" style={{fontSize: "1.645rem"}}>walktru</span>
									<span className="ml-3  font-Gochi  font-normal uppercase tracking-widest text-gray-700 " style={{fontSize: "1.945rem"}}>walktru</span> */}
								<span className="ml-3  font-MonAlt  font-extrabold tracking-tighter text-gray-700" style={{ fontSize: "1.745rem" }}>
									walktru
								</span>
							</div>
							<div className="-mr-2 -my-2 md:hidden">
								<button type="button" onClick={this.toggleMenuMobile} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
									<span className="sr-only">Open menu</span>
									{/* Heroicon name: menu */}
									<svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
									</svg>
								</button>
							</div>
							<nav className="hidden md:flex space-x-10">
								<div className="relative">
									<MenuFeatures showMenu={showMenuSolutions} />
								</div>

								<a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">
									Pricing
								</a>
								<a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">
									Docs
								</a>

								<div className="relative">
									<MenuMore />
								</div>

								<a href="#" className="absolute top-0 left-0 hidden  ml-10 mx-2 text-gray-600 lg:inline-block md:mt-0 md:ml-2 lg:mx-3 md:relative">
									<svg className="inline w-5 h-5" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
										<path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
									</svg>
								</a>
							</nav>
							<div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
								{/* <a href="#" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
									Sign in
								</a> */}
								<Link to="/login">
									<div className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">Sign in</div>
								</Link>
								<a href="#" className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
									Get early access
								</a>
							</div>
						</div>
					</div>

					<MenuMobile showMenuMobile={showMenuMobile} toggleMenuMobile={this.toggleMenuMobile} handleClickOutsideforMobileMenu={this.handleClickOutsideforMobileMenu} />
				</div>

				<LandingContent />
			</Fragment>
		);
	}
}
