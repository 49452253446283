import React, { Component, Fragment } from "react";
import { uuid } from "uuidv4";
import ReactPlayer from "react-player/lazy";
import { flowSample, walkthruSample } from "./../../utilities/mockdata";
import * as Scroll from 'react-scroll';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {sampletext ,sampletext2}  from "./../../utilities/sampletext"
import Utube from './Utube';

import { ReactComponent as PlaySvg } from "assets/images/play.svg";

// import PlaySvg from 'assets/images/play';

export default class PortalContent3 extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hover: false,
			items: [...Array(20).keys()].map((index) => ({
				index: String(index),
				value: String(index), //`item-${index}`,
				visible: true,
			})),
			cards: [...Array(3).keys()].map((index) => ({
				// index: index,
				index: uuid(),
				value: String(index), //`item-${index}`,
				visible: true,
			})),
			clickedCard: null,
			sampledata: flowSample,
			walkthruSample: walkthruSample,
			textAreaValue: sampletext,
			labelList: [
				{
					start: "00:00",
					label: "Introduction",
				},
			],
        };
        
        
    }
    componentDidMount(){
        this.handleGet();
    }
    
    handleChange = (event) => {
        this.setState({ textAreaValue: event.target.value });
    };

	handleGet = (event) => {
		const { textAreaValue, labelList } = this.state;
		// console.log(textAreaValue);

		// const reg = /\s\(*[\d+:]*\d+:\d+\)*\s(.*)[\n|\r\n|\r]/g
		// const reg = new RegExp(/\s\(*[\d+:]*\d+:\d+\)*\s(.*)[\n|\r\n|\r]/g);
		// const reg = new RegExp(/\s(\(*[\d+:]*\d+:\d+\))+\s(.*)[\n|\r\n|\r]/g);
		const reg = new RegExp(/\(*([\d+:]*\d+:\d+)\)*\s(.*)[\n|\r\n|\r]/g);
		// let results = reg.exec(textAreaValue);

		// if (results){
		//     console.log(results[0]);
		//     console.log(results[1]);
		//     console.log(results[2]);
		// }

		// x = 'a a a a';
		let z = null;
		let counter = 0;
		let list = [];
		// y = new RegExp(/a/g);
		while (null != (z = reg.exec(textAreaValue))) {
			console.log(z[0]); // output: object
			console.log(z[1]); // output: object
			console.log(z[2]); // output: "a"
			list.push({
				start: z[1],
				label: z[2],
			});
			counter++;
		}
		// console.log(counter); // output: 4
		this.setState({ labelList: list });

		// (\s\(*[\d+:]*\d+:\d+\)*\s(.*)[\n|\r\n|\r])
	};

	render() {
		const { items, cards, walkthruSample, textAreaValue, labelList } = this.state;
		return (
        <Fragment>
                {/* Header Section */}
            	<div className=" h-28 flex flex-row items-center " style={{ backgroundColor: "#e1e7ef" }}>
					<div className="self-stretch w-1/3 flex flex-col items-center justify-center  p-3">
						<span className="font-Inter font-bold  tracking-tight text-right" style={{ fontSize: "1rem", lineHeight: "1.25" }}>
							AWS Certified Cloud Practitioner Training 2020 - Full Course
						</span>
					</div>

					<Fragment>
						<div className="flex flex-col self-stretch">
							<div className="mt-12 w-4 h-4 bg-white border-2 border-gray-600 mx-auto rounded-full text-white flex items-center">
								<span className="text-gray-700 text-center w-full font-inter font-semibold text-sm">{/* {item.value} */}</span>
							</div>

							{/* LINE */}
							<React.Fragment>
								<div className="mt-0.5 flex-grow flex flex-col align-middle items-center">
									<div className=" flex-grow bg-gray-500   flex items-center" style={{ width: "0.175rem" }}>
										{" "}
									</div>
								</div>
							</React.Fragment>
						</div>
					</Fragment>

					<div className="self-stretch  flex-grow flex flex-row-reverse items-center p-4">
                    <textarea
                    className="w-48"
                        value={textAreaValue}
                        onChange={this.handleChange}
                        rows={2}
                        cols={5}
                        // onChange={this.handleChange}
                        />
						<button onClick={this.handleGet} className="bg-transparent font-Inter text-sm hover:bg-indigo-500 text-indigo-700 font-semibold hover:text-white py-1 px-3 border border-indigo-500 hover:border-transparent rounded">Get</button>
					</div>
				</div>

                <div className="self-stretch flex-grow flex flex-row">
    
                    {/* Left Side Bar */}
                    {/* style={{height:'80vh'}} */}
                    <Fragment>
                        <div className=" w-1/3 flex  overflow-hidden"  >
                            

                            <Fragment>
                            <div className="self-stretch flex-grow flex flex-col   p-3 debug-border   overflow-y-scroll ">
                                {labelList.map((item,key) => (
                                        <Link key={key} to={item.label} spy={true} smooth={true} duration={500} containerId="containerElement" className="self-stretch flex-grow cursor-pointer border-b border-gray-300  hover:bg-gray-200 py-1 pl-2 debug-border" >
                                            {/* <span className="font-Inter font-semibold  text-2xs tracking-tight text-gray-300 ">{item.start} {'  '}</span> */}
                                            <span className="ml-2 font-Inter font-medium  text-smaller tracking-tight text-gray-600 ">{item.label}</span>
                                        </Link>
                                    ))}
                            </div>
                            </Fragment>



                            
                        </div>
                    </Fragment>
    
                    {/* Content Section */}
                    <Fragment>
                        <div className="  flex-grow flex flex-col items-center self-stretch    overflow-hidden">


{/*                                     
    <Link activeclassName="active" to="firstInsideContainer" spy={true} smooth={true} duration={250} containerId="containerElement" style={{ display: 'inline-block', margin: '20px' }}>
    Go to first element inside container
</Link>

    <Link activeclassName="active" to="secondInsideContainer" spy={true} smooth={true} duration={250} containerId="containerElement" style={{ display: 'inline-block', margin: '20px' }}>
    Go to second element inside container
</Link>

    <Element name="test7" className="element" id="containerElement" style={{
    position: 'relative',
    height: '200px',
    overflow: 'scroll',
    marginBottom: '100px'
    }}>

    <Element name="firstInsideContainer" style={{
        marginBottom: '200px'
    }}>
        first element inside container
    </Element>

    <Element name="secondInsideContainer" style={{
        marginBottom: '200px'
    }}>
        second element inside container
    </Element>
    </Element> */}





                            


                                <Fragment>
                                <Element className=" flex-grow flex flex-col items-center self-stretch h-screen overflow-y-scroll" id="containerElement">




                {
                    // [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map( (item,key) =>
                    labelList.map((item, index) => (
                        <div key={index} className="  flex flex-row items-center self-stretch ">
                            <Fragment>
                                <div className="  flex flex-col self-stretch">
                                    <React.Fragment>
                                        <div className="pb-0.5 h-4 flex flex-col align-middle items-center">
                                            <div className=" flex-grow bg-gray-500   flex items-center" style={{ width: "0.175rem" }}>
                                                {" "}
                                            </div>
                                        </div>
                                    </React.Fragment>

                                    <div className=" w-4 h-4 bg-white border-2 border-gray-800 mx-auto rounded-full text-white flex items-center" style={{ backgroundColor: "#072032" }}>
                                        <span className="text-gray-700 text-center w-full font-inter font-semibold text-sm">{/* {item.value} */}</span>
                                    </div>

                                    {/* LINE */}
                                    <React.Fragment>
                                        <div className="pt-0.5 flex-grow flex flex-col align-middle items-center">
                                            <div className=" flex-grow bg-gray-500   flex items-center" style={{ width: "0.175rem" }}>
                                                {" "}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                </div>
                            </Fragment>

                            <Fragment>
                                <div className="flex flex-col self-stretch flex-grow p-3  ">
                                    <Fragment>
                                    
                                        <span id={item.label} className="font-Inter font-medium  text-base tracking-tight text-left"> {item.label}</span>
                                        
                                    </Fragment>
                                    <Fragment>
                                        <div className="flex flex-row align-middle self-stretch ">
                                            <div className="w-full  self-stretch  border-solid border border-gray-400 bg-white shadow-md flex flex-col ml-1 my-3">
                                                <div className="self-stretch flex-grow px-1 py-1 flex flex-col items-center justify-center  cursor-default  ">

                                                    {/* <Utube/> */}
                                                    

                                                    <div className="  w-full" style={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden" }}>
                                                            <ReactPlayer
                                                                style={{ position: "absolute", width: "100%", height: "100%", left: "0", top: "0" }}
                                                                // url="https://www.youtube.com/watch?v=3hLmDS179YE"
                                                                url="https://www.youtube.com/watch?v=a_kVafB-0C4"
                                                                width="100%"
                                                                height="100%"
                                                                light={true}
                                                                playIcon={<PlaySvg  className=" w-12 h-12"  />}
                                                                onStart={() => console.log("onStart")}
                                                                stopOnUnmount={true}
                                                                config={{
                                                                    youtube: {
                                                                        playerVars: { start: 1230, end: 1240, modestbranding: 1, controls: 1 },
                                                                    },
                                                                }}
                                                            />
                                                        </div>

        


                                                    {/* <div className="self-stretch flex flex-row items-center">
                                                        <svg className="h-8 flex-grow opacity-10" fill="currentColor" viewBox="0 -77 512.00213 512" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m501.453125 56.09375c-5.902344-21.933594-23.195313-39.222656-45.125-45.128906-40.066406-10.964844-200.332031-10.964844-200.332031-10.964844s-160.261719 0-200.328125 10.546875c-21.507813 5.902344-39.222657 23.617187-45.125 45.546875-10.542969 40.0625-10.542969 123.148438-10.542969 123.148438s0 83.503906 10.542969 123.148437c5.90625 21.929687 23.195312 39.222656 45.128906 45.128906 40.484375 10.964844 200.328125 10.964844 200.328125 10.964844s160.261719 0 200.328125-10.546875c21.933594-5.902344 39.222656-23.195312 45.128906-45.125 10.542969-40.066406 10.542969-123.148438 10.542969-123.148438s.421875-83.507812-10.546875-123.570312zm0 0"
                                                                fill="#f00"
                                                            />
                                                            <path d="m204.96875 256 133.269531-76.757812-133.269531-76.757813zm0 0" fill="#fff" />
                                                        </svg>

                                                        <span className="font-Inter font-medium  text-2xs tracking-tight text-right text-gray-500 ">{item.start}</span>
                                                        <svg width="13" height="11" viewBox="0 0 13 11">
                                                            <path
                                                                d="M9.38278 5.94244H1.85331C1.71043 5.94244 1.5734 5.89342 1.47237 5.80617C1.37133 5.71891 1.31458 5.60057 1.31458 5.47717C1.31458 5.35378 1.37133 5.23543 1.47237 5.14818C1.5734 5.06092 1.71043 5.01191 1.85331 5.01191H9.38278L9.92151 5.47717L9.38278 5.94244ZM8.33986 8.59778L11.5722 5.80617C11.6223 5.76297 11.662 5.71168 11.689 5.65523C11.7161 5.59878 11.73 5.53827 11.73 5.47717C11.73 5.41607 11.7161 5.35557 11.689 5.29912C11.662 5.24267 11.6223 5.19138 11.5722 5.14817L8.33986 2.35657C8.23883 2.26931 8.1018 2.22029 7.95891 2.22029C7.81603 2.22029 7.679 2.26931 7.57796 2.35657C7.47693 2.44382 7.42017 2.56217 7.42017 2.68557C7.42017 2.80897 7.47693 2.92732 7.57796 3.01457L10.4294 5.47717L7.57796 7.93977C7.47693 8.02703 7.42017 8.14537 7.42017 8.26877C7.42017 8.39217 7.47693 8.51052 7.57796 8.59778C7.679 8.68503 7.81603 8.73405 7.95891 8.73405C8.1018 8.73405 8.23883 8.68503 8.33986 8.59778Z"
                                                                fill="#949494"
                                                            />
                                                        </svg>
                                                    </div> */}

            

                                                    {/* <span className="  font-semibold font-Inter  mr-4">Introduction</span>
                                        <span className="ml-8 mr-4 font-normal text-xs  text-gray-500  mr-4 leading-tight ">Addresses that your contributors can connect </span> */}
                                                </div>

                                                {/* <div className="self-stretch px-2 py-4  flex-c border-b-2 border-gray-300 hover:bg-gray-200 cursor-default ">
                                                <span className="  font-semibold text ml-8 mr-4">Folders</span>
                                                <span className="ml-8 mr-4 font-normal text-xs  text-gray-500  mr-4 leading-tight">
                                                Addresses that your contributors can connect{" "}
                                                </span>
                                            </div>

                                            <div className="self-stretch px-2 py-4  flex-c border-b-2 border-gray-300 hover:bg-gray-200 cursor-default ">
                                                <span className="  font-semibold text ml-8 mr-4">Folders</span>
                                                <span className="ml-8 mr-4 font-normal text-xs  text-gray-500  mr-4 leading-tight">
                                                Addresses that your contributors can connect{" "}
                                                </span>
                                            </div> */}

                                            
                                            </div>
                                        
                                        
                                        <div className="ml-2 self-stretch w-1/6 flex flex-row content-center justify-center ">
                                            <div className="self-center p-1 border border-gray-300">
                                                <svg  className="w-6 h-6 fill-current text-gray-200" viewBox="0 0 31 31"  xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0)">
                                                    <path d="M28.6295 13.8795H16.5751V1.8249C16.5751 0.99332 15.9 0.318176 15.0681 0.318176C14.2365 0.318176 13.5614 0.99332 13.5614 1.8249V13.8795H1.50673C0.675144 13.8795 0 14.5547 0 15.3863C0 16.2181 0.675144 16.8933 1.50673 16.8933H13.5614V28.9476C13.5614 29.7795 14.2365 30.4546 15.0681 30.4546C15.9 30.4546 16.5751 29.7795 16.5751 28.9476V16.8933H28.6295C29.4613 16.8933 30.1365 16.2181 30.1365 15.3863C30.1365 14.5547 29.4613 13.8795 28.6295 13.8795Z"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0">
                                                    <rect width="30.1364" height="30.1364" fill="white" transform="translate(0 0.318176)"/>
                                                    </clipPath>
                                                    </defs>
                                                    </svg>
                                            </div>

                                        </div>
                                        </div>
                                    </Fragment>
                                </div>
                            </Fragment>
                        </div>
                    ))
                }
            </Element>
                                </Fragment>

                            
                        </div>
                    </Fragment>
                </div>


        </Fragment>
		);
	}
}
