import React, { Component, Fragment } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { BrowserRouter as Router, Switch, Route, Link, NavLink, withRouter, useParams } from "react-router-dom";
import axios from "axios";
import uuid from "react-uuid";
import * as Yup from "yup";

const BlankWalkthruSchema = Yup.object().shape({
	walkthruname: Yup.string().min(5, "Name must be 5 characters at minimum!").max(100, "Name can not be longer than 100 characters!").required("Name is required!"),
	walkthrudescription: Yup.string().min(5, "Description must be 5 characters at minimum!").max(100, "Description can not be longer than 100 characters!").required("Description is required!"),
});

class BlankWalkthru extends Component {
    constructor(props) {
		super(props);
		this.state = {
            walkthruname: "",
            walkthrudescription: "",
			isCreating: false,
			errors: "",
		};
    }
    

    render() {
        return (
            <React.Fragment>
            <div className="self-stretch flex flex-row items-start flex-wrap  p-3  font-Inter text-sm text-bluegray-700">
                <div className="flex flex-col justify-center items-center bg-white  w-full md:w-3/4">
                    <Formik
                        initialValues={{ walkthruname: "", walkthrudescription:"" }}
                        validationSchema={BlankWalkthruSchema}
                        isInitialValid="false"
                        // validator={() => ({})}
                        onSubmit={(values, { setSubmitting }) => {
                            console.log("onSubmit: ", JSON.stringify(values, null, 2));

                            this.setState((prevState, props) => ({
                                ...prevState,
                                walkthruname: values.walkthruname,
                                walkthrudescription: values.walkthrudescription,
                            }));

                            this.createWalkthruHandler({ setSubmitting });
                            // setSubmitting(false);
                        }}
                    >
                        {({ touched, errors, isSubmitting, values, isValid, dirty }) => (
                            <Form className="self-stretch flex flex-col justify-between items-center h-full flex-wrap">
                                <div className="self-stretch flex flex-col items-center flex-wrap ">
                                    {/* HEADER SECTION */}
                                    <div className=" border-b border-gray-200 flex flex-row items-center self-stretch flex-wrap md:justify-between ">
                                        <div className="font-inter text-base leading-8 font-bold tracking-tight text-bluegray-600 self-stretch  w-full p-3 space-x-2  flex flex-row items-center pb-6 flex-wrap ">
                                            <div className="text-bluegray-600  font-semibold ">Create a New Walkthru</div>
                                        </div>
                                    </div>

                                    {/* CONTENT SECTION */}
                                    <div className="w-full  flex flex-row items-center self-stretch flex-wrap ">
                                        <div className="self-stretch flex flex-col items-center border border-solid border-gray-200 w-full  md:w-1/4 ">
                                            <div className="m-2  flex items-center self-stretch flex-row md:flex-row-reverse ">
                                                <div className="mr-2 py-2 border-gray-300 font-semibold text-sm text-bluegray-600 ">Name: *</div>
                                            </div>
                                        </div>
                                        <div className="self-stretch flex flex-col items-center  border border-solid border-gray-200 w-full  md:w-2/4">
                                            <div className="border-gray-300 font-bold p-2  flex flex-col items-start  self-stretch ">
                                                <Field
                                                    name="walkthruname"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    className={`bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                                    ${touched.walkthruname && errors.walkthruname ? "border-Theme05-500 " : ""}`}
                                                />
                                                <ErrorMessage component="div" name="walkthruname" className=" text-purple-600  text-xs tracking-wide ml-2" />
                                            </div>
                                        </div>
                                        <div className=" self-stretch flex flex-col items-center border border-solid border-gray-200 w-full  md:w-1/4">
                                            <div className="m-2 flex-r">
                                                <div className=" border-gray-200 font-light text-xs italic">
                                                    Please provide a name for your walkthru<br />
                                                    You can change this later
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full  flex flex-row items-center self-stretch flex-wrap ">
                                        <div className="self-stretch flex flex-col items-center border border-solid border-gray-200 w-full  md:w-1/4 ">
                                            <div className="m-2  flex items-center self-stretch flex-row md:flex-row-reverse ">
                                                <div className="mr-2 py-2 border-gray-300 font-semibold text-sm text-bluegray-600 ">Description: *</div>
                                            </div>
                                        </div>
                                        <div className="self-stretch flex flex-col items-center  border border-solid border-gray-200 w-full  md:w-2/4">
                                            <div className="border-gray-300 font-bold p-2  flex flex-col items-start self-stretch ">
                                                <Field
                                                    name="walkthrudescription"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    className={`bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                                    ${touched.walkthrudescription && errors.walkthrudescription ? "border-Theme05-500 " : ""}`}
                                                />
                                                <ErrorMessage component="div" name="walkthrudescription" className=" text-purple-600  text-xs tracking-wide ml-2" />
                                            </div>
                                        </div>
                                        <div className=" self-stretch flex flex-col items-center border border-solid border-gray-200 w-full  md:w-1/4">
                                            <div className="m-2 flex-r">
                                                <div className=" border-gray-200 font-light text-xs italic">
                                                    Please provide a description for your walkthru<br />
                                                    You can change this later
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* BUTTONS SECTION */}
                                    <div className="px-4 py-4 w-full flex flex-row justify-between content-center">
                                        <span
                                            className="sm:block rounded-md mr-4 mt-2"
                                            onClick={() => {
                                                this.props.history.goBack();
                                            }}
                                        >
                                            <button type="button" className="font-inter  shadow-sm inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-bold rounded-md text-gray-700 bg-white hover:bg-gray-200 focus:outline-none focus:shadow-outline focus:border-indigo-400 transition duration-150 ease-in-out">
                                                Cancel
                                            </button>
                                        </span>
                                        {/* <svg className="ml-2  h-3 w-3 text-gray-200" fill="currentColor" viewBox="0 0 512.005 512.005">
                            <path d="M388.418 240.923L153.751 6.256c-8.341-8.341-21.824-8.341-30.165 0s-8.341 21.824 0 30.165L343.17 256.005 123.586 475.589c-8.341 8.341-8.341 21.824 0 30.165 4.16 4.16 9.621 6.251 15.083 6.251 5.461 0 10.923-2.091 15.083-6.251l234.667-234.667c8.34-8.34 8.34-21.823-.001-30.164z"/>
                        </svg> */}

                                        <button
                                            type="submit"
                                            disabled={!isValid || isSubmitting}
                                            //  (!isValid || dirty || isSubmitting)
                                            className={`mr-4 mt-2 font-Inter shadow-sm inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md  hover:bg-blue-500 focus:outline-none focus:shadow-outline focus:border-indigo-400 transition duration-150 ease-in-out ${!isValid || isSubmitting ? "opacity-75 pointer-events-none bg-indigo-500 text-gray-100" : "bg-indigo-500 text-gray-100"}`}
                                        >
                                            {isSubmitting && (
                                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            )}{" "}
                                            Import
                                        </button>

                                        {/* {isValid ? 'isvalid' : 'not isvalid'} / {dirty ? 'is dirty' : ' is not dirty'} / {touched.length ? 'is touched' : ' is not touched'} */}
                                    </div>
                                </div>

                                {/* <div className="mt-8 mb-2 p-2 border border-solid border-gray-400">
                                    <strong>Injected Formik props (the form's state)</strong>
                                    <div>
                                        <code>errors:</code> {JSON.stringify(errors, null, 2)}
                                    </div>
                                    <div>
                                        <code>values:</code> {JSON.stringify(values, null, 2)}
                                    </div>
                                    <div>
                                        <code>isValid:</code> {JSON.stringify(isValid, null, 2)}
                                    </div>
                                    <div>
                                        <code>dirty:</code> {JSON.stringify(dirty, null, 2)}
                                    </div>
                                    <div>
                                        <code>values.youtubeaddress:</code> {JSON.stringify(values.youtubeaddress, null, 2)}
                                    </div>
                                    <div>
                                        <code>isSubmitting:</code> {JSON.stringify(isSubmitting, null, 2)}
                                    </div>
                                </div> */}
                            </Form>
                        )}
                    </Formik>
                </div>


            </div>
        </React.Fragment>
    
        )
    }
}



export default withRouter(BlankWalkthru);
