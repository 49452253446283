import React, { Component } from "react";
import { Fragment } from "react";
var classNames = require("classnames");

export default class Fonts extends Component {
	render() {
		return (
			<Fragment>
				<div className="antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz No font</div>
				<div className="font-Inter antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz Inter</div>
				<div className="font-Hero antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz Hero</div>
				<div className="font-Herolight antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz Herolight</div>
				<div className="font-baloo antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz baloo</div>
				<div className="font-montserrat antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz montserrat</div>
				<div className="font-maven antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz maven</div>
				<div className="font-nunito antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz nunito</div>
				<div className="font-raleway antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz raleway</div>
				<div className="font-baloopaaji antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz baloopaaji</div>
				<div className="font-Lato antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz Lato</div>
				<div className="font-Source antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz Source</div>
				<div className="font-Work antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz Work</div>
				{/* <div className="font-Babes antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz Babes</div>
				<div className="font-Marker antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz Marker</div>
				<div className="font-Blink antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz Blink</div> */}
				<div className="font-MonAlt antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz MonAlt</div>
				<div className="font-Rubik antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz Rubik</div>
				<div className="font-Quicksand antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz Quicksand</div>
				<div className="font-Sriracha antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz Sriracha</div>
				<div className="font-ShortStack antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz ShortStack</div>
				<div className="font-Gochi antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz Gochi</div>
				<div className="font-Atos antialiased my-2 text-xl"> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz Atos</div>

				<div className="font-Inter">
					<div className="flex flex-col justify-between items-center flex-wrap self-stretch text-xs mb-2">
						{["font-thin           ", "font-extralight     ", "font-light          ", "font-normal         ", "font-medium         ", "font-semibold       ", "font-bold           ", "font-extrabold      ", "font-black          "].map((item, key) => (
							<div key={key} className="antialiased self-stretch  tracking-wide">
								<div className={classNames(" ", { [`${item}`]: true })}> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz</div>
							</div>
						))}
					</div>

					<div className="flex flex-col justify-between items-center flex-wrap self-stretch text-sm mb-2">
						{["font-thin           ", "font-extralight     ", "font-light          ", "font-normal         ", "font-medium         ", "font-semibold       ", "font-bold           ", "font-extrabold      ", "font-black          "].map((item, key) => (
							<div key={key} className="antialiased self-stretch tracking-wide">
								<div className={classNames(" ", { [`${item}`]: true })}> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz</div>
							</div>
						))}
					</div>

					<div className="flex flex-col justify-between items-center flex-wrap self-stretch mb-2">
						{["font-thin           ", "font-extralight     ", "font-light          ", "font-normal         ", "font-medium         ", "font-semibold       ", "font-bold           ", "font-extrabold      ", "font-black          "].map((item, key) => (
							<div key={key} className="antialiased self-stretch tracking-wide">
								<div className={classNames(" ", { [`${item}`]: true })}> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz</div>
							</div>
						))}
					</div>

					<div className="flex flex-col justify-between items-center flex-wrap self-stretch mb-2 text-lg">
						{["font-thin           ", "font-extralight     ", "font-light          ", "font-normal         ", "font-medium         ", "font-semibold       ", "font-bold           ", "font-extrabold      ", "font-black          "].map((item, key) => (
							<div key={key} className="antialiased self-stretch tracking-wide">
								<div className={classNames(" ", { [`${item}`]: true })}> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz</div>
							</div>
						))}
					</div>

					<div className="flex flex-col justify-between items-center flex-wrap self-stretch mb-2 text-xl">
						{["font-thin           ", "font-extralight     ", "font-light          ", "font-normal         ", "font-medium         ", "font-semibold       ", "font-bold           ", "font-extrabold      ", "font-black          "].map((item, key) => (
							<div key={key} className="antialiased self-stretch tracking-wide">
								<div className={classNames(" ", { [`${item}`]: true })}> ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz</div>
							</div>
						))}
					</div>
				</div>
			</Fragment>
		);
	}
}
