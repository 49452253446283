import React, { Component, Fragment } from "react";
// import { Scrollbars } from 'react-custom-scrollbars';
import { Scrollbars } from 'rc-scrollbars';

export default class PortalSideBar extends Component {
	render() {
        
		const { walkthruObj = {} } = this.props;
		const { walkthruObj: { WMetadata: { title = "", originalcreator = "", description = "", tmedium = "", tstandard = "" } = {}, WMetadata, WSteps: steps } = {} } = this.props;

        const { selectAndScrollStep } = this.props;

		return (
			<Scrollbars  className="flex flex-col self-stretch items-center flex-grow overflow-y-scroll "  >
                {/* {steps.length} */}
				{/* PortalSideBar
				 */}
				{/* {[...Array(20).keys()].map((item, key) => (
									<div key={item}>{key}</div>
								))} */}
				<Fragment>
					{steps &&
						steps.map(({ metadata: { id, title: steptitle, selected: stepselected, completed: stepcompleted } }, index) => (
							<div key={id} index={index} className=" ">
								<div
									className={`debug-border ml-2 self-stretch cursor-pointer border-b border-l bg-bluegray-200 border-gray-300  hover:bg-bluegray-300 flex flex-row items-center   `}
									onClick={() => {
										selectAndScrollStep(index, steps);
									}}
								>
									<div className={`self-stretch flex flex-row items-center flex-nowrap flex-grow overflow-hidden  ${stepselected ? " bg-bluegray-300 " : ""} `}>
										<div className={`  w-1 self-stretch     ${stepselected ? " bg-indigo-500 " : stepcompleted ? "" : ""}   `}></div>

										<span className="ml-2 font-Inter font-semibold  text-2xs tracking-tightest  text-gray-500 "> {index + 1} </span>

										<div title={steptitle} className={`pl-1 self-stretch  ml-2 font-Inter text-xs tracking-tight font-semibold truncate  ${stepselected ? " text-gray-600" : "text-gray-600"}`} style={{ paddingTop: "0.375rem", paddingBottom: "0.375rem" }}>
											{steptitle}
										</div>
									</div>
								</div>
							</div>
						))}
				</Fragment>
			</Scrollbars>
		);
	}
}
