import React, { Component, Fragment } from "react";

export default class Resume extends Component {
	render() {
		return (
			<Fragment>
				<div className=" flex flex-col self-stretch flex-grow min-h-screen ">
					<div className="mx-auto container flex flex-row self-stretch justify-between items-start bg-white px-16 pt-10  border-b  border-gray-200">
						<div className=" w-2/5 flex flex-col items-center flex-none   ">
							<div className="antialiased  font-Inter text-3xl font-extrabold items-start self-stretch">Burak Vural</div>

						</div>

						<div className=" flex flex-col items-end   pt-1 pb-3 ">
                            <div className=" text-2xs font-bold leading-none">
								<a href="info@burakvural.com">info@burakvural.com</a>
							</div>
							<div className=" text-2xs font-bold ">(+44) 7958295315</div>
							<div className=" text-2xs font-bold  leading-none ">
								<a href="https://www.linkedin.com/in/burakvural/">https://www.linkedin.com/in/burakvural/</a>
							</div>
							{/* <div className=" text-3xs font-medium leading-none items-start self-stretch">7 Cambridge Road, TW1 2HN, Twickenham, London </div> */}
						</div>
					</div>

					{/* <div className="mx-auto container flex flex-row self-stretch items-start bg-white px-16 pt-6 pb-6   ">
						<div className="  w-1/6 flex flex-col items-center flex-none  ">
							<div className="font-Inter text-xs font-bold items-start self-stretch">Profile</div>
						</div>

						<div className=" flex flex-col items-center ">
							<div className=" text-xs font-medium items-start self-stretch text-bluegray-500">
								I am a technologist that has proven track record of designing, building and managing IT solutions for some of the most reputable companies in the world across a broad range of sectors including Investment Banking, Finance Risk, Regtech, Data Management and Robotics. I have solid experience in building products, leading teams of varying profiles and diverse skill sets, interacting efficiently with all stakeholders including C level and
								highly technical individuals.
							</div>
						</div>
					</div> */}

					<div className="mx-auto container flex flex-row self-stretch items-start bg-white px-16 pb-3 pt-4  ">
						<div className="  w-1/6 flex flex-col items-center flex-none  ">
							<div className="font-Inter text-xs font-bold items-start self-stretch">Education and Certifications</div>
						</div>

						<div className=" flex flex-col items-center flex-grow">
							<div className="flex flex-col self-stretch justify-between">
								<div className=" text-xs  font-semibold items-start self-stretch leading-none">Master of Science - Software Management </div>
								<div className=" text-2xs font-base items-start self-stretch leading-tight">Institute of Electrical Engineering and Information Technology Software and Knowledge Engineering</div>
							</div>
							<div className="flex flex-row self-stretch  justify-between  border-b  border-gray-200">
								<div className=" text-2xs font-base items-start self-stretch italic"> Paderborn University / Germany</div>
								{/* <div className=" text-2xs font-base items-start self-stretch italic"> Securities Finance, Delta One and Dividend Forecasting</div> */}
								<div className=" text-2xs font-base items-start self-stretch italic">2005 </div>
							</div>

							<div className="flex flex-col self-stretch justify-between pt-4">
								<div className=" text-xs  font-semibold items-start self-stretch leading-none">Bsc Mat. Science Engineering Department </div>
								<div className=" text-2xs font-base items-start self-stretch leading-tight">Institute of Engineering</div>
							</div>
							<div className="flex flex-row self-stretch  justify-between  border-b  border-gray-200">
								<div className=" text-2xs font-base items-start self-stretch italic"> METU</div>
								{/* <div className=" text-2xs font-base items-start self-stretch italic"> Securities Finance, Delta One and Dividend Forecasting</div> */}
								<div className=" text-2xs font-base items-start self-stretch italic">1999 </div>
							</div>

							<div className="flex flex-col self-stretch justify-between border-b  border-gray-200 pt-4">
								<div className="flex flex-row self-stretch  justify-between ">
									<div className=" text-3xs  font-medium items-start self-stretch ">AWS Certified Architect , AWS Certified Developer  </div>
									{/* <div className=" text-2xs font-base items-start self-stretch leading-tight italic">v/u 2023</div> */}
								</div>
							</div>
							<div className=" flex flex-col items-center flex-grow"></div>
						</div>
					</div>

					{/* <div className="mx-auto container flex flex-row self-stretch items-start bg-white px-16 pb-6   ">
						<div className="  w-1/6 flex flex-col items-center flex-none  ">
							<div className="font-Inter text-xs font-bold items-start self-stretch">Certifications</div>
						</div>

						<div className=" flex flex-col items-center flex-grow">
							

							<div className="flex flex-row self-stretch justify-between pt-3">
								<div className=" text-2xs  font-medium items-start self-stretch leading-none">AWS Certified Architect Associate , AWS Certified Developer Associate , AWS Certified Practitioner </div>
								<div className=" text-2xs font-base items-start self-stretch italic"> </div>
							</div>
							<div className="flex flex-row self-stretch  justify-between  border-b  border-gray-200 pt-2"></div>
						</div>
					</div> */}

					<div className="mx-auto container flex flex-row self-stretch items-start bg-white px-16 pt-3">
						<div className="  w-1/6 flex flex-col items-center flex-none  ">
							<div className="font-Inter text-xs font-bold items-start self-stretch">Experience</div>
						</div>

						<div className=" flex flex-col items-center ">
							<div className="flex flex-col self-stretch justify-between">
								<div className=" text-sm  font-bold items-start self-stretch leading-none">IHS Markit (S&P Global) </div>
								<div className=" text-xs font-bold items-start self-stretch leading-tight">Head of Technology and Product Development / CTO</div>
							</div>
							<div className="flex flex-row self-stretch  justify-between  border-b  border-gray-200">
								<div className=" text-2xs font-base items-start self-stretch italic"> Equities, Data and Analytics - Securities Finance, Delta One and Dividend Forecasting</div>
								{/* <div className=" text-2xs font-base items-start self-stretch italic"> Securities Finance, Delta One and Dividend Forecasting</div> */}
								<div className=" text-2xs font-base items-start self-stretch italic">08/2016 - 01/2019, London UK </div>
							</div>
							<div className="text-2xs font-medium items-start self-stretch text-bluegray-500 pt-1 tracking-tight leading-snug">
								{/* I was responsible for all aspects of greenfield SFTR product development from concept to requirements gathering, tech discovery, architecting to product build. Interacted with all project stakeholders including senior Tier 1/2/3 financial banking clients, C level organization leaders, other vendors, industry organizations and all tech related counterparts. */}
								{/* <br /> */}
								Leading, architecting and building one of the first enterprise scale AWS based serverless cloud projects in the city of London.
								<br />
								Planned, set up and recruited a brand-new product development team with diverse skills and experience levels, reaching to 50+ individuals (developers, devops engineers, BAs, SMEs, testers) in a 28-month period globally. Advocated the cloud initiative in the organization by setting up a pioneering blueprint.
								<br />
								Performed multiple hybrid roles concurrently across projects based on multiple cloud providers (AWS/Azure). Took part in architecting and hands on development for multiple critical modules for the data processing ETL pipeline, frontend and data modelling.
								<br /> Working closely with AWS, our architecture was shortlisted and invited to be showcased at annual ReInvent by the cloud service provider.
								<br />
								<span className="font-bold text-xs "> AWS Lambda functions ,Step Functions ,Nodejs, Python .NET C# Core, Web Api, Angular 2.0, S3, Systems Manager, Athena, CloudWatch, CloudFormation, S3/Glacier, SFTP Transfer, Azure Devops, Git, Neptune DB, Aurora DB, Dynamo DB. </span>
							</div>
						</div>
					</div>

					<div className="mx-auto container flex flex-row self-stretch items-start bg-white px-16 pt-4">
						<div className="  w-1/6 flex flex-col items-center flex-none  ">
							<div className="font-Inter text-sm font-bold items-start self-stretch"></div>
						</div>

						<div className=" flex flex-col items-center flex-grow">
							<div className="flex flex-col self-stretch justify-between">
								<div className=" text-sm  font-bold items-start self-stretch leading-none">E-Connect </div>
								<div className=" text-xs font-bold items-start self-stretch leading-tight">Founder</div>
							</div>
							<div className="flex flex-row self-stretch  justify-between  border-b  border-gray-200 f">
								<div className=" text-2xs font-base items-start self-stretch italic"> Proptech Startup</div>
								<div className=" text-2xs font-base items-start self-stretch italic">02/2014 - 08/2016, London UK</div>
							</div>
							<div className="text-2xs font-medium items-start self-stretch text-bluegray-500 pt-1 tracking-tight leading-snug  flex-grow">
								{/* UK's first Estate Management Proptech Startup. <br/> Build on Azure using Microsoft C# .NET for the desktop end, Angular web front end, Ionic for IOS/Android mobile apps. */}
								Founded and bootstrapped UK's first Estate Management Proptech Startup. Partnered with the UK’s largest management company which had 1450 sites under management across England. Solution was built on Azure using C# .NET for the desktop end, Angular web front end, Ionic for IOS/Android mobile apps.
							</div>
						</div>
					</div>

					<div className="mx-auto container flex flex-row self-stretch items-start bg-white px-16 pt-4">
						<div className="  w-1/6 flex flex-col items-center flex-none  ">
							<div className="font-Inter text-sm font-bold items-start self-stretch"></div>
						</div>

						<div className=" flex flex-col items-center ">
							<div className="flex flex-col self-stretch justify-between">
								<div className=" text-sm  font-bold items-start self-stretch leading-none">Societe Generale </div>
								<div className=" text-xs font-bold items-start self-stretch leading-tight">Senior Technology Consultant</div>
							</div>
							<div className="flex flex-row self-stretch  justify-between  border-b  border-gray-200">
								<div className=" text-2xs font-base items-start self-stretch italic">Investment Banking - Alpha Trading Platform</div>
								<div className=" text-2xs font-base items-start self-stretch italic">06/2012 - 02/2014, London UK</div>
							</div>
							<div className="text-2xs font-medium items-start self-stretch text-bluegray-500 pt-1 tracking-tight leading-snug">
								Front Office Multi Asset Single Dealer Platform across various product types Money Markets, FX, Base Metals, Structured Products.
								<br />
								Designed and implemented various modules . Developed and maintained MVVM based trading UIs, consuming real time low latency data streams orchestrating with highly complex RX Reactive Extensions.
							</div>
						</div>
					</div>

					<div className="mx-auto container flex flex-row self-stretch items-start bg-white px-16 pt-4">
						<div className="  w-1/6 flex flex-col items-center flex-none  ">
							<div className="font-Inter text-sm font-bold items-start self-stretch"></div>
						</div>

						<div className=" flex flex-col items-center ">
							<div className="flex flex-col self-stretch justify-between">
								<div className=" text-sm  font-bold items-start self-stretch leading-none">Barclays Capital </div>
								<div className=" text-xs font-bold items-start self-stretch leading-tight">Senior Technology Consultant</div>
							</div>
							<div className="flex flex-row self-stretch  justify-between  border-b  border-gray-200">
								<div className=" text-2xs font-base items-start self-stretch italic">Equity Derivatives Technology - RFQ </div>
								<div className=" text-2xs font-base items-start self-stretch italic">06/2011 - 12/2011 , London UK</div>
							</div>
							<div className="text-2xs font-medium items-start self-stretch text-bluegray-500 pt-1 tracking-tight leading-snug">
								Worked on front office equity derivatives trade capture - RFQ (Request for Quote) application named HELIX. C# .NET WPF based system integrated sales, trading, middle and back office with a workflow process that priced and booked trades, while connecting to a number of other internal Barclays' systems. Led the refactoring of existing legacy winforms modules to a cutting edge WPF based MVVM architecture.
							</div>
						</div>
					</div>

					<div className="mx-auto container flex flex-row self-stretch items-start bg-white px-16 pt-4">
						<div className="  w-1/6 flex flex-col items-center flex-none  ">
							<div className="font-Inter text-sm font-bold items-start self-stretch"></div>
						</div>

						<div className=" flex flex-col items-center ">
							<div className="flex flex-col self-stretch justify-between">
								<div className=" text-sm  font-bold items-start self-stretch leading-none">Morgan Stanley </div>
								<div className=" text-xs font-bold items-start self-stretch leading-tight">Senior Technology Consultant</div>
							</div>
							<div className="flex flex-row self-stretch  justify-between  border-b  border-gray-200">
								<div className=" text-2xs font-base items-start self-stretch italic">ISG Technology Equity Derivatives RFQ Flow </div>
								<div className=" text-2xs font-base items-start self-stretch italic">02/2010 - 06/2011, London UK</div>
							</div>
							<div className="text-2xs font-medium items-start self-stretch text-bluegray-500 pt-1 tracking-tight leading-snug">
								Designed and implemented various Equity Derivatives RFQ Flow/Exotics Business Modules, such as Sales Blotter, Pricing, Workflow Search & History, Collaboration, Grid Management. Worked on Trader Desktop migration project for Flow/Exotics Equity Derivatives which is aiming to move legacy Winforms to a WPF .NET C# based architecture using MVVM and best practices.
							</div>
						</div>
					</div>

					<div className="mx-auto container flex flex-row self-stretch items-start bg-white px-16 pt-4">
						<div className="  w-1/6 flex flex-col items-center flex-none  ">
							<div className="font-Inter text-sm font-bold items-start self-stretch"></div>
						</div>

						<div className=" flex flex-col items-center ">
							<div className="flex flex-col self-stretch justify-between">
								<div className=" text-sm  font-bold items-start self-stretch leading-none">Barclays Capital </div>
								<div className=" text-xs font-bold items-start self-stretch leading-tight">Senior Technology Consultant</div>
							</div>
							<div className="flex flex-row self-stretch  justify-between  border-b  border-gray-200">
								<div className=" text-2xs font-base items-start self-stretch italic">DCRM - Derivative Counterparty Risk Management </div>
								<div className=" text-2xs font-base items-start self-stretch italic">09/2008 - 02/2010, London UK</div>
							</div>
							<div className="text-2xs font-medium items-start self-stretch text-bluegray-500 pt-1 tracking-tight leading-snug">
								Cross-asset class trading and structuring desk that actively manage the credit risk of derivative trades from other desks in the bank. Worked on derivative risk application framework (named as Seahawk 8.0, formerly known Eagle). Responsible for migration of Winforms based C# application to XAML based rich WPF using COM Interop utilizing existing Monte Carlo service libraries
							</div>
						</div>
					</div>

					<div className="mx-auto container flex flex-row self-stretch items-start bg-white px-16 pt-4">
						<div className="  w-1/6 flex flex-col items-center flex-none  ">
							<div className="font-Inter text-sm font-bold items-start self-stretch"></div>
						</div>

						<div className=" flex flex-col items-center ">
							<div className="flex flex-col self-stretch justify-between">
								<div className=" text-sm  font-bold items-start self-stretch leading-none">Santander Global Banking & Markets </div>
								<div className=" text-xs font-bold items-start self-stretch leading-tight">Senior Technology Consultant</div>
							</div>
							<div className="flex flex-row self-stretch  justify-between  border-b  border-gray-200">
								<div className=" text-2xs font-base items-start self-stretch italic">Investment Banking </div>
								<div className=" text-2xs font-base items-start self-stretch italic">10/2007 - 08/2008 , London UK</div>
							</div>
							<div className="text-2xs font-medium items-start self-stretch text-bluegray-500 pt-1 tracking-tight leading-snug">Santander Global Banking & Markets, formerly known as Abbey Financial Markets is the investment banking division of Spanish Bank Banco Santander in the UK. Rapid application development in a central IT team which was responsible for development and support of front/middle office systems.</div>
						</div>
					</div>

					<div className="mx-auto container flex flex-row self-stretch items-start bg-white px-16 pt-4">
						<div className="  w-1/6 flex flex-col items-center flex-none  ">
							<div className="font-Inter text-sm font-bold items-start self-stretch"></div>
						</div>

						<div className=" flex flex-col items-center ">
							<div className="flex flex-col self-stretch justify-between">
								<div className=" text-sm  font-bold items-start self-stretch leading-none">Barclays Capital </div>
								<div className=" text-xs font-bold items-start self-stretch leading-tight">Senior Technology Consultant</div>
							</div>
							<div className="flex flex-row self-stretch  justify-between  border-b  border-gray-200">
								<div className=" text-2xs font-base items-start self-stretch italic">Credit Lending IT</div>
								<div className=" text-2xs font-base items-start self-stretch italic">03/2007 - 10/2007 , London UK</div>
							</div>
							<div className="text-2xs font-medium items-start self-stretch text-bluegray-500 pt-1 tracking-tight leading-snug">Development of syndicated loans trading service automation projects. Front to Middle Office strategic application development with Multithreaded C# Winforms in a team of 20 serving for business users around 100. New Agency Task Reporting functionality in Front Office Trading Application with C#.</div>
						</div>
					</div>
					<div className="mx-auto container flex flex-row self-stretch items-start bg-white px-16 pt-4">
						<div className="  w-1/6 flex flex-col items-center flex-none  ">
							<div className="font-Inter text-sm font-bold items-start self-stretch"></div>
						</div>

						<div className=" flex flex-col items-center ">
							<div className="flex flex-col self-stretch justify-between">
								<div className=" text-sm  font-bold items-start self-stretch leading-none">Tecan Robotics</div>
								<div className=" text-xs font-bold items-start self-stretch leading-tight">Senior Technology Consultant</div>
							</div>
							<div className="flex flex-row self-stretch  justify-between  border-b  border-gray-200">
								<div className=" text-2xs font-base items-start self-stretch italic">Biotechnology, Biopharmaceuticals & Forensics </div>
								<div className=" text-2xs font-base items-start self-stretch italic">04/2005 - 11/2006, Switzerland, Germany, South Africa</div>
							</div>
							<div className="text-2xs font-medium items-start self-stretch text-bluegray-500 pt-1 tracking-tight leading-snug">
								Proud member of a team which built the world's first fully automated DNA extraction robotic system that can process 8K samples a day without any human interaction. I was responsible for implementation of programmatic access to multiple biotech hardware device interfaces. Modelled Human DNA in form of a XML Schema for payloads. C# .NET for data processing scheduling and Java for interface development with Oracle Database.
							</div>
						</div>
					</div>
					<div className=" container flex flex-row self-stretch items-start bg-white px-16 pt-4 pb-96">
						<div className="  w-1/6 flex flex-col items-center flex-none  ">
							<div className="font-Inter text-sm font-bold items-start self-stretch"></div>
						</div>

						<div className=" flex flex-col items-center self-stretch  flex-grow">
							<div className="flex flex-col self-stretch justify-between">
								<div className=" text-sm  font-bold items-start self-stretch leading-none">Siemens Business Services</div>
								<div className=" text-xs font-bold items-start self-stretch leading-tight">Senior Technology Consultant</div>
							</div>
							<div className="flex flex-row self-stretch  justify-between  border-b  border-gray-200">
								<div className=" text-2xs font-base items-start self-stretch italic">SBS GmBH & Co OHG, SAP Solutions International </div>
								<div className=" text-2xs font-base items-start self-stretch italic">10/2004 - 04/2005 , Munich, Germany</div>
							</div>
							<div className="text-2xs font-medium items-start self-stretch text-bluegray-500 pt-1 tracking-tight leading-snug"></div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
