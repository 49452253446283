import React, { Component, Fragment } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import APPCONFIG, { AppConfig } from "../../../../../utilities/AppConfig";

const schema = Yup.object().shape({
	youtubeaddress: Yup.string().min(3, "Youtube address must be 3 characters at minimum!").max(100, "Youtube address can not be longer than 100 characters!").required("Youtube address is required!"),
});

export default class AddContentYoutubeImportUrl extends Component {
	constructor(props) {
		super(props);
		this.state = {
			YoutubeAddress: "",
			isChecking: false,
			errors: "",
		};
	}

	submitHandler = ({ setSubmitting }) => {
		const { handleCloseModal, handleNext, setPayloadFromYoutube } = this.props;
		const { YoutubeAddress } = this.state;

		let dataToSend = {
			// address: "https://www.youtube.com/watch?v=3hLmDS179YE",
			// address: "https://www.youtube.com/watch?v=aoQ6S1a32j8",
			address: YoutubeAddress,
		};

		axios({
			method: "get",
			baseURL: AppConfig.ApiYoutubeBaseUrl,
			// url: '',
			params: {
				videoid: "" + YoutubeAddress.trim(),
			},
		})
			.then((response) => {
				console.log("success");
				// console.log( JSON.stringify(response.data, null, 2) );
				const { data: { items = [] } = {} } = response;

				if (items && items[0]) {
					setPayloadFromYoutube(items[0]);
					handleNext(2);
				}

				// this.setState((prevState, props) => ({
				//     ...prevState,
				// }));

				// var walkthru = scaffoldWalkthru(response.data.items[0]);
				// console.log(walkthru, JSON.stringify(walkthru, null, 2));
				// console.log( JSON.stringify(walkthru, null, 2) );
				// console.log( JSON.parse(walkthru) );

				setSubmitting(false);
			})
			.catch((error) => {
				console.log("error");
				console.log(JSON.stringify(error, null, 2));

				setSubmitting(false);
			})
			.then(function () {
				// always executed
				console.log(">> Finally <<");
			});
	};

	render() {
		const { handleCloseModal } = this.props;
		return (
			<Fragment>
				<Formik
					initialValues={{ youtubeaddress: "GQp1zzTwrIg" }}
					validationSchema={schema}
					isInitialValid="false"
					// validator={() => ({})}
					onSubmit={(values, { setSubmitting }) => {
						console.log("onSubmit: ", JSON.stringify(values, null, 2));

						//   setTimeout(() => {
						//     console.log('onSubmit: ', JSON.stringify(values, null, 2));
						//     setSubmitting(false);
						//   }, 5000);

						this.setState((prevState, props) => ({
							...prevState,
							YoutubeAddress: values.youtubeaddress,
						}));

						this.submitHandler({ setSubmitting });
						// this.createWalkthruHandler({ setSubmitting });
						// setSubmitting(false);
					}}
				>
					{({ touched, errors, isSubmitting, values, isValid, dirty }) => (
						<Form className="flex-grow self-stretch flex flex-col items-center overflow-hidden  ">
							{/* HEADER SECTION */}
							<div className=" border-b border-gray-200 flex flex-row items-center self-stretch flex-wrap md:justify-between ">
								{/* <div  className="self-stretch  flex-c  pt-4 pl-4 pb-4 "> */}
								<div className="font-inter text-base leading-8 font-bold tracking-tight text-bluegray-600 self-stretch  w-full p-3 space-x-2  flex flex-row items-center pb-6 flex-wrap ">
									<div className="text-bluegray-400  font-semibold ">Add Content</div>
									<svg className="h-5 w-5 text-bluegray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
									</svg>
									<div className="">Import Youtube Video</div>
								</div>
								{/* </div> */}

								{/* <span className="font-inter text-lg leading-8 font-semibold tracking-tight text-indigo-500 self-stretch  w-full p-3 md:w-1/2 xl:w-1/2 r">Import Youtube Chapters</span> */}
							</div>

							<Fragment>
								{/* CONTENT SECTION */}
								<div className="w-full flex flex-row items-center self-stretch flex-wrap flex-grow">
									<div className="self-stretch flex flex-col items-center border border-solid border-gray-200 w-full  md:w-1/4 ">
										<div className="m-2  flex items-center self-stretch flex-col md:flex-col ">
											<div className="mr-2 pt-2 border-gray-300 font-semibold text-sm text-bluegray-600 text-right self-stretch">Youtube address:</div>
											<div className=" border-gray-200 font-light text-xs italic text-right px-2 pb-14">
												Please provide a youtube video url <br />
											</div>
										</div>
									</div>
									<div className="self-stretch flex flex-col items-center  border border-solid border-gray-200 w-full  md:w-3/4">
										<div className="border-gray-300 font-bold p-3  flex flex-col items-center self-stretch ">
											<Field
												name="youtubeaddress"
												placeholder=""
												autoComplete="off"
												className={`bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                                    ${touched.youtubeaddress && errors.youtubeaddress ? "border-purple-500 " : ""}`}
											/>
											<ErrorMessage component="div" name="youtubeaddress" className=" text-purple-600  text-xs tracking-wide self-stretch ml-2" />
										</div>
									</div>
								</div>
							</Fragment>

							{/* BUTTONS SECTION */}
							<div className="px-4 pb-4 pt-2 w-full flex flex-row justify-between content-center bg-gray-100 ">
								<span className="sm:block rounded-md mr-4 mt-2" onClick={handleCloseModal}>
									<button type="button" className="font-inter  shadow-sm inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-200 focus:outline-none focus:shadow-outline focus:border-indigo-400 transition duration-150 ease-in-out">
										Cancel
									</button>
								</span>
								<div className="">
									<button
										type="submit"
										disabled={!isValid || isSubmitting}
										//  (!isValid || dirty || isSubmitting)
										className={`mr-4 mt-2 font-Inter shadow-sm inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md  hover:bg-blue-500 focus:outline-none focus:shadow-outline focus:border-indigo-400 transition duration-150 ease-in-out ${!isValid || isSubmitting ? "opacity-75 pointer-events-none bg-indigo-500 text-gray-100" : "bg-indigo-500 text-gray-100"}`}
									>
										{isSubmitting && (
											<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
												<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
												<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
											</svg>
										)}{" "}
										Next
									</button>
								</div>

								{/* {isValid ? 'isvalid' : 'not isvalid'} / {dirty ? 'is dirty' : ' is not dirty'} / {touched.length ? 'is touched' : ' is not touched'} */}
							</div>

							{/* <div className="mt-8 mb-2 p-2 border border-solid border-gray-400">
                                    <strong>Injected Formik props (the form's state)</strong>
                                    <div>
                                        <code>errors:</code> {JSON.stringify(errors, null, 2)}
                                    </div>
                                    <div>
                                        <code>values:</code> {JSON.stringify(values, null, 2)}
                                    </div>
                                    <div>
                                        <code>isValid:</code> {JSON.stringify(isValid, null, 2)}
                                    </div>
                                    <div>
                                        <code>dirty:</code> {JSON.stringify(dirty, null, 2)}
                                    </div>
                                    <div>
                                        <code>values.youtubeaddress:</code> {JSON.stringify(values.youtubeaddress, null, 2)}
                                    </div>
                                    <div>
                                        <code>isSubmitting:</code> {JSON.stringify(isSubmitting, null, 2)}
                                    </div>
                                </div> */}
						</Form>
					)}
				</Formik>
			</Fragment>
		);
	}
}
