import React, { Component, Fragment } from "react";

import Slider from "rc-slider";
import ProgressBar from "../../../../../assets/components/ProgressBar";
// import "rc-slider/assets/index.css";
import { Range } from "rc-slider";
import NumericInput from "react-numeric-input";
import AddContentYoutubeImportUrl from "./AddContentYoutubeImportUrl";
import AddContentYoutubeEditAttributes from "./AddContentYoutubeEditAttributes";

export default class AddContentYoutube extends Component {
	constructor(props) {
		super(props);
		this.state = {
			YoutubeAddress: "",
			isChecking: false,
			errors: "",
			newcard: {},

			step: -1,

			url: "",

			isYoutubeEditVisible: false,
		};
	}

	componentDidMount() {
		const {} = this.props;
		this.setState((prevState) => ({
			...prevState,
			step: 1,
		}));
	}



	handleBack = () => {
		this.setState((prevState) => ({
			...prevState,
			step: 1,
		}));
	};

	handleNext = (to) => {
		console.log("to ", to);
		this.setState((prevState) => ({
			...prevState,
			step: to,
		}));
	};

	render() {
		const { handleCloseModal, handleAddContentYoutube , setPayloadFromYoutube, payloadFromYoutube} = this.props;
		const { step, url } = this.state;

		return (
			<Fragment>
				<div className="self-stretch flex flex-row items-center bg-white ">
				{step == 1 
					? 
						<AddContentYoutubeImportUrl handleCloseModal={handleCloseModal} handleNext={this.handleNext} setPayloadFromYoutube={setPayloadFromYoutube} /> 	
					:
						<AddContentYoutubeEditAttributes handleAddContentYoutube={handleAddContentYoutube}   handleCloseModal={handleCloseModal} handleBack={this.handleBack} payloadFromYoutube={payloadFromYoutube} />}
				 </div>
			</Fragment>
		);
	}
}
