import React, { Component, Fragment } from "react";

export default class CategoryTitleCreatorComponent extends Component {
	render() {
		const {
			WMetadata: { title, originalcreator },
		} = this.props;
		return (
			<Fragment>
				{/* TITLE */}
				<div className="flex flex-col self-stretch items-center flex-grow py-3 pl-2">
					<span className={` font-Inter font-semibold tracking-wider antialiased  pb-1 pt-1 text-left self-stretch pl-3 ${true ? "  " : " hidden  "}`} style={{ fontSize: "0.6rem", lineHeight: ".75" }}>
						{"Online Learning >"}
					</span>
					{/* <span className={` font-Inter font-semibold tracking-wider antialiased  pb-1 pt-3 text-left self-stretch pl-3 ${true ? "  " : " hidden  "}`} style={{ fontSize: "0.6rem", lineHeight: ".75" }}>
												{selectedStepOnSideBar}
											</span> */}
					<span className={`self-stretch font-Inter font-bold  tracking-tight text-left px-3 overflow-hidden line-clamp-3 ${true ? "  " : " hidden  "}`} style={{ fontSize: "1rem", lineHeight: "1.25" }}>
						{title || "Title"}
					</span>
					{/* <div className="self-stretch flex flex-row items-center ml-20">
											<img className="inline-block h-8 w-8 rounded-full ring-2 ring-white" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
											<div className="flex flex-col items-center ml-2">
												<span className="font-Inter text-smaller font-bold items-start self-stretch ">{originalcreator}</span>
												<span className="font-Inter text-xs font-medium items-start self-stretch">{(walkthruobject && walkthruobject.createdon) || "created on"}</span>
											</div>
										</div> */}
					<span className={`self-stretch font-Inter font-bold text-purple-600 tracking-tight text-left px-3 overflow-hidden line-clamp-3 text-sm mt-1 ${true ? "  " : " hidden  "}`} style={{ fontSize: "0.72rem", lineHeight: "1.25" }}>
						{"by " + originalcreator || ""}
					</span>
				</div>
			</Fragment>
		);
	}
}
