import React, { Component, Fragment } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
	stepname: Yup.string().min(3, "Step name must be 3 characters at minimum!").max(60, "Step name can not be longer than 50 characters!").required("Step name is required!"),
});

export default class AddStepComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			StepName: "",
			isSubmitting: false,
			isErrored: "",
			errorMessage: "",
		};
	}

	render() {
		const { isSubmitting, isErrored, errorMessage } = this.state;
		return (
			<Fragment>
				<Formik
					initialValues={{ stepname: "" }}
					validationSchema={ValidationSchema}
					onSubmit={(values, { setSubmitting }) => {
						this.setState((prevState, props) => ({
							...prevState,
							StepName: values.stepname,
						}));

						this.props.handleAddStep(values.stepname);
						// this.btnSubmitHandler();
						// setSubmitting(false);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form className="bg-white self-stretch flex flex-col justify-start items-center flex-grow rounded-xl border-solid border-t-4 border-purple-500  ">
							<div className="relative self-stretch flex flex-col justify-start items-center">
								<div className="z-2 self-stretch flex flex-row justify-start items-center p-3  border-b border-bluegray-200 bg-gray-100 bg-opacity-50">
									<span className="z-3 font-semibold text-xl font-Inter text-gray-900 ml-4">Add Step</span>
								</div>
								<div className="">{isErrored ? <div className="flex flex-row items-center justify-start font-xs font-Inter font-semibold text-purple-500 mb-4">{errorMessage}</div> : <div className="mb-4"></div>}</div>

								<div className="flex flex-row items-center flex-grow self-stretch px-8 py-3">
									<div className="mb-6 ml-2 flex-grow flex-col ">
										<label className="block mb-1 font-Inter text-gray-800  font-semibold text-base">Step Name</label>

										<Field
											name="stepname"
											placeholder=""
											autoComplete="off"
											className={` appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-900 font-semibold leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                              ${touched.stepname && errors.stepname ? "text-purple-500 border-purple-500" : ""}`}
										/>
										<ErrorMessage component="div" name="stepname" className="text-purple-600 font-Inter font-medium text-xs tracking-wide ml-1" />
									</div>
								</div>

								<div className="flex flex-row-reverse items-center flex-grow self-stretch px-8 py-7">
									<button className="ml-2 bg-indigo-500  text-white py-2 px-8 rounded font-medium font-Inter  text-sm focus:outline-none" type="submit">
										<div className="self-stretch flex flex-row items-center ">
											<span className="font-Inter font-medium text-base">Add</span>
										</div>
									</button>
									<button onClick={this.props.handleCloseModal} className="ml-2 bg-bluegray-200  text-white py-2 px-8 rounded font-medium font-Inter  text-sm focus:outline-none">
										<div className="self-stretch flex flex-row items-center ">
											<span className="font-Inter font-medium text-base text-bluegray-900">Cancel</span>
										</div>
									</button>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</Fragment>
		);
	}
}
