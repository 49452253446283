import React, { Component, Fragment } from "react";
import MarkdownComponent from "./MarkdownComponent";
import YoutubeComponent from "./YoutubeComponent";
import EditorComponent from './EditorComponent';

export default class CardComponent extends Component {
	constructor(props) {
		super(props);
		this.myEditorRef = React.createRef();
	}

	// handleExpand = () => {
	// 	const {
	// 		card: {
	// 			metadata: { id: cardid , size},
	// 		},
	// 		stepindex,
	// 		handleExpand,
	// 	} = this.props;
	// 	console.log(`CardComponent handleExpand  stepindex ${stepindex} cardid  ${cardid} `);



	// 	handleExpand({ stepindex: stepindex, cardid: cardid });
	// };

	componentWillUnmount() { 
		if(this.myEditorRef.current){
			this.myEditorRef.current.destroy()
		}
     }
    

	render() {
		const {
			card: {
				metadata: { id: cardid , type,  size},
			},
			stepmetadata,
		} = this.props;
		return (
			<Fragment>
				<div
					className={`self-stretch   flex flex-col  space-x-4 resize   w-${size}/12 `}
					// className={`self-stretch  border-solid border border-gray-400 bg-white shadow-md rounded-lg flex flex-col ml-1 my-3 ${item.ready ? "w-full" : "w-2/5"}`}
					style={{
						transition: "all 0.4s cubic-bezier(0.65, 0, 0.35, 1)",
					}}
				>
					{/* *******************  NOTE  *********************** 
					The order is important. Later attributes override previous ones.
					var props = { foo: 'default' };
					var component = <Component {...props} foo={'override'} />;
					console.log(component.props.foo); // 'override' */}
					{/* <YoutubeComponent {...this.props} handleExpand={this.handleExpand} /> */}
					{ type== "video"  
					? <YoutubeComponent {...this.props}  /> 
					: type== "text"  
					?  <MarkdownComponent {...this.props} /> 
					:	<EditorComponent {...this.props}   ref={this.myEditorRef} />
					}
				</div>
			</Fragment>
		);
	}
}
