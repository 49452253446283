export const walkthruSample = [
	{ level: "1", title: "Meet Your Instructor Andrew Brown", start: "0:00:00" },
	{ level: "1", title: "Why Get AWS Cloud Practitioner Certified?", start: "0:00:40" },
	{ level: "1", title: "Exam Guide Overview", start: "0:06:40" },
	{ level: "1", title: "What is Cloud Computing?", start: "0:09:57" },
];

export const flowSample = [
	{
		company: "Twitter Inc",
		ticker: "TWTR",
		stockPrice: "22.76 USD",
		timeElapsed: "5 sec ago",
	},
	{
		company: "Square Inc",
		ticker: "SQ",
		stockPrice: "45.28 USD",
		timeElapsed: "10 sec ago",
	},
	{
		company: "Shopify Inc",
		ticker: "SHOP",
		stockPrice: "341.79 USD",
		timeElapsed: "3 sec ago",
	},
	{
		company: "Sunrun Inc",
		ticker: "RUN",
		stockPrice: "9.87 USD",
		timeElapsed: "4 sec ago",
	},
	{
		company: "Adobe Inc",
		ticker: "ADBE",
		stockPrice: "300.99 USD",
		timeElapsed: "10 sec ago",
	},
	{
		company: "HubSpot Inc",
		ticker: "HUBS",
		stockPrice: "115.22 USD",
		timeElapsed: "12 sec ago",
	},
];
