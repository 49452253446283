// https://serverless-stack.com/chapters/configure-aws-amplify.html

const config = {
    // s3: {
    //   REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
    //   BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME",
    // },
    // apiGateway: {
    //   REGION: "YOUR_API_GATEWAY_REGION",
    //   URL: "YOUR_API_GATEWAY_URL",
    // },
    
    cognito: {
      USER_POOL_ID: "us-east-1_7UuCaDhMW",
      APP_CLIENT_ID: "7g86i2oe3jo4ijlsvaj2rkf80j",
      IDENTITY_POOL_ID: "us-east-1:985569712105:userpool/us-east-1_7UuCaDhMW",
    },
    // cognito: {
    //   REGION: "YOUR_COGNITO_REGION",
    //   USER_POOL_ID: "eu-west-2_mM4lxUNRj",
    //   APP_CLIENT_ID: "1rtm6kqg58a1k6gd38rj2a2448",
    //   IDENTITY_POOL_ID: "eu-west-2:490af4da-5ca6-4d3b-906b-b56e40937964",
    // },
  };
  
  export default config;