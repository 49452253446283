import React, { Component, Fragment } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { BrowserRouter as Router, Switch, Route, Link, NavLink, withRouter, useParams } from "react-router-dom";
import * as Yup from "yup";
import APPCONFIG, { AppConfig } from "./../../../utilities/AppConfig";
import axios from "axios";
import { Auth } from "aws-amplify";
const _ = require("lodash");

class WAccessComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selection: "blankcanvas",

			isSavingWalkthu: false,
			errors: "",
		};
	}

	handleSelection = (payload) => {
		const { currentTarget: { dataset: { selection } = {} } = {} } = payload;
		console.log("selection", selection);
		this.setState((prevState) => ({
			...prevState,
			selection: selection,
		}));
	};

	render() {
		const { walkbo: { WMetadata: { title = "", originalcreator = "", description = "", tmedium = "", tstandard = "" } = {}, WMetadata, WSteps: steps } = {} } = this.props;
		const { setActiveTab } = this.props;

		const { selection } = this.state;
		return (
			<Fragment>
				<section className="container flex flex-col items-center self-stretch flex-grow pt-2" style={{ backgroundColor: "#fff" }}>
					<div className=" border-b border-gray-200 flex flex-row items-center self-stretch flex-wrap md:justify-between ">
						<div className="font-inter text-base leading-8 font-bold tracking-tight text-bluegray-600 self-stretch  w-full p-3 space-x-2  flex flex-row items-center pb-6 flex-wrap ">
							<div className="text-bluegray-400  font-semibold ">Walkthru</div>
							<svg className="h-5 w-5 text-bluegray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
							</svg>
							<div className="">Manage Access</div>
						</div>
					</div>

					<Fragment>
						<div className="flex flex-row items-center self-stretch  flex-wrap">
							<div className="flex flex-row items-center self-stretch flex-grow w-full p-3 md:w-1/2 xl:w-1/2  ">
								<div className={`group hover:text-indigo-400  hover:bg-bluegray-200 cursor-pointer flex flex-row items-center self-stretch justify-between flex-grow  bg-white p-5 ${selection == "private" ? "border-2  border-indigo-500 " : "border-2  border-bluegray-300"}  `} onClick={this.handleSelection} data-selection="private">
									<div className="flex flex-col items-center self-stretch flex-grow w-5/6">
										<div className="flex flex-row items-center self-stretch  flex-wrap font-Inter text-base font-semibold text-bluegray-600">Private</div>
										<div className="flex flex-row items-center self-stretch  flex-wrap font-Inter text-sm font-base text-bluegray-600">Access is restricted to the people you manage.</div>
									</div>
									<div className="flex flex-col items-center self-stretch w-1/6">
										<div className="flex flex-row items-center self-stretch  flex-wrap font-Inter text-lg font-medium">
											<div className={`mt-3 w-4 h-4 bg-white  mx-auto rounded-full text-white flex items-center ${selection == "private" ? "border-2 border-indigo-500 bg-purple-200" : "border-2  border-gray-400"}  `}>{/* <span className="text-gray-700 text-center w-full font-inter font-semibold text-sm"></span> */}</div>
										</div>
									</div>
								</div>
							</div>
							<div className="flex flex-row items-center self-stretch  w-full p-3 md:w-1/2 xl:w-1/2 ">
								<div className={`group hover:text-indigo-400  hover:bg-bluegray-200 cursor-pointer  flex flex-row items-center self-stretch justify-between  flex-grow  bg-white p-5  ${selection == "public" ? "border-2 border-indigo-500 " : "border-2  border-bluegray-300"}  `} onClick={this.handleSelection} data-selection="public">
									<div className="flex flex-col items-center self-stretch ">
										<div className="flex flex-row items-center self-stretch  flex-wrap font-Inter text-base font-semibold text-bluegray-600 ">Public</div>
										<div className="flex flex-row items-center self-stretch  flex-wrap font-Inter text-sm font-base text-bluegray-600 ">Anyone with the web address of the wakthru can access and see the contents of it.</div>
									</div>
									<div className="flex flex-col items-center self-stretch w-1/6">
										<div className="flex flex-row items-center self-stretch  flex-wrap font-Inter text-lg font-medium">
											<div className={`mt-3 w-4 h-4 bg-white border-2 border-gray-400 mx-auto rounded-full text-white flex items-center ${selection == "public" ? "border-2 border-indigo-500 bg-purple-200" : "border-2  border-gray-400"}  `}>
												<span className="text-gray-700 text-center w-full font-inter font-semibold text-sm"></span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Fragment>
				</section>
			</Fragment>
		);
	}
}

export default withRouter(WAccessComponent);
