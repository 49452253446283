
export const AppConfig = {
    ApiWalkthruBaseUrl: "https://k220v1aofh.execute-api.us-east-1.amazonaws.com/prod/",
    ApiYoutubeBaseUrl: "https://krh2ec9l2d.execute-api.us-east-1.amazonaws.com/prod/"
}



// export const AppConfig {

//     // apiBaseUrl: "https://roc9xcq8ff.execute-api.eu-west-1.amazonaws.com/api/",


//     // apiBase: "https://devzero-api.serverlesssftp.co.uk",   // no / at the end
//     // apiBaseIdentity: "https://devzero-api.serverlesssftp.co.uk" + "/identity", 
//     // apiBaseBackend: "https://devzero-api.serverlesssftp.co.uk" + "/backend", 
//     // apiBaseTransfer: "https://devzero-api.serverlesssftp.co.uk" + "/transfer", 


//     // apiBaseUrl: "https://gd8x2o19vh.execute-api.eu-west-1.amazonaws.com/dev",

//     // apiBase: "https://gd8x2o19vh.execute-api.eu-west-1.amazonaws.com/dev",   // no / at the end
//     // apiBaseIdentity: "https://gd8x2o19vh.execute-api.eu-west-1.amazonaws.com/dev" , 
//     // apiBaseBackend: "https://gd8x2o19vh.execute-api.eu-west-1.amazonaws.com/dev" + "/backend", 
//     // apiBaseTransfer: "https://gd8x2o19vh.execute-api.eu-west-1.amazonaws.com/dev" + "/transfer", 



//     // ApiScrapper: "https://91ynxv908i.execute-api.us-east-1.amazonaws.com/prod", // no / at the end

//     // apiDelay: "300",
//     // apiTimeout: "10000",



//     ApiWalkthru: "https://91ynxv908i.execute-api.us-east-1.amazonaws.com/prod" // no / at the end



// };
