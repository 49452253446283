import React, { Component, Fragment } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import APPCONFIG, { AppConfig } from "../../../../../utilities/AppConfig";
import ReactPlayer from "react-player";
import NumericInput from "react-numeric-input";
import { Range } from "rc-slider";

const schema = Yup.object().shape({
	title: Yup.string().max(100, "Title can not be longer than 100 characters!"),
	description: Yup.string().max(100, "Description can not be longer than 100 characters!"),
});

const getFormattedDuration = (duration) => {
	if (!duration) duration = 0;
	var hours = Math.floor(duration / 60 / 60);
	var minutes = Math.floor(duration / 60) - hours * 60;
	var seconds = duration % 60;
	var formatted = hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
	return {
		duration: duration,
		hours: hours,
		minutes: minutes,
		seconds: seconds,
		formatted: formatted,
	};
};

export default class AddContentYoutubeEditAttributes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			YoutubeAddress: "",
			isChecking: false,
			errors: "",

			url: "",

			durationLowerBound: 0,
			durationUpperBound: 0,
			durationValues: [0, 0],
			selectedDurationLowerBound: 0,
			selectedDurationUpperBound: 0,
			selectedDurationValues: [0, 0],

			durationLowerBoundHours: 0,
			durationLowerBoundMinutes: 0,
			durationLowerBoundSeconds: 0,

			durationUpperBoundHours: 0,
			durationUpperBoundMinutes: 0,
			durationUpperBoundSeconds: 0,
		};
	}

	ref = (player) => {
		this.player = player;
	};

	setupScreen() {
		const { payloadFromYoutube, payloadFromYoutube: { id, status: { privacyStatus = "", embeddable = "" } = {}, contentDetails: { duration: isoduration = {} } = {}, snippet: { title = "", description = "", thumbnails: { high: thigh } = {} } = {} } = {} } = this.props;

		console.log(JSON.stringify(payloadFromYoutube, null, 2));
		console.log(JSON.stringify(title, null, 2));
		console.log(JSON.stringify(isoduration, null, 2));
		console.log(JSON.stringify(thigh, null, 2));

		let duration = 0;

		let z = null;
		const reg = /(?:PT)(\d+)(?:H)(\d+)(?:M)(\d+)(?:S)/g;
		while (null != (z = reg.exec(isoduration))) {
			let hour = parseInt(z[1] ? z[1] : 0);
			console.log("hour ", hour);
			let min = parseInt(z[2] ? z[2] : 0);
			console.log("min ", min);
			let sec = parseInt(z[3] ? z[3] : 0);
			console.log("sec ", sec);

			duration = hour * 3600 + min * 60 + sec;
		}

		var { hours, minutes, seconds } = getFormattedDuration(duration);
		this.setState((prevState) => ({
			...prevState,
			url: `https://www.youtube.com/watch?v=${id}`,

			durationValues: [1, duration],
			selectedDurationValues: [1, duration],
			durationLowerBound: 0,
			durationUpperBound: duration,
			selectedDurationLowerBound: 0,
			selectedDurationUpperBound: duration,

			durationLowerBoundHours: 0,
			durationLowerBoundMinutes: 0,
			durationLowerBoundSeconds: 0,

			durationUpperBoundHours: hours,
			durationUpperBoundMinutes: minutes,
			durationUpperBoundSeconds: seconds,
		}));
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		console.log("AddContentYoutubeEditAttributes componentDidUpdate");
		// this.setupScreen();
	}

	componentDidMount() {
		console.log("AddContentYoutubeEditAttributes componentDidMount");
		const {} = this.props;
		this.setupScreen();
	}

	onSliderChange = (value) => {
		console.log(value);

		var { hours: lowerhours, minutes: lowerminutes, seconds: lowerseconds } = getFormattedDuration(value[0]);

		var { hours: upperhours, minutes: upperminutes, seconds: upperseconds } = getFormattedDuration(value[1]);

		this.setState((prevState) => ({
			...prevState,
			selectedDurationLowerBound: value[0],
			selectedDurationUpperBound: value[1],
			selectedDurationValues: value,

			durationLowerBoundHours: lowerhours,
			durationLowerBoundMinutes: lowerminutes,
			durationLowerBoundSeconds: lowerseconds,

			durationUpperBoundHours: upperhours,
			durationUpperBoundMinutes: upperminutes,
			durationUpperBoundSeconds: upperseconds,
		}));
	};

	onLowerBoundChange = (event, source) => {
		console.log("event", event);
		console.log("source", source);

		this.setState((prevState) => ({
			...prevState,
			selectedDurationLowerBound: (source == "hours" ? event : prevState.durationLowerBoundHours) * 3600 + (source == "minutes" ? event : prevState.durationLowerBoundMinutes) * 60 + (source == "seconds" ? event : prevState.durationLowerBoundSeconds),
			durationLowerBoundHours: source == "hours" ? event : prevState.durationLowerBoundHours,
			durationLowerBoundMinutes: source == "minutes" ? event : prevState.durationLowerBoundMinutes,
			durationLowerBoundSeconds: source == "seconds" ? event : prevState.durationLowerBoundSeconds,
		}));
	};

	onUpperBoundChange = (event, source) => {
		console.log("event", event);
		console.log("source", source);

		this.setState((prevState) => ({
			...prevState,
			selectedDurationUpperBound: (source == "hours" ? event : prevState.durationUpperBoundHours) * 3600 + (source == "minutes" ? event : prevState.durationUpperBoundMinutes) * 60 + (source == "seconds" ? event : prevState.durationUpperBoundSeconds),
			durationUpperBoundHours: source == "hours" ? event : prevState.durationUpperBoundHours,
			durationUpperBoundMinutes: source == "minutes" ? event : prevState.durationUpperBoundMinutes,
			durationUpperBoundSeconds: source == "seconds" ? event : prevState.durationUpperBoundSeconds,
		}));
	};

	submitHandler = ({ setSubmitting }) => {
		const { handleAddContentYoutube } = this.props;
		const { title, description, selectedDurationLowerBound, selectedDurationUpperBound } = this.state;
		const { durationUpperBoundHours, durationUpperBoundMinutes, durationUpperBoundSeconds } = this.state;
		const { durationLowerBoundHours, durationLowerBoundMinutes, durationLowerBoundSeconds } = this.state;

		handleAddContentYoutube({
			title: title,
			description: description,
			selectedDurationLowerBound: selectedDurationLowerBound,
			selectedDurationUpperBound: selectedDurationUpperBound,

			durationLowerBoundHours: durationLowerBoundHours,
			durationLowerBoundMinutes: durationLowerBoundMinutes,
			durationLowerBoundSeconds: durationLowerBoundSeconds,

			durationUpperBoundHours: durationUpperBoundHours,
			durationUpperBoundMinutes: durationUpperBoundMinutes,
			durationUpperBoundSeconds: durationUpperBoundSeconds,
		});
	};

	render() {
		const { handleCloseModal, handleBack } = this.props;



		const { selectedDurationLowerBound, selectedDurationUpperBound, durationLowerBound, durationUpperBound } = this.state;
		const { durationUpperBoundHours, durationUpperBoundMinutes, durationUpperBoundSeconds } = this.state;
		const { durationLowerBoundHours, durationLowerBoundMinutes, durationLowerBoundSeconds } = this.state;

		const { payloadFromYoutube: { id, status: { privacyStatus = "", embeddable = "" } = {}, contentDetails: { duration: isoduration = {} } = {}, snippet: { title = "", description = "", thumbnails: { high: thigh } = {} } = {} } = {} } = this.props;

		const { url } = this.state;

		return (
			<Fragment>
				<div className="self-stretch flex flex-row items-center flex-grow bg-white debug-border ">
					<Formik
						initialValues={{ title: title, description: description.substring(0, 100)   }}
						validationSchema={schema}
						isInitialValid="false"
						// validator={() => ({})}
						onSubmit={(values, { setSubmitting }) => {
							console.log("onSubmit: ", JSON.stringify(values, null, 2));

							//   setTimeout(() => {
							//     console.log('onSubmit: ', JSON.stringify(values, null, 2));
							//     setSubmitting(false);
							//   }, 5000);

							this.setState((prevState, props) => ({
								...prevState,
								title: values.title,
								description: values.description,
							}));

							this.submitHandler({ setSubmitting });
							// this.createWalkthruHandler({ setSubmitting });
							// setSubmitting(false);
						}}
					>
						{({ touched, errors, isSubmitting, values, isValid, dirty }) => (
							<Form className="flex-grow self-stretch flex flex-col items-center overflow-hidden  ">
								{/* HEADER SECTION */}
								<div className=" border-b border-gray-200 flex flex-row items-center self-stretch flex-wrap md:justify-between ">
									{/* <div  className="self-stretch  flex-c  pt-4 pl-4 pb-4 "> */}
									<div className="font-inter text-base leading-8 font-bold tracking-tight text-bluegray-600 self-stretch  w-full p-3 space-x-2  flex flex-row items-center pb-6 flex-wrap ">
										<div className="text-bluegray-400  font-semibold ">Add Content</div>
										<svg className="h-5 w-5 text-bluegray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
										</svg>
										<div className="">Import Youtube Video</div>
									</div>
									{/* </div> */}

									{/* <span className="font-inter text-lg leading-8 font-semibold tracking-tight text-indigo-500 self-stretch  w-full p-3 md:w-1/2 xl:w-1/2 r">Import Youtube Chapters</span> */}
								</div>

								{/* CONTENT SECTION */}
								<Fragment>
									<div className="flex flex-row items-center self-stretch flex-wrap flex-none">
										<div className="self-stretch flex flex-col items-center border border-solid border-gray-200 w-full flex-grow  md:w-1/4 ">
											<div className="m-2  flex items-center self-stretch flex-col md:flex-col ">
												<div className="mr-2 pt-2 border-gray-300 font-semibold text-sm text-bluegray-600 text-right self-stretch">Youtube video:</div>
												<div className=" border-gray-200 font-light text-xs italic text-right px-2">
													This is the full length youtube with the id you have provided. You can preview the whole video and determine the duration you would like to add, using the slider below. <br />
												</div>
											</div>
										</div>
										<div className="self-stretch flex flex-col items-center  border border-solid border-gray-200 w-full flex-none md:w-3/4">
											<div className="border-gray-300 font-bold p-3  flex flex-col items-center self-stretch  ">
												<div className={`w-full border-0 min-h-0`} style={{ position: "relative", paddingBottom: "56.25%", overflow: "hidden" }}>
													<ReactPlayer
														style={{ position: "absolute", width: "100%", height: "100%", left: "0", top: "0" }}
														ref={this.ref}
														// playing={playing}
														// onPlay={this.handleOnPlay}
														// onPause={this.handleonPause}
														// progressInterval= {200}
														url={url}
														// url={`https://www.youtube.com/watch?v=${id}`}
														// url="https://www.youtube.com/watch?v=a_kVafB-0C4"
														// url="https://www.youtube.com/watch?v=rfscVS0vtbw&t=17s"
														// url="https://www.youtube.com/watch/9qZAfcZx6U0"
														width="100%"
														height="100%"
														playIcon={
															// <PlaySvg className=" w-1/5 bg-gray-600 " />
															<div className="w-full h-full bg-cover bg-center hover:opacity-60" onClick={this.preloadClick} style={{ backgroundImage: `url('${thigh}')` }}>
																{/* overlay */}
															</div>

															// <svg className=" w-full fill-current text-indigo-500 bg-red-500 " xviewBox="0 0 24 24" stroke="currentColor">
															// <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
															// <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
															// </svg>
														}
														onStart={() => console.log("onStart")}
														onReady={() => {
															// console.log("onReady: ", stepindex);
															// this.setState((prevState) => {
															// 	const newLabelList = [...prevState.labelList];
															// 	newLabelList[index].ready = true;
															// 	return { labelList: newLabelList };
															// }
														}}
														onEnded={() => {
															// this.scrollToNext(index);
															// this.setState((prevState) => {
															// 	const newLabelList = [...prevState.labelList];
															// 	newLabelList[index].ready = false;
															// 	newLabelList[index + 1].ready = true;
															// 	return { labelList: newLabelList };
															// });
														}}
														onProgress={this.handleProgress}
														// onDuration={this.handleDuration}
														stopOnUnmount={true}
														config={{
															youtube: {
																playerVars: {
																	start: selectedDurationLowerBound == 0 ? 1 : selectedDurationLowerBound,
																	end: selectedDurationUpperBound,
																	modestbranding: 1,
																	controls: 1,
																	rel: 0,
																	showinfo: 0,
																},
															},
														}}
													/>
												</div>
											</div>
										</div>
										{/* {selectedDurationLowerBound} : {selectedDurationUpperBound} : {title} */}
									</div>

									<div className="w-full flex flex-row items-center self-stretch flex-wrap flex-grow">
										<div className="self-stretch flex flex-col items-center border border-solid border-gray-200 w-full  md:w-1/4 ">
											<div className="m-2  flex items-center self-stretch flex-col md:flex-col ">
												<div className="mr-2 pt-2 border-gray-300 font-semibold text-sm text-bluegray-600 text-right self-stretch">Duration:</div>
												<div className=" border-gray-200 font-light text-xs italic text-right px-2">
													You can set start and end times for the duration of the video to be added. If left as default full length video is added.
													<br />
													<br />
													{/* <label>LowerBound: </label>
                <input type="number" value={selectedDurationLowerBound} onChange={this.onLowerBoundChange} />
                <br />
                <label>UpperBound: </label>
                <input type="number" value={getFormattedDuration(selectedDurationUpperBound).formatted} onChange={this.onUpperBoundChange} />
                <br /> */}
												</div>
											</div>
										</div>
										<div className="self-stretch flex flex-col items-center  border border-solid border-gray-200 w-full  md:w-3/4">
											<div className="border-gray-300 font-bold p-3 px-5  flex flex-col items-center self-stretch ">
												{/* {selectedDurationLowerBound} {selectedDurationUpperBound} {durationLowerBound} {durationUpperBound} {"  "}
            {getFormattedDuration(selectedDurationUpperBound).formatted} */}

												{/* {durationLowerBound} - {durationUpperBound} */}

												<div className="flex flex-row justify-between items-center self-stretch pt-1 ">
													<div className="flex flex-row items-center self-stretch ">
														<div className="font-Inter text-sm font-semibold w-14 text-left self-stretch  ">From</div>
														<div className="font-Inter text-xs font-semibold w-14 text-left self-stretch  "></div>
														<div className="font-Inter text-xs font-semibold w-14 text-left self-stretch  "></div>
													</div>
													<div className="flex flex-row items-center self-stretch ">
														<div className="font-Inter text-sm font-semibold w-14 text-left self-stretch  mr-2">To</div>
														<div className="font-Inter text-xs font-semibold w-14 text-left self-stretch  "></div>
														<div className="font-Inter text-xs font-semibold w-14 text-left self-stretch  "></div>
													</div>
												</div>

												<div className="flex flex-row justify-between items-center self-stretch">
													<div className="flex flex-row items-center self-stretch space-x-1">
														<NumericInput strict min={0} max={11} value={durationLowerBoundHours} onChange={(e) => this.onLowerBoundChange(e, "hours")} className="border border-gray-200 font-semibold w-14" />
														<NumericInput strict min={0} max={59} value={durationLowerBoundMinutes} onChange={(e) => this.onLowerBoundChange(e, "minutes")} className="border border-gray-200 font-semibold w-14" />
														<NumericInput strict min={0} max={59} value={durationLowerBoundSeconds} onChange={(e) => this.onLowerBoundChange(e, "seconds")} className="border border-gray-200 font-semibold w-14" />
													</div>
													<div className="flex flex-row items-center self-stretch space-x-1">
														<NumericInput strict min={0} max={11} value={durationUpperBoundHours} onChange={(e) => this.onUpperBoundChange(e, "hours")} className="border border-gray-200 font-semibold w-14" />
														<NumericInput strict min={0} max={59} value={durationUpperBoundMinutes} onChange={(e) => this.onUpperBoundChange(e, "minutes")} className="border border-gray-200 font-semibold w-14" />
														<NumericInput strict min={0} max={59} value={durationUpperBoundSeconds} onChange={(e) => this.onUpperBoundChange(e, "seconds")} className="border border-gray-200 font-semibold w-14" />
													</div>
												</div>
												<div className="flex flex-row justify-between items-center self-stretch">
													<div className="flex flex-row items-center self-stretch space-x-1">
														<div className="font-Inter text-xs font-normal w-14">hours</div>
														<div className="font-Inter text-xs font-normal w-14">mins</div>
														<div className="font-Inter text-xs font-normal w-14">seconds</div>
													</div>
													<div className="flex flex-row items-center self-stretch space-x-1">
														<div className="font-Inter text-xs font-semi font-normal w-14">hours</div>
														<div className="font-Inter text-xs font-normal w-14">mins</div>
														<div className="font-Inter text-xs font-normal w-14">seconds</div>
													</div>
												</div>

												<Range
													allowCross={false}
													className=" cursor-pointer  self-stretch mt-4 "
													// style={{paddingTop:'0px', paddingBottom:'0px'}}

													// marks={{
													//     0: `$ 0`,
													//     100: `$ 100`
													// }}

													min={durationLowerBound}
													max={durationUpperBound}
													value={[selectedDurationLowerBound, selectedDurationUpperBound]}
													// defaultValue={durationValues}
													defaultValue={[selectedDurationLowerBound, selectedDurationUpperBound]}
													onChange={this.onSliderChange}
													tipFormatter={(value) => `$ ${value}`}
													tipProps={{
														placement: "top",
														visible: true,
													}}
													// onChange={this.onRCSliderChange}

													railStyle={{ backgroundColor: "lightgray", height: 7 }}
													// trackStyle={{ backgroundColor: "rgba(139, 92, 246,1)", height: 10, marginTop: 0 }}
													trackStyle={[
														{ backgroundColor: "rgba(139, 92, 246,1)", height: 6, marginTop: 0 },
														{ backgroundColor: "rgba(139, 92, 246,1)", height: 6, marginTop: 0 },
													]}
													// handleStyle={{

													// 	borderColor: "rgba(139, 92, 246,1)",
													// 	height: 18,
													// 	width: 18,
													// 	marginLeft: 0,
													// 	marginTop: -6,
													// 	backgroundColor: "rgba(139, 92, 246,0.5)",
													// }}
													handleStyle={[
														{
															borderColor: "rgba(139, 92, 246,1)",
															height: 16,
															width: 16,
															marginLeft: 0,
															marginTop: -5,
															backgroundColor: "#fff",
															borderWidth: 4,
														},
														{
															borderColor: "rgba(139, 92, 246,1)",
															height: 16,
															width: 16,
															marginLeft: 0,
															marginTop: -5,
															backgroundColor: "#fff",
															borderWidth: 4,
														},
													]}
												/>
											</div>
										</div>
									</div>

									<div className="w-full flex flex-row items-center self-stretch flex-wrap flex-grow">
										<div className="self-stretch flex flex-col items-center border border-solid border-gray-200 w-full  md:w-1/4 ">
											<div className="m-2  flex items-center self-stretch flex-col md:flex-col ">
												<div className="mr-2 pt-2 border-gray-300 font-semibold text-sm text-bluegray-600 text-right self-stretch">Title:</div>
												<div className=" border-gray-200 font-light text-xs italic text-right px-2">
													Please provide a title for your content
													<br />
													<br />
												</div>
											</div>
										</div>
										<div className="self-stretch flex flex-col items-center  border border-solid border-gray-200 w-full  md:w-3/4">
											<div className="border-gray-300 font-bold p-3  flex flex-col items-center self-stretch ">
												<div className="flex flex-row items-center flex-grow self-stretch py-1 pr-2">
													<div className=" ml-2 flex-grow flex-col ">
														<Field
															component="textarea"
															name="title"
															placeholder=""
															autoComplete="off"
															className={`h-20 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-900 font-normal leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                              ${touched.title && errors.title ? "text-purple-500 border-purple-500" : ""}`}
														/>
														<ErrorMessage component="div" name="title" className="text-purple-600 font-Inter font-medium text-xs tracking-wide ml-1" />
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="w-full flex flex-row items-center self-stretch flex-wrap flex-grow">
										<div className="self-stretch flex flex-col items-center border border-solid border-gray-200 w-full  md:w-1/4 ">
											<div className="m-2  flex items-center self-stretch flex-col md:flex-col ">
												<div className="mr-2 pt-2 border-gray-300 font-semibold text-sm text-bluegray-600 text-right self-stretch">Description:</div>
												<div className=" border-gray-200 font-light text-xs italic text-right px-2 self-stretch">
													(Optional)
													<br />
												</div>
											</div>
										</div>
										<div className="self-stretch flex flex-col items-center  border border-solid border-gray-200 w-full  md:w-3/4">
											<div className="border-gray-300 font-bold p-3  flex flex-col items-center self-stretch ">
												<div className="flex flex-row items-center flex-grow self-stretch py-1 pr-2">
													<div className=" ml-2 flex-grow flex-col ">
														<Field
															component="textarea"
															name="description"
															placeholder=""
															autoComplete="off"
															className={`h-20 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-900 font-normal leading-tight focus:outline-none focus:bg-white focus:border-purple-500
${touched.description && errors.description ? "text-purple-500 border-purple-500" : ""}`}
														/>
														<ErrorMessage component="div" name="description" className="text-purple-600 font-Inter font-medium text-xs tracking-wide ml-1" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</Fragment>

								{/* BUTTONS SECTION */}
								<div className="px-4 pb-4 pt-2 w-full flex flex-row justify-between content-center bg-gray-100 ">
									<span className="sm:block rounded-md mr-4 mt-2" onClick={handleCloseModal}>
										<button type="button" className="font-inter  shadow-sm inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-200 focus:outline-none focus:shadow-outline focus:border-indigo-400 transition duration-150 ease-in-out">
											Cancel
										</button>
									</span>
									<div className="">
										<button
											type="button"
											onClick={handleBack}
											//  (!isValid || dirty || isSubmitting)
											className={`font-inter  shadow-sm inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-200 focus:outline-none focus:shadow-outline focus:border-indigo-400 transition duration-150 ease-in-out mr-3 `}
										>
											Back
										</button>

										<button
											type="submit"
											disabled={!isValid || isSubmitting}
											//  (!isValid || dirty || isSubmitting)
											className={`mr-4 mt-2 font-Inter shadow-sm inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md  hover:bg-blue-500 focus:outline-none focus:shadow-outline focus:border-indigo-400 transition duration-150 ease-in-out ${!isValid || isSubmitting ? "opacity-75 pointer-events-none bg-indigo-500 text-gray-100" : "bg-indigo-500 text-gray-100"}`}
										>
											{isSubmitting && (
												<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
													<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
												</svg>
											)}{" "}
											Add
										</button>
									</div>

									{/* {isValid ? 'isvalid' : 'not isvalid'} / {dirty ? 'is dirty' : ' is not dirty'} / {touched.length ? 'is touched' : ' is not touched'} */}
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Fragment>
		);
	}
}
