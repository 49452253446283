import React, { Component, Fragment } from "react";
import PortalContent4 from "../PortalContent4";
import PortalHeaderNextGen from "../PortalHeaderNextGen";
import PortalTopHeader from './../layout/content/PortalTopHeader';


export default class Layout extends Component {
	render() {
		return (
			<Fragment>
				<div className=" flex flex-col self-stretch flex-grow min-h-screen ">
					<PortalTopHeader />

					<div className=" flex flex-row self-stretch flex-grow ">

						{/* items-start is needed for the children to be sticky */}
						<div className="mx-auto px-4 sm:px-6 container flex flex-row self-stretch flex-grow items-start ">

							{/* flex-shrink-0 flex-grow-0  => same as flex-none */}
							<div className="sticky top-10  w-1/5 flex flex-col items-center flex-none  border-2 border-gray-300">
								{[...Array(20).keys()].map((item, key) => (
									<div key={item}>{key}</div>
								))}
							</div>

							<div className="flex flex-col items-center self-stretch flex-grow  border-2 border-gray-300 space-y-2 p-4">

									{[...Array(100).keys()].map((item, key) => (
										<div className="p-20 bg-white self-stretch " key={item}>{key}</div>
									))}

							</div>

						</div>
					</div>

				</div>
			</Fragment>
		);
	}
}
