import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
// import Xarrow from "react-xarrows";
import Draggable, { DraggableCore } from "react-draggable";

import Arrow from "./dev/Xarrow.js";
import Box from "./dev/Box";



// https://codesandbox.io/embed/github/Eliav2/react-xarrows/tree/master/examples?fontsize=14&hidenavigation=1&theme=dark

const lines = [
	{
		props: {
			start: "b1",
			end: "b2",
			headSize: 4,
			strokeWidth: 2,
			dashness: { strokeLen: 5, nonStrokeLen: 4, animation: 1.5 }
			//   label: { end: "endLable" },
		},
	},
	{
		props: {
			start: "b2",
			end: "b3",
			headSize: 4,
			strokeWidth: 2,
			// label: { end: "endLable" },
		},
	},
];

const shapes = ["wideBox", "tallBox", "intefaceBox"];

class PortalContent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selected: null,
			lines: lines,
			boxes: [],
			interfaces: [],
			shapes: shapes,
			actionState: "Normal",
		};
	}

	handleStart = () => {
		this.setState({});
	};

	handleStart = () => {
		this.setState({});
	};
	handleDrag = () => {
		this.setState({});
	};
	handleStop = () => {
		this.setState({});
	};

	setSelected = (value) => {
		this.setState((prevState, props) => ({
			...prevState,
			selected: value,
		}));
	};

	checkExsitence = (id) => {
		const { boxes, interfaces } = this.state;

		return [...boxes, ...interfaces].map((b) => b.id).includes(id);
	};

	setBoxes = () => {
		const { boxes } = this.state;
		this.setState((prevState, props) => ({
			...prevState,
			boxes: [...boxes],
		}));
	};

	handleDropDynamic = (e) => {
		console.log("Drag Over ", e);
		const { boxes } = this.state;

		let shape = e.dataTransfer.getData("shape");
		if (shapes.includes(shape)) {
			let l = boxes.length;
			while (this.checkExsitence("box" + l)) l++;
			let { x, y } = e.target.getBoundingClientRect();
			var newName = prompt("Enter box name: ", "box" + l);
			if (newName) {
				let newBox = { id: newName, x: e.clientX - x, y: e.clientY - y, shape };
				// setBoxes([...boxes, newBox]);
				// this.setBoxes(newBox); // [BV]
				this.setState((prevState, props) => ({
					...prevState,
					boxes: [...boxes, newBox],
				}));
			}
		}
	};

	handleSelect = (e) => {
		if (e === null) {
			this.setSelected(null);
			this.setActionState("Normal");
		} else this.setSelected({ id: e.target.id, type: "box" });
	};

	render() {
		const { selected, lines, boxes, shapes, actionState } = this.state;

		return (
			<Fragment>
				<section>
					<div className="relative self-stretch h-full debug-border " style={{ backgroundColor: "#353f59" }}>
						{/* <Xarrow start="b1" end="b2" color="#fff" headSize="4" strokeWidth="2" />
                                <Xarrow start="b2" end="b3" color="#fff" headSize="4" strokeWidth="2" /> */}

						<div className="flex flex-row items-center self-stretch debug-border" style={{ height: "30rem" }}>
							<div className="w-40 self-stretch debug-border">
								Toolbar
								{shapes.map((shapeName) => (
									<div key={shapeName} className="text-white border border-1 border-white p-2 m-2 " onDragStart={(e) => e.dataTransfer.setData("shape", shapeName)} draggable>
										{shapeName}
										{/* <div style={{ textAlign: "center" }}> {shapeName}</div>
                                        <img src={shapeName2Icon[shapeName]} alt="SwitchIcon" className={"switchIcon"} /> */}
									</div>
								))}
							</div>
							<div id="boxesContainer" className="relative flex-grow self-stretch  border-bluegray-400 border" onDragOver={(e) => e.preventDefault()} onDrop={this.handleDropDynamic}>
								{/* <TopBar {...props} /> */}

								{boxes.map((box) => (
									<Box boxes={boxes} setBoxes={this.setBoxes} selected={selected} handleSelect={this.handleSelect} actionState={actionState} setLines={this.setLines} lines={lines} key={box.id} box={box} position="absolute" sidePos="middle" />
								))}
							</div>

							{/* xarrow connections*/}
							{lines.map((line, i) => (
								<Arrow key={line.props.start + "-" + line.props.end + i} line={line} selected={selected} setSelected={this.setSelected} />
							))}
						</div>


						<div className="grid grid-cols-3 gap-2 w-36 mt-10">
  <div className="rounded-lg bg-white opacity-40 h-1 "></div>
  <div className="rounded-lg bg-white opacity-40 h-1 "></div>
  <div className="rounded-lg bg-white opacity-40 h-1 "></div>
  <div className="col-span-2 rounded-lg bg-white opacity-40 h-1"></div>
  <div className="rounded-lg bg-white opacity-40 h-1 "></div>
  <div className="rounded-lg bg-white opacity-40 h-1 "></div>
  <div className="col-span-2  bg-white opacity-40 h-1 "></div>
</div>




						<Draggable
							// axis="x"
							handle=".handle"
							defaultPosition={{ x: 0, y: 0 }}
							position={null}
							// grid={[25, 25]}
							scale={1}
							onStart={this.handleStart}
							onDrag={this.handleDrag}
							onStop={this.handleStop}
						>
							<div id="b1" className="handle ml-28 mt-32  w-36 relative cursor-pointer  shadow-md  border border-indigo-500 flex-row flex">
								{/* <span className="font-Inter  text-xs font-base text-gray-300 uppercase ">Function</span> */}
								<div className="w-9  bg-indigo-500 self-stretch flex flex-row items-center justify-center">
									<svg className="h-5 w-5 fill-current text-gray-200 " viewBox="0 0 512 512">
										<path d="M447.716 97.794L297.207 10.993c-25.416-14.658-56.997-14.657-82.414 0L64.285 97.794C38.819 112.479 23 139.866 23 169.266v173.467c0 29.4 15.819 56.787 41.284 71.472l150.509 86.801c25.421 14.661 57.001 14.656 82.414 0l150.508-86.801C473.181 399.52 489 372.133 489 342.733V169.266c0-29.399-15.819-56.786-41.284-71.472zM449 342.733c0 15.144-8.148 29.251-21.266 36.815l-150.509 86.801c-13.093 7.552-29.358 7.552-42.451 0L84.265 379.548C71.148 371.983 63 357.877 63 342.733V169.266c0-15.143 8.148-29.25 21.266-36.814l150.508-86.801c13.094-7.552 29.364-7.549 42.452 0l150.509 86.8C440.852 140.016 449 154.122 449 169.266v173.467z" />
										<path d="M236.994 240.729l-74.281-62.863c-8.431-7.136-21.052-6.085-28.187 2.349-7.135 8.434-6.083 21.055 2.349 28.191L193.113 256l-56.238 47.593c-8.432 7.136-9.483 19.757-2.349 28.191 7.152 8.452 19.776 9.467 28.187 2.348l74.281-62.863c9.45-7.997 9.423-22.565 0-30.54zM362.206 298.859h-89.995c-11.046 0-20 8.955-20 20.003s8.954 20.003 20 20.003h89.995c11.045 0 20-8.955 20-20.003s-8.954-20.003-20-20.003z" />
									</svg>
								</div>
								<span className="ml-1  p-2  font-Open  text-smaller font-semibold text-bluegray-300 leading-tight">DoSomething</span>
							</div>
						</Draggable>

						<Draggable
							// axis="x"
							handle=".handle"
							defaultPosition={{ x: 0, y: 0 }}
							position={null}
							// grid={[25, 25]}
							scale={1}
							onStart={this.handleStart}
							onDrag={this.handleDrag}
							onStop={this.handleStop}
						>
							<div id="b2" className="handle ml-64 mt-32  w-36 relative cursor-pointer  shadow-md  border border-indigo-500 flex-row flex">
								{/* <span className="font-Inter  text-xs font-base text-gray-300 uppercase ">Function</span> */}
								<div className="w-9  bg-indigo-500 self-stretch flex flex-row items-center justify-center">
									<svg className="h-5 w-5 fill-current text-gray-200 " viewBox="0 0 512 512">
										<path d="M447.716 97.794L297.207 10.993c-25.416-14.658-56.997-14.657-82.414 0L64.285 97.794C38.819 112.479 23 139.866 23 169.266v173.467c0 29.4 15.819 56.787 41.284 71.472l150.509 86.801c25.421 14.661 57.001 14.656 82.414 0l150.508-86.801C473.181 399.52 489 372.133 489 342.733V169.266c0-29.399-15.819-56.786-41.284-71.472zM449 342.733c0 15.144-8.148 29.251-21.266 36.815l-150.509 86.801c-13.093 7.552-29.358 7.552-42.451 0L84.265 379.548C71.148 371.983 63 357.877 63 342.733V169.266c0-15.143 8.148-29.25 21.266-36.814l150.508-86.801c13.094-7.552 29.364-7.549 42.452 0l150.509 86.8C440.852 140.016 449 154.122 449 169.266v173.467z" />
										<path d="M236.994 240.729l-74.281-62.863c-8.431-7.136-21.052-6.085-28.187 2.349-7.135 8.434-6.083 21.055 2.349 28.191L193.113 256l-56.238 47.593c-8.432 7.136-9.483 19.757-2.349 28.191 7.152 8.452 19.776 9.467 28.187 2.348l74.281-62.863c9.45-7.997 9.423-22.565 0-30.54zM362.206 298.859h-89.995c-11.046 0-20 8.955-20 20.003s8.954 20.003 20 20.003h89.995c11.045 0 20-8.955 20-20.003s-8.954-20.003-20-20.003z" />
									</svg>
								</div>
								<span className="ml-1  p-2  font-Open  text-smaller font-semibold text-bluegray-300 leading-tight">DoSomething</span>
							</div>
						</Draggable>

						<Draggable
							// axis="x"
							handle=".handle"
							defaultPosition={{ x: 0, y: 0 }}
							position={null}
							// grid={[25, 25]}
							scale={1}
							onStart={this.handleStart}
							onDrag={this.handleDrag}
							onStop={this.handleStop}
						>
							<div id="b3" className="handle w-36 relative cursor-pointer  shadow-md  border border-indigo-500 flex-row flex">
								{/* <span className="font-Inter  text-xs font-base text-gray-300 uppercase ">Function</span> */}
								<div className="w-9  bg-indigo-500 self-stretch flex flex-row items-center justify-center">
									<svg className="h-5 w-5 fill-current text-gray-200 " viewBox="0 0 512 512">
										<path d="M447.716 97.794L297.207 10.993c-25.416-14.658-56.997-14.657-82.414 0L64.285 97.794C38.819 112.479 23 139.866 23 169.266v173.467c0 29.4 15.819 56.787 41.284 71.472l150.509 86.801c25.421 14.661 57.001 14.656 82.414 0l150.508-86.801C473.181 399.52 489 372.133 489 342.733V169.266c0-29.399-15.819-56.786-41.284-71.472zM449 342.733c0 15.144-8.148 29.251-21.266 36.815l-150.509 86.801c-13.093 7.552-29.358 7.552-42.451 0L84.265 379.548C71.148 371.983 63 357.877 63 342.733V169.266c0-15.143 8.148-29.25 21.266-36.814l150.508-86.801c13.094-7.552 29.364-7.549 42.452 0l150.509 86.8C440.852 140.016 449 154.122 449 169.266v173.467z" />
										<path d="M236.994 240.729l-74.281-62.863c-8.431-7.136-21.052-6.085-28.187 2.349-7.135 8.434-6.083 21.055 2.349 28.191L193.113 256l-56.238 47.593c-8.432 7.136-9.483 19.757-2.349 28.191 7.152 8.452 19.776 9.467 28.187 2.348l74.281-62.863c9.45-7.997 9.423-22.565 0-30.54zM362.206 298.859h-89.995c-11.046 0-20 8.955-20 20.003s8.954 20.003 20 20.003h89.995c11.045 0 20-8.955 20-20.003s-8.954-20.003-20-20.003z" />
									</svg>
								</div>
								<span className="ml-1  p-2  font-Open  text-smaller font-semibold text-bluegray-300 leading-tight">DoSomething</span>
							</div>
						</Draggable>

						{/* {lines.map((line, i) => (
                                    <Xarrow key={i} {...line} />
                                ))} */}





						{lines.map((line, i) => (
							<Arrow key={line.props.start + "-" + line.props.end + i} line={line} selected={selected} setSelected={this.setSelected} />
						))}


						
					</div>
				</section>
			</Fragment>
		);
	}
}

export default withRouter(PortalContent);
