import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, NavLink, withRouter } from "react-router-dom";
import ReactModal from "react-modal";
import uuid from "react-uuid";
import { Auth } from "aws-amplify";
import axios from "axios";
import { AppConfig } from './../../../utilities/AppConfig';
import NewWalkthruComponent from "../walkthrus/NewWalkthruComponent";
import BlankWalkthru from "../walkthrus/BlankWalkthru";
import StudioComponentList from "./StudioComponentList";

class StudioComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isNewWalkthruModalOpen: false,
			newselection: '',
			searchText: "",
			wbolist: [],
			isWbolistLoading: false,
			isNewWalkthruSelectionVisible: false,
		};
	}
	componentDidMount() {
		this.initilizeData();
	}

	initilizeData = async (event) => {
		console.log("initilizeData");

		this.setState((prevState, props) => ({
			...prevState,
			isWbolistLoading: true,
		}));
		
		const { idToken: { jwtToken } } = await Auth.currentSession();

		axios({
			method: "get",
			baseURL: AppConfig.ApiWalkthruBaseUrl,
			url: "walkthru",
			params: {
				userscope: 'user',
			},
			headers: {
			    'authorization' : `${jwtToken}`,
			    'Content-Type':'application/json',
			    'Accept' : 'application/json'
			}
		})
			// .then(ResponseDelayer(1000))
			.then((response) => {
				console.log("success response.data", JSON.stringify(response.data, null, 2));

				if (response && response.data) {
					this.setState((prevState, props) => ({
						...prevState,
						wbolist: response.data,
					}));
				}
				this.setState((prevState, props) => ({
					...prevState,
					isWbolistLoading: false,
				}));
			})
			.catch((error) => {
				console.log("success response.data", JSON.stringify(error, null, 2));
				this.setState((prevState, props) => ({
					...prevState,
					isWbolistLoading: false,
				}));
			})
			.then(() => {
				// always executed
				console.log(">> Finally <<");
			});
	};


	newWalkthruSelectionToggle = () => {
		console.log("isNewWalkthruSelectionVisible", this.state.isNewWalkthruSelectionVisible);
		this.setState((prevState, props) => ({
		  ...prevState,
		  isNewWalkthruSelectionVisible:  !prevState.isNewWalkthruSelectionVisible,
		}));
	}
	// ----------------------------------------------------------------------------------
	// Import Modal
	// ----------------------------------------------------------------------------------
	createWalkthruHandler = () => {
		const {
			match: { path },
			location: { pathname },
			history,
		} = this.props;
		// console.log("btnHandlerImport", this.state.isNewWalkthruModalOpen);
		// this.setState((prevState, props) => ({
		//   ...prevState,
		//   isNewWalkthruModalOpen:  !prevState.isNewWalkthruModalOpen,
		// }));

		// history.push({
		// 	pathname: "/portal/walkthrus/new",
		// });
	};
	postNewWalkthruActions = () => {
		// console.log("postImportActions", this.state.isNewWalkthruModalOpen);
		// this.setState((prevState, props) => ({
		//   ...prevState,
		//   isNewWalkthruModalOpen:  !prevState.isNewWalkthruModalOpen,
		// }));
		// this.getUsersList();
	};
	// ----------------------------------------------------------------------------------
	// end of Import Modal
	// ----------------------------------------------------------------------------------

	handleNewSelection= (event) => {
		console.log("handleNewSelection");
		const { currentTarget : { dataset : { selection } = {}} = {}} = event;
		console.log("handleNewSelection event.currentTarget.dataset.selection ", selection);
		if (selection){
			this.setState((prevState, props) => ({
				...prevState,
				newselection: selection,
			}));
			// var payload = {  "walkthruid": id };
			// this.props.history.push({
			// 	pathname: "/portal/walkthrus/" + id,
			// 	state : JSON.stringify(payload),
			// 	//routerpayload : walkthru   //response.data // { routerdata : JSON.stringify(result.data, null, 2) }
			//   });
		}	

	}


	render() {
		const {
			match: { path },
			location: { pathname },
			history,
		} = this.props;
		const { isNewWalkthruSelectionVisible, newselection } = this.state;
		const { searchText, wbolist, isWbolistLoading } = this.state;
		return (
			<div className="flex flex-col items-center flex-grow self-stretch" style={{ backgroundColor: "#edf2f7" }}>
				<div className="flex flex-col items-center self-stretch container p-3">

					<div className="font-semibold text ml-8 mt-4 text-indigo-600 mr-4 cursor-pointer flex flex-row justify-between items-center self-end" onClick={this.newWalkthruSelectionToggle}>
						<svg className="h-6 w-6 p-1 fill-current text-Theme05-500" viewBox="0 0 448 448">
							<path d="M408 184H272c-4.417969 0-8-3.582031-8-8V40c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8H40c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40V272c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
						</svg>
						<span className="ml-1">Create Walkthru</span>
					</div>

					{ isNewWalkthruSelectionVisible
						?
						(
							
					<Fragment>
					<div className="flex flex-row items-center self-stretch  flex-wrap">

						<div className="flex flex-row items-center self-stretch  w-full p-3 md:w-1/2 xl:w-1/2 ">

							<div className={`group hover:text-indigo-400  hover:bg-bluegray-200 cursor-pointer flex flex-row items-center self-stretch justify-between  bg-white p-5 ${ (newselection=='blankcanvas') ? 'border-2  border-indigo-500 ' : 'border-2  border-bluegray-300' }  `} onClick={this.handleNewSelection} data-selection="blankcanvas">
								<div className="flex flex-col items-center self-stretch ">
									<div className="flex flex-row items-center self-stretch  flex-wrap font-Inter text-base font-semibold text-bluegray-600">New Walkthru</div>
									<div className="flex flex-row items-center self-stretch  flex-wrap font-Inter text-sm font-base text-bluegray-600">Author your walkthrough using a {" "} <span className="font-base whitespace-pre-wrap">{` blank`} </span> canvas. You can generate code snippets to easily build out your workflow steps.</div>
								</div>
								<div className="flex flex-col items-center self-stretch w-1/6">
									<div className="flex flex-row items-center self-stretch  flex-wrap font-Inter text-lg font-medium">
										<div className={`mt-3 w-4 h-4 bg-white  mx-auto rounded-full text-white flex items-center ${ (newselection=='blankcanvas') ? 'border-2 border-indigo-500 ' : 'border-2  border-gray-400' }  `}>
											{/* <span className="text-gray-700 text-center w-full font-inter font-semibold text-sm"></span> */}
										</div>
									</div>
								</div>
							</div>

						</div>
						<div className="flex flex-row items-center self-stretch  w-full p-3 md:w-1/2 xl:w-1/2 ">

							<div className={`group hover:text-indigo-400  hover:bg-bluegray-200 cursor-pointer  flex flex-row items-center self-stretch justify-between  bg-white p-5  ${ (newselection=='youtubechapter') ? 'border-2 border-indigo-500 ' : 'border-2  border-bluegray-300' }  `} onClick={this.handleNewSelection} data-selection="youtubechapter">
									<div className="flex flex-col items-center self-stretch ">
										<div className="flex flex-row items-center self-stretch  flex-wrap font-Inter text-base font-semibold text-bluegray-600 ">Import from Youtube ( Videos with Chapters ) </div>
										<div className="flex flex-row items-center self-stretch  flex-wrap font-Inter text-sm font-base text-bluegray-600 ">Youtube videos with chapters can be split automatically into a walkthru. Each chapter is translated into a seperate step, start-end times are set automatically.</div>
									</div>
									<div className="flex flex-col items-center self-stretch w-1/6">
										<div className="flex flex-row items-center self-stretch  flex-wrap font-Inter text-lg font-medium">
											<div className={`mt-3 w-4 h-4 bg-white border-2 border-gray-400 mx-auto rounded-full text-white flex items-center ${ (newselection=='youtubechapter') ? 'border-2 border-indigo-500 ' : 'border-2  border-gray-400' }  `}>
												<span className="text-gray-700 text-center w-full font-inter font-semibold text-sm"></span>
											</div>
										</div>
									</div>
							</div>

						</div>
					</div>
				</Fragment>

						)


						: <StudioComponentList></StudioComponentList>

					}

					<Fragment>
						{ (newselection=='blankcanvas') 
						? 
						<BlankWalkthru></BlankWalkthru>
						:
						(newselection=='youtubechapter') ?	<NewWalkthruComponent/> : ''
						
						}
					</Fragment>



				</div>



				{/* <Fragment>
					<span className="text-xs  font-Lato text-gray-500">
						{"       "} Your location is {pathname} , match is {path}, history is {JSON.stringify(history)}{" "}
					</span>
				</Fragment> */}
				{/* <ShowTheLocation/> */}
				{/*
				<button className="bg-indigo-500  hover:bg-indigo-400  text-white font-Inter font-base text-sm  py-1 px-3 rounded inline-flex items-center border border-gray-400 mx-2">
					<svg className="h-4 w-4 mr-2 fill-current text-white " viewBox="0 0 448 448" xmlns="http://www.w3.org/2000/svg">
						<path d="M408 184H272c-4.417969 0-8-3.582031-8-8V40c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8H40c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40V272c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
					</svg>
					<span>New</span>
				</button> */}
				<ReactModal
					isOpen={this.state.isNewWalkthruModalOpen}
					contentLabel="Modal"
					shouldCloseOnOverlayClick={true}
					shouldCloseOnEsc={true}
					onRequestClose={this.postNewWalkthruActions}
					ariaHideApp={false}
					// style={{width:"101px"}}
					overlayClassName="bg-gray-200 bg-opacity-60  fixed inset-0
					flex flex-col justify-center items-center z-20"
					className="container max-w-3xl min-h-1/4 bg-white rounded-lg shadow-lg 
								flex flex-col justify-center items-center "
					// onAfterOpen={afterOpenModal}
					// onRequestClose={closeModal}
					// style={customStyles}
					// contentLabel="Example Modal"
				>
					{/* <CreateUserForm postCreationActions={this.postUserCreationActions} ServerId={this.state.server && this.state.server.ServerId}/> */}
					<Fragment>
						<div className="bg-white self-stretch flex flex-col justify-start items-center">
							Modal	
							<button onClick={this.postNewWalkthruActions}>Close Modal</button>
	<NewWalkthruComponent/>
							{/* <div className="w-full flex-r mt-3  ">
								<div className="w-full flex-c flex-auto ">
									<div className="flex-r ml-8 pt-2 border-gray-300 font-bold text-base font-inter text-gray-500">New Walkthru </div>
								</div>
								<div className="w-full flex-c flex-auto "></div>
								<div className="w-full flex-c flex-auto "></div>
							</div> */}
						</div>
					</Fragment>
				</ReactModal>
			</div>
		);
	}
}

export default withRouter(StudioComponent);
