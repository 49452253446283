import React, { Component } from 'react'
import { Fragment } from 'react';
import ShowTheLocation from 'utilities/ShowTheLocation';
import LandingHeader from './sub/LandingHeader';
import LandingContent from './sub/LandingContent';
import LandingFooter from './sub/LandingFooter';
import LandingHero from './sub/LandingHero';
import Temp from './../portal/Temp';
import LandingTemp from './../portal/dev/LandingTemp';

export default class Landing extends Component {
    render() {
        return (
            <Fragment>
                {/* <LandingHeader/> */}
                {/* <LandingHero/> */}

                {/* <Temp/> */}

                <LandingTemp/>
                {/* <LandingContent/> */}
                <LandingFooter/>
                
            </Fragment>
        )
    }
}
