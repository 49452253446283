import React, { Component, Fragment } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, NavLink, withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";

const SignupValidationSchema = Yup.object().shape({
	username: Yup.string().min(3, "Username must be 3 characters at minimum!").max(30, "Username can not be longer than 24 characters!").required("Userame is required!"),
	firstname: Yup.string().min(3, "First Name must be 3 characters at minimum!").max(30, "First Name can not be longer than 30 characters!").required("First Name is required!"),
	lastname: Yup.string().min(3, "Last Name must be 2 characters at minimum!").max(30, "Last Name can not be longer than 30 characters!").required("Last Name is required!"),
	email: Yup.string().email("Please enter a valid email").max(60, "Email can not be longer than 30 characters!").required("Email is required!"),
	password: Yup.string().required("This field is required"),
	repeatpassword: Yup.string()
		.when("password", {
			is: (val) => (val && val.length > 0 ? true : false),
			then: Yup.string().oneOf([Yup.ref("password")], "Both passwords need to be the same"),
		})
		.required("Repeat password is required!"),
});

class Signup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Username: "",
			Firstname: "",
			Lastname: "",
			Email: "",
			Password: "",
			RepeatPassword: "",

			isSigningup: false,

			isErrored: "",
			errorMessage: "",
		};
	}

	btnHandlerSignup = () => {
		const { Username, Firstname, Lastname, Email, Password, isErrored, errorMessage } = this.state;

		this.setState((prevState, props) => ({
			...prevState,
			isSigningup: true,
			isErrored: false,
			errorMessage: "",
		}));

		try {
			Auth.signUp({
				username: Email,
				password: Password,
				attributes: {
					email: Email,
					given_name: Firstname,
					family_name: Lastname,
					preferred_username: Username,
				},
			})
				.then((result) => {
					this.setState((prevState, props) => ({
						...prevState,
						isSigningup: false,
						isErrored: false,
						errorMessage: "",
					}));

					this.props.history.push({
						pathname: "/signup/confirm",
						state: {
							username: Username,
							firstname: Firstname,
							lastname: Lastname,
							email: Email,
							password: Password,
						},
					});

					console.log("successful sign in", result);
				})

				.catch((err) => {
					this.setState((prevState, props) => ({
						...prevState,
						isSigningup: false,
						isErrored: true,
						errorMessage: "Error during signed up",
					}));
					console.log("err.message", err.message);
				});
		} catch (e) {
			alert(e.message);
		}
	};

	render() {
		const { isErrored, errorMessage, isSigningup } = this.state;

		return (
			<Fragment>
				<React.Fragment>
					<Formik
						initialValues={{
							username: "BurVur", //Firstname,
							firstname: "Bur", //Firstname,
							lastname: "Vur", // Lastname,
							email: "b3@buppel.com", //Email,
							password: "Password01.",
							repeatpassword: "Password01.",
						}}
						validationSchema={SignupValidationSchema}
						onSubmit={(values, { setSubmitting }) => {
							console.log("Formik submit");
							this.setState((prevState, props) => ({
								...prevState,
								Username: values.username,
								Firstname: values.firstname,
								Lastname: values.lastname,
								Email: values.email,
								Password: values.password,
								RepeatPassword: values.repeatpassword,
							}));

							this.btnHandlerSignup();
							// setSubmitting(false);
						}}
					>
						{({ touched, errors, isSubmitting, values }) => (
							<Form className="self-stretch flex flex-col justify-center items-center  min-h-screen ">
								{/* SIGNUP PANEL */}
								<div className="relative flex flex-col justify-start items-center bg-white shadow-md rounded w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/3  border-solid border-t-4 border-purple-500">
									<div className="absolute w-full h-12  border-b border-gray-400 bg-gray-100 opacity-50"></div>
									<div className="px-6 pb-6 h-full w-full z-10">
										<div className="mb-12 flex flex-row justify-between items-center h-12  ">
											{/* <span className="brandlettersmall mb-2 ">D</span> */}

											<div className="font-semibold text-gray-700 text-lg ml-2 font-Inter  ">Sign up</div>
											{/* 
                                            <svg className="fill-current w-7 h-7 text-white bg-purple-600  rounded-full " style={{ padding: "0.2rem" }} viewBox="0 0 393 424" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M296.722 55.9049H282.588C263.831 55.8972 245.619 62.2129 230.894 73.8319C223.678 79.5197 217.449 86.3589 212.459 94.0739C207.336 102 203.596 110.738 201.397 119.916L159.18 295.821C157.863 301.326 155.621 306.568 152.548 311.322C148.043 318.281 141.886 324.016 134.627 328.018C127.228 332.099 118.914 334.236 110.464 334.227H96.3297C87.1077 334.227 79.6307 341.704 79.6307 350.926C79.6307 360.148 87.1077 367.625 96.3297 367.625H110.464C129.221 367.633 147.433 361.317 162.158 349.698C169.374 344.01 175.603 337.171 180.593 329.456C185.715 321.53 189.456 312.792 191.655 303.614L233.872 127.709C235.188 122.204 237.431 116.962 240.504 112.208C245.009 105.249 251.165 99.5135 258.425 95.5119C265.823 91.4297 274.138 89.2933 282.588 89.3029H296.722C305.944 89.3029 313.421 81.8259 313.421 72.6039C313.421 63.3819 305.945 55.9049 296.722 55.9049Z"
													fill="white"
												/>
											</svg> */}
										</div>

										{/* <div className="mb-4">
                          <label className="block mb-1 font-raleway text-gray-800 font-semibold text-sm">
                            First Name
                          </label>
                          <Field
                            name="firstname"
                            placeholder=""
                            autoComplete="off"
                            className={` appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                                                            ${
                                                              touched.firstname &&
                                                              errors.firstname
                                                                ? "text-indigo-700"
                                                                : ""
                                                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="firstname"
                            className=" text-purple-600 font-Inter font-medium text-xs tracking-wide ml-1"
                          />
                        </div> */}

										{/* <div className="mb-4">
                          <label className="block mb-1 font-raleway text-gray-800 font-semibold text-sm">
                            Last Name
                          </label>
                          <Field
                            name="lastname"
                            placeholder=""
                            autoComplete="off"
                            className={` appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                                                            ${
                                                              touched.lastname &&
                                                              errors.lastname
                                                                ? "text-indigo-700"
                                                                : ""
                                                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="lastname"
                            className=" text-purple-600 font-Inter font-medium text-xs tracking-wide ml-1"
                          />
                        </div> */}

										<div className="flex flex-row justify-between align-center ">
											<div className="mb-6 ml-2 flex-grow flex-col ">
												<label className="block mb-1 font-Inter text-gray-800  font-semibold text-smaller">Username*</label>

												<Field
													name="username"
													placeholder=""
													autoComplete="none"
													className={` appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                              ${touched.username && errors.username ? "text-purple-500 border-purple-500" : ""}`}
												/>
												<ErrorMessage component="div" name="username" className="text-purple-600 font-Inter font-medium text-xs tracking-wide ml-1" />
											</div>
										</div>

										<div className="flex flex-row justify-between align-center ">
											<div className="mb-6 ml-2  flex-1">
												<label className="block mb-1 font-Inter text-gray-800 font-semibold text-smaller">First Name*</label>
												<Field
													name="firstname"
													placeholder=""
													autoComplete="none"
													className={` appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                              ${touched.firstname && errors.firstname ? "text-purple-500 border-purple-500" : ""}`}
												/>
												<ErrorMessage component="div" name="firstname" className=" text-purple-600 font-Inter font-medium text-xs tracking-wide ml-1" />
											</div>

											<div className="mb-6 ml-2  flex-1">
												<label className="block mb-1 font-Inter text-gray-800  font-semibold text-smaller">Last Name*</label>
												<Field
													name="lastname"
													placeholder=""
													autoComplete="none"
													className={` appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                              ${touched.lastname && errors.lastname ? "text-purple-500 border-purple-500" : ""}`}
												/>
												<ErrorMessage component="div" name="lastname" className="text-purple-600 font-Inter font-medium text-xs tracking-wide ml-1" />
											</div>
										</div>

										{/* <div className="flex flex-row justify-between align-center ">
											<div className="mb-8 ml-2 flex-grow ">
												<label className="block mb-1 font-raleway text-gray-800 font-semibold text-sm">Organisation*</label>
												<Field
													name="organisation"
													placeholder=""
													autoComplete="none"
													className={` appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                              ${touched.organisation && errors.organisation ? " text-purple-500 border-purple-500" : ""}`}
												/>
												<ErrorMessage component="div" name="organisation" className=" text-purple-600 font-Inter font-medium text-xs tracking-wide ml-1" />
											</div>
										</div> */}

										<div className="flex flex-row justify-between align-center ">
											<div className="mb-6 ml-2 flex-grow flex-col ">
												<label className="block mb-1 font-Inter text-gray-800  font-semibold text-smaller">Email*</label>

												<Field
													name="email"
													placeholder=""
													autoComplete="new-password"
													className={` appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                              ${touched.email && errors.email ? "text-purple-500 border-purple-500" : ""}`}
												/>
												<ErrorMessage component="div" name="email" className="text-purple-600 font-Inter font-medium text-xs tracking-wide ml-1" />
												<div className=" mr-0 mt-1 text-right font-Inter text-gray-400  font-medium text-xs">A confirmation code will be send to your email address.</div>
											</div>
										</div>

										<div className="flex flex-row justify-between align-center mb-4 ml-1">
											<div className="mb-4 mr-2 flex-grow ">
												<label className="block mb-1 font-Inter text-gray-800  font-semibold text-smaller">Password</label>
												<Field
													name="password"
													placeholder=""
													autoComplete="none"
													type="password"
													className={` appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                                                                ${touched.password && errors.password ? "text-purple-500 border-purple-500" : ""}`}
												/>
												<ErrorMessage component="div" name="password" className=" text-purple-600 font-Inter font-medium text-xs tracking-wide ml-1" />
											</div>

											<div className="mb-4  flex-grow ">
												<label className="block mb-1 font-Inter text-gray-800  font-semibold text-smaller">Repeat Password</label>
												<Field
													name="repeatpassword"
													placeholder=""
													autoComplete="none"
													type="password"
													className={` appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                                                                ${touched.repeatpassword && errors.repeatpassword ? "text-purple-500 border-purple-500" : ""}`}
												/>
												<ErrorMessage component="div" name="repeatpassword" className=" text-purple-600 font-Inter font-medium text-xs tracking-wide ml-1" />
											</div>
										</div>

										{isErrored ? <div className="flex flex-row items-center justify-center mb-4 font-bold text-Theme04-500">{errorMessage}</div> : <br />}

										<div className="flex  items-center justify-between ">
											<NavLink to={`/`} className="">
												<button className="bg-transparent border text-indigo-700 ml-2 text-Theme05-500 py-2 px-2 rounded font-bold font-Inter text-sm" onClick={this.btnHandlerCancel} type="button">
													Cancel
												</button>
											</NavLink>

											<button
												className="ml-2 bg-indigo-500  text-white py-2 px-4 rounded font-medium font-Inter  text-sm"
												// onClick={this.btnHandlerSignup}
												type="submit"
											>
												{isSigningup ? (
													<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
														<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
														<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
													</svg>
												) : (
													""
												)}
												Sign Up
											</button>
										</div>
									</div>
								</div>

								<div className="flex flex-row justify-between items-center w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/3  ">
									<NavLink to={`/forgot`} className="no-underline font-bold text-sm text-gray-500 hover:text-gray-700   mt-4 ml-2 font-raleway">
										Forgot Password?
									</NavLink>

									<NavLink to={`/login`} className="no-underline  font-bold text-sm  text-gray-500 hover:text-gray-700   mt-4 mr-2 font-raleway">
										Already Signed Up?
									</NavLink>
								</div>

								{/* <div className="mt-8 mb-2 p-2 border border-solid border-gray-400">
                                            <strong>Injected Formik props (the form's state)</strong>
                                            <div>
                                            <code>errors:</code> {JSON.stringify(errors, null, 2)}
                                            </div>
                                            <div>
                                            <code>values:</code> {JSON.stringify(values, null, 2)}
                                            </div>
                                            <div>
                                            <code>isSubmitting:</code>{" "}
                                            {JSON.stringify(isSubmitting, null, 2)}
                                            </div>
                                    </div> */}
							</Form>
						)}
					</Formik>
				</React.Fragment>
			</Fragment>
		);
	}
}

export default withRouter(Signup);
