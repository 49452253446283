import React, { Component, Fragment } from 'react'

export default class Temp extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             showMenu : false
        }
    }

    toggleMenu = () => {
        const {showMenu} = this.state;
        console.log('showMenu ', showMenu)
        this.setState((prevState, props) => ({
            ...prevState,
            showMenu : !prevState.showMenu,
        }));
    }


    





    render() {
        const {showMenu} = this.state;
        return (
            <Fragment>
                
<section className="w-full px-6 mb-12 antialiased bg-white  ">
    <div className="mx-auto max-w-7xl">
        <nav className="relative z-50 h-24" >
            <div className="container relative flex flex-wrap items-center justify-between h-24 mx-auto font-medium border-b border-gray-200 lg:justify-center sm:px-4 md:px-2">
                <a href="#_" className="w-1/4 py-4 pr-4 md:py-0">
                    <span className="text-xl font-black leading-none text-gray-900   logo">tails<span className="text-indigo-600">.</span></span>
                </a>
                <div className={`top-0 left-0 items-start hidden w-full h-full p-4 text-sm bg-gray-900 bg-opacity-50 md:w-3/4 md:absolute lg:text-base md:h-auto md:bg-transparent md:p-0 md:relative md:flex  ${showMenu ? "flex fixed" : "hidden" } `}>
                    <div className="flex-col w-full h-auto overflow-hidden bg-white rounded-lg   md:bg-transparent md:rounded-none md:relative md:flex md:flex-row md:overflow-auto">
                        <a href="#_" className="inline-flex items-center block w-auto h-16 px-6 text-xl font-black leading-none text-gray-900   md:hidden">tails<span className="text-indigo-600">.</span></a>
                        <div className="flex flex-col items-start justify-center w-full text-center md:w-2/3 md:mt-0 md:flex-row md:items-center">
                            <a href="#" className="inline-block w-full px-6 py-2 mx-0 font-medium text-left text-indigo-600 md:w-auto md:px-0 md:mx-2 lg:mx-3 md:text-center">Home</a>
                            <a href="#" className="inline-block w-full px-6 py-2 mx-0 font-medium text-left text-gray-700 md:w-auto md:px-0 md:mx-2 hover:text-indigo-600 lg:mx-3 md:text-center">Features</a>
                            <a href="#" className="inline-block w-full px-6 py-2 mx-0 font-medium text-left text-gray-700 md:w-auto md:px-0 md:mx-2 hover:text-indigo-600 lg:mx-3 md:text-center">Blog</a>
                            <a href="#" className="inline-block w-full px-6 py-2 mx-0 font-medium text-left text-gray-700 md:w-auto md:px-0 md:mx-2 hover:text-indigo-600 lg:mx-3 md:text-center">Contact</a>
                            <a href="#" className="absolute top-0 left-0 hidden py-2 mt-6 ml-10 mr-2 text-gray-600 lg:inline-block md:mt-0 md:ml-2 lg:mx-3 md:relative">
                                <svg className="inline w-5 h-5" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                </svg>
                            </a>
                        </div>
                        <div className="flex flex-col items-start justify-end w-full pt-4 md:items-center md:w-1/3 md:flex-row md:py-0">
                            <a href="#" className="w-full px-6 py-2 mr-0 text-gray-700 md:px-0 lg:pl-2 md:mr-4 lg:mr-5 md:w-auto">Sign In</a>
                            <span className="inline-flex w-full shadow-sm md:rounded-full md:w-auto">
                                <button className="inline-flex items-center w-full px-6 py-3 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out bg-transparent bg-indigo-600 border border-transparent md:px-3 md:w-auto md:rounded-full lg:px-5 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700">Sign Up</button>
                            </span>
                        </div>
                    </div>
                </div>
                <div onClick={this.toggleMenu} className="absolute right-0 flex flex-col items-center items-end justify-center w-10 h-10 rounded-full cursor-pointer md:hidden hover:bg-gray-100">
                    <svg className="w-6 h-6 text-gray-700" x-show="!showMenu" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor" x-cloak>
                        <path d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                    <svg className="w-6 h-6 text-gray-700" x-show="showMenu" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" x-cloak>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </div>
            </div>
        </nav>
        <div className="container max-w-lg px-4 py-32 mx-auto text-left md:max-w-none md:text-center">

            <h1 className="text-5xl font-extrabold tracking-tight text-left text-gray-900 leading-tightest md:leading-10 md:text-center sm:leading-none md:text-6xl lg:text-7xl"><span className="inline md:block">Start Crafting Your</span> <span className="relative mt-2 text-transparent md:inline-block bg-clip-text bg-gradient-to-br from-purple-600 to-blue-500">Next Great Idea</span></h1>
            <div className="mx-auto mt-5 text-gray-500 md:mt-12 md:max-w-lg md:text-center lg:text-lg">Simplifying the creation of landing pages, blog pages, application pages and so much more!</div>
            <div className="flex flex-col items-center mt-12 text-center">
                <span className="relative inline-flex w-full rounded-full shadow-sm md:w-auto">
                    <button type="button" className="inline-flex items-center justify-center w-full px-8 py-4 text-base font-bold leading-6 text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-full md:w-auto hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700">
                        Purchase Now
                    </button>
                    <span className="absolute top-0 right-0 px-2 py-1 -mt-3 -mr-6 text-xs font-medium leading-tight text-white bg-green-400 rounded-full">only $15/mo</span>
                </span>
                <a href="#" className="mt-3 text-sm text-teal-500">Learn More</a>
            </div>
        </div>
    </div>
</section>


<header className="w-full px-3 antialiased bg-indigo-600    lg:px-6">
    <div className="mx-auto max-w-7xl">
        <nav className="flex items-center w-full h-24" x-data="{ showMenu: false }">
            <div className="relative flex flex-wrap items-center justify-between w-full h-24 mx-auto font-medium md:justify-center">
                <a href="#_" className="w-1/4 py-4 pl-6 pr-4 md:pl-4 md:py-0">
                    <span className="text-xl font-black leading-none text-white    logo">tails<span className="text-pink-500">.</span></span>
                </a>
                <div className={`top-0 left-0 items-start hidden w-full h-full p-4 text-sm bg-gray-900 bg-opacity-50 md:w-3/4 md:absolute lg:text-base md:h-auto md:bg-transparent md:p-0 md:relative md:flex  ${showMenu ? "flex fixed" : "hidden" } `}>
                    <div className="flex-col w-full h-auto h-full overflow-hidden bg-white rounded-lg    md:bg-transparent md:rounded-none md:relative md:flex md:flex-row md:overflow-auto">
                        <div className="flex flex-col items-center justify-center w-full h-full mt-12 text-center text-indigo-700 md:text-indigo-200 md:w-2/3 md:mt-0 md:flex-row md:items-center">
                            <a href="#" className="inline-block px-4 py-2 mx-2 font-medium text-left text-indigo-700 md:text-white md:px-0 lg:mx-3 md:text-center">Home</a>
                            <a href="#" className="inline-block px-0 px-4 py-2 mx-2 font-medium text-left md:px-0 hover:text-indigo-800 md:hover:text-white lg:mx-3 md:text-center">Features</a>
                            <a href="#" className="inline-block px-0 px-4 py-2 mx-2 font-medium text-left md:px-0 hover:text-indigo-800 md:hover:text-white lg:mx-3 md:text-center">Blog</a>
                            <a href="#" className="inline-block px-0 px-4 py-2 mx-2 font-medium text-left md:px-0 hover:text-indigo-800 md:hover:text-white lg:mx-3 md:text-center">Contact</a>
                        </div>
                        <div className="flex flex-col items-center justify-end w-full h-full pt-4 md:w-1/3 md:flex-row md:py-0">
                            <a href="#_" className="w-full pl-6 mr-0 text-indigo-200 hover:text-white md:pl-0 md:mr-3 lg:mr-5 md:w-auto">Sign In</a>
                            <a href="#_" className="inline-flex items-center justify-center px-4 py-2 mr-1 text-base font-medium leading-6 text-indigo-600 whitespace-no-wrap transition duration-150 ease-in-out bg-indigo-100 border border-transparent rounded-full hover:bg-white focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700">Sign Up</a>
                        </div>
                    </div>
                </div>
                <div className={`absolute right-0 z-50 flex flex-col items-end w-10 h-10 p-2 mr-4 rounded-full cursor-pointer md:hidden  ${showMenu ? "text-indigo-700 hover:bg-gray-200" : "hover:bg-indigo-700 text-indigo-200" }   `}>
                    <svg className="w-6 h-6" x-show="!showMenu" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor" x-cloak>
                        <path d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                    <svg className="w-6 h-6" x-show="showMenu" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" x-cloak>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </div>
            </div>
        </nav>
        <div className="container py-32 mx-auto text-center sm:px-4">

            <h1 className="text-4xl font-extrabold leading-10 tracking-tight text-white sm:text-5xl sm:leading-none md:text-6xl xl:text-7xl"><span className="block">Simplify the way you</span> <span className="relative inline-block mt-3 text-transparent text-white">design websites</span></h1>
            <div className="max-w-lg mx-auto mt-6 text-sm text-center text-indigo-200 md:mt-12 sm:text-base md:max-w-xl md:text-lg xl:text-xl">If you are ready to change the way you design websites, then <br/>use our block builder to make it fun and easy!</div>
            <div className="relative flex items-center max-w-md mx-auto mt-12 text-center">
                <input type="text" name="email" placeholder="Email Address" className="w-full h-12 px-6 py-2 font-medium text-indigo-800 rounded-l-full focus:outline-none" />
                <span className="relative top-0 right-0 block rounded-full shadow-sm">
                    <button type="button" className="inline-flex items-center w-32 h-12 px-8 text-base font-bold leading-6 text-white transition duration-150 ease-in-out bg-indigo-400 border border-transparent rounded-r-full hover:bg-indigo-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700">
                        Sign Up
                    </button>
                </span>
            </div>
            <div className="mt-8 text-sm text-indigo-300">By signing up, you agree to our terms and services.</div>
        </div>
    </div>
</header>



<div className="relative flex flex-col items-center justify-center w-full h-auto space-y-6 overflow-hidden text-left text-white rounded-lg p-9 bg-gradient-to-br from-blue-500 via-indigo-500 to-purple-600">
                    <p className="relative z-10 w-full text-lg font-black leading-5 text-left text-white">New Blocks Released Every Week ✌️</p>
                    <p className="relative z-10 text-sm text-indigo-200">Each week we will be releasing new templates. Want to get notified?</p>
                    <div className="relative z-10 flex w-full space-x-4">
                        <div onclick="document.getElementById('modals').__x.$data.learnMoreModal = true" className="w-full py-3 text-base font-medium text-center text-indigo-700 bg-indigo-200 rounded-lg shadow-sm cursor-pointer opacity-90 hover:bg-white">Learn More</div>
                        <div onclick="document.getElementById('modals').__x.$data.notificationModal = true" className="flex items-center justify-center w-full py-3 text-base font-medium text-center text-blue-100 border border-blue-500 rounded-lg shadow-sm cursor-pointer hover:text-white bg-gradient-to-br from-purple-500 via-indigo-500 to-blue-500">
                            <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"></path></svg>
                            Get Notified</div>
                    </div>
                    <div className="absolute top-0 z-0 transform -translate-x-5 -translate-y-20 bg-indigo-700 rounded-full h-96 w-96 opacity-20"></div>
                    <div className="absolute top-0 z-0 transform scale-x-125 -translate-x-24 -translate-y-20 bg-indigo-700 rounded-full h-96 w-96 opacity-20"></div>
                </div>


            </Fragment>
        )
    }
}
