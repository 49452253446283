import React, { Component,Fragment } from "react";
import NumberFormat from "react-number-format";

export default class ProgressBar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			completed: 0,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { bgcolor, completed } = this.state;
		if (prevProps.played !== this.props.played) {
			this.setState((prevState, props) => ({
				...prevState,
				completed: this.props.played,
			}));
		}
	}

	render() {
		// const { bgcolor, completed } = this.props;
		const { bgcolor, completed } = this.state;
		const {  playedSeconds } = this.props;

		// const containerStyles = {
		//     height: 20,
		//     width: '100%',
		//     backgroundColor: "#e0e0de",
		//     borderRadius: 50,
		//     margin: 50,
		//   }

		//   const fillerStyles = {
		//     height: '100%',
		//     width: `${this.state.completed}%`,
		//     backgroundColor: 'blue',
		//     transition: 'width 1s ease-in-out',
		//     borderRadius: 'inherit',
		//     textAlign: 'right',
		//   }

		// const labelStyles = {
		// 	padding: 5,
		// 	color: "white",
		// 	fontWeight: "bold",
		// };

		return (
        <Fragment>

                            <div className="flex h-2 flex-row-reverse self-stretch items-center w-full bg-transparent">
                                {/* <div className="flex-1 font-montserrat text-xs font-semibold  text-bluegray-700 ml-1 ">{this.state.completed && this.state.completed.toFixed()}%</div> */}
                                {/* <div className="flex-1 font-montserrat text-xs font-semibold  text-bluegray-700 ml-1 ">{playedSeconds && playedSeconds.toFixed()} </div> */}
                                <div className={`self-stretch  bg-transparent  `} style={{ width: `${this.state.completed}%`, textAlign: "right", borderRadius: "inherit" }}>


                                    {/* <span style={labelStyles}>{`${completed}%`}</span> */}
                                    {/* <span style={labelStyles}>{this.state.completed}</span> */}
                                </div>
                            </div>

        </Fragment>
		);
	}
}
