import React, { Component, Fragment } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BrowserRouter as Router, Switch, Route, Link, NavLink, withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import  axios  from 'axios';

const LoginValidationSchema = Yup.object().shape({
	email: Yup.string().min(3, "Username must be 3 characters at minimum!").max(60, "Username can not be longer than 60 characters!").required("Username is required!"),
	password: Yup.string().min(3, "Password must be 3 characters at minimum!").max(30, "Password can not be longer than 30 characters!").required("Password is required!"),
});

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Email: "",
			Password: "",

			isLoggingIn: false,

			isErrored: "",
			errorMessage: "",
		};
	}


	 btnHandlerLogin = async() => {
		const { Email, Password, isLoggingIn, isErrored, errorMessage } = this.state;
		console.log("btnHandlerLogin")

		this.setState((prevState, props) => ({
			...prevState,
			isLoggingIn: true,
			isErrored: false,
			errorMessage: ""
		  }));


		try {
			 Auth.signIn(Email, Password)
			 .then( async( success) => {
				 

				console.log('successful sign in');


				// const { idToken: { jwtToken } } = await Auth.currentSession();
				// console.log('idToken jwtToken ', JSON.stringify(jwtToken, null, 2));
		
				// axios.interceptors.request.use(req => {
				// 	console.log(`${req.method} ${req.url}`);
				// 	if (jwtToken) {
				// 		req.headers.authorization = jwtToken;
				// 	}
				// 	return req;
				//   });

				this.setState((prevState, props) => ({
					...prevState,
					isLoggingIn: false,
					isErrored: false,
					errorMessage: ""
				  }));


				this.props.history.push({
					pathname: "/portal/studio",
					state: {
					email: Email,
					}
				});


			})
			 .catch(err => {
				this.setState((prevState, props) => ({
				  ...prevState,
				  isLoggingIn: false,
				  isErrored: true,
				  errorMessage: "You have entered an invalid username or password"
				}));
				console.log("err.message", err.message);
			  });
			// alert("Logged in");
		  } catch (e) {
			alert(e.message);
		  }

	}

	render() {
		const { Email, Password, isLoggingIn, isErrored, errorMessage } = this.state;
		const { location } = this.props;
		return (
			<Fragment>
				<React.Fragment>
					<Formik
						initialValues={{ email: "b1@buppel.com" || "", password: "Password01" }}
						validationSchema={LoginValidationSchema}
						onSubmit={(values, { setSubmitting }) => {
							this.setState((prevState, props) => ({
								...prevState,
								Email: values.email,
								Password: values.password,
							}));

							this.btnHandlerLogin();
							// setSubmitting(false);
						}}
					>
						{({ touched, errors, isSubmitting, values }) => (
							<Form className="self-stretch flex flex-col justify-center items-center min-h-screen ">
								{/* LOGIN PANEL */}
								<div className="relative flex flex-col justify-start items-center bg-white shadow-md rounded w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/3  border-solid border-t-4 border-purple-500">
									<div className="absolute w-full h-12  border-b border-gray-400 bg-gray-100 opacity-50" ></div>
									<div className="px-6 pb-6 h-full w-full z-10">
										<div className="mb-2 flex flex-row justify-between items-center h-12 ">
											<div className="font-semibold text-gray-700  text-lg ml-2 font-Inter  ">Login</div>
											{/* <svg className="fill-current w-7 h-7 text-white bg-purple-500  rounded-full " style={{ padding: "0.2rem" }} viewBox="0 0 393 424" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M296.722 55.9049H282.588C263.831 55.8972 245.619 62.2129 230.894 73.8319C223.678 79.5197 217.449 86.3589 212.459 94.0739C207.336 102 203.596 110.738 201.397 119.916L159.18 295.821C157.863 301.326 155.621 306.568 152.548 311.322C148.043 318.281 141.886 324.016 134.627 328.018C127.228 332.099 118.914 334.236 110.464 334.227H96.3297C87.1077 334.227 79.6307 341.704 79.6307 350.926C79.6307 360.148 87.1077 367.625 96.3297 367.625H110.464C129.221 367.633 147.433 361.317 162.158 349.698C169.374 344.01 175.603 337.171 180.593 329.456C185.715 321.53 189.456 312.792 191.655 303.614L233.872 127.709C235.188 122.204 237.431 116.962 240.504 112.208C245.009 105.249 251.165 99.5135 258.425 95.5119C265.823 91.4297 274.138 89.2933 282.588 89.3029H296.722C305.944 89.3029 313.421 81.8259 313.421 72.6039C313.421 63.3819 305.945 55.9049 296.722 55.9049Z"
													fill="white"
												/>
											</svg> */}
										</div>

										<div className="">
											{isErrored ? (
											<div className="flex flex-row items-center justify-start font-xs font-Inter font-semibold text-purple-500 mb-4">
												{errorMessage}
											</div>
											) : (
												<div className="mb-4"></div>
											)}
										</div>

										<div className="flex flex-row justify-between align-center ">
											<div className="mb-6 ml-2 flex-grow flex-col ">
												<label className="block mb-1 font-Inter text-gray-800  font-semibold text-smaller">Email</label>

												<Field
													name="email"
													placeholder=""
													autoComplete="off"
													className={` appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                              ${touched.email && errors.email ? "text-purple-500 border-purple-500" : ""}`}
												/>
												<ErrorMessage component="div" name="email" className="text-purple-600 font-Inter font-medium text-xs tracking-wide ml-1" />
											</div>
										</div>

										<div className="flex flex-row justify-between align-center ">
											<div className="mb-6 ml-2 flex-grow flex-col ">
												<label className="block mb-1 font-Inter text-gray-800  font-semibold text-smaller">Password</label>

												<Field
													name="password"
													type="password"
													placeholder=""
													autoComplete="off"
													className={` appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
                              ${touched.password && errors.password ? "text-purple-500 border-purple-500" : ""}`}
												/>
												<ErrorMessage component="div" name="password" className="text-purple-600 font-Inter font-medium text-xs tracking-wide ml-1" />
											</div>
										</div>

										<div className="flex  items-center justify-between mt-4">
											<NavLink to={`/`} className="">
												<button className="bg-transparent border text-indigo-700 ml-2 text-Theme05-500 py-2 px-2 rounded font-bold font-Inter text-sm" onClick={this.btnHandlerCancel} type="button">
													Cancel
												</button>
											</NavLink>

											<button
												className="ml-2 bg-indigo-500  text-white py-2 px-4 rounded font-medium font-Inter  text-sm focus:outline-none"
												// onClick={this.btnHandlerLogin}
												type="submit"
											>
												<div className="self-stretch flex flex-row  items-center ">
														{isLoggingIn ? 
															<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
															<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
															<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
														</svg> : 
														''
														}
														Login</div>
												
											</button>
										</div>
									</div>
								</div>


                                <div className="flex flex-row justify-between items-center w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/3 ">
									<NavLink to={`/forgot`} className="no-underline font-bold text-sm text-gray-500 hover:text-gray-700   mt-4 ml-2 font-raleway">
										Forgot Password?
									</NavLink>

									{/* <NavLink to={`/signup`} className="no-underline  font-bold text-sm  text-gray-500 hover:text-gray-700   mt-4 mr-2 font-raleway">
                                        Sign Up
									</NavLink> */}
								</div>

								{/* <div className="mt-8 mb-2 p-2 border border-solid border-gray-400">
                                            <strong>Injected Formik props (the form's state)</strong>
                                            <div>
                                            <code>errors:</code> {JSON.stringify(errors, null, 2)}
                                            </div>
                                            <div>
                                            <code>values:</code> {JSON.stringify(values, null, 2)}
                                            </div>
                                            <div>
                                            <code>isSubmitting:</code>{" "}
                                            {JSON.stringify(isSubmitting, null, 2)}
                                            </div>
                                    </div> */}
							</Form>
						)}
					</Formik>
				</React.Fragment>
			</Fragment>
		);
	}
}
export default withRouter(Login);