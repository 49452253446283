import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, NavLink, withRouter } from "react-router-dom";

export default class PortalTopHeader extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
            // sticky top-0
			<div className="sticky top-0 h-12" style={{ backgroundColor: "#edf2f7" }}>
				<div className="mx-auto px-4 sm:px-6 container">
					<div className="flex flex-row self-stretch justify-between items-center md:justify-start md:space-x-10">
						<div className="flex justify-start lg:w-0 lg:flex-1 ">
							<Link to="/portal/discover">
								<span className="ml-3  font-MonAlt  font-extrabold tracking-tighter text-gray-700" style={{ fontSize: "1.745rem" }}>
									walkthru
								</span>
							</Link>
						</div>
						PortalTopHeader
					</div>
				</div>
			</div>
		);
	}
}
