import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, NavLink, withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import MenuMobile from "./dev/MenuMobile";
import { Menu } from "@szhsin/react-menu";
import { MenuItem } from "@szhsin/react-menu";

class PortalHeaderNextGen extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showMenuMobile: false,
			preferredUsername: "",
			email: "",
		};
	}

	componentDidMount() {
		this.initilizeData();
	}

	initilizeData = async (event) => {
		// const { idToken: { jwtToken } } = await Auth.currentSession();
		const usersession = await Auth.currentSession();
		const { idToken: { payload: { preferred_username = "", email = "" } = {} } = {} } = await Auth.currentSession();
		this.setState((prevState, props) => ({
			...prevState,
			preferredUsername: preferred_username,
			email: email,
		}));
		console.log("preferred_username ", JSON.stringify(preferred_username, null, 2));
		console.log("email ", JSON.stringify(email, null, 2));
	};

	handleSignOut = (event) => {
		// event.preventDefault();
		Auth.signOut().then((result) => {
			this.props.history.push({
				pathname: "/",
			});
		});
	};

	toggleMenuMobile = () => {
		const { showMenuMobile } = this.state;
		this.setState((prevState, props) => ({
			...prevState,
			showMenuMobile: !prevState.showMenuMobile,
		}));
		// console.log("LandingTemp toggleMenuMobile new showMenuMobile ", showMenuMobile);
	};

	// https://codedaily.io/tutorials/63/Create-a-Dropdown-in-React-that-Closes-When-the-Body-is-Clicked
	handleClickOutsideforMobileMenu = (event) => {
		const { showMenuMobile } = this.state;
		this.setState((prevState, props) => ({
			...prevState,
			showMenuMobile: false,
		}));
		// console.log("LandingTemp handleClickOutsideforMobileMenu showMenuMobile ", showMenuMobile);
	};

	render() {
		const { showMenuMobile, preferredUsername, email } = this.state;
		return (
			<Fragment>
				<div className="relative flex-none border-b-2 border-bluegray-200 " style={{ backgroundColor: "#edf2f7" }}>
					<div className="mx-auto px-4 sm:px-6 container">
						<div className="flex flex-row self-stretch justify-between items-center md:justify-start md:space-x-10">
							<div className="flex justify-start lg:w-0 lg:flex-1 ">
								<Link to="/portal/discover">
									<span className="ml-3  font-MonAlt  font-extrabold tracking-tighter text-gray-700" style={{ fontSize: "1.745rem" }}>
										walkthru
									</span>
								</Link>
							</div>
							<div className="-mr-2 -my-2 md:hidden ">
								<button type="button" onClick={this.toggleMenuMobile} className="rounded-md p-2  inline-flex items-center justify-center text-bluegray-500 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
									<span className="sr-only">Open menu</span>
									{/* Heroicon name: menu */}
									<svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
									</svg>
								</button>
							</div>
							<nav className="hidden md:flex space-x-2 self-stretch ">
								{/* <NavLink to={`${path}/studio`} style={{ textDecoration: "none" }} activeClassName="border-indigo-500  text-gray-800  no-underline text-bluegray-700 " className="border-bluegray-300  block px-4 align-middle text-gray-500 font-semibold no-underline  hover:text-indigo-500 border-b-2 border-transparent leading-tight py-2    tracking-wide"> */}
								<NavLink to="/portal/studio" style={{ textDecoration: "none" }} activeClassName="border-indigo-500  text-gray-600 no-underline  text-bluegray-700 " className=" block px-4 py-3  align-middle text-gray-500 font-semibold no-underline  hover:text-indigo-500 border-b-2 border-transparent leading-tight   tracking-wide">
									<span className="">My Walkthrus</span>
								</NavLink>
								<NavLink exact to="/portal/discover" style={{ textDecoration: "none" }} activeClassName="border-indigo-500  text-gray-600 no-underline  text-bluegray-700 " className="self-stretch py-3   block px-4 align-middle text-gray-500 font-semibold no-underline  hover:text-indigo-500 border-b-2 border-transparent leading-tight  tracking-wide">
									{/* <svg className="inline w-5 h-5" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
											<path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
										</svg> */}
									<span className="pl-3">Discover</span>
								</NavLink>
								{/* <a href="#" className="absolute top-0 left-0 hidden  ml-10 mx-2 text-gray-600 lg:inline-block md:mt-0 md:ml-2 lg:mx-3 md:relative">
                                    <svg className="inline w-5 h-5" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
									<path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                    </svg>
                                </a> */}
							</nav>
							<div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
								{/* <Link to="/login">
									<div className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">Sign in</div>
								</Link>
								<a href="#" className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                                Get early access
								</a> */}

								{/* <div className="flex flex-col items-start justify-center self-stretch">
	                                <span className="font-Inter text-2xs font-semibold text-purple-700">{preferredUsername}</span>
	                                <span className="font-Inter text-2xs font-base text-purple-700">{email}</span>
</div> */}

								{/* Heroicon name: menu */}
								{/* <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
									</svg> */}

								<Menu
									arrow={true}
									arrowClassName="ml-4"
									animation={true}
									menuButton={
										<div className="flex flex-row items-center self-stretch">
											<div className="pt-0.5 pr-1 flex flex-col items-start justify-center self-stretch ">
												<span className="font-Inter text-2xs font-medium text-indigo-600 leading-none text-right self-stretch tracking-wide" style={{ fontSize: "0.625rem" }}>
													{preferredUsername}
												</span>
												<span className="font-Inter text-2xs font-medium text-indigo-600 leading-none text-right self-stretch tracking-wide" style={{ fontSize: "0.625rem" }}>
													{email}
												</span>
											</div>
											<svg className="pt-0.5 h-7 w-7 text-indigo-600  opacity-100" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
												<path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
												<circle cx="12" cy="7" r="4" />
											</svg>

											<span className=" group self-stretch  hover:bg-bluegray-50  group-hover:text-bluegray-600 focus:text-bluegray-600 focus:outline-none text-bluegray-500 font-Inter font-base text-sm  inline-flex items-center cursor-pointer">
												{/* <span className="text-base font-Inter font-medium">Draft</span> */}
												<svg className="mr-2 h-4 text-indigo-600 opacity-90" viewBox="0 0 20 20" fill="currentColor">
													<path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
												</svg>
											</span>
										</div>
									}
								>
									{/* <MenuItem onClick={this.handlePageWidthChange} value={""}> */}
									<MenuItem onClick={this.handleSignOut} value={""}>
										<div className="self-stretch  flex flex-row items-center ">
											{/* {true ? (
												<svg className=" -ml-4  mr-2 h-5 text-green-600 opacity-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
													<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5 13l4 4L19 7" />
												</svg>
											) : (
												<div className="pl-3"></div>
											)} */}
											<span className="text-sm font-Inter font-semibold text-indigo-600">Sign Out</span>
										</div>
									</MenuItem>
								</Menu>
							</div>
						</div>
					</div>

					<MenuMobile showMenuMobile={showMenuMobile} toggleMenuMobile={this.toggleMenuMobile} handleClickOutsideforMobileMenu={this.handleClickOutsideforMobileMenu} />
				</div>
			</Fragment>
		);
	}
}

export default withRouter(PortalHeaderNextGen);
