import React, { Component } from 'react'
import { Fragment } from 'react';

export default class FRow extends Component {
    render() {
        return (
            <Fragment>
                <div className="flex flex-row justify-start items-center flex-wrap self-stretch       bg-gray-300 h-32 my-10 mx-4">
                    <div className="                 flex flex-row justify-items-center items-center        rounded-md bg-cyan-500 m-1 ">  <div className="h-4 w-4 bg-gray-400 rounded-sm m-1" ></div> <div className="h-4 w-4 bg-gray-500 rounded-sm m-1" ></div> </div>
                    <div className="                 flex flex-row justify-items-center items-center        rounded-md bg-cyan-500 m-1 ">  <div className="h-4 w-4 bg-gray-400 rounded-sm m-1" ></div> <div className="h-4 w-4 bg-gray-500 rounded-sm m-1" ></div> </div>
                    <div className="                 flex flex-row justify-items-center items-center        rounded-md bg-cyan-500 m-1 ">  <div className="h-4 w-4 bg-gray-400 rounded-sm m-1" ></div> <div className="h-4 w-4 bg-gray-500 rounded-sm m-1" ></div> </div>
                    <div className="                 flex flex-row justify-items-center items-center        rounded-md bg-cyan-500 m-1 ">  <div className="h-4 w-4 bg-gray-400 rounded-sm m-1" ></div> <div className="h-4 w-4 bg-gray-500 rounded-sm m-1" ></div> </div>
                    <div className="                 flex flex-row justify-items-center items-center        rounded-md bg-cyan-500 m-1 ">  <div className="h-4 w-4 bg-gray-400 rounded-sm m-1" ></div> <div className="h-4 w-4 bg-gray-500 rounded-sm m-1" ></div> </div>
                    <div className="                 flex flex-row justify-items-center items-center        rounded-md bg-cyan-500 m-1 ">  <div className="h-4 w-4 bg-gray-400 rounded-sm m-1" ></div> <div className="h-4 w-4 bg-gray-500 rounded-sm m-1" ></div> </div>
                    <div className="                 flex flex-row justify-items-center items-center        rounded-md bg-cyan-500 m-1 ">  <div className="h-4 w-4 bg-gray-400 rounded-sm m-1" ></div> <div className="h-4 w-4 bg-gray-500 rounded-sm m-1" ></div> </div>
                    <div className="                 flex flex-row justify-items-center items-center        rounded-md bg-cyan-500 m-1 ">  <div className="h-4 w-4 bg-gray-400 rounded-sm m-1" ></div> <div className="h-4 w-4 bg-gray-500 rounded-sm m-1" ></div> </div>
                    <div className=" self-stretch    flex flex-row justify-items-center items-center        rounded-md bg-cyan-500 m-1 ">  <div className="h-4 w-4 bg-gray-400 rounded-sm m-1" ></div> <div className="h-4 w-4 bg-gray-500 rounded-sm m-1" ></div> </div>
                </div>
            </Fragment>
        )
    }
}
