import React, { Component } from "react";
import { Fragment } from "react";
import Header from "./sections/Header";
import FRow from "./sections/FRow";
import FCol from "./sections/FCol";
import Fonts from "./sections/Fonts";
import ReactPlayer from "react-player";

export default class UI extends Component {
	render() {
		return (
			<Fragment>
				<div className="flex flex-row space-x-2">
					<span>
						<svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full" viewBox="0 0 24 24">
							<path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
						</svg>
					</span>
					<span>
					<svg className="h-4 w-4 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                                                    </svg>
					</span>

					<span>
						<svg className="fill-current w-10 h-10 text-white  bg-indigo-500 rounded-full" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 512 512">
							<path d="M322.797 72.308h-14.134a83.396 83.396 0 00-51.694 17.927 83.555 83.555 0 00-18.435 20.242 83.424 83.424 0 00-11.062 25.842l-42.217 175.905a49.962 49.962 0 01-6.632 15.501 50.239 50.239 0 01-17.921 16.696 49.92 49.92 0 01-24.163 6.209h-14.134c-9.222 0-16.699 7.477-16.699 16.699 0 9.222 7.477 16.699 16.699 16.699h14.134a83.396 83.396 0 0051.694-17.927 83.536 83.536 0 0018.435-20.242 83.424 83.424 0 0011.062-25.842l42.217-175.905a49.962 49.962 0 016.632-15.501 50.239 50.239 0 0117.921-16.696 49.899 49.899 0 0124.163-6.209h14.134c9.222 0 16.699-7.477 16.699-16.699 0-9.222-7.476-16.699-16.699-16.699zM324.148 333.929l21.59-21.59c6.522-6.522 6.522-17.094 0-23.616-6.521-6.521-17.094-6.521-23.616 0l-21.59 21.59-21.59-21.59c-6.522-6.522-17.094-6.522-23.616 0-6.522 6.522-6.522 17.095 0 23.616l21.59 21.59-21.59 21.59c-6.522 6.522-6.522 17.094 0 23.616 6.522 6.522 17.095 6.522 23.616 0l21.59-21.59 21.59 21.59c6.522 6.522 17.095 6.522 23.616 0 6.522-6.522 6.522-17.095 0-23.616l-21.59-21.59z" />
						</svg>
					</span>

					<span>
						<svg className="fill-current w-10 h-10 text-white  bg-indigo-500 rounded-full p-1" viewBox="0 0 393 424" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M296.722 55.9049H282.588C263.831 55.8972 245.619 62.2129 230.894 73.8319C223.678 79.5197 217.449 86.3589 212.459 94.0739C207.336 102 203.596 110.738 201.397 119.916L159.18 295.821C157.863 301.326 155.621 306.568 152.548 311.322C148.043 318.281 141.886 324.016 134.627 328.018C127.228 332.099 118.914 334.236 110.464 334.227H96.3297C87.1077 334.227 79.6307 341.704 79.6307 350.926C79.6307 360.148 87.1077 367.625 96.3297 367.625H110.464C129.221 367.633 147.433 361.317 162.158 349.698C169.374 344.01 175.603 337.171 180.593 329.456C185.715 321.53 189.456 312.792 191.655 303.614L233.872 127.709C235.188 122.204 237.431 116.962 240.504 112.208C245.009 105.249 251.165 99.5135 258.425 95.5119C265.823 91.4297 274.138 89.2933 282.588 89.3029H296.722C305.944 89.3029 313.421 81.8259 313.421 72.6039C313.421 63.3819 305.945 55.9049 296.722 55.9049Z"
								fill="white"
							/>
						</svg>
					</span>


					<button className="flex items-center justify-center shadow-sm h-10 w-10 bg-red-500 text-white rounded-full">
							<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" >
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
							</svg>
					</button>

					<button onClick={this.handleShowAddStepModal} disabled={false} className="disabled:opacity-50  focus:outline-none w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-3 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-50  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
						<span className="self-stretch flex flex-row  items-center ">
							<svg className="h-5 w-5 fill-current text-bluegray-600" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
							</svg>
						</span>

						<span className="self-stretch font-Inter font-base text-sm text-gray-700 ">Add</span>
					</button>

					

					


					<div className="flex items-center justify-center shadow-sm h-5 w-5 bg-green-500 text-white rounded-full">
							<svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" >
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
							</svg>
					</div>


					<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
							<svg
								className="h-6 w-6 text-red-600"
								stroke="currentColor"
								fill="none"
								viewBox="0 0 24 24"
							>
								<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
								/>
							</svg>
							</div>

					
				</div>

				<Header />
				<FRow />
				<FCol />

				<Fonts />


				                {/* BURDAKI YOUTUBE RESPONSIVE */}
								<div className="" style={{position:"relative", paddingBottom: "56.25%", height: 0, overflow: "hidden"   }}>
                                <ReactPlayer style={{position:"absolute", width: "100%", height:"100%", left: "0", top: "0"   }}
                                url='https://www.youtube.com/watch?v=3hLmDS179YE'
                                width='100%'
                                height='100%'
                                config={{
                                    youtube: {
                                    playerVars: { start: 1230, end : 1240, modestbranding:1, controls:1 }
                                    }
                                }}
                                
                                />
                </div>

				
			</Fragment>
		);
	}
}
