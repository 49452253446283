import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link, NavLink, withRouter } from "react-router-dom";
import { Auth } from 'aws-amplify';
class MenuMobile extends Component {
	constructor(props) {
		super(props);
		this.refDiv = React.createRef();

		this.state = {
			givenName: "",
			familyName: "",
			email: "",
		};
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
		this.loadUserDetails();


	}
	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}

	loadUserDetails = async () => {
		try {
			const {
				idToken: {
					payload: { given_name = "", family_name = "", email = "" },
				},
			} = await Auth.currentSession();
			this.setState((prevState, props) => ({
				...prevState,
				givenName: given_name,
				familyName: family_name,
				email: email,
			}));
		} catch(e) {
			console.log(e);
		}
	};


	handleSignOut = (event) => {
		event.preventDefault();
		Auth.signOut().then((result) => {
			this.props.history.push({
				pathname: "/",
			});
		});
    };


	// https://codedaily.io/tutorials/63/Create-a-Dropdown-in-React-that-Closes-When-the-Body-is-Clicked
	handleClickOutside = (event) => {
		const { toggleMenuMobile } = this.props;
		if (this.refDiv && this.refDiv.current && !this.refDiv.current.contains(event.target)) {
			// console.log("MenuMobile handleClickOutside outside ", this.refDiv);
			this.props.handleClickOutsideforMobileMenu();
			return;
		}
		// console.log("MenuMobile handleClickOutside inside ", this.refDiv);
	};

	render() {
		const { showMenuMobile: showMenu, toggleMenuMobile } = this.props;
		const { email } = this.state;
		return (
			<Fragment>
				{/*   
                        Mobile menu, show/hide based on mobile menu state.

                        Entering: "duration-200 ease-out"
                        From: "opacity-0 scale-95"
                        To: "opacity-100 scale-100"
                        Leaving: "duration-100 ease-in"
                        From: "opacity-100 scale-100"
                        To: "opacity-0 scale-95"
                    */}
				<div ref={this.refDiv} className={`absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50 ${showMenu ? "" : "hidden"}  `}>
					<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
						<div className="pt-5 pb-6 px-5">
							<div className="flex items-center justify-between">
								<div>
								<Link to="/portal/discover">
									<span className="ml-3  font-MonAlt  font-extrabold tracking-tighter text-gray-700" style={{fontSize: "1.745rem"}}>walkthru</span>
							</Link>
								</div>
								<div className="-mr-2">
									<button type="button" onClick={toggleMenuMobile} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
										<span className="sr-only">Close menu</span>
										{/* Heroicon name: x  */}
										<svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
										</svg>
									</button>
								</div>
							</div>
							{/* <div className="mt-6">
								<nav className="grid gap-y-8">
									<a href="#" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
										<svg className="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
										</svg>
										<span className="ml-3 text-base font-medium text-gray-900">Analytics</span>
									</a>

									<a href="#" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
										<svg className="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
										</svg>
										<span className="ml-3 text-base font-medium text-gray-900">Engagement</span>
									</a>

									<a href="#" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
										<svg className="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
										</svg>
										<span className="ml-3 text-base font-medium text-gray-900">Security</span>
									</a>

									<a href="#" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
										<svg className="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
										</svg>
										<span className="ml-3 text-base font-medium text-gray-900">Integrations</span>
									</a>

									<a href="#" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
										<svg className="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
										</svg>
										<span className="ml-3 text-base font-medium text-gray-900">Automations</span>
									</a>
								</nav>
							</div> */}
						</div>
						<div className="py-6 px-5 space-y-6  flex flex-col items-center self-stretch">
							{/* <div className="self-stretch  grid grid-cols-2 gap-y-4 gap-x-8">
								<a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
									Pricing
								</a>

								<a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
									Docs
								</a>

								<a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
									Enterprise
								</a>

								<a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
									Blog
								</a>

								<a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
									Help Center
								</a>

								<a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
									Guides
								</a>

								<a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
									Security
								</a>

								<a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
									Events
								</a>
							</div> */}

							{ email ? 

								
(								
<Fragment>
	<div className="px-2 self-stretch font-Inter text-base font-base">
		Logged in as <span className="font-bold">{email}</span>
	</div>
	<button type="button" onClick={this.handleSignOut} className="p-2 self-stretch whitespace-nowrap inline-flex items-center justify-center  border border-transparent rounded-md shadow-sm text-smaller font-semibold text-white bg-indigo-600 hover:bg-indigo-600">
										<span className="sr-only">Sign Out</span>
										Sign Out
									</button>
</Fragment>
								)
						
							: 
							<div>
								<a href="#" className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
									Get Early Access
								</a>
								<div className="mt-6 text-center text-base font-medium text-gray-500">
									{/* Existing customer? */}
									Got Invitation?
									{/* <a href="#" className="text-indigo-600 hover:text-indigo-500">
											Sign in
										</a> */}
									<Link to="/login">
										<div className="whitespace-nowrap text-base font-medium text-indigo-600 hover:text-indigo-500">Sign in</div>
									</Link>
								</div>
							</div>
							}
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
export default withRouter(MenuMobile);