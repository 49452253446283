import React, { Component, Fragment } from 'react'
import ReactPlayer from "react-player";


export default class Utube extends Component {
    render() {
        return (
            <Fragment>
                										{/* BURDAKI YOUTUBE RESPONSIVE */}
															<div className="" style={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden" }}>
																<ReactPlayer
																	style={{ position: "absolute", width: "100%", height: "100%", left: "0", top: "0" }}
																	// url="https://www.youtube.com/watch?v=3hLmDS179YE"
																	url="hhttps://www.youtube.com/watch?v=a_kVafB-0C4"
																	width="100%"
																	height="100%"
																	config={{
																		youtube: {
																			playerVars: { start: 1230, end: 1240, modestbranding: 1, controls: 1 },
																		},
																	}}
																/>
															</div>
            </Fragment>
        )
    }
}
