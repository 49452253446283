import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, NavLink, withRouter } from "react-router-dom";
import ShowTheLocation from "utilities/ShowTheLocation";
import { Menu } from "@szhsin/react-menu";
import { MenuItem } from "@szhsin/react-menu";

class SubHeader extends Component {
	render() {
		const { path, url } = this.props.match;
		return (
			<Fragment>
				<section className="flex flex-col items-center flex-none  border-b-2 border-gray-200 " style={{ backgroundColor: "#e2e8f0" }}>
					<div className="flex flex-col-reverse items-center flex-none  mx-auto container ">
						<div className=" pl-4  flex flex-row items-center  justify-between   self-stretch no-underline  flex-grow-0  pt-1  ">
							<div className="flex flex-row items-center self-stretch font-Inter text-base">
								<NavLink exact to="/portal/discover" style={{ textDecoration: "none" }} activeClassName="border-indigo-500  text-gray-600 no-underline  text-bluegray-700 " className="block px-4 align-middle text-gray-500 font-semibold no-underline  hover:text-indigo-500 border-b-2 border-transparent leading-tight py-2  tracking-wide">
									<svg className="inline w-5 h-5" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
										<path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
									</svg>
									<span className="pl-3">Discover</span>
								</NavLink>

								{/* <NavLink to={`${path}/studio`} style={{ textDecoration: "none" }} activeClassName="border-indigo-500  text-gray-800  no-underline text-bluegray-700 " className="border-bluegray-300  block px-4 align-middle text-gray-500 font-semibold no-underline  hover:text-indigo-500 border-b-2 border-transparent leading-tight py-2    tracking-wide"> */}
								<NavLink to="/portal/studio" style={{ textDecoration: "none" }} activeClassName="border-indigo-500  text-gray-600 no-underline  text-bluegray-700 " className="block px-4 align-middle text-gray-500 font-semibold no-underline  hover:text-indigo-500 border-b-2 border-transparent leading-tight py-2  tracking-wide">
									<span className="">My Walkthrus</span>
								</NavLink>
								{/* <ShowTheLocation/> */}
							</div>
							<div className="flex flex-row items-center self-stretch font-Inter text-base ">
								<svg className="text-bluegray-500 w-5 h-5 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
									/>
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
								</svg>
								Settings
							</div>


						</div>

						<div className="  flex flex-row items-center self-stretch flex-grow"></div>
					</div>
				</section>
			</Fragment>
		);
	}
}

export default withRouter(SubHeader);
